import { skills } from "redux/constants";

const skillsErrorReducer = (state = {}, action) => {
  switch (action.type) {
    case skills.FETCH_SKILL_FAILURE:
      return action.error;
    case skills.FETCH_SKILL:
    case skills.FETCH_SKILL_SUCCESS:
      return null;
    default:
      return state;
  }
};

export default skillsErrorReducer;
