import { profileCompletion } from "redux/constants";

const profileCompletionVerbiageReducer = (state = [], action) => {
  switch (action.type) {
    case profileCompletion.FETCH_PROFILE_COMPLETION_VERBIAGE_SUCCESS: {
      return [...action.data];
    }
    default:
      return state;
  }
};

export default profileCompletionVerbiageReducer;
