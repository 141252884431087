import { linkedIn } from "redux/constants";

const linkedInUserStatusLoadingReducer = (state = false, action) => {
  switch (action.type) {
    case linkedIn.FETCH_USER_LINKEDIN_STATUS:
      return true;
    case linkedIn.FETCH_USER_LINKEDIN_STATUS_SUCCESS:
    case linkedIn.FETCH_USER_LINKEDIN_STATUS_FAILURE:
      return false;
    default:
      return state;
  }
};

export default linkedInUserStatusLoadingReducer;
