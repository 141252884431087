import { takeLatest } from "redux-saga/effects";

import { editProfile } from "redux/constants";
import handleUpdateProposal from "../worker/handleUpdateProposal";

function* watchUpdateProposal() {
  yield takeLatest(editProfile.UPDATE_PROPOSAL, handleUpdateProposal);
}

export default watchUpdateProposal;
