const TRIGGERS = {
  COMMON: {
    EDIT: "Edit",
    VIEW_ALL: "View All",
    DOWNLOAD: "Download",
    PAGE_LOAD: "Page_Load",
    HIDE: "Hide",
    DELETE: "Delete",
    SAVE: "Save",
    CANCEL: "Cancel",
    NO: "No",
    YES: "Yes"
  },
  PROFILE_SUMMARY: {
    SHOW_MORE_SUMMARY: "Show More_Summary",
    SHOW_LESS_SUMMARY: "Show Less_Summary",
    SHOW_MORE_PROPOSAL_BIO: "Show More_Proposal Bio/Resume",
    SHOW_LESS_PROPOSAL_BIO: "Show Less_Proposal Bio/Resume",
    RESUME: "Resume",
    BIO: "Bio",
    SAVE: "Save",
    UPLOAD: "Upload"
  },
  BANNER: {
    PHONE: "Phone",
    SETTINGS: "Settings",
    KEY_TO_PUPOSE: "Key to Purpose",
    ASSISTANT: "Assistant",
    EMAIL: "Email",
    SLACK: "Slack",
    PHONE_COPY: "Copy Number",
    EMAIL_COPY: "Copy Email",
    EMAIL_CLICK: "Email Click",
    LOCATION: "Location Click",
    ASSISTANT_CLICK: "Assistant Profile Name Click",
    PROFILE_IMAGE: "Profile Image",
    FACE_CARD_GENERATOR: "Face Card Generator"
  },
  BANNER_SETTINGS: {
    VIEW_ADDITIONAL_SYSTEM_FIELDS: "Settings_View Additional System Fields",
    VIEW_PROFILE_UPDATE_HISTORY: "Settings_View Profile Update History",
    IMPERSONATE_USER: "Settings_Impersonate User",
    CHANGE_USER_ACCESS_ROLE: "Settings_Change User's Access Role",
    REINSTATE_USER_ACCESS_IP_GUARDIAN: "Settings_Reinstate User Access ( IP Guardian )"
  },
  EXPERTISE: {
    MANAGE_EXPERTISE_SUMMARY: "Manage Expertise Summary",
    HOW_EXPERTISE_IS_CALCULATED: "How expertise is calculated",
    VIEW_ALL_EXPERTISE: "View All Expertise"
  },
  CASE: {
    OPEN_CASE_PAGE: "Open Case Page",
    COPY_CASE_NUMBER: "Copy Case Number",
    TAXONOMY_CLICK: "Taxonomy Click",
    EXPAND: "Expand",
    CASE_DESC: "Case Description",
    CASE_VIG: "Case Vignettes",
    CASE_TEAM: "Case Team",
    VIEW_PROFILE: "View Profile",
    ASSISTANT_PROFILE: "Assistant Profile",
    COPY_PROF_URL: "Copy Profile URL",
    EMAIL: "Email",
    SLACK: "Slack",
    COLLAPSE: "Collapse"
  },
  VIGNETTE: {
    BULK_EMAIL: "BULK_EMAIL",
    BULK_DOWNLOAD: "Bulk Download",
    SHARE_VIA_EMAIL: "Share via Email",
    COPY_TO_VIG: "Copy Link to Vignette",
    COPY_TO_CASE_PAGE: "Copy Link to Case Page"
  },
  MATERIAL: {
    OPEN_MATERIAL_PAGE: "Open Knowledge Page",
    TAXONOMY_CLICK: "Taxonomy Click",
    EXPAND: "Expand",
    COLLAPSE: "Collapse",
    PIN: "Pin",
    UNPIN: "Unpin",
    PREVIEW: "Preview",
    DOWNLOAD: "Download",
    SHARE_VIA_EMAIL: "Share via Email",
    COPY_LINK_TO_KNOWLEDGE_PAGE: "Copy Link to Knowledge Page",
    COPY_LINK_TO_ATTACHMENT: "Copy Link to Attachment",
    DESCRIPTION: "Description",
    ASSOCIATED_BUNDLES: "Associated Bundles",
    AUTHORS: "Authors",
    VIEW_BUNDLE_PAGE: "View Bundle Page",
    COPY_BUNDLE_URL: "Copy Bundle URL",
    VIEW_PROFILE: "View Profile",
    ASSISTANT_PROFILE: "Assistant Profile",
    COPY_PROFILE_URL: "Copy Profile URL"
  },
  AFFILIATIONS: {
    PROFICIENCY: "Proficiency",
    ROLES: "Roles",
    AFFILIATION: "Affiliation"
  },
  PIN: {
    PIN_TAB_OPEN: "Pin Tab Open"
  },
  PROFILE_COMPLETION: {
    I_CONFIRM: "I confirm my profile is current",
    UPDATE_NOW: "Update now",
    SKIP_FOR_NOW: "Skip for now"
  },
  DIGITIAL_CREDENTIALS: {
    ADD_CERTIFICATE: "Add Certificate",
    ADD_AWARDS: "Add Awards"
  },
  LINKEDIN: {
    SETTINGS: "Settings",
    SAVE_AND_CLOSE: "Save and close",
    REMOVE_ACCESS: "Remove access",
    CLOSE: "Close",
    DECLINE: "Decline",
    ALLOW: "Allow"
  }
};

const TABS = {
  OVERVIEW: "Overview",
  CASES: "Cases",
  MATERIALS: "Materials",
  EXPERTISE: "Expertise"
};

const EVENTS = {
  PROFILE_SUMMARY: "Profile_Summary",
  TAB_CLICKS: "Tab_Clicks_New",
  BANNER_CLICKS: "Banner_Clicks",
  EDUCATION_SECTION: "Education_Section",
  CRED_SECTION: "Credentials_Click",
  EXPERTISE_SECTION: "Expertise_Section",
  EXPERIENCE_SECTION: "Experience_Section",
  CASES_SECTION: "Cases_Section",
  MATERIAL_SECTION: "Material_Card_Actions",
  VIEW_KP_KB_CP: "View_KP_KB_CP",
  FILTER_SELECTION: "Filter_Selection",
  SORT_BY: "Sort_By",
  GLOBAL_RULE: "Global_Variable_Rule",
  ABOUT: "About",
  PIN_TAB_CLICK: "Pin_Tab_Click",
  INTERNAL_SEARCH: "Internal_Search",
  OVERVIEW_EDIT: "Overview_Edit"
};

const NEW_TAB_NAMES = {
  ASSISTANT: "Assistant",
  COPY_PHONE_OFFICE: "Copy Phone Office",
  COPY_PHONE_MOBILE: "Copy Phone Mobile",
  LOCATION: "Location",
  COPY_EMAIL: "Copy Email",
  CLICK_EMAIL: "Email Click",
  BANNER: "Banner"
};

const NOTIFICATION_EVENTS = {
  CAMPAIGN_SOURCE: "campaign_source",
  CAMPAIGN_TEXT: "campaign_text"
};

const PAGE_NAME = "BCG Link Profile";

export { TRIGGERS, TABS, EVENTS, PAGE_NAME, NEW_TAB_NAMES, NOTIFICATION_EVENTS };
