import { Card } from "semantic-ui-react";

const PageLoading = () => {
  return (
    <Card fluid className="full__page--top full__page--loading">
      <Card.Content />
    </Card>
  );
};

export default PageLoading;
