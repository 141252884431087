import { skills } from "redux/constants";

const skillDeletingReducer = (state = false, action) => {
  switch (action.type) {
    case skills.DELETE_SKILL:
      return true;
    case skills.DELETE_SKILL_SUCCESS:
    case skills.DELETE_SKILL_FAILURE:
      return false;
    default:
      return state;
  }
};

export default skillDeletingReducer;
