import { profileCompletion } from "redux/constants";

const getProfileCompletion = (payload) => {
  return {
    type: profileCompletion.FETCH_PROFILE_COMPLETION,
    payload
  };
};

const setProfileCompletion = (data) => ({
  type: profileCompletion.FETCH_PROFILE_COMPLETION_SUCCESS,
  data
});

const setProfileCompletionError = (error) => ({
  type: profileCompletion.FETCH_PROFILE_COMPLETION_FAILURE,
  error
});

const getProfileCompletionVerbiage = (payload) => {
  return {
    type: profileCompletion.FETCH_PROFILE_COMPLETION_VERBIAGE,
    payload
  };
};

const setProfileCompletionVerbiage = (data) => ({
  type: profileCompletion.FETCH_PROFILE_COMPLETION_VERBIAGE_SUCCESS,
  data
});

const setProfileCompletionVerbiageError = (error) => ({
  type: profileCompletion.FETCH_PROFILE_COMPLETION_VERBIAGE_FAILURE,
  error
});

const setSkipForNow = (payload) => ({
  type: profileCompletion.SET_SKIP_FOR_NOW,
  payload
});

const setSkipForNowSuccess = (data) => ({
  type: profileCompletion.SET_SKIP_FOR_NOW_SUCCESS,
  data
});

const setSkipForNowError = (error) => ({
  type: profileCompletion.SET_SKIP_FOR_NOW_FAILURE,
  error
});

const setProfileComplete = (payload) => {
  return {
    type: profileCompletion.UPDATE_PROFILE_COMPLETE,
    payload
  };
};

const setProfileCompleteSuccess = (data) => ({
  type: profileCompletion.UPDATE_PROFILE_COMPLETE_SUCCESS,
  data
});

const setProfileCompleteError = (error) => ({
  type: profileCompletion.UPDATE_PROFILE_COMPLETE_FAILURE,
  error
});

export {
  getProfileCompletion,
  setProfileCompletion,
  setProfileCompletionError,
  getProfileCompletionVerbiage,
  setProfileCompletionVerbiage,
  setProfileCompletionVerbiageError,
  setSkipForNow,
  setSkipForNowSuccess,
  setSkipForNowError,
  setProfileComplete,
  setProfileCompleteSuccess,
  setProfileCompleteError
};
