import React from "react";
import PropTypes from "prop-types";

import "./styles.scss";

function VerticalEllipsis({ numberOfDots }) {
  const itemList = [];
  for (let i = 0; i < numberOfDots; i += 1) {
    itemList.push(<li key={i} data-testid="single-dot" />);
  }
  return (
    <ul className="vertical__ellipsis" data-testid="vertical-ellipsis">
      {itemList}
    </ul>
  );
}

VerticalEllipsis.defaultProps = {
  numberOfDots: 4
};

VerticalEllipsis.propTypes = {
  numberOfDots: PropTypes.number
};

export default VerticalEllipsis;
