import { topics } from "redux/constants";

const topicsErrorReducer = (state = {}, action) => {
  switch (action.type) {
    case topics.FETCH_TOPIC_FAILURE:
      return action.error;
    case topics.FETCH_TOPIC_SUCCESS:
    case topics.FETCH_TOPIC:
      return null;
    default:
      return state;
  }
};

export default topicsErrorReducer;
