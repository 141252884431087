import { about } from "redux/constants";

const aboutAllTermsLoadingReducer = (state = false, action) => {
  switch (action.type) {
    case about.FETCH_ABOUT_ME_ALL_TERMS:
      return true;
    case about.FETCH_ABOUT_ME_ALL_TERMS_SUCCESS:
    case about.FETCH_ABOUT_ME_ALL_TERMS_FAILURE:
      return false;
    default:
      return state;
  }
};

export default aboutAllTermsLoadingReducer;
