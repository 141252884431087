import { call, put } from "redux-saga/effects";
import { get } from "redux/api";
import { setMaterials, setError } from "redux/actions/materials";

function* handleFetchMaterials(endpoint) {
  const { data, error } = yield call(get, endpoint);
  if (error) {
    yield put(setError(error));
  } else {
    yield put(setMaterials(data));
  }
}

export default handleFetchMaterials;
