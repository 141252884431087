import { takeLatest } from "redux-saga/effects";

import { certificate } from "redux/constants";
import handleDeleteCertificate from "../worker/handleDeleteCertificate";

function* watchDeleteCertificate() {
  yield takeLatest(certificate.DELETE_CERTIFICATE, handleDeleteCertificate);
}

export default watchDeleteCertificate;
