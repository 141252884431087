import { searchTopicsSkills } from "redux/constants";

const topicsSkillsSearchLoadingReducer = (state = false, action) => {
  switch (action.type) {
    case searchTopicsSkills.FETCH_TOPICS_SKILLS_FINDER:
      return true;
    case searchTopicsSkills.FETCH_TOPICS_SKILLS_FINDER_SUCCESS:
    case searchTopicsSkills.FETCH_TOPICS_SKILLS_FINDER_FAILURE:
      return false;
    default:
      return state;
  }
};

export default topicsSkillsSearchLoadingReducer;
