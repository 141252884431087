/* eslint-disable no-extra-boolean-cast */
import React from "react";
import PropTypes from "prop-types";
import { Table } from "semantic-ui-react";
import LABEL from "constants/label";
import { formatDateByMoment, getDuration } from "helpers/utils";

const { TABLE_HEADER } = LABEL.EXPERIENCE;
const { Present, NA } = LABEL.COMMON;

function LinkedInRoleChildRow({ experience, rowItem, index }) {
  return (
    <Table.Row key={rowItem?.title} className="role__tree-row">
      <Table.Cell className="d-flex align-items-center" />
      <Table.Cell
        className={`${index === experience?.roles?.length - 1 ? "role__tree-last-cell" : ""} ${
          index % 2 !== 0 ? "gray-cell" : ""
        }`}
      >
        <span className="td-label mt-3">{TABLE_HEADER.Role} </span>
        {rowItem?.title ? rowItem.title : "-"}
      </Table.Cell>
      <Table.Cell>
        <span className="td-label">{TABLE_HEADER.StartEndDate} </span>
        {(!!rowItem?.startYear &&
          formatDateByMoment(
            new Date(rowItem?.startYear, !!rowItem?.startMonth ? rowItem?.startMonth - 1 : rowItem?.startMonth),
            "MMM DD, YYYY"
          )) ||
          NA}{" "}
        –{" "}
        {(rowItem?.endYear &&
          formatDateByMoment(
            new Date(rowItem?.endYear, !!rowItem?.endMonth ? rowItem?.endMonth - 1 : rowItem?.endMonth),
            "MMM DD, YYYY"
          )) || <span data-testid="ongoing-child">{Present}</span>}{" "}
        {!!rowItem?.startYear && (
          <span data-testid="duration">
            |{" "}
            {getDuration(
              (!!rowItem?.endYear &&
                new Date(rowItem?.endYear, !!rowItem?.endMonth ? rowItem?.endMonth : rowItem?.endMonth - 1)) ||
                new Date(),
              new Date(rowItem?.startYear, !!rowItem?.startMonth ? rowItem?.startMonth - 1 : rowItem?.startMonth)
            )}
          </span>
        )}
      </Table.Cell>
      <Table.Cell>
        <span className="td-label">{TABLE_HEADER.Location}</span> {rowItem?.location ? rowItem?.location : "-"}
      </Table.Cell>
    </Table.Row>
  );
}

LinkedInRoleChildRow.defaultProps = {
  rowItem: {},
  experience: []
};

LinkedInRoleChildRow.propTypes = {
  rowItem: PropTypes.shape({
    title: PropTypes.string,
    endYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    startYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    endMonth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    startMonth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    location: PropTypes.string
  }),
  experience: PropTypes.shape({ roles: PropTypes.arrayOf(PropTypes.shape({})) }),
  index: PropTypes.number.isRequired
};

export default LinkedInRoleChildRow;
