import { ssCases } from "redux/constants";

const ssCasesErrorReducer = (state = {}, action) => {
  switch (action.type) {
    case ssCases.FETCH_SSCASE_FAILURE:
      return action.error;
    case ssCases.FETCH_SSCASE_SUCCESS:
    case ssCases.FETCH_SSCASE:
      return null;
    default:
      return state;
  }
};

export default ssCasesErrorReducer;
