import { takeLatest } from "redux-saga/effects";

import { linkedIn } from "redux/constants";
import handleFetchUserLinkedInStatus from "../worker/handleFetchUserLinkedInStatus";

function* watchFetchUserLinkedInStatus() {
  yield takeLatest(linkedIn.FETCH_USER_LINKEDIN_STATUS, handleFetchUserLinkedInStatus);
}

export default watchFetchUserLinkedInStatus;
