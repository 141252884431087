import { Button } from "semantic-ui-react";
import PropTypes from "prop-types";
import "./styles.scss";

const OutlinedButton = ({ text, ...restProps }) => {
  return (
    <>
      {text ? (
        <Button className="outlinedButton" {...restProps}>
          {text}
        </Button>
      ) : null}
    </>
  );
};

OutlinedButton.defaultProps = {
  text: ""
};

OutlinedButton.propTypes = {
  text: PropTypes.string
};

export default OutlinedButton;
