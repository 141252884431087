import { about } from "redux/constants";

const updateAboutKT = (payload) => ({
  type: about.UPDATE_ABOUT_KT,
  payload
});

const setUpdateAboutKTSuccess = (data) => ({
  type: about.UPDATE_ABOUT_KT_SUCCESS,
  data
});

const setUpdateAboutKTError = (error) => ({
  type: about.UPDATE_ABOUT_KT_FAILURE,
  error
});

const updateAboutECT = (payload) => ({
  type: about.UPDATE_ABOUT_ECT,
  payload
});

const setUpdateAboutECTSuccess = (data) => ({
  type: about.UPDATE_ABOUT_ECT_SUCCESS,
  data
});

const setUpdateAboutECTError = (error) => ({
  type: about.UPDATE_ABOUT_ECT_FAILURE,
  error
});

const updateAboutSA = (payload) => ({
  type: about.UPDATE_ABOUT_SA,
  payload
});

const setUpdateAboutSASuccess = (data) => ({
  type: about.UPDATE_ABOUT_SA_SUCCESS,
  data
});

const setUpdateAboutSAError = (error) => ({
  type: about.UPDATE_ABOUT_SA_FAILURE,
  error
});

const getAbout = (payload) => ({
  type: about.FETCH_ABOUT,
  payload
});

const setAbout = (data) => ({
  type: about.FETCH_ABOUT_SUCCESS,
  data
});

const setAboutAdditonalData = (data) => ({
  type: about.SET_ABOUT_ADDITIONAL_DATA,
  data
});

const setAboutError = (error) => ({
  type: about.FETCH_ABOUT_FAILURE,
  error
});

const getAboutMeAllTerms = (payload) => ({
  type: about.FETCH_ABOUT_ME_ALL_TERMS,
  payload
});

const setAboutMeAllTerms = (data) => ({
  type: about.FETCH_ABOUT_ME_ALL_TERMS_SUCCESS,
  data
});

const setAboutMeAllTermsError = (error) => ({
  type: about.FETCH_ABOUT_ME_ALL_TERMS_FAILURE,
  error
});

const getAboutSponserDetails = (payload) => ({
  type: about.FETCH_ABOUT_SPONSER_DETAILS,
  payload
});

const setAboutSponserDetails = (data) => ({
  type: about.FETCH_ABOUT_SPONSER_DETAILS_SUCCESS,
  data
});

const setAboutSponserDetailsError = (error) => ({
  type: about.FETCH_ABOUT_SPONSER_DETAIL_FAILURE,
  error
});

const getAboutTopicDetails = (payload) => ({
  type: about.FETCH_ABOUT_TOPIC_DETAILS,
  payload
});

const setAboutTopicDetails = (data) => ({
  type: about.FETCH_ABOUT_TOPIC_DETAILS_SUCCESS,
  data
});

const resetAboutTopicDetails = (payload) => ({
  type: about.RESET_ABOUT_TOPIC_DETAILS,
  data: payload
});

const getPracticeAreaOptions = (payload) => ({
  type: about.FETCH_PA_OPTIONS,
  payload
});

const setPracticeAreaOptionsSuccess = (data) => ({
  type: about.FETCH_PA_OPTIONS_SUCCESS,
  data
});

const setPracticeAreaOptionsError = (error) => ({
  type: about.FETCH_PA_OPTIONS_FAILURE,
  error
});

export {
  getPracticeAreaOptions,
  setPracticeAreaOptionsSuccess,
  setPracticeAreaOptionsError,
  updateAboutKT,
  setUpdateAboutKTSuccess,
  setUpdateAboutKTError,
  getAbout,
  setAbout,
  setAboutError,
  getAboutMeAllTerms,
  setAboutMeAllTerms,
  setAboutMeAllTermsError,
  getAboutSponserDetails,
  setAboutSponserDetails,
  setAboutSponserDetailsError,
  updateAboutECT,
  setUpdateAboutECTSuccess,
  setUpdateAboutECTError,
  updateAboutSA,
  setUpdateAboutSASuccess,
  setUpdateAboutSAError,
  getAboutTopicDetails,
  setAboutTopicDetails,
  resetAboutTopicDetails,
  setAboutAdditonalData
};
