import { materials } from "redux/constants";

const getMaterials = (endpoint) => {
  return {
    type: materials.FETCH_MATERIALS,
    endpoint
  };
};

const setMaterials = (data) => ({
  type: materials.FETCH_MATERIALS_SUCCESS,
  data
});

const setError = (error) => ({
  type: materials.FETCH_MATERIALS_FAILURE,
  error
});

export { getMaterials, setMaterials, setError };
