import { takeLatest } from "redux-saga/effects";

import { profileUpdateHistory } from "redux/constants";
import handleFetchProfileUpdateHistory from "../worker/handleFetchProfileUpdateHistory";

function* watchProfileUpdateHistory() {
  yield takeLatest(profileUpdateHistory.FETCH_PROFILE_UPDATE_HISTORY, handleFetchProfileUpdateHistory);
}

export default watchProfileUpdateHistory;
