import { ssMaterials } from "redux/constants";

const ssMaterialsReducer = (state = {}, action) => {
  switch (action.type) {
    case ssMaterials.FETCH_SSMATERIALS_SUCCESS: {
      return { ...action.data };
    }
    default:
      return state;
  }
};

export default ssMaterialsReducer;
