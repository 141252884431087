import { call, put } from "redux-saga/effects";
import { get } from "redux/api";
import { setMemberListImage, setErrorImage } from "redux/actions/memberList";

function* handleFetchMemberListImage({ payload }) {
  const { data, error } = yield call(get, payload);
  if (error) {
    yield put(setErrorImage(error));
  } else {
    yield put(setMemberListImage({ data, payload }));
  }
}

export default handleFetchMemberListImage;
