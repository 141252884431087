import { call, put } from "redux-saga/effects";
import { get } from "redux/api";
import { initiateLinkedInConnectSuccess, initiateLinkedInConnectError } from "redux/actions/linkedIn";

function* handleInitiateLinkedInConnect({ payload }) {
  const { data, error } = yield call(get, payload);
  if (error) {
    yield put(initiateLinkedInConnectError(error));
  } else {
    yield put(initiateLinkedInConnectSuccess(data));
  }
}

export default handleInitiateLinkedInConnect;
