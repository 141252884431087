import { call, put } from "redux-saga/effects";
import { get } from "redux/api";
import { fetchProfileViewsSuccess, fetchProfileViewsFailure } from "redux/actions/profileViews";

function* handleFetchProfileViews({ payload }) {
  const { data, error } = yield call(get, payload);

  if (error) {
    yield put(fetchProfileViewsFailure(error));
  } else {
    yield put(fetchProfileViewsSuccess(data));
  }
}

export default handleFetchProfileViews;
