/**
 *
 * @param {string} scriptSource Source of the script
 * @param {string} scriptId id of the script
 */

const loadScript = (scriptSource, scriptId, callback) => {
  const existingScript = document.getElementById(scriptId);
  if (!existingScript) {
    const script = document.createElement("script");
    script.src = scriptSource;
    script.id = scriptId;
    script.defer = true;
    document.head.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
    if (existingScript && callback) callback();
  }
};
export default loadScript;
