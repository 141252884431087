import { profileUpdateHistory } from "redux/constants";

const getProfileUpdateHistory = (endpoint) => {
  return {
    type: profileUpdateHistory.FETCH_PROFILE_UPDATE_HISTORY,
    endpoint
  };
};

const setProfileUpdateHistory = (data) => ({
  type: profileUpdateHistory.FETCH_PROFILE_UPDATE_HISTORY_SUCCESS,
  data
});

const setError = (error) => ({
  type: profileUpdateHistory.FETCH_PROFILE_UPDATE_HISTORY_FAILURE,
  error
});

export { getProfileUpdateHistory, setProfileUpdateHistory, setError };
