import PropTypes from "prop-types";
import Cookies from "js-cookies";
import { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Card } from "semantic-ui-react";
import { Loading } from "components/common";
import qs from "qs";
import moment from "moment";
import EditAbout from "components/EditAbout";
import LABEL from "constants/label";
import { isEmptyOrNull, generateSLToken, createParamsObject, useQuery } from "helpers/utils";
import sendEventData from "analytics/analytics";
import CONFIG from "constants/config";
import { EVENTS, TRIGGERS, NOTIFICATION_EVENTS } from "analytics/constants";
import { isUserAlumni } from "components/App/helper/utils";
import EditButton from "components/common/EditButton";
import useUpdateQueryParams from "customHooks/useUpdateQueryParams";
import {
  getAboutSponserDetails,
  getAboutTopicDetails,
  setAboutSponserDetails,
  getAbout,
  getPracticeAreaOptions,
  setAboutAdditonalData,
  setAboutTopicDetails
} from "redux/actions/about";
import * as utils from "@kdshared/okta-utils";
import "./styles.scss";

const { ABOUT, COMMON } = LABEL;
const { xApiKey, apiUrl, cookies } = CONFIG;

/**
 * About
 *
 * @param  {object} profile about details object
 * @param  {string} className class name
 * @param  {bool} isLoading loading state
 * @param  {bool} isEditable true if user seeing his own profile
 *
 * @return About component
 */

const About = ({ profile, className, isEditable, loggedInUserHrId }) => {
  const dispatch = useDispatch();

  const isImpersonate = Cookies.getItem("impersonateUser");
  const impersonatedUserHrId = Cookies.getItem("impersonatedUserHrId");

  const [isDataMappingDone, setIsDataMappingDone] = useState(false);
  const smartLogicToken = JSON.parse(Cookies.getItem(cookies.SMART_LOGIC_TOKEN)) || {};

  const {
    userEntitlements,
    aboutSponsersData,
    peopleData,
    ccoData,
    ccoLoading,
    aboutMe,
    aboutAdditionalData,
    aboutMeLoading,
    aboutTopicDetails,
    practiceAreaOptions,
    practiceAreaOptionsError
  } = useSelector((state) => state);
  const { isECT, isKT, isCCO, isSA } = userEntitlements;

  const [staffType, setStaffType] = useState("");
  const history = useHistory();
  const location = useLocation();
  // Edit About popup state
  const [open, setOpen] = useState(false);
  const { updateQueryParams, deleteQueryParam } = useUpdateQueryParams(location, history);
  const [hasEditAbout, setHasEditAbout] = useState(false);

  const currentDate = moment();
  const SLTokenExpiryDate = moment(smartLogicToken[".expires"]);
  const isSLTokenExpired = currentDate.diff(SLTokenExpiryDate, "days") >= 0;

  // logic to fetch Practice Area options from Smart Logic taxonomy
  useEffect(() => {
    if (smartLogicToken?.access_token && !isSLTokenExpired) {
      dispatch(
        getPracticeAreaOptions({
          endpoint: `${apiUrl.smartLogicApi}?TBDB=${ABOUT.AboutMeTaxonomy}&service=conceptmap&query=Yes&FILTER=EQ_REL=Sponsor%20PA&template=service.json`,
          config: {
            headers: {
              authorization: `Bearer ${smartLogicToken.access_token}`,
              noAuthHeader: true
            }
          }
        })
      );
    }
  }, [smartLogicToken?.access_token]);

  // refresh smart logic token when SL api gives 403 error
  useEffect(() => {
    if (practiceAreaOptionsError === 403) {
      generateSLToken();
    }
  }, [practiceAreaOptionsError]);

  useEffect(() => {
    if (profile?.isKT) setStaffType("kt");
    if (profile?.isECT) setStaffType("ect");
    if (profile?.isSA) setStaffType("sa");
  }, [profile]);

  useEffect(() => {
    if (profile?.hrId && !!staffType) {
      dispatch(
        getAbout({
          endpoint: `${apiUrl.linkApi}profile/${profile?.hrId}/about?staffType=${staffType}`,
          config: {
            headers: {
              "x-api-key": `${xApiKey.profile}`
            }
          }
        })
      );
    }
  }, [dispatch, profile?.hrId, staffType]);

  // Edit About Popup
  const query = useQuery(location?.search);
  const section = query.get("edit");

  const urlQueryParams = qs.parse(location?.search, { ignoreQueryPrefix: true });
  const applicationSource = urlQueryParams[COMMON.SOURCE_NAME];
  const [sourceApplicationName, setSourceApplicationName] = useState("");

  useEffect(() => {
    if (applicationSource) {
      const source = `${applicationSource ? `&source=${applicationSource}` : ""}`;
      setSourceApplicationName(source);
    }
  }, []);

  useEffect(() => {
    if (profile?.hrId && loggedInUserHrId) {
      if (section === "about-me" && isEditable) {
        setOpen(true);
      }
    }
  }, [profile?.hrId, loggedInUserHrId, sourceApplicationName]);
  const campaignSource = query.get(NOTIFICATION_EVENTS.CAMPAIGN_SOURCE);
  const campaignText = query.get(NOTIFICATION_EVENTS.CAMPAIGN_TEXT);

  const openEditAboutPopup = () => {
    sendEventData(
      TRIGGERS.COMMON.EDIT,
      EVENTS.ABOUT,
      campaignSource && {
        campaignSource,
        campaignText
      }
    ); // Analytics Event
    updateQueryParams({ ...createParamsObject(location?.search), edit: "about-me" });
    setOpen(true);
  };
  const closeEditAboutPopup = () => {
    deleteQueryParam({}, "edit");
    setOpen(false);
  };

  const aboutMeItems = [
    "practiceArea1Guid",
    "practiceArea2Guid",
    "practiceAreaGuid",
    "primaryTopicGuid",
    "subTopicGuid",
    "sponsorPracticeAreaGuid",
    "sponsor1HrId",
    "sponsor2HrId",
    "sponsorHrId"
  ];

  useEffect(() => {
    if (aboutMe) {
      aboutMeItems.forEach((key) => {
        switch (key) {
          case "practiceArea1Guid": {
            if (aboutMe[key]) {
              dispatch(
                setAboutAdditonalData({
                  practiceAreaOne: practiceAreaOptions.find((pa) => pa.value === aboutMe[key])?.text
                })
              );
            } else
              dispatch(
                setAboutAdditonalData({
                  practiceAreaOne: ""
                })
              );
            break;
          }
          case "practiceArea2Guid": {
            if (aboutMe[key]) {
              dispatch(
                setAboutAdditonalData({
                  practiceAreaTwo: practiceAreaOptions.find((pa) => pa.value === aboutMe[key])?.text
                })
              );
            } else
              dispatch(
                setAboutAdditonalData({
                  practiceAreaTwo: ""
                })
              );
            break;
          }
          case "practiceAreaGuid": {
            if (aboutMe[key]) {
              dispatch(
                setAboutAdditonalData({
                  practiceArea: practiceAreaOptions.find((pa) => pa.value === aboutMe[key])?.text
                })
              );
            } else
              dispatch(
                setAboutAdditonalData({
                  practiceArea: ""
                })
              );
            break;
          }
          case "primaryTopicGuid": {
            if (aboutMe[key]) {
              dispatch(
                getAboutTopicDetails({
                  endpoint: `${apiUrl.smartLogicApi}?TBDB=${ABOUT.AboutMeTaxonomy}&service=browse&template=service.json&id=${aboutMe[key]}`,
                  config: {
                    headers: {
                      authorization: `Bearer ${smartLogicToken.access_token}`,
                      noAuthHeader: true
                    }
                  },
                  topicType: "primaryTopic"
                })
              );
            } else {
              dispatch(
                setAboutAdditonalData({
                  primaryTopic: ""
                })
              );
              dispatch(setAboutTopicDetails());
            }
            break;
          }
          case "subTopicGuid": {
            if (aboutMe[key]) {
              dispatch(
                getAboutTopicDetails({
                  endpoint: `${apiUrl.smartLogicApi}?TBDB=${ABOUT.AboutMeTaxonomy}&service=browse&template=service.json&id=${aboutMe[key]}`,
                  config: {
                    headers: {
                      authorization: `Bearer ${smartLogicToken.access_token}`,
                      noAuthHeader: true
                    }
                  },
                  topicType: "subTopic"
                })
              );
            } else {
              dispatch(
                setAboutAdditonalData({
                  subTopic: ""
                })
              );
              dispatch(setAboutTopicDetails());
            }
            break;
          }
          case "sponsorPracticeAreaGuid": {
            if (aboutMe[key]) {
              dispatch(
                setAboutAdditonalData({
                  sponserPracticeArea: practiceAreaOptions.find((pa) => pa.value === aboutMe[key])?.text
                })
              );
            } else
              dispatch(
                setAboutAdditonalData({
                  sponserPracticeArea: ""
                })
              );
            break;
          }
          case "sponsor1HrId": {
            if (aboutMe[key]) {
              dispatch(
                getAboutSponserDetails({
                  endpointUrl: `${apiUrl.peopleV2Data}`,
                  data: {
                    query: `
                query {
                  employees(ids: "${aboutMe[key]}") {
                    id
                    preferredName
                    preferredFirstName
                    preferredLastName
                    businessTitle
                    workEmail
                    slackId
                  }
                }`
                  },
                  config: {
                    headers: {
                      "x-api-key": `${xApiKey.peopleV2}`,
                      psid: utils.getPsId()
                    }
                  },
                  sponserType: "sponser1Name"
                })
              );
            } else {
              dispatch(
                setAboutAdditonalData({
                  sponser1Name: ""
                })
              );
              dispatch(setAboutSponserDetails());
            }
            break;
          }
          case "sponsor2HrId": {
            if (aboutMe[key]) {
              dispatch(
                getAboutSponserDetails({
                  endpointUrl: `${apiUrl.peopleV2Data}`,
                  data: {
                    query: `
                query {
                  employees(ids: "${aboutMe[key]}") {
                    id
                    preferredName
                    preferredFirstName
                    preferredLastName
                    businessTitle
                    workEmail
                    slackId
                  }
                }`
                  },
                  config: {
                    headers: {
                      "x-api-key": `${xApiKey.peopleV2}`,
                      psid: utils.getPsId()
                    }
                  },
                  sponserType: "sponser2Name"
                })
              );
            } else {
              dispatch(
                setAboutAdditonalData({
                  sponser2Name: ""
                })
              );
              dispatch(setAboutSponserDetails());
            }
            break;
          }
          case "sponsorHrId": {
            if (aboutMe[key]) {
              dispatch(
                getAboutSponserDetails({
                  endpointUrl: `${apiUrl.peopleV2Data}`,
                  data: {
                    query: `
                query {
                  employees(ids: "${aboutMe[key]}") {
                    id
                    preferredName
                    preferredFirstName
                    preferredLastName
                    businessTitle
                    workEmail
                    slackId
                  }
                }`
                  },
                  config: {
                    headers: {
                      "x-api-key": `${xApiKey.peopleV2}`,
                      psid: utils.getPsId()
                    }
                  },
                  sponserType: "sponserName"
                })
              );
            } else {
              dispatch(
                setAboutAdditonalData({
                  sponserName: ""
                })
              );
              dispatch(setAboutSponserDetails());
            }
            break;
          }
          default: {
            return null;
          }
        }
      });
      setIsDataMappingDone(true);
    }
    if (!aboutMeLoading) setIsDataMappingDone(true);
  }, [aboutMe, practiceAreaOptions]);

  useEffect(() => {
    let isMyProfile;
    let isEAUser;
    if (isImpersonate) {
      isMyProfile = +impersonatedUserHrId === +peopleData?.id;
      isEAUser = peopleData?.assistantIds?.includes(impersonatedUserHrId) && !isUserAlumni(peopleData?.alumni);
    } else {
      isMyProfile = +loggedInUserHrId === +profile?.hrId;
      isEAUser = peopleData?.assistantIds?.includes(loggedInUserHrId) && !isUserAlumni(peopleData?.alumni);
    }

    const hasEditSA = (isMyProfile || isEAUser || isSA) && !isUserAlumni(peopleData?.alumni);
    const hasEditEct = isECT && !isUserAlumni(peopleData?.alumni);
    const hasEditKT = (isKT || isMyProfile || isEAUser) && !isUserAlumni(peopleData?.alumni);
    const hasEditCCO = (isCCO || isMyProfile || isEAUser) && !isUserAlumni(peopleData?.alumni);

    dispatch(
      setAboutAdditonalData({
        editAboutPermissions: {
          canEditSA: hasEditSA,
          canEditEct: hasEditEct,
          canEditKT: hasEditKT,
          canEditCCO: hasEditCCO
        }
      })
    );

    //  function to identify whether user can launch the Edit About modal page, i.e., launch only
    setHasEditAbout(hasEditSA || hasEditEct || hasEditKT || hasEditCCO || isEditable);
  }, [userEntitlements, peopleData, profile, isEditable]);

  return (
    <>
      {(!aboutMeLoading && isDataMappingDone && !ccoLoading && !aboutTopicDetails && !aboutSponsersData && (
        <Card fluid className={`${className}`}>
          <Card.Content>
            <Card.Header>
              <div className="d-flex justify-content-between align-items-center">
                {ABOUT.About}
                {hasEditAbout && <EditButton caption={COMMON.Edit} callback={openEditAboutPopup} />}
              </div>
            </Card.Header>
          </Card.Content>
          <Card.Content className="about">
            {/* Check if profile is CCO */}
            {!!ccoData?.length && aboutAdditionalData?.editAboutPermissions?.canEditCCO && (
              <div className="about__block">
                <p>
                  <h5>{ABOUT.CCO}</h5>
                  {ccoData.map((item) => (
                    <h4 key={item}>{item}</h4>
                  ))}
                </p>
              </div>
            )}

            {/* Check if profile is Senior Advisor */}
            {profile?.isSA && (
              <div className="about__block">
                <p>
                  {ABOUT.PracticeAreaExpertise}
                  <span>
                    {!isEmptyOrNull(aboutAdditionalData?.practiceAreaOne) ? aboutAdditionalData?.practiceAreaOne : "-"}
                    <br />
                    {!isEmptyOrNull(aboutAdditionalData?.practiceAreaTwo) && aboutAdditionalData?.practiceAreaTwo}
                  </span>
                </p>
                <p>
                  {ABOUT.SenAdvSponser1}
                  <span>
                    {!isEmptyOrNull(aboutAdditionalData?.sponser1Name) ? aboutAdditionalData?.sponser1Name : "-"}
                  </span>
                </p>
                <p>
                  {ABOUT.SenAdvSponser2}
                  <span>
                    {!isEmptyOrNull(aboutAdditionalData?.sponser2Name) ? aboutAdditionalData?.sponser2Name : "-"}
                  </span>
                </p>
              </div>
            )}
            {/* Check if profile is KT */}
            {profile?.isKT && (
              <div className="about__block">
                <p>
                  {ABOUT.PracticeAreaExpertise}
                  <span>
                    {!isEmptyOrNull(aboutAdditionalData?.practiceArea) ? aboutAdditionalData?.practiceArea : "-"}
                  </span>
                </p>
                <p>
                  {ABOUT.PrimaryTopic}
                  <span>
                    {!isEmptyOrNull(aboutAdditionalData?.primaryTopic) ? aboutAdditionalData?.primaryTopic : "-"}
                  </span>
                </p>
                <p>
                  {ABOUT.SubTopic}{" "}
                  <span>{!isEmptyOrNull(aboutAdditionalData?.subTopic) ? aboutAdditionalData?.subTopic : "-"} </span>
                </p>
              </div>
            )}
            {/* Check if profile is ECT */}
            {profile?.isECT && (
              <div className="about__block">
                <p>
                  {ABOUT.Track} <span>Expert</span>
                </p>
                <p>
                  {ABOUT.Sponsor}
                  <span>
                    {!isEmptyOrNull(aboutAdditionalData?.sponserName) ? aboutAdditionalData?.sponserName : "-"}
                  </span>
                </p>
                <p>
                  {ABOUT.SponsorPA}
                  <span>
                    {!isEmptyOrNull(aboutAdditionalData?.sponserPracticeArea)
                      ? aboutAdditionalData?.sponserPracticeArea
                      : "-"}
                  </span>
                </p>
                <p>
                  {ABOUT.PrimaryTopic}
                  <span>
                    {!isEmptyOrNull(aboutAdditionalData?.primaryTopic) ? aboutAdditionalData?.primaryTopic : "-"}
                  </span>
                </p>
              </div>
            )}
          </Card.Content>
        </Card>
      )) || <Loading title="About" className="mobile-order-3 card-about" />}
      {open && (
        <EditAbout
          open={open}
          onClose={closeEditAboutPopup}
          onOpen={openEditAboutPopup}
          profile={profile}
          ktDetails={{
            aboutKtId: aboutMe?.aboutKtId,
            ktPracticeArea: { id: aboutMe?.practiceAreaGuid, name: aboutAdditionalData?.practiceArea },
            ktTopic: { id: aboutMe?.primaryTopicGuid, name: aboutAdditionalData?.primaryTopic },
            ktSubTopic: { id: aboutMe?.subTopicGuid, name: aboutAdditionalData?.subTopic }
          }}
          ectDetails={{
            aboutEctId: aboutMe?.aboutEctId,
            sponsorPA: { id: aboutMe?.sponsorPracticeAreaGuid, name: aboutAdditionalData?.sponserPracticeArea },
            primaryTopic: { id: aboutMe?.primaryTopicGuid, name: aboutAdditionalData?.primaryTopic },
            sponsor: {
              id: aboutMe?.sponsorHrId || null,
              name: aboutAdditionalData?.sponserName || ""
            }
          }}
          saDetails={{
            aboutSaId: aboutMe?.aboutSaId,
            saPracticeArea1: { id: aboutMe?.practiceArea1Guid, name: aboutAdditionalData?.practiceAreaOne },
            saPracticeArea2: { id: aboutMe?.practiceArea2Guid, name: aboutAdditionalData?.practiceAreaTwo },
            sponsor1: {
              id: aboutMe?.sponsor1HrId || null,
              name: aboutAdditionalData?.sponser1Name || ""
            },
            sponsor2: {
              id: aboutMe?.sponsor2HrId || null,
              name: aboutAdditionalData?.sponser2Name || ""
            }
          }}
          permissions={aboutAdditionalData?.editAboutPermissions}
        />
      )}
    </>
  );
};

About.defaultProps = {
  profile: {},
  className: "",
  isEditable: false
};

About.propTypes = {
  profile: PropTypes.shape(),
  className: PropTypes.string,
  isEditable: PropTypes.bool,
  loggedInUserHrId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
};

export default About;
