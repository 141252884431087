import { call, put } from "redux-saga/effects";
import { post } from "redux/api";
import { setResumeFileSuccess, setResumeFileError } from "redux/actions/editProfile";

function* handleResumeFile({ payload }) {
  const { data, error } = yield call(post, payload);
  if (error) {
    yield put(setResumeFileError(error));
  } else {
    yield put(setResumeFileSuccess(data));
  }
}

export default handleResumeFile;
