import { profile } from "redux/constants";

const profileReducer = (state = {}, action) => {
  switch (action.type) {
    case profile.FETCH_PROFILE_SUCCESS: {
      return { ...action.data };
    }
    case profile.UPDATE_PROFILE_PRONUNCIATION: {
      return { ...state, audioId: action.data.id, audioUrl: action.data.url };
    }
    default:
      return state;
  }
};

export default profileReducer;
