import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { EVENTS, TRIGGERS, NEW_TAB_NAMES } from "analytics/constants";
import sendEventData from "analytics/analytics";

import "./styles.scss";

const getAssistantProfile = (url, id) => {
  const arr = url.split("/");
  return `${arr[0]}//${arr[2]}/overview?hrid=${id}`;
};

/**
 * @param {Array} assistants array of assistants
 * @param {string} text label to be show
 *
 * @returns Assistant Component
 *
 * @example
 * <Assistant assistants={[{id:12345, firstName:"John", lastName:"Wick", email:"BCG@johnwick.com", alumni:"NO"}]} />
 */

function Assistant({ assistants = [], text = "Assistant(s)" }) {
  const handleOnClick = () => {
    sendEventData(TRIGGERS.BANNER.ASSISTANT, EVENTS.BANNER_CLICKS); // Analytics Event
  };

  const [sortedAssistants, setSortedAssistants] = useState([]);

  useEffect(() => {
    const sortedAssistantsData = assistants?.sort((a, b) => {
      if (a.firstName < b.firstName) return -1;
      if (a.firstName > b.firstName) return 1;
      return 0;
    });
    setSortedAssistants(sortedAssistantsData);
  }, [assistants]);

  return (
    <div className="assistant-info">
      <>
        {!!sortedAssistants.length && (
          <span className="d-block" data-testid="assistants-label">
            {text}:
          </span>
        )}
        {!!sortedAssistants.length &&
          sortedAssistants?.map((assistant) => (
            <p key={`main-ea-to-${assistant?.id}`}>
              <a
                className="fw-bold"
                key={`ea-to-${assistant?.id}`}
                target="blank"
                rel="noreferrer noopener"
                href={getAssistantProfile(window.location.href, assistant?.id)}
                data-testid="assistants-main-list-item"
                onClick={() => {
                  sendEventData(TRIGGERS.BANNER.ASSISTANT_CLICK, EVENTS.BANNER_CLICKS, {
                    newTabName: NEW_TAB_NAMES.ASSISTANT
                  });
                }}
              >
                {`${assistant?.firstName} ${assistant?.lastName} :`}
              </a>
              <span>
                <a
                  href={`mailto:${assistant?.email}`}
                  onClick={handleOnClick}
                  data-testid="assistants-main-list-item-email"
                >
                  {assistant?.email}
                </a>
              </span>
            </p>
          ))}
      </>
    </div>
  );
}

Assistant.defaultProps = {
  assistants: [],
  text: "Assistant(s)"
};

Assistant.propTypes = {
  assistants: PropTypes.arrayOf(PropTypes.shape({})),
  text: PropTypes.string
};

export default Assistant;
