import { call, put } from "redux-saga/effects";
import { delete as del } from "redux/api";
import { setDeleteTopicSummarySuccess, setDeleteTopicSummaryError } from "redux/actions/topics";

function* handleDeleteTopicSummary({ payload }) {
  const { data, error } = yield call(del, payload);
  if (error) {
    yield put(setDeleteTopicSummaryError(error));
  } else {
    yield put(setDeleteTopicSummarySuccess(data));
  }
}

export default handleDeleteTopicSummary;
