import { experience } from "redux/constants";

const getExperience = (endpoint) => {
  return {
    type: experience.FETCH_EXPERIENCE,
    endpoint
  };
};

const setExperience = (data) => ({
  type: experience.FETCH_EXPERIENCE_SUCCESS,
  data
});

const setError = (error) => ({
  type: experience.FETCH_EXPERIENCE_FAILURE,
  error
});

export { getExperience, setExperience, setError };
