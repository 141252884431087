import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";

/**
 * @param {string} imgSrc source of the image
 *
 * @returns a circular profile image
 *
 * @example
 * <Avatar
 *  imgSrc="https://image.shutterstock.com/image-vector/profile-placeholder-image-gray-silhouette-260nw-1153673752.jpg"
 *  />
 */

function Avatar({ imgSrc }) {
  return (
    <>
      <img src={imgSrc} className="avatar" alt="avatar" data-testid="avatar-image" />
    </>
  );
}

Avatar.propTypes = {
  imgSrc: PropTypes.string.isRequired
};

export default Avatar;
