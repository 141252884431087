import { isIE } from "helpers/utils";
import { useState, useEffect } from "react";

const useOnScreenIntersection = (options) => {
  const [ref, setRef] = useState(null);
  const [hasIntersected, setHasIntersected] = useState(false);

  useEffect(() => {
    if (!isIE()) {
      const observer = new IntersectionObserver(([entry]) => {
        setHasIntersected(entry.isIntersecting);
      }, options);

      if (ref) {
        observer.observe(ref);
      }

      return () => {
        if (ref) {
          observer.unobserve(ref);
        }
      };
    }
    return null;
  }, [ref, options]);

  return [setRef, hasIntersected];
};

export default useOnScreenIntersection;
