import { organization } from "redux/constants";

const orgLoadingReducer = (state = true, action) => {
  switch (action.type) {
    case organization.FETCH_ORG:
      return true;
    case organization.FETCH_ORG_SUCCESS:
    case organization.FETCH_ORG_FAILURE:
      return false;
    default:
      return state;
  }
};

export default orgLoadingReducer;
