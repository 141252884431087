import { takeLatest } from "redux-saga/effects";

import { about } from "redux/constants";
import handleFetchPracticeAreaOptions from "../worker/handleFetchPracticeAreaOptions";

function* watchPracticeAreaOptions() {
  yield takeLatest(about.FETCH_PA_OPTIONS, handleFetchPracticeAreaOptions);
}

export default watchPracticeAreaOptions;
