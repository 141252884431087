import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Form, Modal, TextArea } from "semantic-ui-react";
import LABEL from "constants/label";
import "./styles.scss";

function LinkedInCancelConfirmation({ open, onDeclineConfirm, imgSrc }) {
  const {
    LINKEDIN: {
      CANCEL_CONFIRMATION,
      REASON_FOR_CANCELLATION,
      TEXTAREA_PLACEHOLDER,
      SKIP_BUTTON_TEXT,
      CONFIRM_BUTTON_TEXT,
      CANCEL_WARNING_MESSAGE
    }
  } = LABEL;
  const [denyMessage, setDenyMessage] = useState("");

  return (
    <Modal
      open={open}
      className="linkedin__cancel__popup"
      data-testid="cancel-confirmation-modal"
      closeOnDimmerClick={false}
    >
      <div className="d-flex justify-content-center">
        {imgSrc && (
          <>
            <img src={imgSrc} className="" alt="avatar" data-testid="cancel-icon" />
          </>
        )}
      </div>
      <Modal.Header>{CANCEL_CONFIRMATION}</Modal.Header>
      <div className="warning-message">{CANCEL_WARNING_MESSAGE}</div>
      <Modal.Content>
        <p>
          {REASON_FOR_CANCELLATION} <span>(optional)</span>
        </p>
        <Form>
          <TextArea
            placeholder={TEXTAREA_PLACEHOLDER}
            value={denyMessage}
            onChange={(e) => setDenyMessage(e.target.value)}
            data-testid="reason-textarea"
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          basic
          secondary
          size="medium"
          className="mr-1"
          onClick={() => onDeclineConfirm()}
          data-testid="skip-button"
        >
          {SKIP_BUTTON_TEXT}
        </Button>
        <Button primary type="submit" data-testid="confirm-button" onClick={() => onDeclineConfirm(denyMessage)}>
          {CONFIRM_BUTTON_TEXT}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

LinkedInCancelConfirmation.defaultProps = {
  imgSrc: ""
};
LinkedInCancelConfirmation.propTypes = {
  open: PropTypes.bool.isRequired,
  onDeclineConfirm: PropTypes.func.isRequired,
  imgSrc: PropTypes.string
};

export default LinkedInCancelConfirmation;
