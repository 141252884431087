import { call, put } from "redux-saga/effects";
import { get } from "redux/api";
import { uploadProposalBioToS3, setUploadBioError } from "redux/actions/upload";
import CONFIG from "constants/config";

const { xApiKey } = CONFIG;

function* handleUploadProposalBioStart({ payload }) {
  const { fileInfo } = payload;
  const { data, error } = yield call(get, payload);
  if (error) {
    yield put(setUploadBioError(error));
  } else {
    yield put(
      uploadProposalBioToS3({
        ...payload,
        endpoint: data?.signedRequest,
        config: {
          headers: {
            "Content-Type": fileInfo.ext,
            "x-api-key": `${xApiKey.profile}`,
            customAuthHeader: true
          }
        }
      })
    );
  }
}

export default handleUploadProposalBioStart;
