import { profile } from "redux/constants";

const profileLoadingReducer = (state = true, action) => {
  switch (action.type) {
    case profile.FETCH_PROFILE:
      return true;
    case profile.FETCH_PROFILE_SUCCESS:
    case profile.FETCH_PROFILE_FAILURE:
      return false;
    default:
      return state;
  }
};

export default profileLoadingReducer;
