import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, Segment, Dimmer, Loader } from "semantic-ui-react";
import { Icon as IconC } from "components/common";
import LABEL from "constants/label";
import CONFIG from "constants/config";
import PropTypes from "prop-types";
import { savePronunciation, resetPronunciationState } from "redux/actions/pronunciation";
import VoiceRecorder from "components/common/VoiceRecorder";
import { toastError, toastSuccess } from "helpers/utils";
import { setProfilePronunciation } from "redux/actions/profile";
import useRecorder from "./hooks/useRecorder";

const { COMMON, MANAGE_PRONUNCIATION } = LABEL;
const { widenApi, xApiKey } = CONFIG;

function ManagePronunciationModal({ open, hasPronunciation, onClose, onOpen, staffId }) {
  const dispatch = useDispatch();
  const url = widenApi.uploads;
  const { recorderState, isDirty, setIsDirty, ...handlers } = useRecorder();
  const [openWarnPopup, setOpenWarnPopup] = useState(false);

  const { pronunciationSaving, pronunciationDeleting } = useSelector((state) => state);

  const checkDirty = () => {
    if (isDirty === true) {
      setOpenWarnPopup(true);
    } else {
      onClose();
    }
  };

  // Listen to window or tab closing
  useEffect(() => {
    window.onbeforeunload = isDirty && (() => "Are you sure you want to leave without saving your changes?");
    return () => {
      window.onbeforeunload = null;
    };
  });

  useEffect(() => {
    if (pronunciationSaving === COMMON.StatusSuccess) {
      setIsDirty(false);
      onClose();
      toastSuccess();
      dispatch(setProfilePronunciation({ id: recorderState.id, url: recorderState.audio }));
      dispatch(resetPronunciationState());
    }
    if (pronunciationSaving === COMMON.StatusFailure) {
      setIsDirty(false);
      handlers.deleteRecording();
      onClose();
      toastError();
    }
  }, [pronunciationSaving]);

  const handleSave = () => {
    const formData = new FormData();

    formData.append("profile", MANAGE_PRONUNCIATION.ProfileUploads);
    formData.append("filename", `${staffId}_${new Date()}.mp3`);
    formData.append("file", recorderState.blob);

    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${xApiKey.widen}`,
      noAuthHeader: true
    };
    dispatch(
      savePronunciation({
        endpointUrl: url,
        data: formData,
        config: { headers },
        id: staffId
      })
    );
  };

  const handleOnWarningModalContinue = () => {
    handlers.deleteRecording();
    onClose();
    setOpenWarnPopup(false);
  };

  return (
    <>
      <Modal
        onClose={onClose}
        onOpen={onOpen}
        open={open}
        closeOnDimmerClick={false}
        className={`${openWarnPopup ? "d-none" : ""} custom-modal-sm`}
      >
        <Modal.Header className="heading pl-3 pr-3">
          {(hasPronunciation && MANAGE_PRONUNCIATION.Title) || MANAGE_PRONUNCIATION.InitialTitle}
        </Modal.Header>
        <Modal.Content>
          {(pronunciationSaving === COMMON.StatusProcessing || pronunciationDeleting === COMMON.StatusProcessing) && (
            <Segment className="loading__segment">
              <Dimmer active inverted>
                <Loader size="large">
                  {pronunciationSaving === COMMON.StatusProcessing && COMMON.Saving}
                  {pronunciationDeleting === COMMON.StatusProcessing && COMMON.Deleting}
                </Loader>
              </Dimmer>
            </Segment>
          )}
          <VoiceRecorder recorderState={recorderState} handlers={handlers} onClose={onClose} />
        </Modal.Content>
        <Modal.Actions className="footer pl-3 pr-3">
          <Button basic secondary onClick={checkDirty}>
            {COMMON.Cancel}
          </Button>
          <Button primary type="submit" onClick={handleSave} disabled={!isDirty}>
            {COMMON.Save}
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal open={openWarnPopup} centered closeOnDimmerClick={false} className="custom-modal-small">
        <Modal.Content className="text-right p-1">
          <div className="warningpopupContent">
            <IconC name="warning" size={32} className="mr-1" /> {COMMON.Warnmesg}
          </div>
          <Button
            data-testid="cancel"
            basic
            className="button-sm mr-1"
            secondary
            onClick={() => setOpenWarnPopup(false)}
          >
            {COMMON.No}
          </Button>
          <Button primary className="button-sm" type="submit" onClick={handleOnWarningModalContinue}>
            {COMMON.Yes}
          </Button>
        </Modal.Content>
      </Modal>
    </>
  );
}

ManagePronunciationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  staffId: PropTypes.number.isRequired,
  hasPronunciation: PropTypes.bool.isRequired
};

export default ManagePronunciationModal;
