import PropTypes from "prop-types";
import { Popup } from "semantic-ui-react";

/**
 * ControlledPopover - wrapper for ppopup where open state can be controlled
 * @param  {node} children Popover/tooltip content
 * @param  {node} trigger Element on which by clicking/hover Popover will appear
 * @param  {string} position Popover position
 * @param  {bool} inverted Show Inverted (black) color.
 * @param  {string} on Triggers on click or hover
 * @param  {string} className Custom classes
 * @param  {object} popper Popper object to pass custom id
 * @param  {func} onClose function on closing of popover
 * @param  {func} onOpen function on opening on popover
 * @param  {func|arrayOf} offset Popper popover offset
 * @param  {string} size
 * @param  {bool} hideOnScroll Hides the popup menu on window scroll.
 * @param  {bool} openState Display state of popup.
 * @param  {bool} pinned Disable automatic repositioning.
 *
 * @return Popup Component
 *
 * @example
 *
 * <ControlledPopover trigger={<Icon name="phone" circular size="large" />} on="click" inverted>
    <span>ControlledPopover content</span>
  </ControlledPopover>
 *
 */

const ControlledPopover = ({
  children,
  trigger,
  position,
  inverted,
  on,
  className,
  popper,
  basic,
  onClose,
  onOpen,
  offset,
  size,
  openState,
  hideOnScroll,
  pinned
}) => {
  const optionalProps = {
    hideOnScroll,
    pinned,
    open: openState
  };
  return (
    <Popup
      content={children}
      trigger={trigger}
      on={on}
      position={position}
      inverted={inverted}
      className={className}
      popper={popper}
      basic={basic}
      onClose={onClose}
      onOpen={onOpen}
      offset={offset}
      size={size}
      {...optionalProps}
    />
  );
};

ControlledPopover.defaultProps = {
  position: "top left",
  inverted: false,
  className: "",
  popper: {},
  basic: false,
  onClose: () => {},
  onOpen: () => {},
  offset: [null, null],
  size: "small",
  hideOnScroll: true,
  pinned: null,
  openState: false
};

ControlledPopover.propTypes = {
  children: PropTypes.node.isRequired,
  trigger: PropTypes.node.isRequired,
  position: PropTypes.string,
  inverted: PropTypes.bool,
  on: PropTypes.string.isRequired,
  className: PropTypes.string,
  popper: PropTypes.objectOf(PropTypes.string),
  basic: PropTypes.bool,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  offset: PropTypes.oneOfType([PropTypes.func, PropTypes.arrayOf(PropTypes.number)]),
  size: PropTypes.string,
  hideOnScroll: PropTypes.bool,
  pinned: PropTypes.bool,
  openState: PropTypes.bool
};

export default ControlledPopover;
