import { profile } from "redux/constants";

const getProfile = (payload) => {
  return {
    type: profile.FETCH_PROFILE,
    payload
  };
};

const setProfile = (data) => ({
  type: profile.FETCH_PROFILE_SUCCESS,
  data
});

const setError = (error) => ({
  type: profile.FETCH_PROFILE_FAILURE,
  error
});

const setProfilePronunciation = (data) => ({
  type: profile.UPDATE_PROFILE_PRONUNCIATION,
  data
});

export { getProfile, setProfile, setError, setProfilePronunciation };
