import { expertiseList } from "redux/constants";

const getExpertiseList = (endpoint) => {
  return {
    type: expertiseList.FETCH_EXPERTISE_SUMMARY,
    endpoint
  };
};

const setExpertiseList = (data) => ({
  type: expertiseList.FETCH_EXPERTISE_SUMMARY_SUCCESS,
  data
});

const setError = (error) => ({
  type: expertiseList.FETCH_EXPERTISE_SUMMARY_FAILURE,
  error
});

export { getExpertiseList, setExpertiseList, setError };
