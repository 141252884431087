import { call, put } from "redux-saga/effects";
import { get } from "redux/api";
import { setAbout, setAboutError } from "redux/actions/about";

function* handleFetchAbout({ payload }) {
  const { data, error } = yield call(get, payload);
  if (error) {
    yield put(setAboutError(error));
  } else {
    yield put(setAbout(data));
  }
}

export default handleFetchAbout;
