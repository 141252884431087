import { produce } from "immer";

const rootTerm = {
  id: "root",
  name: "root"
};
export const rootNode = {
  term: rootTerm,
  isOpen: true,
  isLeaf: false,
  children: [],
  error: null
};

const findNodeByIdAux = (arr, id) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const node of arr) {
    if (node.term.id === id) return node;
    const nestedObj = findNodeByIdAux(node.children, id);
    if (nestedObj) return nestedObj;
  }
  return null;
};
export const findNodeById = (node, id) => {
  if (id === "root") return node;
  return findNodeByIdAux([node], id);
};

export const taxonomySelectorReducer = produce((draftRoot, action) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case "SET_CHILDREN": {
      const { nodePath, children } = action;
      const node = findNodeById(draftRoot, nodePath) || draftRoot;

      if (node) {
        node.children = children;
      }
      break;
    }

    case "SET_ERROR": {
      const { nodePath, error } = action;
      const node = findNodeById(draftRoot, nodePath);

      if (node) {
        node.error = error;
      }

      break;
    }
  }
});
