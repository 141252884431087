import { datadogRum } from "@datadog/browser-rum";
import { isUserAuthenticated, getUserEmail } from "auth/utils";
import Hashes from "jshashes";

const setGlobalRumVars = async () => {
  let email = "unknown";
  if (isUserAuthenticated()) {
    email = await getUserEmail();
  }
  if (email) {
    const staffSystemId = new Hashes.SHA256().hex(email);
    datadogRum.addRumGlobalContext("user", {
      staffSystemId,
      principal: email
    });
  }
};

if (process.env.REACT_APP_APPLICATION_ENVIRONMENT !== "local") {
  datadogRum.init({
    applicationId: process.env.REACT_APP_APPLICATION_ID,
    clientToken: process.env.REACT_APP_CLIENT_TOKEN,
    site: "datadoghq.com",
    service: process.env.REACT_APP_APPLICATION_NAME,
    version: "1.0.0",
    env: process.env.REACT_APP_APPLICATION_ENVIRONMENT,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 40,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input"
  });

  setGlobalRumVars();
}
