import React from "react";
import PropTypes from "prop-types";
import { Button, Image } from "semantic-ui-react";

import "./styles.scss";

function EditButton({ caption, callback, isDisabled, imgSrc }) {
  return (
    <Button className="edit__button" onClick={callback} disabled={isDisabled} data-testid="btn-edit">
      {imgSrc && <Image src={imgSrc} />}
      {caption}
    </Button>
  );
}

EditButton.defaultProps = {
  isDisabled: false,
  imgSrc: ""
};

EditButton.propTypes = {
  caption: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  imgSrc: PropTypes.string
};

export default EditButton;
