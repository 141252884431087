import { takeLatest } from "redux-saga/effects";

import { editProfile } from "redux/constants";
import handleDeleteProfileFile from "../worker/handleDeleteProfileFile";

function* watchDeleteProfileFile() {
  yield takeLatest(editProfile.DELETE_FILE, handleDeleteProfileFile);
}

export default watchDeleteProfileFile;
