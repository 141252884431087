import { userStaffId } from "redux/constants";

const getStaffId = (endpoint) => {
  return {
    type: userStaffId.FETCH_USER_STAFF_ID,
    endpoint
  };
};

const setStaffId = (data) => ({
  type: userStaffId.FETCH_USER_STAFF_ID_SUCCESS,
  data
});

const setError = (error) => ({
  type: userStaffId.FETCH_USER_STAFF_ID_FAILURE,
  error
});

export { getStaffId, setStaffId, setError };
