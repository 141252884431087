import { proficiency } from "redux/constants";

const proficiencyReducer = (state = [], action) => {
  switch (action.type) {
    case proficiency.FETCH_PROFICIENCY_SUCCESS:
      return action.data?.proficienciyList;
    case proficiency.FETCH_PROFICIENCY_FAILURE:
      return null;
    default:
      return state;
  }
};

export default proficiencyReducer;
