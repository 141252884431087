import PropTypes from "prop-types";
import { useState } from "react";
import LABEL from "constants/label";
import ControlledPopover from "components/common/ControlledPopover";
import { Icon } from "components/common";
import { copyToClipboard } from "helpers/utils";
import { EVENTS, TRIGGERS, NEW_TAB_NAMES } from "analytics/constants";
import sendEventData from "analytics/analytics";
import styles from "./styles.module.scss";

const { TOOLTIPS } = LABEL.BANNER;

const EmailCopy = ({ profile }) => {
  const [hoverText, setHoverText] = useState(TOOLTIPS.EmailCopy);
  const [showTooltip, setShowTooltip] = useState(false);

  const onCopy = () => {
    copyToClipboard(profile?.workEmail);
    sendEventData(TRIGGERS.BANNER.EMAIL_COPY, EVENTS.BANNER_CLICKS, { newTabName: NEW_TAB_NAMES.COPY_EMAIL }); // Analytics Event
    setHoverText(TOOLTIPS.EmailCopyDone);
    setShowTooltip(true);
  };

  const onHoverOut = () => {
    setHoverText(TOOLTIPS.EmailCopy);
    setShowTooltip(false);
  };

  const onHover = () => {
    setShowTooltip(true);
  };

  return (
    profile?.workEmail && (
      <div className={styles["banner_contacts_email__popup"]}>
        <span>
          <a
            href={
              profile?.workEmail &&
              `mailto:${profile?.workEmail}?cc=${
                (profile?.assistants?.length && profile?.assistants?.map((assist) => assist?.email)?.join(";")) || ""
              }`
            }
            target="_blank"
            rel="noreferrer noopener"
            data-testid="banner-email--send"
            onClick={() =>
              sendEventData(TRIGGERS.BANNER.EMAIL_CLICK, EVENTS.BANNER_CLICKS, {
                newTabName: NEW_TAB_NAMES.CLICK_EMAIL
              })
            }
          >
            {profile?.workEmail}
          </a>
        </span>
        <div className={styles["banner_contacts_email__popup--pipe-divider"]} />
        <ControlledPopover
          trigger={
            <span className={styles["banner_contacts_email__popup--copy-container"]} data-testid="banner-email--copy">
              <Icon name="copy" size={16} onIconClick={onCopy} />
            </span>
          }
          inverted
          position="bottom center"
          onOpen={onHover}
          onClose={onHoverOut}
          openState={showTooltip}
          on="hover"
          popper={{ className: "banner-email__popup--copy__tooltip" }}
        >
          {hoverText}
        </ControlledPopover>
      </div>
    )
  );
};

EmailCopy.defaultProps = {
  profile: {
    workEmail: "",
    assistants: []
  }
};

EmailCopy.propTypes = {
  profile: PropTypes.shape({
    workEmail: PropTypes.string,
    assistants: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        email: PropTypes.string
      })
    )
  })
};

export default EmailCopy;
