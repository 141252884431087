import { takeLatest } from "redux-saga/effects";

import { awardTypes } from "redux/constants";
import handleFetchAwardTypes from "../worker/handleFetchAwardTypes";

function* watchAwardTypes() {
  yield takeLatest(awardTypes.FETCH_AWARD_TYPES, handleFetchAwardTypes);
}

export default watchAwardTypes;
