import React, { useEffect, useState } from "react";
import { Button, Image } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import LABEL from "constants/label";
import CONFIG from "constants/config";
import RecorderToggleIcons from "components/common/RecorderToggleIcons";
import RecordingToggleButton from "components/common/RecordingToggleButton";
import { deletePronunciation, resetPronunciationState, fetchPronunciation } from "redux/actions/pronunciation";
import PropTypes from "prop-types";
import { toastDeleteSuccess, toastError } from "helpers/utils";
import { setProfilePronunciation } from "redux/actions/profile";
import getDuration from "./helpers/utils";
import "./styles.scss";

const { MANAGE_PRONUNCIATION, COMMON } = LABEL;
const { apiUrl, xApiKey } = CONFIG;

function VoiceRecorder({ recorderState, handlers, onClose }) {
  const dispatch = useDispatch();
  const { pronunciationDeleting, peopleData } = useSelector((state) => state);
  const { recordingSeconds, initRecording, audio, id } = recorderState;
  const { startRecording, stopRecording, deleteRecording } = handlers;
  const [recording, setRecording] = useState(null);
  const [audioDuration, setAudioDuration] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);

  //const url = `${widenApi.assets}${id}`;

  useEffect(() => {
    if (audio) {
      getDuration(audio, (duration) => {
        setAudioDuration(duration);
      });
      setRecording(new Audio(audio));
    }
  }, [audio]);

  useEffect(() => {
    if (recording) {
      recording.addEventListener("ended", () => {
        setIsPlaying(false);
      });
    }
  }, [recording]);

  useEffect(() => {
    if (pronunciationDeleting === COMMON.StatusSuccess) {
      deleteRecording();
      toastDeleteSuccess();
      dispatch(resetPronunciationState());
      dispatch(setProfilePronunciation({ id: null, url: null }));
      dispatch(
        fetchPronunciation({
          endpoint: `${apiUrl.linkApi}profile/${peopleData.id}/audio`,
          config: {
            headers: {
              "x-api-key": `${xApiKey.profile}`
            }
          }
        })
      );
    }
    if (pronunciationDeleting === COMMON.StatusFailure) {
      onClose();
      toastError();
      dispatch(resetPronunciationState());
    }
  }, [pronunciationDeleting]);

  const handleOnPlay = () => {
    setIsPlaying(true);
    recording?.play();
  };

  const handleOnDelete = () => {
    if (recorderState.id) {
      dispatch(
        deletePronunciation({
          endpointUrl: `${apiUrl.linkApi}profile/${peopleData.id}/audio/${id}`,
          config: {
            headers: {
              "x-api-key": `${xApiKey.profile}`
            }
          }
        })
      );
    } else {
      deleteRecording();
    }
  };

  return (
    <div className="recorder">
      <p>{MANAGE_PRONUNCIATION.HelpText}</p>
      <RecordingToggleButton recorderState={recorderState} callbacks={[startRecording, stopRecording]} />
      <RecorderToggleIcons recorderState={recorderState} />
      {initRecording && (
        <div className="recorder__rec-time">
          {recordingSeconds} {COMMON.Seconds}
        </div>
      )}
      {audio && (
        <div className="recorder__rec-time">
          {Math.round(audioDuration)} {COMMON.Seconds}
        </div>
      )}
      <div className="mt-1 mb-2 d-flex justify-content-between align-items-center">
        <Button onClick={handleOnDelete} className="btn delete" disabled={audio === null}>
          <Image src="/icons/delete-dark.svg" className="mr-h" />
          {MANAGE_PRONUNCIATION.Delete}
        </Button>
        <Button type="submit" onClick={handleOnPlay} className="btn play ml-1" disabled={audio === null}>
          <Image src="/icons/ic-play-dark.svg" className="mr-h" />
          {(isPlaying && MANAGE_PRONUNCIATION.Playing) || MANAGE_PRONUNCIATION.PlayBack}
        </Button>
      </div>
    </div>
  );
}

VoiceRecorder.propTypes = {
  recorderState: PropTypes.shape({
    recordingSeconds: PropTypes.number,
    initRecording: PropTypes.bool,
    audio: PropTypes.string,
    id: PropTypes.string
  }).isRequired,
  handlers: PropTypes.shape({
    startRecording: PropTypes.func,
    stopRecording: PropTypes.func,
    deleteRecording: PropTypes.func
  }).isRequired,
  onClose: PropTypes.func.isRequired
};

export default VoiceRecorder;
