import { linkedIn } from "redux/constants";

const linkedInDataErrorReducer = (state = null, action) => {
  switch (action.type) {
    case linkedIn.FETCH_LINKEDIN_DATA_FAILURE:
      return action.error;
    case linkedIn.FETCH_LINKEDIN_DATA:
    case linkedIn.FETCH_LINKEDIN_DATA_SUCCESS:
      return null;
    default:
      return state;
  }
};

export default linkedInDataErrorReducer;
