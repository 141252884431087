import { awardNames } from "redux/constants";

const awardNamesReducer = (state = {}, action) => {
  switch (action.type) {
    case awardNames.FETCH_AWARD_NAMES_SUCCESS: {
      return action.data;
    }
    default:
      return state;
  }
};

export default awardNamesReducer;
