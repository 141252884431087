import { download } from "redux/constants";

const downloadPendingReducer = (state = false, action) => {
  switch (action.type) {
    case download.DOWNLOAD_START:
      return true;
    case download.DOWNLOAD_FAILURE:
    case download.DOWNLOAD_SUCCESS:
      return false;
    default:
      return state;
  }
};

export default downloadPendingReducer;
