import { takeLatest } from "redux-saga/effects";

import { skills } from "redux/constants";
import handleFetchSkillsTools from "../worker/handleFetchSkillsTools";

function* watchSkillsToolsData() {
  yield takeLatest(skills.GET_SKILL_TOOLS_DATA, handleFetchSkillsTools);
}

export default watchSkillsToolsData;
