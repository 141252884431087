import { topicsFinder } from "redux/constants";

const getTopicsFinder = (endpoint) => {
  return {
    type: topicsFinder.FETCH_TOPICS_FINDER,
    endpoint
  };
};

const setTopicsFinder = (data) => ({
  type: topicsFinder.FETCH_TOPICS_FINDER_SUCCESS,
  data
});

const setError = (error) => ({
  type: topicsFinder.FETCH_TOPICS_FINDER_FAILURE,
  error
});

export { getTopicsFinder, setTopicsFinder, setError };
