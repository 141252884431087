import { pronunciation } from "redux/constants";

const pronunciationErrorReducer = (state = {}, action) => {
  switch (action.type) {
    case pronunciation.SAVE_PRONUNCIATION_FAILURE:
    case pronunciation.FETCH_PRONUNCIATION_FAILURE:
      return action.error;
    default:
      return state;
  }
};

export default pronunciationErrorReducer;
