const CONFIG = {
  env: process.env.REACT_APP_ENV,
  customLoggedInUserHrId: process.env.REACT_APP_LOGGEDIN_USER_HRID,
  xApiKey: {
    smartsearch: process.env.REACT_APP_XAPIKEY_SMARTSEARCH,
    people: process.env.REACT_APP_XAPIKEY_PEOPLE,
    peopleV2: process.env.REACT_APP_XAPIKEY_PEOPLEV2,
    profile: process.env.REACT_APP_XAPIKEY_PROFILE,
    widen: process.env.REACT_APP_XAPIKEY_WIDEN,
    upload: process.env.REACT_APP_UPLOAD_API_KEY,
    smartLogic: process.env.REACT_APP_XAPIKEY_SMARTLOGIC,
    grantType: process.env.REACT_APP_GRANT_TYPE_SMARTLOGIC,
    entitlementEngine: process.env.REACT_APP_XAPIKEY_ENTITLEMENT_ENGINE,
    slideLevelSearch: process.env.REACT_APP_SLIDES_XAPI_KEY,
    metaData: process.env.REACT_APP_META_DATA_XAPI_KEY,
    slideApikey: process.env.REACT_APP_SLIDES_XAPI_KEY,
    docvizApiConfig: process.env.REACT_APP_META_DATA_XAPI_KEY
  },
  apiUrl: {
    linkApi: process.env.REACT_APP_APIURL,
    peopleData: `${process.env.REACT_APP_APIURL_PEOPLE}people`,
    peopleImage: `${process.env.REACT_APP_APIURL_PEOPLEV2DATA}/picture?hrid=`,
    peopleV2Data: process.env.REACT_APP_APIURL_PEOPLEV2DATA,
    ssCases: `${process.env.REACT_APP_APIURL_ENRICH}case-search/v2/search`,
    ssRecentCases: `${process.env.REACT_APP_APIURL_ENRICH}case-search/v2/search?query=&sortingOrder=dateOpened.desc&viewState=list&enableAutoCorrect=true&hrId=`,
    ssMaterial: `${process.env.REACT_APP_APIURL_ENRICH}knowledge-search/v1/search`,
    ssInternalMaterials: `${process.env.REACT_APP_APIURL_ENRICH}internal-search/v2/search`,
    ssRecentMaterials: `${process.env.REACT_APP_APIURL_ENRICH}knowledge-search/v1/search?query=&sortingOrder=relevance.desc&viewState=list&enableAutoCorrect=true`,
    uploadPreflight: process.env.REACT_APP_UPLOAD_API_URL,
    downloadFile: process.env.REACT_APP_APIURL,
    smartLogicApi: process.env.REACT_APP_SMART_LOGIC_URL,
    smartLogicToken: process.env.REACT_APP_SMART_LOGIC_TOKEN_URL,
    entitlementEngine: process.env.REACT_APP_APIURL,
    slideLevel: `${process.env.REACT_APP_APIURL_ENRICH}slidelevelsearch/v3`,
    metaData: process.env.REACT_APP_META_DATA_API_URL
  },
  knowledge: {
    kp: process.env.REACT_APP_MATERIAL_KP,
    kb: process.env.REACT_APP_MATERIAL_KB,
    download: `${process.env.REACT_APP_NAVIGATOR_PAGE}download/kp/SmartSearch/Knowledge_Page`,
    attachment: process.env.REACT_APP_MATERIAL_ATTACHMENT,
    privacyPolicyLink: process.env.REACT_APP_PRIVACY_POLICY_PAGE
  },
  directory: {
    office: process.env.REACT_APP_DIRECTORY_OFFICE,
    room: process.env.REACT_APP_DIRECTORY_ROOM
  },
  exceptions: {
    ktStaffExceptions: process.env.REACT_APP_PROFILES_KTSTAFF_EXCEPTION
  },
  cookies: {
    RELOAD_ATTEMPTS: "RELOAD_ATTEMPTS",
    SMART_LOGIC_TOKEN: "smart-logic-token"
  },
  DEBOUNCE_DELAY: 500,
  THROTTLE_DELAY: 300,
  USE_URL_SHORTENER: false,
  widenApi: {
    uploads: process.env.REACT_APP_APIURL_WIDEN_UPLOAD,
    assets: process.env.REACT_APP_APIURL_WIDEN_ASSETS
  },
  workDay: {
    workDayUrl: process.env.REACT_APP_WORK_DAY,
    editWorkDayUrl: process.env.REACT_APP_EDIT_WORK_DAY
  },
  cases: {
    casePageUrl: `${process.env.REACT_APP_NAVIGATOR_PAGE}cases/`
  },
  redirectionUrl: {
    pagesAppUrl: process.env.REACT_APP_PAGES_URL
  },
  disabledUserIds: process.env.REACT_APP_DISABLED_USER_IDS.split(","),
  linkedInUrl: process.env.REACT_APP_LINKEDIN_URL,
  linkedInRegionCodeSwitch: process.env.REACT_APP_LINKEDIN_REGION_CODE_SWITCH,
  linkedInSuperUsers: process.env.REACT_APP_LINKEDIN_SUPER_USERS.split("|"),
  linkedInReleaseRegions: process.env.REACT_APP_LINKEDIN_RELEASE_REGIONS.split("|"),
  linkedInReleaseOffices: process.env.REACT_APP_LINKEDIN_RELEASE_OFFICES.split("|"),
  avgProfileViews: process.env.REACT_APP_AVG_PROFILE_VIEWS,
  avgCvBioDownloads: process.env.REACT_APP_AVG_BIO_DOWNLOADS,
  sharedConfig: {
    GN_CO: {
      SP_XA: process.env.REACT_APP_STAFF_PHOTO_API_KEY,
      PP_XA: process.env.REACT_APP_PRIVACY_POLICY_API_KEY,
      SC_XA: process.env.REACT_APP_SEARCH_CONFIG_API_KEY,
      NO_XA: process.env.REACT_APP_NOTIFICATION_API_KEY
    },
    ST_CO: {
      MAT_XA: process.env.REACT_APP_MATERIALS_API_KEY,
      CA_XA: process.env.REACT_APP_CASES_API_KEY,
      DV1_XA: process.env.REACT_APP_DIRECTORY_API_KEY,
      DV2_XA: process.env.REACT_APP_DIRECTORY_V2_API_KEY,
      BI_XA: process.env.REACT_APP_BCG_INTERNAL_API_KEY
    }
  },
  notificationConfig: {
    publisher: process.env.REACT_APP_NOTIFICATION_PUBLISHERS,
    headerTitle: process.env.REACT_APP_NOTIFICATION_HEADER_TITLE,
    poolingInterval: process.env.REACT_APP_NOTIFICATION_POOLING_INTERVAL,
    preferencesTitle: process.env.REACT_APP_NOTIFICATION_PREFERENCES_TITLE
  }
};

export default CONFIG;
