import { proficiency } from "redux/constants";

const getProficiency = (payload) => {
  return {
    type: proficiency.FETCH_PROFICIENCY,
    payload
  };
};
const setProficiency = (data) => {
  return {
    type: proficiency.FETCH_PROFICIENCY_SUCCESS,
    data
  };
};
const setProficiencyError = (error) => {
  return {
    type: proficiency.FETCH_PROFICIENCY_FAILURE,
    error
  };
};
export { getProficiency, setProficiency, setProficiencyError };
