import { call, put as putSaga } from "redux-saga/effects";
import { put } from "redux/api";
import { setDeleteCaseTopicsAndSkillsSuccess, setDeleteCaseTopicsAndSkillsError } from "redux/actions/cases";

function* handleDeleteCaseTopicsSkills({ payload }) {
  const { res, error } = yield call(put, payload);
  if (res?.data?.error || error) {
    yield putSaga(setDeleteCaseTopicsAndSkillsError(error));
  } else {
    yield putSaga(setDeleteCaseTopicsAndSkillsSuccess(res));
  }
}

export default handleDeleteCaseTopicsSkills;
