import { award } from "redux/constants";

const awardDeletingErrorReducer = (state = null, action) => {
  switch (action.type) {
    case award.DELETE_AWARD_FAILURE:
      return action.error;
    case award.DELETE_AWARD_SUCCESS:
    case award.DELETE_AWARD:
      return null;
    default:
      return state;
  }
};

export default awardDeletingErrorReducer;
