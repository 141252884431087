import { takeLatest } from "redux-saga/effects";

import { cases } from "redux/constants";
import handleFetchCaseResponsibility from "../worker/handleFetchCaseResponsibility";

function* watchFetchCaseResponsibility() {
  yield takeLatest(cases.GET_RESPONSIBILITIES, handleFetchCaseResponsibility);
}

export default watchFetchCaseResponsibility;
