import { takeLatest } from "redux-saga/effects";

import { cases } from "redux/constants";
import handleUpdateCaseSkills from "../worker/handleUpdateCaseSkills";

function* watchUpdateCaseSkills() {
  yield takeLatest(cases.UPDATE_CASE_SKILLS, handleUpdateCaseSkills);
}

export default watchUpdateCaseSkills;
