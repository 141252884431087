import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";

function PipedList({ items }) {
  return (
    <span className="piped-list">
      {items?.map((item) => (
        <span key={item?.name}>{item?.name}</span>
      ))}
    </span>
  );
}

PipedList.defaultProps = {
  items: []
};

PipedList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({}))
};

export default PipedList;
