import { experience } from "redux/constants";

const experienceErrorReducer = (state = {}, action) => {
  switch (action.type) {
    case experience.FETCH_EXPERIENCE_FAILURE:
      return action.error;
    case experience.FETCH_EXPERIENCE_SUCCESS:
    case experience.FETCH_EXPERIENCE:
      return null;
    default:
      return state;
  }
};

export default experienceErrorReducer;
