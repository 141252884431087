import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Card } from "semantic-ui-react";
import { noop } from "lodash";
import { Loading } from "components/common";
import LABEL from "constants/label";
import sendEventData from "analytics/analytics";
import { EVENTS, TRIGGERS } from "analytics/constants";
import { isMD } from "pages/helpers/utils";
import PowerByLinkedIn from "components/common/PowerByLinkedIn";
import { sortListBasedOnEndYear, sortRolesListBasedOnEndYear, createParamsObject } from "helpers/utils";
import EditButton from "components/common/EditButton";
import { useLocation, useHistory } from "react-router-dom";
import useUpdateQueryParams from "customHooks/useUpdateQueryParams";
import ExperienceViewAll from "./ExperienceViewAll";
import ExperienceCard from "./ExperienceCard";
import { LinkedInExperienceCard, LinkedInExperienceViewAll } from "./LinkedInExperience";
import "./styles.scss";

const { EXPERIENCE, COMMON } = LABEL;

/*
 * Experience - Experience card
 * @param  {object} experience object with experience values
 * @param  {string} className class name
 * @param  {bool} isEditable  true if user seeing his own profile
 * @param  {bool} isLoading loading state
 *
 * @return return component values
 *
 * @example
 *
 * <Experience experience={experience} />
 */

const Experience = ({
  className,
  isEditable,
  isLoading,
  experience,
  workdayURL,
  setLinkedInModalOpen,
  loggedInUserHrId
}) => {
  const [open, setOpen] = useState(false);
  const [sortedExperience, setSortedExperience] = useState([]);

  const {
    linkedInData,
    linkedInDataLoading,
    linkedInUserStatus,
    peopleData: { id = null }
  } = useSelector((state) => state);
  const [sortedLinkedInExperience, setSortedLinkedInExperience] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const { updateQueryParams } = useUpdateQueryParams(location, history);

  useEffect(() => {
    setSortedLinkedInExperience([]);

    if (linkedInData?.experiences?.length > 0) {
      const expRoleListSorted = sortRolesListBasedOnEndYear(linkedInData?.experiences); // sorts the role list based on the end date

      setSortedLinkedInExperience(sortListBasedOnEndYear(expRoleListSorted)); // sort the experience list based on roles end date and set the state
    }
  }, [linkedInData?.experiences]);

  const handleOnClick = () => {
    window.$tabName = EXPERIENCE.Experience; // Global variable for Analytics
    sendEventData(TRIGGERS.COMMON.VIEW_ALL, EVENTS.EXPERIENCE_SECTION); // Analytics Event
    setOpen(true);
  };

  const handleOnCloseClick = () => {
    window.$tabName = EXPERIENCE.Experience; // Global variable for Analytics
    sendEventData(TRIGGERS.LINKEDIN.CLOSE, EVENTS.EXPERIENCE_SECTION);
    setOpen(false);
  };

  const handleEditOnWorkDay = () => {
    window.$tabName = EXPERIENCE.Experience; // Global variable for Analytics
    sendEventData(TRIGGERS.COMMON.EDIT, EVENTS.EXPERIENCE_SECTION); // Analytics Event
    window.open(workdayURL, "_blank");
  };

  const handleLinkedInSettings = () => {
    updateQueryParams({ ...createParamsObject(location?.search), edit: "linked-in" });
    setLinkedInModalOpen(true);
  };

  useEffect(() => {
    if (experience?.length) {
      setSortedExperience(
        experience.sort((a, b) => {
          return new Date(b.endDate) - new Date(a.endDate);
        })
      );
    }
  }, [experience]);

  return (
    <>
      {(!isLoading && !linkedInDataLoading && (
        <Card fluid className={`${className}`}>
          <Card.Content>
            <Card.Header>
              <div className="d-flex justify-content-between align-items-center mb-h">
                <div style={isMD() ? { width: "85%", maxWidth: "22rem" } : {}}>{EXPERIENCE.Experience}</div>
                {/* Edit button popup */}
                {isEditable && (!linkedInUserStatus?.status?.optin || sortedLinkedInExperience?.length === 0) && (
                  <EditButton caption={COMMON.EditOnWorkDay} callback={handleEditOnWorkDay} />
                )}
                {isEditable &&
                  linkedInUserStatus?.status?.optin &&
                  !!sortedLinkedInExperience?.length &&
                  +loggedInUserHrId === +id && (
                    <EditButton caption={COMMON.Settings} callback={handleLinkedInSettings} />
                  )}
              </div>
              {linkedInUserStatus?.status?.optin && !!sortedLinkedInExperience?.length && <PowerByLinkedIn />}
            </Card.Header>
          </Card.Content>
          {sortedExperience?.length > 0 &&
            (!linkedInData?.experiences?.length || !linkedInUserStatus?.status?.optin) && (
              <ExperienceCard experience={sortedExperience?.slice(0, 3)} />
            )}
          {linkedInUserStatus?.status?.optin && linkedInData?.experiences?.length > 0 && (
            <LinkedInExperienceCard linkedInExperience={sortedLinkedInExperience?.slice(0, 3)} />
          )}
          {!sortedExperience?.length && (!linkedInData?.experiences || !linkedInUserStatus?.status?.optin) && (
            <Card.Content textAlign="center" className="content--no-info">
              <h4>{EXPERIENCE.NoExpAdded}</h4>
            </Card.Content>
          )}
          {/* Showing View All link if experience count is more then 3 */}
          {sortedExperience?.length > 3 || linkedInData?.experiences?.length > 3 ? (
            <Card.Content textAlign="center" extra>
              <span role="button" className="cursor-pointer" onClick={handleOnClick} tabIndex={0} onKeyDown={noop}>
                {COMMON.ViewAll}
              </span>
            </Card.Content>
          ) : null}
        </Card>
      )) || <Loading title="Pre-BCG Experience" className="mobile-order-6 card-exp" />}
      {open && !linkedInData?.experiences?.length && (
        <ExperienceViewAll
          open={open}
          onClose={handleOnCloseClick}
          experience={sortedExperience}
          workdayURL={workdayURL}
        />
      )}
      {open && linkedInData?.experiences?.length && (
        <LinkedInExperienceViewAll
          open={open}
          onClose={handleOnCloseClick}
          linkedInExperience={sortedLinkedInExperience}
        />
      )}
    </>
  );
};

Experience.defaultProps = {
  className: "",
  isEditable: false,
  isLoading: false,
  experience: [],
  workdayURL: "",
  setLinkedInModalOpen: () => {}
};

Experience.propTypes = {
  className: PropTypes.string,
  isEditable: PropTypes.bool,
  isLoading: PropTypes.bool,
  experience: PropTypes.arrayOf(
    PropTypes.shape({
      jobTitle: PropTypes.string,
      company: PropTypes.string,
      companyDesc: PropTypes.string,
      endDate: PropTypes.string,
      startDate: PropTypes.string,
      location: PropTypes.string
    })
  ),
  workdayURL: PropTypes.string,
  setLinkedInModalOpen: PropTypes.func,
  loggedInUserHrId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

export default Experience;
