import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";

/**
 * @param {string} text text to be displayed
 * @param {string} imgSrc src of the icon image
 * @param {string} altText alternate text for image
 * @param {string} labelText text of the lable if any, will not be shown in mobile view
 * @param {string} delimiter text or character delimiter separating label from text display
 * @param {string} subText subtext if any, will not be displayed in mobile view
 *
 * @returns IconsWithText Component
 *
 * @example
 * <IconWithText
 *  text="Los Angeles, USA"
 *  imgSrc="/icons/loc_marker.svg"
 *  delimiter=":"
 *  altText="Location"
 * />
 */

function IconWithText({ text, imgSrc, altText, labelText, delimiter, subText }) {
  const styleRefName = subText ? "icon-text--with-subtext" : "icon-text";

  return (
    (text && (
      <div className={`${styleRefName}`}>
        <span>
          <img src={imgSrc} alt={altText} className="icon-text__image" />
        </span>
        {labelText && <span className="label__text ml-h">{labelText}</span>}
        {delimiter && text && <span className="label__text ml-h">{delimiter}</span>}
        <span className="ml-h">{text}</span>
        {!!subText && <span className="ml-h m-d-none">{subText}</span>}
      </div>
    )) ||
    null
  );
}

IconWithText.propTypes = {
  text: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
  altText: PropTypes.string.isRequired,
  labelText: PropTypes.string,
  delimiter: PropTypes.string,
  subText: PropTypes.string
};

export default IconWithText;
