import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Grid, Card, Placeholder, Image } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import { fetchProfileCompletionData } from "components/App/handlers/handlers";
import Cookies from "js-cookies";
import LABEL from "constants/label";
import "./styles.scss";
import ProfileCompletion from "components/ProfileCompletion";
import { setProfileComplete } from "redux/actions/profileCompletion";
import CONFIG from "constants/config";
import { isEmptyOrNull } from "helpers/utils";
import ProfileInsights from "./ProfileInsights";

/**
 * Update Overview
 *
 * @param  {object} profile contains profile info
 * @param  {string} className class name
 * @param  {bool} isLoading loading state of profiles data
 * @param  {object} materials collection of the profile's materials data
 * @param  {string} loggedInUserHrId loggedin User HrId
 *
 * @return component
 *
 * @example
 * <UpdateOverview className="mobile-order-1" profile={profile} cases={cases} isLoading={isLoading} loggedInUserHrId={loggedInUserHrId} />
 */

const UpdateOverview = ({ profile, className, isLoading, loggedInUserHrId }) => {
  const dispatch = useDispatch();
  const { apiUrl, xApiKey } = CONFIG;
  const { role } = profile;
  const { profileCompletionData, profileCompletionLoading, peopleData, updateProfileCompletion } = useSelector(
    (state) => state
  );

  const {
    PROFILE_OVERVIEW: { ProfileOverview, PrivateToYou }
  } = LABEL;

  const [isProfileCompletionVisible, setProfileCompletionVisibility] = useState(false);
  const [isProfileInsightsVisible, setProfileInsightsVisibility] = useState(false);
  const [isVisitorView, setVisitorView] = useState(true);
  const profileCompletionPercentage = Math.floor(profileCompletionData?.totalPercentage);
  const [percentCompleted, setPercentCompleted] = useState(profileCompletionPercentage);
  const [isConfirmationInProgress, setIsConfirmationInProgress] = useState(false);

  const isImpersonate = Cookies.getItem("impersonateUser");
  const impersonatedUserHrId = Cookies.getItem("impersonatedUserHrId");
  const [userId, setUserId] = useState(loggedInUserHrId);

  useEffect(() => {
    if (isImpersonate) {
      setUserId(impersonatedUserHrId);
      setVisitorView(profile?.hrId !== +impersonatedUserHrId);
    } else {
      setVisitorView(profile?.hrId !== +loggedInUserHrId);
    }
  }, [impersonatedUserHrId, isImpersonate, profile, loggedInUserHrId]);

  useEffect(() => {
    fetchProfileCompletionData(peopleData?.id, dispatch);
  }, [peopleData?.id]);

  useEffect(() => {
    if (updateProfileCompletion) {
      fetchProfileCompletionData(peopleData?.id, dispatch);
    }
  }, [updateProfileCompletion]);

  useEffect(() => {
    if (updateProfileCompletion && !isEmptyOrNull(profileCompletionData && !profileCompletionLoading)) {
      setIsConfirmationInProgress(false);
    }
  }, [profileCompletionData, updateProfileCompletion, profileCompletionLoading]);

  useEffect(() => {
    setPercentCompleted(profileCompletionPercentage);
  }, [profileCompletionData]);

  const toggelPercentCompleted = (hrId, isComplete) => {
    dispatch(
      setProfileComplete({
        endpoint: `${apiUrl.linkApi}profile/${hrId}/confirm/currentProfile/${isComplete}`,
        config: {
          headers: {
            "x-api-key": `${xApiKey.profile}`
          }
        }
      })
    );
    setIsConfirmationInProgress(true);
  };
  const allowedUserRoles = ["ct", "ect", "kt", "sa", "sct"]; // Roles for which update overview section is enabled.

  useEffect(() => {
    if (allowedUserRoles.includes(role)) {
      setProfileCompletionVisibility(true);
    }
    if (Number(userId) === profile?.hrId) {
      setProfileInsightsVisibility(true);
    }
  }, [profile, userId]);

  return (
    <>
      {!isLoading ? (
        <>
          {isProfileInsightsVisible || isProfileCompletionVisible}
          {isProfileCompletionVisible ? (
            <Card fluid className={`${className} profile__overview`}>
              {isProfileInsightsVisible || !isVisitorView ? (
                <Card.Content>
                  <Card.Header data-testid="updateOverviewHeader">{ProfileOverview}</Card.Header>
                  <Card.Meta>
                    <Image src="/icons/private-view.svg" />
                    <span>{PrivateToYou}</span>
                  </Card.Meta>
                </Card.Content>
              ) : null}
              {isProfileInsightsVisible ? <ProfileInsights profile={profile} loggedInUserHrId={userId} /> : null}
              {isProfileCompletionVisible ? (
                <>
                  <Card.Content>
                    <div className="update-overview" data-testid="profileCompletionSection">
                      <Grid>
                        <Grid.Row>
                          <Grid.Column mobile={8} computer="16">
                            <ProfileCompletion
                              isVisitorView={isVisitorView}
                              loggedInUserHrId={userId}
                              percentCompleted={percentCompleted}
                              toggelPercentCompleted={toggelPercentCompleted}
                              isConfirmationInProgress={isConfirmationInProgress}
                            />
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </div>
                  </Card.Content>
                </>
              ) : null}
            </Card>
          ) : null}
        </>
      ) : (
        <Card fluid style={{ padding: "10px" }}>
          <Placeholder data-testid="updateOverviewLoader">
            <Placeholder.Header>
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Header>
            <Placeholder.Paragraph>
              <Placeholder.Line length="medium" />
              <Placeholder.Line length="short" />
            </Placeholder.Paragraph>
          </Placeholder>
        </Card>
      )}
    </>
  );
};

UpdateOverview.defaultProps = {
  profile: {},
  className: ""
};

UpdateOverview.propTypes = {
  profile: PropTypes.shape({
    id: PropTypes.number,
    hrId: PropTypes.number,
    hrEmployeeId: PropTypes.number,
    profileLastUpdateDate: PropTypes.string,
    percentComplete: PropTypes.number,
    incompleteFields: PropTypes.arrayOf(PropTypes.string),
    profileView: PropTypes.shape({
      total: PropTypes.number,
      details: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          count: PropTypes.number
        })
      )
    }),
    role: PropTypes.string
  }),
  className: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  loggedInUserHrId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

export default UpdateOverview;
