import { takeLatest } from "redux-saga/effects";

import { linkedIn } from "redux/constants";
import handleFetchLinkedInAuthStatus from "../worker/handleFetchLinkedInAuthStatus";

function* watchFetchLinkedInAuthStatus() {
  yield takeLatest(linkedIn.FETCH_LINKEDIN_AUTH_STATUS, handleFetchLinkedInAuthStatus);
}

export default watchFetchLinkedInAuthStatus;
