import { skills } from "redux/constants";

const skillUpdatingReducer = (state = false, action) => {
  switch (action.type) {
    case skills.UPDATE_SKILL:
      return true;
    case skills.UPDATE_SKILL_SUCCESS:
    case skills.UPDATE_SKILL_FAILURE:
      return false;
    default:
      return state;
  }
};

export default skillUpdatingReducer;
