import { takeLatest } from "redux-saga/effects";

import { userAccessRoles } from "redux/constants";
import { handleFetchAccessRoles } from "../worker/handleFetchAccessRoles";

function* watchAccessRoles() {
  yield takeLatest(userAccessRoles.FETCH_USER_ACCESS_ROLES, handleFetchAccessRoles);
}

export default watchAccessRoles;
