import { takeEvery } from "redux-saga/effects";

import { organization } from "redux/constants";
import handleFetchOrgMemberImage from "../worker/handleFetchOrgMemberImage";

function* watchOrgMemberImage() {
  yield takeEvery(organization.FETCH_ORG_MEMBER_IMAGE, handleFetchOrgMemberImage);
}

export default watchOrgMemberImage;
