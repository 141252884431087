import { user } from "redux/constants";

const getUser = (endpoint) => {
  return {
    type: user.FETCH_USER,
    endpoint
  };
};

const setUser = (data) => ({
  type: user.FETCH_SUCCESS,
  data
});

const setError = (error) => ({
  type: user.FETCH_FAILURE,
  error
});

export { getUser, setUser, setError };
