import { takeLatest } from "redux-saga/effects";

import { proficiency } from "redux/constants";
import handleProficiency from "../worker/handleProficiency";

function* watchProficiency() {
  yield takeLatest(proficiency.FETCH_PROFICIENCY, handleProficiency);
}

export default watchProficiency;
