import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";

function PrimaryButton({ btnText, btnIcon, onBtnClick }) {
  return (
    <button className="primary__button" onClick={onBtnClick} type="button" data-testid="primary-button">
      {btnIcon && (
        <span className="primary__button__icon" data-testid="primary_button-icon">
          {btnIcon}
        </span>
      )}
      <span className="primary__button__text">{btnText}</span>
    </button>
  );
}

PrimaryButton.defaultProps = {
  btnIcon: "",
  onBtnClick: () => {}
};

PrimaryButton.propTypes = {
  btnText: PropTypes.string.isRequired,
  btnIcon: PropTypes.string,
  onBtnClick: PropTypes.func
};

export default PrimaryButton;
