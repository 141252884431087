import { linkedIn } from "redux/constants";

const removeLinkedInAccessReducer = (state = null, action) => {
  switch (action.type) {
    case linkedIn.REMOVE_LINKEDIN_ACCESS_SUCCESS: {
      return action?.data ? action?.data : null;
    }
    default:
      return state;
  }
};

export default removeLinkedInAccessReducer;
