import { impersonate } from "redux/constants";

const getImpersonatedUserImage = (payload) => {
  return {
    type: impersonate.FETCH_IMAGE,
    payload
  };
};

const setImpersonatedUserImage = (data) => {
  return {
    type: impersonate.FETCH_IMAGE_SUCCESS,
    data
  };
};
const setImpersonatedUserImageError = (error) => {
  return {
    type: impersonate.FETCH_IMAGE_FAILURE,
    error
  };
};

export { getImpersonatedUserImage, setImpersonatedUserImage, setImpersonatedUserImageError };
