import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import { Icon } from "semantic-ui-react";

function ExternalLinkButton({ btnText, externalUrl }) {
  return (
    <a
      className="external__link"
      href={externalUrl}
      target="_blank"
      rel="noopener noreferrer"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <span className="external__link__text">{btnText}</span>
      <Icon name="external alternate" />
    </a>
  );
}

ExternalLinkButton.propTypes = {
  btnText: PropTypes.string.isRequired,
  externalUrl: PropTypes.string.isRequired
};

export default ExternalLinkButton;
