import { call, put } from "redux-saga/effects";
import { get } from "redux/api";
import { setCases, setError } from "redux/actions/cases";

function* handleFetchCases(endpoint) {
  const { data, error } = yield call(get, endpoint);
  if (error) {
    yield put(setError(error));
  } else {
    yield put(setCases(data));
  }
}

export default handleFetchCases;
