import { userStaffId } from "redux/constants";

const staffIdErrorReducer = (state = {}, action) => {
  switch (action.type) {
    case userStaffId.FETCH_USER_STAFF_ID_FAILURE:
      return action.error;
    case userStaffId.FETCH_USER_STAFF_ID_SUCCESS:
    case userStaffId.FETCH_USER_STAFF_ID:
      return null;
    default:
      return state;
  }
};

export default staffIdErrorReducer;
