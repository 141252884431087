import { call, put } from "redux-saga/effects";
import { post } from "redux/api";
import { setUpdateResponsibilitySuccess, setUpdateResponsibilityFailure } from "redux/actions/cases";

function* handleUpdateResponsibilities({ payload }) {
  const { res } = yield call(post, payload);
  if (res.data.error.code !== "200") {
    yield put(setUpdateResponsibilityFailure(res.data.error));
  } else {
    yield put(setUpdateResponsibilitySuccess(res.data.data));
  }
}

export default handleUpdateResponsibilities;
