import { entitlements } from "redux/constants";

const getPermissions = (userEntitlements) => {
  let permissions = {};
  if (userEntitlements.length > 0)
    userEntitlements.forEach((entitlement) => {
      switch (entitlement.entitlementId) {
        case 19: {
          permissions = { ...permissions, canViewProfile: entitlement?.valid };
          break;
        }
        case 20: {
          permissions = { ...permissions, canEditProfile: entitlement?.valid };
          break;
        }
        case 21: {
          permissions = { ...permissions, canEditColleagueProfile: entitlement?.valid };
          break;
        }
        case 22: {
          permissions = { ...permissions, isECT: entitlement?.valid };
          break;
        }
        case 23: {
          permissions = { ...permissions, isSA: entitlement?.valid };
          break;
        }
        case 24: {
          permissions = { ...permissions, isKT: entitlement?.valid };
          break;
        }
        case 25: {
          permissions = { ...permissions, isCCO: entitlement?.valid };
          break;
        }
        case 26: {
          permissions = { ...permissions, canImpersonate: entitlement?.valid };
          break;
        }
        default: {
          return permissions;
        }
      }
    });
  return permissions;
};

const entitlementsReducer = (state = {}, action) => {
  switch (action.type) {
    case entitlements.FETCH_ENTITLEMENTS_SUCCESS: {
      return getPermissions(action?.data?.products[0]?.entitlements);
    }
    default:
      return state;
  }
};

export default entitlementsReducer;
