import { call, put } from "redux-saga/effects";
import { post } from "redux/api";
import { setProposalFileSuccess, setProposalFileError } from "redux/actions/editProfile";

function* handleProposalFile({ payload }) {
  const { data, error } = yield call(post, payload);
  if (error) {
    yield put(setProposalFileError(error));
  } else {
    yield put(setProposalFileSuccess(data));
  }
}

export default handleProposalFile;
