import { takeLatest } from "redux-saga/effects";

import { profileCompletion } from "redux/constants";
import handleFetchProfileCompletionVerbiage from "../worker/handleFetchProfileCompletionVerbiage";

function* watchProfileCompletionVerbiage() {
  yield takeLatest(profileCompletion.FETCH_PROFILE_COMPLETION_VERBIAGE, handleFetchProfileCompletionVerbiage);
}

export default watchProfileCompletionVerbiage;
