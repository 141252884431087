import { materials } from "redux/constants";

const materialsLoadingReducer = (state = false, action) => {
  switch (action.type) {
    case materials.FETCH_MATERIALS:
      return true;
    case materials.FETCH_MATERIALS_SUCCESS:
    case materials.FETCH_MATERIALS_FAILURE:
      return false;
    default:
      return state;
  }
};

export default materialsLoadingReducer;
