import PropTypes from "prop-types";

import Masonry from "react-masonry-css";
import ProfileSummary from "components/ProfileSummary";
import Experience from "components/Experience";
import Education from "components/Education/Education";
import { useSelector } from "react-redux";
import UpdateOverview from "components/UpdateOverview";
import Achievements from "components/Achievements";
// import Expertise from "components/Expertise";
import About from "components/About/About";

import OrgStructure from "components/OrgStructure";

const IpadMasonary = ({
  isProfileLoading,
  showElement,
  isEditable,
  allCases,
  ssCasesLoading,
  materialsData,
  ssMaterialLoadingBatch,
  isAboutHasValue,
  setLinkedInModalOpen,
  showMetrics,
  loggedInUserHrId
}) => {
  const { profile, peopleData, peopleDataError, peopleDataLoading } = useSelector((state) => state);

  return (
    <>
      <Masonry breakpointCols={1} className="masonry-gridIpad" columnClassName="masonry-gridIpad__column">
        <ProfileSummary
          className="profile-summury-card"
          user={profile}
          isLoading={isProfileLoading}
          isEditable={isEditable}
          languageData={peopleData?.languages}
        />
        {showElement}
      </Masonry>
      <Masonry breakpointCols={2} className="masonry-gridIpad" columnClassName="masonry-gridIpad__column">
        {showMetrics && (
          <UpdateOverview
            className="mx-auto pt-0"
            profile={profile}
            isLoading={isProfileLoading}
            cases={allCases}
            isSSCasesLoadingBatch={ssCasesLoading}
            materials={materialsData}
            isSSMaterialLoadingBatch={ssMaterialLoadingBatch}
            loggedInUserHrId={loggedInUserHrId}
          />
        )}
        {!!isAboutHasValue && (
          <About
            className="mobile-order-3"
            profile={profile}
            isEditable={isEditable}
            loggedInUserHrId={loggedInUserHrId}
          />
        )}
        <Experience
          profile={profile}
          isEditable={isEditable}
          isLoading={isProfileLoading || peopleDataLoading}
          experience={peopleData?.workExperience}
          workdayURL={peopleData.workerURL}
          setLinkedInModalOpen={setLinkedInModalOpen}
          loggedInUserHrId={loggedInUserHrId}
        />
        <Education
          profile={profile}
          isEditable={isEditable}
          isLoading={isProfileLoading || peopleDataLoading}
          education={peopleData?.education}
          setLinkedInModalOpen={setLinkedInModalOpen}
          loggedInUserHrId={loggedInUserHrId}
        />
        {/* {renderComponentOrLoader(<Expertise summary={expertiseSummary} isEditable={isEditable} />, {
          title: "Expertise"
        })}
        <></> */}
        {peopleData?.alumni?.toLowerCase() === "no" && (
          <OrgStructure
            profile={profile}
            isLoading={isProfileLoading || peopleDataLoading}
            orgError={peopleDataError}
            organization={peopleData}
          />
        )}
        <Achievements isEditable={isEditable} isLoading={isProfileLoading || peopleDataLoading} />
      </Masonry>
    </>
  );
};

IpadMasonary.defaultProps = {
  isProfileLoading: false,
  showElement: false,
  isEditable: false,
  allCases: [],
  ssCasesLoading: false,
  materialsData: [],
  ssMaterialLoadingBatch: false,
  isAboutHasValue: false,
  showMetrics: false,
  loggedInUserHrId: null,
  setLinkedInModalOpen: () => {}
};

IpadMasonary.propTypes = {
  isProfileLoading: PropTypes.bool,
  showElement: PropTypes.bool,
  isEditable: PropTypes.bool,
  allCases: PropTypes.arrayOf(Object),
  ssCasesLoading: PropTypes.bool,
  materialsData: PropTypes.arrayOf(Object),
  ssMaterialLoadingBatch: PropTypes.bool,
  isAboutHasValue: PropTypes.bool,
  showMetrics: PropTypes.bool,
  loggedInUserHrId: PropTypes.number,
  setLinkedInModalOpen: PropTypes.func
};

export default IpadMasonary;
