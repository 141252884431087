import { userProfileImage } from "redux/constants";

const getProfileImage = (payload) => {
  return {
    type: userProfileImage.FETCH_USER_IMAGE,
    payload
  };
};

const setProfileImage = (data) => {
  return {
    type: userProfileImage.FETCH_USER_IMAGE_SUCCESS,
    data
  };
};
const setProfileImageError = (error) => {
  return {
    type: userProfileImage.FETCH_USER_IMAGE_FAILURE,
    error
  };
};

export { getProfileImage, setProfileImage, setProfileImageError };
