import { linkedIn } from "redux/constants";

const linkedInRedirectUrlLoadingReducer = (state = false, action) => {
  switch (action.type) {
    case linkedIn.FETCH_LINKEDIN_REDIRECT_URL:
      return true;
    case linkedIn.FETCH_LINKEDIN_REDIRECT_URL_SUCCESS:
    case linkedIn.FETCH_LINKEDIN_REDIRECT_URL_FAILURE:
      return false;
    default:
      return state;
  }
};

export default linkedInRedirectUrlLoadingReducer;
