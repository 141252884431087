import { takeLatest } from "redux-saga/effects";

import { linkedIn } from "redux/constants";
import handleUpdateDeclineStatus from "../worker/handleUpdateDeclineStatus";

function* watchUpdateLinkedInDeclineStatus() {
  yield takeLatest(linkedIn.UPDATE_LINKEDIN_DECLINE_STATUS, handleUpdateDeclineStatus);
}

export default watchUpdateLinkedInDeclineStatus;
