import { cases } from "redux/constants";

const { STATUS } = cases;

const deleteCaseTopicsSkillsReducer = (state = "", action) => {
  switch (action.type) {
    case cases.DELETE_CASE_TOPICS_SKILLS:
      return STATUS.DELETING;
    case cases.DELETE_CASE_TOPICS_SKILLS_SUCCESS:
      return STATUS.DELETE_SUCCESS;
    case cases.DELETE_CASE_TOPICS_SKILLS_ERROR:
      return STATUS.DELETE_FAILURE;
    case cases.RESET_CASE_TOPICS_SKILL_STATUS:
      return "";
    default:
      return state;
  }
};

export default deleteCaseTopicsSkillsReducer;
