import { takeLatest } from "redux-saga/effects";

import { editProfile } from "redux/constants";
import handleUpdateProfile from "../worker/handleUpdateProfile";

function* watchUpdateProfile() {
  yield takeLatest(editProfile.UPDATE_PROFILE, handleUpdateProfile);
}

export default watchUpdateProfile;
