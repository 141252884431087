import { award } from "redux/constants";

const awardUpdatingReducer = (state = false, action) => {
  switch (action.type) {
    case award.UPDATE_AWARD:
      return true;
    case award.UPDATE_AWARD_SUCCESS:
    case award.UPDATE_AWARD_FAILURE:
      return false;
    default:
      return state;
  }
};

export default awardUpdatingReducer;
