import { ssRecentMaterials } from "redux/constants";

const ssRecentMaterialsErrorReducer = (state = {}, action) => {
  switch (action.type) {
    case ssRecentMaterials.FETCH_RECENT_SSMATERIALS_FAILURE:
      return action.error;
    case ssRecentMaterials.FETCH_RECENT_SSMATERIALS_SUCCESS:
    case ssRecentMaterials.FETCH_RECENT_SSMATERIALS:
      return null;
    default:
      return state;
  }
};

export default ssRecentMaterialsErrorReducer;
