import { call, put } from "redux-saga/effects";
import { get } from "redux/api";
import { setFetchPronunciationError, setFetchPronunciationSuccess } from "redux/actions/pronunciation";

function* handleFetchPronunciation({ payload }) {
  const { data, error } = yield call(get, payload);
  if (error) {
    yield put(setFetchPronunciationError(error));
  } else {
    yield put(setFetchPronunciationSuccess(data));
  }
}

export default handleFetchPronunciation;
