import { takeLatest } from "redux-saga/effects";

import { designatedBy } from "redux/constants";
import handleDesignatedBy from "../worker/handleDesignatedBy";

function* watchDesignatedBy() {
  yield takeLatest(designatedBy.FETCH_DESIGNATEDBY, handleDesignatedBy);
}

export default watchDesignatedBy;
