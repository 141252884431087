import { call, put as putSaga } from "redux-saga/effects";
import { put } from "redux/api";
import { setRemoveLinkedInAccessError, setRemoveLinkedInAccessSuccess } from "redux/actions/linkedIn";

function* handleRemoveLinkedInAccess({ payload }) {
  const { res, error } = yield call(put, payload);
  if (error) {
    yield putSaga(setRemoveLinkedInAccessError(error));
  } else {
    yield putSaga(setRemoveLinkedInAccessSuccess(res?.data));
  }
}

export default handleRemoveLinkedInAccess;
