import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import Case from "components/common/Case";
import CaseLoading from "components/common/Case/CaseLoading";
import { Card } from "semantic-ui-react";
import { useQuery } from "helpers/utils";
import LABEL from "constants/label";
import "./styles.scss";

/**
 * Cases - Overview page
 *
 * @param  {string} className Class name
 * @param  {bool} isLoading sscase loading boolean
 * @param  {arrayOf} cases merged cases
 * @param  {object} profile user profile object
 * @param  {bool} isEditable checks if profile is editable
 * @param  {bool} casesLoading link cases loading boolean
 * @param  {object} linkCases link cases
 * @param  {bool} ssCasesLoading loading state
 *
 * @return Case card
 *
 */

const ProfileCases = ({ className, isLoading, isEditable, profile, cases, linkCases }) => {
  const query = useQuery(useLocation().search);
  const id = query.get("hrid");

  const [allCases, setAllCases] = useState(cases);

  useEffect(() => {
    setAllCases(cases);
  }, [cases]);

  const len = allCases?.length || 0;

  return (
    <>
      <Card fluid className={`${className}`}>
        <Card.Content>
          <Card.Header>{LABEL.CASES.RecentCases}</Card.Header>
        </Card.Content>
        <Card.Content className="profile-cases">
          {/* Lopping threw cases */}
          {isLoading && <CaseLoading />}
          {!isLoading &&
            len > 0 &&
            allCases.map((caseObj) => {
              return (
                <Case
                  key={caseObj.projectId}
                  caseObj={caseObj}
                  loading={isLoading}
                  userId={profile?.hrId}
                  isEditable={isEditable}
                  profile={profile}
                  hideTopicsAndSkills
                  isOverviewPage
                />
              );
            })}
          {/* Showing No Case Added if no case is avaialbale */}
        </Card.Content>
        {len === 0 && !isLoading && (
          <Card.Content className="content--no-info" textAlign="center">
            <h4 className="mt-3 pb-3">{LABEL.CASES.NoCasesAdded}</h4>
          </Card.Content>
        )}
        {/* Show View all cases link */}
        {!isLoading && !!len && (
          <Card.Content textAlign="center" extra>
            <Link to={`/cases?hrid=${id}`}>{LABEL.CASES.ViewCases}</Link>
          </Card.Content>
        )}
      </Card>
    </>
  );
};

ProfileCases.defaultProps = {
  ssCases: {},
  className: "",
  isLoading: false,
  profile: {},
  linkCases: [],
  isEditable: false,
  cases: []
};

ProfileCases.propTypes = {
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  profile: PropTypes.shape(),
  linkCases: PropTypes.arrayOf(PropTypes.object),
  ssCases: PropTypes.shape({
    doc: PropTypes.arrayOf(PropTypes.object),
    caseNumber: PropTypes.number,
    caseType: PropTypes.string,
    caseOpenDate: PropTypes.string,
    caseCloseDate: PropTypes.string,
    shortDescription: PropTypes.string,
    officeName: PropTypes.string,
    caseVignettes: PropTypes.arrayOf(PropTypes.object),
    clientName: PropTypes.string,
    industryAllPAs: PropTypes.arrayOf(PropTypes.object),
    functionalAllPAs: PropTypes.arrayOf(PropTypes.object),
    caseHours: PropTypes.arrayOf(PropTypes.object)
  }),
  cases: PropTypes.arrayOf(PropTypes.shape()),
  isEditable: PropTypes.bool
};

export default ProfileCases;
