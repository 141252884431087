import PropTypes from "prop-types";
import styles from "./styles.module.scss";

/**
 * Expand component - Custom button component with +/- icon. On clicking it, the icon will get toggled.
 *
 * @param  {string} collapsedText - when expanded is false, default: Show More
 * @param  {string} expandedText - when expanded is true, default: Show Less
 * @param  {boolean} expanded - default: false
 * @param  {function} onClick - function that toggles the expanded state
 *
 * @return link with +/- icon and collapsed/expanded text
 *
 * @example
 *
 * <Expand />
 */
function Expand({ collapsedText, expandedText, expanded, onClick }) {
  const onClickHandler = () => {
    if (onClick && typeof onClick === "function") {
      onClick();
    }
  };

  return (
    <button type="button" className={`${styles["expand__button"]} cursor-pointer p-0 d-flex`} onClick={onClickHandler}>
      <span
        className={`${
          styles["expand__button__icon"]
        }  d-flex justify-content-center align-items-center position-relative ${expanded ? styles["expanded"] : ""}`}
      />
      <span className={styles["expand__button__text"]}>{expanded ? expandedText : collapsedText}</span>
    </button>
  );
}

Expand.defaultProps = {
  collapsedText: "Show More",
  expandedText: "Show Less",
  expanded: false,
  onClick: null
};

Expand.propTypes = {
  collapsedText: PropTypes.string,
  expandedText: PropTypes.string,
  expanded: PropTypes.bool,
  onClick: PropTypes.func
};

export default Expand;
