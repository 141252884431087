import { profileViews } from "redux/constants";

const profileViewsReducer = (state = {}, action) => {
  switch (action.type) {
    case profileViews.SAVE_PROFILE_VIEWS_SUCCESS: {
      return { ...action.data };
    }
    default:
      return state;
  }
};

export default profileViewsReducer;
