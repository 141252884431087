import { takeLatest } from "redux-saga/effects";

import { cases } from "redux/constants";
import handleUpdateResponsibilities from "../worker/handleUpdateResponsibilities";

function* watchUpdateCaseResponsibilities() {
  yield takeLatest(cases.UPDATE_RESPONSIBILITY, handleUpdateResponsibilities);
}

export default watchUpdateCaseResponsibilities;
