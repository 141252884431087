import { takeLatest } from "redux-saga/effects";

import { achievement } from "redux/constants";
import handleFetchAchievements from "../worker/handleFetchAchievements";

function* watchAchievements() {
  yield takeLatest(achievement.FETCH_ACHIEVEMENT, handleFetchAchievements);
}

export default watchAchievements;
