import { call, put } from "redux-saga/effects";
import { get } from "redux/api";
import { setProfileCompletionVerbiage, setProfileCompletionVerbiageError } from "redux/actions/profileCompletion";

function* handleFetchProfileCompletionVerbiage({ payload }) {
  const { data, error } = yield call(get, payload);
  if (error) {
    yield put(setProfileCompletionVerbiageError(error));
  } else {
    yield put(setProfileCompletionVerbiage(data));
  }
}

export default handleFetchProfileCompletionVerbiage;
