import CONFIG from "constants/config";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRecentSSInternalMaterials } from "redux/actions/ssRecentInternalMaterials";
import { getRecentSSMaterials } from "redux/actions/ssRecentMaterials";
import { getAllSortedRecentMaterials } from "helpers/utils";

const { apiUrl, xApiKey } = CONFIG;

const useFetchRecentMaterials = (profile, isErrorApi) => {
  const dispatch = useDispatch();

  const [recentMaterials, setRecentMaterials] = useState({});

  const { ssRecentMaterials } = useSelector((state) => state);
  const { ssRecentMaterialsLoading } = useSelector((state) => state);
  const [materialCount, setMaterialCount] = useState(null);
  const { ssRecentInternalMaterials } = useSelector((state) => state);
  const { ssRecentInternalMaterialsLoading } = useSelector((state) => state);

  useEffect(() => {
    if (profile?.hrId && !isErrorApi) {
      dispatch(
        getRecentSSMaterials({
          endpoint: `${apiUrl.ssRecentMaterials}&hrId=${profile?.hrId}&resultsPerPage=${4}&resultsFromPage=1`,
          config: {
            headers: {
              "x-api-key": `${xApiKey.smartsearch}`
            }
          }
        })
      );
      dispatch(
        getRecentSSInternalMaterials({
          endpoint: `${
            apiUrl.ssInternalMaterials
          }?query=&sortingOrder=dateRevised.desc&viewState=list&enableAutoCorrect=true&hrId=${
            profile?.hrId
          }&resultsPerPage=${4}&resultsFromPage=1`,
          config: {
            headers: {
              "x-api-key": `${xApiKey.smartsearch}`
            }
          }
        })
      );
    }
  }, [profile?.hrId, dispatch]);
  // Material count without initializing to 0

  useEffect(() => {
    if (recentMaterials.TotalCount) setMaterialCount(recentMaterials.TotalCount.toLocaleString());
    else if (!ssRecentMaterialsLoading && recentMaterials.TotalCount === undefined) setMaterialCount("0");
    else setMaterialCount("--");
  }, [recentMaterials]);

  useEffect(() => {
    setRecentMaterials(getAllSortedRecentMaterials(ssRecentMaterials, ssRecentInternalMaterials));
  }, [ssRecentMaterials, ssRecentInternalMaterials]);

  return {
    recentMaterialsLoading: ssRecentMaterialsLoading || ssRecentInternalMaterialsLoading,
    recentMaterials,
    materialCount
  };
};

export default useFetchRecentMaterials;
