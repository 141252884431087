import { call, put as putSaga } from "redux-saga/effects";
import { put } from "redux/api";
import { setProfileCompleteError, setProfileCompleteSuccess } from "redux/actions/profileCompletion";

function* handleUpdateProfileCompletion({ payload }) {
  const { data, error } = yield call(put, payload);
  if (error) {
    yield putSaga(setProfileCompleteError(error));
  } else {
    yield putSaga(setProfileCompleteSuccess(data));
  }
}

export default handleUpdateProfileCompletion;
