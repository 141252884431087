import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import Popover from "components/common/Popover";
import { Placeholder } from "semantic-ui-react";
import LABEL from "constants/label";
import fetchOrgMemberImage from "./handlers/handlers";
import "./styles.scss";

/**
 * OrgMember - Member of user's Organization Structure
 *
 * @param  {Object} member member's profile object
 * @param  {boolean} isBordered tells if the component has border
 *
 *
 * @return OrgMember Component
 *
 * @example  <OrgMember member={member} isBordered={true} />
 */

function OrgMember({ member, isBordered }) {
  const dispatch = useDispatch();

  const { orgMemberImageList } = useSelector((state) => state);
  const { orgMemberImageLoading } = useSelector((state) => state);
  const [profileUrl, setProfileUrl] = useState("");

  const { id, hostOfficeLocation, preferredFirstName, preferredLastName, businessTitle } = member || {};

  const { name = "" } = hostOfficeLocation || {};

  // create a URL for user's profile
  useEffect(() => {
    setProfileUrl(`${process.env.REACT_APP_PROFILES}?hrid=${id}`);
  }, []);

  // fetch the images of all users
  useEffect(() => {
    if (!orgMemberImageList[id]) fetchOrgMemberImage(id, dispatch);
  }, [member]);

  return (
    <div className={`d-flex align-items-start org-member ${isBordered && "org-member-border"}`}>
      {orgMemberImageLoading ? (
        <Placeholder className="mb-0 mt-0 member-placehoder" as="span" data-testid="imageLoadingPlaceHolder">
          <Placeholder.Image as="span" />
        </Placeholder>
      ) : (
        <a href={profileUrl} target="_blank" rel="noopener noreferrer">
          <img src={orgMemberImageList[id]} className="org-member__image" alt="" />
        </a>
      )}
      <div className="ml-1 d-flex flex-column">
        <Popover
          trigger={
            <a href={profileUrl} target="_blank" rel="noopener noreferrer" data-testid="memberName">
              <p className="org-member__name cursor-pointer">{`${preferredFirstName} ${preferredLastName}`}</p>
            </a>
          }
          on="hover"
          inverted
          position="top left"
          size="tiny"
          popper={{ className: "orgMember-tooltip" }}
        >
          {LABEL.COMMON.ViewProfile}
        </Popover>
        <p className="org-member__title">{businessTitle}</p>
        <p className="org-member__location">
          <img src="/icons/loc_marker-grey.svg" alt="location" height="12px" className="mr-h" />
          {name}
        </p>
      </div>
    </div>
  );
}

OrgMember.defaultProps = {
  member: {},
  isBordered: false
};

OrgMember.propTypes = {
  member: PropTypes.shape({
    id: PropTypes.string,
    preferredFirstName: PropTypes.string,
    preferredLastName: PropTypes.string,
    businessTitle: PropTypes.string,
    hostOfficeLocation: PropTypes.shape({ id: PropTypes.string, name: PropTypes.string })
  }),
  isBordered: PropTypes.bool
};

export default OrgMember;
