import { linkedIn } from "redux/constants";

const linkedInDeclineStatusLoadingReducer = (state = false, action) => {
  switch (action.type) {
    case linkedIn.UPDATE_LINKEDIN_DECLINE_STATUS:
      return true;
    case linkedIn.UPDATE_LINKEDIN_DECLINE_STATUS_SUCCESS:
    case linkedIn.UPDATE_LINKEDIN_DECLINE_STATUS_FAILURE:
      return false;
    default:
      return state;
  }
};

export default linkedInDeclineStatusLoadingReducer;
