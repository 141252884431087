import { linkedIn } from "redux/constants";

const updateLinkedInOptinOptionsErrorReducer = (state = null, action) => {
  switch (action.type) {
    case linkedIn.UPDATE_LINKEDIN_OPTIN_OPTIONS_FAILURE:
      return action.error;
    case linkedIn.UPDATE_LINKEDIN_OPTIN_OPTIONS:
    case linkedIn.UPDATE_LINKEDIN_OPTIN_OPTIONS_SUCCESS:
      return null;
    default:
      return state;
  }
};

export default updateLinkedInOptinOptionsErrorReducer;
