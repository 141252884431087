import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import sendEventData from "analytics/analytics";
import { EVENTS, TRIGGERS } from "analytics/constants";
import LABEL from "constants/label";
import { useLocation, useHistory } from "react-router-dom";
import { createParamsObject } from "helpers/utils";
import useUpdateQueryParams from "customHooks/useUpdateQueryParams";

const { LINKEDIN } = LABEL;
function LinkedInFixedIcon({ setLinkedInModalOpen, hrEmpId, loggedInUserHrId }) {
  const history = useHistory();
  const location = useLocation();
  const { updateQueryParams } = useUpdateQueryParams(location, history);
  return (
    <div className="linkedin__fixed">
      <img
        src="/icons/linkedin-small.svg"
        alt="linkedin logo"
        role="presentation"
        className="li_small-logo"
        data-testid="li-small-logo"
      />
      <img
        src="/icons/linkedin-full-with-white-bg.svg"
        alt="linkedin logo"
        className="li_full-logo"
        onClick={() => {
          updateQueryParams({ ...createParamsObject(location?.search), edit: "linked-in" });
          setLinkedInModalOpen(true);
          window.$tabName = LINKEDIN.LINKED_IN; // Global variable for Analytics
          sendEventData(TRIGGERS.LINKEDIN.SETTINGS, EVENTS.EXPERIENCE_SECTION); // Analytics Event
        }}
        role="presentation"
        onKeyDown={() => {
          setLinkedInModalOpen(true);
        }}
        data-testid="li-full-logo"
      />
    </div>
  );
}

LinkedInFixedIcon.propTypes = {
  setLinkedInModalOpen: PropTypes.func.isRequired
};

export default LinkedInFixedIcon;
