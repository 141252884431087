import { call, put as putSaga } from "redux-saga/effects";
import { put } from "redux/api";
import { setUpdateProfileSuccess, setUpdateProfileError } from "redux/actions/editProfile";

function* handleUpdateProfile({ payload }) {
  const { data, error } = yield call(put, payload);
  if (error) {
    yield putSaga(setUpdateProfileError(error));
  } else {
    yield putSaga(setUpdateProfileSuccess(data));
  }
}

export default handleUpdateProfile;
