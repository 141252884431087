import { takeLatest } from "redux-saga/effects";

import { profileViews } from "redux/constants";
import handleFetchProfileViews from "../worker/handleFetchProfileViews";

function* watchFetchProfileViews() {
  yield takeLatest(profileViews.FETCH_PROFILE_VIEW_DETAILS, handleFetchProfileViews);
}

export default watchFetchProfileViews;
