import { ssRecentInternalMaterials } from "redux/constants";

const ssRecentInternalMaterialsErrorReducer = (state = {}, action) => {
  switch (action.type) {
    case ssRecentInternalMaterials.FETCH_RECENT_SS_INTERNAL_MATERIALS_FAILURE:
      return action.error;
    case ssRecentInternalMaterials.FETCH_RECENT_SS_INTERNAL_MATERIALS_SUCCESS:
    case ssRecentInternalMaterials.FETCH_RECENT_SS_INTERNAL_MATERIALS:
      return null;
    default:
      return state;
  }
};

export default ssRecentInternalMaterialsErrorReducer;
