import { takeLatest } from "redux-saga/effects";

import { topics } from "redux/constants";
import handleDeleteTopicSummary from "../worker/handleDeleteTopicSummary";

function* watchDeleteTopicSummary() {
  yield takeLatest(topics.DELETE_TOPICSUMMARY, handleDeleteTopicSummary);
}

export default watchDeleteTopicSummary;
