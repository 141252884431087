import axios from "axios";
import CONFIG from "constants/config";

const sources = [];
const shortenUrl = async (longurl) => {
  if (CONFIG.USE_URL_SHORTENER === true) {
    const newSource = axios.CancelToken.source();
    sources.push(newSource);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_APIURL_ENRICH}v1/urlshort`,
        { url: longurl },
        { cancelToken: newSource.token }
      );
      return response.shortened ? response.shortened : response.url; // return shorturl else original url
    } catch (error) {
      if (error.cancelled) return null;
      // eslint-disable-next-line no-console
      console.error("shortenurl", error);
      throw error;
    }
  } else {
    return longurl;
  }
};

export default shortenUrl;
