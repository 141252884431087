import { organization } from "redux/constants";

const getOrg = (payload) => ({
  type: organization.FETCH_ORG,
  payload
});

const setOrg = (data) => ({
  type: organization.FETCH_ORG_SUCCESS,
  data
});

const setOrgError = (error) => ({
  type: organization.FETCH_ORG_FAILURE,
  error
});

const getOrgMemberImageList = (payload) => ({
  type: organization.FETCH_ORG_MEMBER_IMAGE,
  payload
});

const setOrgMemberImageList = (data) => ({
  type: organization.FETCH_ORG_MEMBER_IMAGE_SUCCESS,
  data
});

const setErrorImage = (error) => ({
  type: organization.FETCH_ORG_MEMBER_IMAGE_FAILURE,
  error
});

export { getOrg, setOrg, setOrgError, getOrgMemberImageList, setOrgMemberImageList, setErrorImage };
