import { cases } from "redux/constants";

const deleteCaseTopicsSkillsErrorReducer = (state = null, action) => {
  switch (action.type) {
    case cases.DELETE_CASE_TOPICS_SKILLS_ERROR:
      return action.error;
    case cases.DELETE_CASE_TOPICS_SKILLS_SUCCESS:
      return null;
    default:
      return state;
  }
};

export default deleteCaseTopicsSkillsErrorReducer;
