import { searchTopicsSkills } from "redux/constants";

const topicsSkillsSearchErrorReducer = (state = {}, action) => {
  switch (action.type) {
    case searchTopicsSkills.FETCH_TOPICS_SKILLS_FINDER_FAILURE:
      return action.error;
    case searchTopicsSkills.FETCH_TOPICS_SKILLS_FINDER_SUCCESS:
    case searchTopicsSkills.FETCH_TOPICS_SKILLS_FINDER:
      return null;
    default:
      return state;
  }
};

export default topicsSkillsSearchErrorReducer;
