/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Modal, Popup } from "semantic-ui-react";
import LABEL from "constants/label";
import sendEventData from "analytics/analytics";
import { EVENTS, TRIGGERS, NEW_TAB_NAMES } from "analytics/constants";
import ProfileImageOptions from "./ProfileImageOptions";
import { getProfileImageStyle } from "../../../helpers/utils";
import "./styles.scss";

/**
 * @param {string} imgSrc source of the image
 * @param {string} size size of the image (meduim, small)
 *
 * @returns a circular profile image
 *
 * @example
 * <ProfileImage
 *  imgSrc="https://image.shutterstock.com/image-vector/profile-placeholder-image-gray-silhouette-260nw-1153673752.jpg"
 *  size="medium"
 *  />
 */

function ProfileImage({ imgSrc, size }) {
  const {
    BANNER: { TOOLTIPS }
  } = LABEL;

  const [openHighResView, setOpenHighResView] = useState(false);
  const { profileImageHighRes } = useSelector((state) => state);
  const { profileImageHighResLoading } = useSelector((state) => state);

  const openHighResPicture = () => {
    setOpenHighResView(true);
  };

  const closeHighResPicture = () => {
    setOpenHighResView(false);
  };

  const options = [
    {
      id: "view-profile-image-1",
      title: TOOLTIPS.ProfileImage,
      callback: () => {
        openHighResPicture();
        sendEventData(TRIGGERS.BANNER.PROFILE_IMAGE, EVENTS.BANNER_CLICKS, { newTabName: NEW_TAB_NAMES.BANNER });
      }
    },
    {
      id: "face-card-generator-2",
      title: TOOLTIPS.FaceCardGenerator,
      iconName: "/icons/New_tag.svg",
      subTitle: TOOLTIPS.FaceCardGeneratorSubTitle,
      callback: () => {
        window.open(process.env.REACT_APP_FACE_CARD_GENERATOR, "_blank");
        sendEventData(TRIGGERS.BANNER.FACE_CARD_GENERATOR, EVENTS.BANNER_CLICKS, { newTabName: NEW_TAB_NAMES.BANNER });
      }
    }
  ];

  return (
    <>
      <Popup
        basic
        hoverable
        className="profile__image--popup"
        trigger={
          <div className="profile__image-container">
            <img
              src={imgSrc}
              className={`profile__image ${getProfileImageStyle(size)}`}
              alt="profile pic"
              data-testid="profile-image"
            />
          </div>
        }
        content={<ProfileImageOptions options={options} />}
        position="bottom center"
        offset={[10, -30]}
      />
      {!profileImageHighResLoading && (
        <Modal
          open={openHighResView}
          closeOnDimmerClick
          onClose={() => closeHighResPicture()}
          data-testid="profile-image-highres-modal"
        >
          <Modal.Content className="image content profile__image__hires--modal-container">
            <img src={profileImageHighRes?.img} alt="high-res profile pic" data-testid="profile-image-highres" />
          </Modal.Content>
        </Modal>
      )}
    </>
  );
}

ProfileImage.defaultProps = {
  size: "medium"
};

ProfileImage.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  size: PropTypes.string
};

export default ProfileImage;
