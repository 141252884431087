import { about } from "redux/constants";

const aboutTopicDetailsReducer = (state = false, action) => {
  switch (action.type) {
    case about.FETCH_ABOUT_TOPIC_DETAILS_SUCCESS: {
      return false;
    }
    case about.FETCH_ABOUT_TOPIC_DETAILS: {
      return true;
    }
    default:
      return state;
  }
};

export default aboutTopicDetailsReducer;
