import { takeLatest } from "redux-saga/effects";

import { about } from "redux/constants";
import handleFetchAbout from "../worker/handleFetchAbout";

function* watchFetchAbout() {
  yield takeLatest(about.FETCH_ABOUT, handleFetchAbout);
}

export default watchFetchAbout;
