import { linkedIn } from "redux/constants";

const linkedInDataReducer = (state = null, action) => {
  switch (action.type) {
    case linkedIn.FETCH_LINKEDIN_DATA_SUCCESS: {
      return action?.data;
    }
    default:
      return state;
  }
};

export default linkedInDataReducer;
