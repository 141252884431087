import { call, put } from "redux-saga/effects";
import { get } from "redux/api";
import { setCVBioMaterialsViewsSuccess, setCVBioMaterialsViewsFailure } from "redux/actions/profileViews";

function* handleFetchCVBioMaterialsViews({ payload }) {
  const { data, error } = yield call(get, payload);

  if (error) {
    yield put(setCVBioMaterialsViewsFailure(error));
  } else {
    yield put(setCVBioMaterialsViewsSuccess(data));
  }
}

export default handleFetchCVBioMaterialsViews;
