import { Pagination, Icon, Grid } from "semantic-ui-react";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";

import "./styles.scss";

/**
 * @param  {string} itemName name of page/element of which pagination data is showing
 * @param  {number} totalPages total pagination pages
 * @param  {number} totalElementCount total data in page
 * @param  {func} onPageChange function for when pagination page changes
 * @param  {number} startPageNumber pagination start page number {default} 1
 * @param  {number} endPageNumber pagination end page number
 * @param  {number} activePage pagination active page {default} 1
 *
 * @return Pagination component
 *
 * @example
 * <PaginationBox itemName="Cases" totalPages={totalPages} onPageChange={onChangeActivePage} startPageNumber={startPageNumber}
     endPageNumber={endPageNumber} activePage={activePage} totalElementCount={CASE-DATA.length}
   />
 */

const PaginationBox = ({
  itemName,
  totalPages,
  totalElementCount,
  onPageChange,
  startPageNumber,
  endPageNumber,
  activePage,
  className
}) => {
  const [classN, setClassN] = useState("first");

  useEffect(() => {
    // Setting class based on if first, last or any other element is selected.
    // Based on class showing/hiding first and last arrow in pagination
    const onChangeActivePage = () => {
      if (activePage === 1) {
        setClassN("first");
      } else if (activePage === totalPages) {
        setClassN("last");
      } else {
        setClassN(null);
      }
    };
    onChangeActivePage();
  }, [activePage, totalPages]);

  return (
    <Grid centered className={`mt-1 position-relative pagination-wrapper ${className || ""}`}>
      <Grid.Column
        className="pagination-wrapper__meta"
        textAlign="left"
        computer={4}
        tablet={16}
        verticalAlign="middle"
      >
        {/* Showing selected page and total pages data */}
        <span className="fs-12" data-testId="case_count_per_page">
          Showing {startPageNumber + 1} - {endPageNumber > totalElementCount ? totalElementCount : endPageNumber} of{" "}
          {totalElementCount} {itemName}
        </span>
      </Grid.Column>
      <Grid.Column
        className="pagination-wrapper__pagination-box"
        textAlign="center"
        tablet={16}
        computer={16}
        verticalAlign="middle"
      >
        {/* Pagination component */}
        <Pagination
          className={classN}
          activePage={activePage}
          ellipsisItem="..."
          firstItem={null}
          lastItem={null}
          prevItem={{ content: <Icon name="arrow left" />, icon: true }}
          nextItem={{ content: <Icon name="arrow right" />, icon: true }}
          totalPages={totalPages}
          onPageChange={onPageChange}
          pageItem={{ className: "pagination-item" }}
        />
      </Grid.Column>
    </Grid>
  );
};

PaginationBox.defaultProps = {
  itemName: "",
  startPageNumber: 1,
  activePage: 1,
  onPageChange: () => {},
  className: ""
};

PaginationBox.propTypes = {
  itemName: PropTypes.string,
  totalPages: PropTypes.number.isRequired,
  totalElementCount: PropTypes.number.isRequired,
  onPageChange: PropTypes.func,
  startPageNumber: PropTypes.number,
  endPageNumber: PropTypes.number.isRequired,
  activePage: PropTypes.number,
  className: PropTypes.string
};

export default PaginationBox;
