import { entitlements } from "redux/constants";

const entitlementsErrorReducer = (state = {}, action) => {
  switch (action.type) {
    case entitlements.FETCH_ENTITLEMENTS_FAILURE:
      return action.error;
    case entitlements.FETCH_ENTITLEMENTS_SUCCESS:
    case entitlements.FETCH_ENTITLEMENTS:
      return null;
    default:
      return state;
  }
};

export default entitlementsErrorReducer;
