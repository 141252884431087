import { OktaAuth } from "@okta/okta-auth-js";
import * as utils from "@kdshared/okta-utils";

const baseConfig = utils.authServiceConfig(process.env.REACT_APP_ENV);

export default new OktaAuth({
  ...baseConfig,
  issuer: process.env.REACT_APP_ISSUER,
  clientId: process.env.REACT_APP_CLIENTID,
  redirectUri: `${window.location.origin}/implicit/callback`
});
