import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useQuery } from "helpers/utils";

const useCheckAPIError = (userError, profileError, staffIdError, error, profileLoading) => {
  const history = useHistory();
  const location = useLocation();
  const query = useQuery(location.search);

  const [errorCalled, setErrorCalled] = useState();

  useEffect(() => {
    if (!errorCalled) {
      // Dedicated error page
      const hrid = query.get("hrid");
      switch (userError?.response?.status || profileError?.response?.status || staffIdError?.response?.status) {
        case 404:
          setErrorCalled(true);
          history.push(`/error?hrid=${hrid}`, { code: 404 });
          break;
        case 403:
          setErrorCalled(true);
          history.push(`/error?hrid=${hrid}`, { code: 403 });
          break;
        case 401:
          setErrorCalled(true);
          history.push(`/error?hrid=${hrid}`, { code: 401 });
          break;
        case 400:
          setErrorCalled(true);
          history.push(`/error?hrid=${hrid}`, { code: 400 });
          break;

        default:
          break;
      }
    }
  }, [error, profileLoading, errorCalled, history, profileError, staffIdError, userError, query]);
};

export default useCheckAPIError;
