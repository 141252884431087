import { editProfile } from "redux/constants";

const resumeUpdatingErrorReducer = (state = null, action) => {
  switch (action.type) {
    case editProfile.UPDATE_RESUME_FAILURE:
      return action.error;
    case editProfile.UPDATE_RESUME_SUCCESS:
    case editProfile.UPDATE_RESUME:
      return null;
    default:
      return state;
  }
};

export default resumeUpdatingErrorReducer;
