import { takeLatest } from "redux-saga/effects";

import { entitlements } from "redux/constants";
import handleFetchEntitlements from "../worker/handleFetchEntitlements";

function* watchFetchEntitlements() {
  yield takeLatest(entitlements.FETCH_ENTITLEMENTS, handleFetchEntitlements);
}

export default watchFetchEntitlements;
