import { call, put as putSaga } from "redux-saga/effects";
import { put } from "redux/api";
import { setUpdateLinkedInOptinOptionsError, setUpdateLinkedInOptinOptionsSuccess } from "redux/actions/linkedIn";

function* handleUpdateLinkedInOptinOptions({ payload }) {
  const { res, error } = yield call(put, payload);
  if (error) {
    yield putSaga(setUpdateLinkedInOptinOptionsError(error));
  } else {
    yield putSaga(setUpdateLinkedInOptinOptionsSuccess(res?.data));
  }
}

export default handleUpdateLinkedInOptinOptions;
