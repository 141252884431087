import { externalCourseId } from "redux/constants";

const externalCourseIdLoadingReducer = (state = false, action) => {
  switch (action.type) {
    case externalCourseId.CHECK_COURSE_ID:
      return true;
    case externalCourseId.CHECK_COURSE_ID_SUCCESS:
    case externalCourseId.CHECK_COURSE_ID_FAILURE:
      return false;
    default:
      return state;
  }
};

export default externalCourseIdLoadingReducer;
