import { profileViews } from "redux/constants";

const fetchProfileViews = (payload) => {
  return {
    type: profileViews.FETCH_PROFILE_VIEW_DETAILS,
    payload
  };
};

const fetchProfileViewsSuccess = (data) => ({
  type: profileViews.FETCH_PROFILE_VIEW_DETAILS_SUCCESS,
  data
});

const fetchProfileViewsFailure = (error) => ({
  type: profileViews.FETCH_PROFILE_VIEW_DETAILS_FAILURE,
  error
});
export { fetchProfileViews, fetchProfileViewsSuccess, fetchProfileViewsFailure };
