import { memberList } from "redux/constants";

const memberListErrorReducer = (state = null, action) => {
  switch (action.type) {
    case memberList.FETCH_MEMBER_LIST_FAILURE:
    case memberList.FETCH_MEMBER_LIST_IMAGE_FAILURE:
      return action.error;
    case memberList.FETCH_MEMBER_LIST_IMAGE_SUCCESS:
    case memberList.FETCH_MEMBER_LIST_SUCCESS:
      return null;
    default:
      return state;
  }
};

export default memberListErrorReducer;
