import { takeEvery } from "redux-saga/effects";

import { certificate } from "redux/constants";
import handleUpdateCertificate from "../worker/handleUpdateCertificate";

function* watchUpdateCertificate() {
  // takeEvery --> use to capture every api call for very quick drag/drop
  yield takeEvery(certificate.UPDATE_CERTIFICATE, handleUpdateCertificate);
}

export default watchUpdateCertificate;
