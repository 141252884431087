const useUpdateQueryParams = (location, history) => {
  const updateQueryParams = (newParams) => {
    const updatedSearchParams = new URLSearchParams(location?.search);

    // Remove any duplicates
    Object.keys(newParams).forEach((key) => {
      if (updatedSearchParams.has(key)) {
        updatedSearchParams.delete(key);
      }
    });

    // Update query parameters
    Object.keys(newParams).forEach((key) => {
      if (newParams[key] !== undefined && newParams[key] !== null) {
        updatedSearchParams.set(key, newParams[key]);
      } else {
        updatedSearchParams.delete(key);
      }
    });

    history.push({
      pathname: location.pathname,
      search: updatedSearchParams.toString()
    });
  };

  const deleteQueryParam = (search, ...params) => {
    const updatedSearchParams = new URLSearchParams(location?.search);
    Object.keys(search).forEach((key) => {
      if (updatedSearchParams.has(key)) {
        updatedSearchParams.delete(key);
      }
    });
    params?.forEach((param) => updatedSearchParams?.delete(param));
    history.push({
      pathname: location?.pathname,
      search: updatedSearchParams?.toString()
    });
  };

  return { updateQueryParams, deleteQueryParam };
};

export default useUpdateQueryParams;
