import PropTypes from "prop-types";
import styles from "./styles.module.scss";

/**
 * Tag component
 *
 * @param  {string} text
 *
 * @return element
 *
 * @example
 *
 * <Tag text="tag" />
 */
function Tag({ text }) {
  if (!text) {
    return null;
  }

  return <div className={`${styles["tag"]} overflow-hidden`}>{text}</div>;
}

Tag.defaultProps = {
  text: ""
};

Tag.propTypes = {
  text: PropTypes.string
};

export default Tag;
