import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Placeholder } from "semantic-ui-react";

import PropTypes from "prop-types";
import PopupDropDown from "../PopupDropDown";

/**
 *
 * @param {bool} isLoading indicates if the component is loading
 * @param {string} awardLocation award recieved loaction (external, BCG)
 * @param {Function} onAwardTypeChange callback
 * @param {boolean} disabled to make the dropdown disabled
 * @param {boolean} invalid boolean flag for validation
 * @param {string} awardTypeName having award name
 *
 * @returns AwardsTypeDropDown Component
 *
 * @example
 *  <AwardsTypeDropDown
      awardLocation="BCG"
      onAwardTypeChange={(e, data) => {
      }}
      isLoading={false}
    />
 */

function AwardsTypeDropDown({ isLoading, awardLocation, onAwardTypeChange, disabled, invalid, awardTypeName }) {
  const awardTypes = useSelector((state) => state.awardTypes?.awardTypesList);
  const [awardTypeOptions, setAwardTypeOptions] = useState([]);
  const [selAward, setSelAward] = useState(0);

  useEffect(() => {
    if (awardTypes?.length > 0) {
      const options = awardTypes.map((awardType) => ({
        key: awardType.awardTypeId,
        text: awardType.name,
        value: awardType.awardTypeId
      }));
      if (awardLocation?.toLowerCase() === "external") {
        const externalOptions = options.filter((item) => item.key === 5);
        externalOptions.unshift({
          key: 0,
          text: "Select",
          value: "Select"
        });
        setAwardTypeOptions(externalOptions);
        setSelAward(externalOptions.findIndex((item) => item.text === awardTypeName));
      } else {
        const bcgOptions = options.filter((item) => item.key !== 5);
        bcgOptions.unshift({
          key: 0,
          text: "Select",
          value: "Select"
        });
        setAwardTypeOptions(bcgOptions);
        setSelAward(
          awardLocation?.toLowerCase() === "select" ? 0 : bcgOptions.findIndex((item) => item.text === awardTypeName)
        );
      }
    }
  }, [awardTypes, awardLocation, awardTypeName]);

  let returnVal = (
    <PopupDropDown
      disabled={disabled}
      onClick={onAwardTypeChange}
      options={awardTypeOptions}
      selectedIndex={selAward}
      id="dd_award_type"
      invalid={invalid}
    />
  );

  if (isLoading) {
    returnVal = (
      <Placeholder fluid>
        <Placeholder.Header>
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Header>
      </Placeholder>
    );
  }

  return returnVal;
}

AwardsTypeDropDown.defaultProps = {
  isLoading: false
};
AwardsTypeDropDown.propTypes = {
  isLoading: PropTypes.bool,
  awardLocation: PropTypes.string.isRequired,
  onAwardTypeChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  awardTypeName: PropTypes.string.isRequired
};

export default AwardsTypeDropDown;
