import { takeLatest } from "redux-saga/effects";

import { linkedIn } from "redux/constants";
import handleFetchLinkedInRedirectUrl from "../worker/handleFetchLinkedInRedirectUrl";

function* watchFetchLinkedInRedirectUrl() {
  yield takeLatest(linkedIn.FETCH_LINKEDIN_REDIRECT_URL, handleFetchLinkedInRedirectUrl);
}

export default watchFetchLinkedInRedirectUrl;
