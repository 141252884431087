import React, { useEffect, useState } from "react";
import { Dimmer, List, Loader } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import CONFIG from "constants/config";
import LABEL from "constants/label";
import { getProfileCompletionVerbiage } from "redux/actions/profileCompletion";
import { makeProfileCompletionVerbiage } from "../helpers/utils";

import "./styles.scss";

const ToolTipContents = () => {
  const {
    PROFILE_OVERVIEW: { ProfileCompletionHoverTitle }
  } = LABEL;

  const { apiUrl, xApiKey } = CONFIG;
  const dispatch = useDispatch();
  const { profile, profileCompletionVerbiage, profileCompletionVerbiageLoading } = useSelector((state) => state);

  const [finalVerbiageList, setFinalVerbiageList] = useState([]);

  useEffect(() => {
    if (profile?.hrId && !profileCompletionVerbiage?.length && !profileCompletionVerbiageLoading) {
      dispatch(
        getProfileCompletionVerbiage({
          endpoint: `${apiUrl.linkApi}profile/${profile?.hrId}/percentage/verbiage`,
          config: {
            headers: {
              "x-api-key": `${xApiKey.profile}`
            }
          }
        })
      );
    }
  }, [profile?.hrId]);

  useEffect(() => {
    if (profileCompletionVerbiage?.length) {
      setFinalVerbiageList(makeProfileCompletionVerbiage(profileCompletionVerbiage));
    }
  }, [profileCompletionVerbiage]);

  return (
    <div className="profile__completion--tooltip" data-testid="tooltip-contents">
      {(!profileCompletionVerbiageLoading && !!finalVerbiageList?.length && (
        <>
          <p>{ProfileCompletionHoverTitle}</p>
          <List bulleted>
            {finalVerbiageList?.map((item) => {
              return <List.Item key={item}>{item}</List.Item>;
            })}
          </List>
        </>
      )) || (
        <Dimmer active>
          <Loader />
        </Dimmer>
      )}
    </div>
  );
};

export default ToolTipContents;
