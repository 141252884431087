import { call, put } from "redux-saga/effects";
import { delete as del } from "redux/api";
import { setDeletePronunciationSuccess, setDeletePronunciationError } from "redux/actions/pronunciation";

function* handleDeletePronunciation({ payload }) {
  const { data, error } = yield call(del, payload);
  if (error) {
    yield put(setDeletePronunciationError(error));
  } else {
    yield put(setDeletePronunciationSuccess(data));
  }
}

export default handleDeletePronunciation;
