import { useEffect, useState } from "react";
import { Card } from "semantic-ui-react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import { Loading } from "components/common";
import LABEL from "constants/label";
import { EVENTS, TRIGGERS } from "analytics/constants";
import sendEventData from "analytics/analytics";
import { nanoid } from "nanoid/non-secure";
import { useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { createParamsObject } from "helpers/utils";
import useUpdateQueryParams from "customHooks/useUpdateQueryParams";
import PowerByLinkedIn from "components/common/PowerByLinkedIn";
import EditButton from "components/common/EditButton";
import EducationItem from "./EducationItem";
import EducationViewAll from "./EducationViewAll";
import { LinkedInEducationCard, LinkedInEducationViewAll } from "./LinkedInEducation";

const { COMMON, EDUCATION } = LABEL;

/**
 * Education Component
 * It loops through data and renders multiple EducationItem components.
 * If no data is found, `No Education Added` text is rendered with a button
 *
 * @param  {object} profile
 * @param  {boolean} isEditable
 * @param {boolean} isLoading loading state
 *
 * @return element containing list of user's education information
 *
 * @example
 *
 * <Education profile={profile} isEditable={isEditable}  />
 */
const Education = ({ isEditable, isLoading, education, setLinkedInModalOpen, loggedInUserHrId }) => {
  const [open, setOpen] = useState(false);
  const { peopleData } = useSelector((state) => state);
  const { workerURL: workdayURL, id = null } = peopleData;
  const { linkedInData, linkedInDataLoading, linkedInUserStatus } = useSelector((state) => state);
  const [sortedLinkedInEducation, setSortedLinkedInEducation] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const { updateQueryParams } = useUpdateQueryParams(location, history);

  useEffect(() => {
    setSortedLinkedInEducation([]);
    if (linkedInData?.educations?.length > 0)
      setSortedLinkedInEducation(
        linkedInData?.educations?.sort((a, b) => {
          const endYearA = new Date(
            a?.endYear === 0 ? new Date().getFullYear() : a?.endYear,
            a?.endMonth ? a?.endMonth - 1 : a?.endMonth
          );
          const endYearB = new Date(
            b?.endYear === 0 ? new Date().getFullYear() : b?.endYear,
            b?.endMonth ? b?.endMonth - 1 : b?.endMonth
          );
          return endYearB - endYearA;
        })
      );
  }, [linkedInData?.educations]);

  const handleOnClick = () => {
    window.$tabName = EDUCATION.Education; // Global variable for Analytics
    sendEventData(TRIGGERS.COMMON.VIEW_ALL, EVENTS.EDUCATION_SECTION); // Analytics Event
    setOpen(true);
  };

  const handleOnCloseClick = () => {
    window.$tabName = EDUCATION.Education; // Global variable for Analytics
    sendEventData(TRIGGERS.LINKEDIN.CLOSE, EVENTS.EDUCATION_SECTION); // Analytics Event
    setOpen(false);
  };

  let showViewAll = false;
  let educationData = education;

  if (education?.length > 3) {
    showViewAll = true;
    educationData = education.slice(0, 3);
  }

  const handleEditOnWorkDay = () => {
    window.$tabName = EDUCATION.Education; // Global variable for Analytics
    sendEventData(TRIGGERS.COMMON.EDIT, EVENTS.EDUCATION_SECTION); // Analytics Event
    window.open(workdayURL, "_blank");
  };

  const handleLinkedInSettings = () => {
    updateQueryParams({ ...createParamsObject(location?.search), edit: "linked-in" });
    setLinkedInModalOpen(true);
  };

  return (
    <>
      {(!isLoading && !linkedInDataLoading && (
        <Card fluid>
          <Card.Content>
            <Card.Header>
              <div className="d-flex justify-content-between align-items-center">
                {EDUCATION.Education}
                {isEditable && (!linkedInUserStatus?.status?.optin || sortedLinkedInEducation?.length === 0) && (
                  <EditButton caption={COMMON.EditOnWorkDay} callback={handleEditOnWorkDay} />
                )}
                {isEditable &&
                  linkedInUserStatus?.status?.optin &&
                  !!sortedLinkedInEducation?.length &&
                  +loggedInUserHrId === +id && (
                    <EditButton caption={COMMON.Settings} callback={handleLinkedInSettings} />
                  )}
              </div>
              {linkedInUserStatus?.status?.optin && !!sortedLinkedInEducation?.length && <PowerByLinkedIn />}
            </Card.Header>
          </Card.Content>
          {linkedInUserStatus?.status?.optin &&
            sortedLinkedInEducation?.length > 0 &&
            sortedLinkedInEducation?.slice(0, 3)?.map((liEducation) => {
              const { degreeName, schoolName, fieldsOfStudy, startMonth, endMonth, startYear, endYear } = liEducation;

              return (
                <LinkedInEducationCard
                  degreeName={degreeName}
                  schoolName={schoolName}
                  fieldsOfStudy={fieldsOfStudy}
                  startMonth={startMonth}
                  endMonth={endMonth}
                  startYear={startYear}
                  endYear={endYear}
                />
              );
            })}
          {education?.length > 0 &&
            (!sortedLinkedInEducation.length > 0 || !linkedInUserStatus?.status?.optin) &&
            educationData.map((item) => {
              const { degree, school, major, startDate, endDate } = item;

              return (
                <EducationItem
                  degree={degree}
                  school={school}
                  major={major}
                  key={nanoid()}
                  start={startDate}
                  end={endDate}
                />
              );
            })}
          {(showViewAll || sortedLinkedInEducation.length > 3) && (
            <Card.Content textAlign="center" extra>
              <div role="button" className="cursor-pointer" onClick={handleOnClick} tabIndex={0} onKeyDown={noop}>
                {COMMON.ViewAll}
              </div>
            </Card.Content>
          )}
          {!education?.length && (!sortedLinkedInEducation?.length || !linkedInUserStatus?.status?.optin) && (
            <Card.Content textAlign="center" className="noEducation content--no-info">
              <h4>{EDUCATION.NoEducation}</h4>
            </Card.Content>
          )}
        </Card>
      )) || <Loading title="Education" />}
      {open && !sortedLinkedInEducation.length && (
        <EducationViewAll open={open} onClose={handleOnCloseClick} education={education} workdayURL={workdayURL} />
      )}
      {open && !!sortedLinkedInEducation.length && (
        <LinkedInEducationViewAll
          open={open}
          onClose={handleOnCloseClick}
          linkedInEducation={sortedLinkedInEducation}
        />
      )}
    </>
  );
};

Education.defaultProps = {
  profile: {},
  isEditable: false,
  isLoading: false,
  education: [],
  setLinkedInModalOpen: () => {}
};

Education.propTypes = {
  profile: PropTypes.shape({
    id: PropTypes.number,
    percentComplete: PropTypes.number,
    incompleteFields: PropTypes.arrayOf(PropTypes.string),
    numberOfCases: PropTypes.number,
    workdayWorkerUrl: PropTypes.string
  }),
  education: PropTypes.arrayOf(PropTypes.shape({})),
  isEditable: PropTypes.bool,
  isLoading: PropTypes.bool,
  setLinkedInModalOpen: PropTypes.func,
  loggedInUserHrId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

export default Education;
