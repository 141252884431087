import { organization } from "redux/constants";

const orgMemberImageErrorReducer = (state = {}, action) => {
  switch (action.type) {
    case organization.FETCH_ORG_MEMBER_IMAGE_FAILURE:
      return action.error;
    case organization.FETCH_ORG_MEMBER_IMAGE_SUCCESS:
    case organization.FETCH_ORG_MEMBER_IMAGE:
      return null;
    default:
      return state;
  }
};

export default orgMemberImageErrorReducer;
