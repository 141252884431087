import { call, put } from "redux-saga/effects";
import { get } from "redux/api";
import { checkIfCourseIdExistsSuccess, checkIfCourseIdExistsError } from "redux/actions/externalCourseId";

function* handleCheckExternalCourseId({ payload }) {
  const { data, error } = yield call(get, payload);
  if (error) {
    yield put(checkIfCourseIdExistsError(error));
  } else {
    yield put(checkIfCourseIdExistsSuccess(data));
  }
}

export default handleCheckExternalCourseId;
