import { takeEvery } from "redux-saga/effects";

import { userData } from "redux/constants";
import handleFetchUserData from "../worker/handleFetchUserData";

function* watchUserData() {
  yield takeEvery(userData.FETCH_USER_DATA, handleFetchUserData);
}

export default watchUserData;
