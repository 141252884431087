import { profileUpdateHistory } from "redux/constants";

const profileUpdateHistoryReducer = (state = [], action) => {
  switch (action.type) {
    case profileUpdateHistory.FETCH_PROFILE_UPDATE_HISTORY_SUCCESS: {
      return [...action.data];
    }
    default:
      return state;
  }
};

export default profileUpdateHistoryReducer;
