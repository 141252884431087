import React, { useState } from "react";
import PropTypes from "prop-types";
import LABEL from "constants/label";
import { Grid, Icon, Label } from "semantic-ui-react";
import { Icon as IconCustom, Menu, Popover } from "components/common";
import { isKeyToPurposeAward, toggleAwardIcon } from "components/Achievements/helper/utils";
import Tags from "components/common/Tags";
import ExternalLinkButton from "components/common/ExternalLinkButton";
import { isEmptyOrNull } from "helpers/utils";
import "./styles.scss";

const {
  COMMON: { Expired, Hidden },
  ACHIEVEMENTS: { BcgCredsDragDropRestrictedMessage, ExternalCredsDropRestrictedMessage, BcgCredSource }
} = LABEL;

function CredentialsGridRow({
  showSkills,
  credential,
  credIconName,
  isEditable,
  onClickRow,
  setData,
  onEditClick,
  onDragStart,
  onDragEnter,
  featuredCredential,
  handleOnDelete,
  recordType,
  handleOnHideUnHide
}) {
  const isHiddenOrExpired = !!credential?.hidden || !!credential.expired;
  const dragDropHoverMessage =
    recordType === BcgCredSource ? BcgCredsDragDropRestrictedMessage : ExternalCredsDropRestrictedMessage;
  const trigger = <Icon className="cursor-pointer" name="ellipsis horizontal" data-testid="share" />;
  const onGridEditClick = () => {
    setData(credential);
    onEditClick();
  };

  const editMenuItem = { name: "Edit", onClick: onGridEditClick, icon: "pencil" };
  const deleteMenuItem = {
    name: "Delete",
    onClick: () => handleOnDelete(credential, recordType),
    icon: "trash alternate"
  };
  const hideMenuItem = {
    name: "Hide",
    onClick: () => handleOnHideUnHide(credential),
    icon: "hide"
  };
  const unHideMenuItem = {
    name: "Unhide",
    onClick: () => handleOnHideUnHide(credential),
    icon: "unhide"
  };
  const menuItems = [];

  const showHideUnHideMenuItems = () => {
    if (credential?.hidden) {
      menuItems.push(unHideMenuItem);
    } else {
      menuItems.push(hideMenuItem);
    }
  };

  if (isEditable) {
    switch (true) {
      case credential?.addedByCredly:
        showHideUnHideMenuItems();
        break;
      case credential?.expired && !credential?.addedByCredly:
        menuItems.push(deleteMenuItem);
        break;
      case !isKeyToPurposeAward(credential):
        menuItems.push(editMenuItem, deleteMenuItem);
        break;
      default:
        break;
    }
  }

  const [selectedRow, setSelectedRow] = useState(false);

  const onGridRowClick = () => {
    setData(credential);
    onClickRow();
  };

  const renderRowDetails = () => {
    return (
      <Grid.Column width={((showSkills || isKeyToPurposeAward(credential)) && 9) || 15}>
        {!!credential?.hidden && <Label className="hidden__label">{Hidden}</Label>}
        {!!credential?.expired && <Label className="hidden__label expired__label">{Expired}</Label>}
        <div className="credentials__grid__name">
          {credential?.addedByCredly ? (
            <img src={credential?.imageURL} width="30" height="30" alt="credlyLogo" />
          ) : (
            <IconCustom
              name={
                (!credIconName && (isKeyToPurposeAward(credential) ? "keyToPurpose" : toggleAwardIcon(credential))) ||
                credIconName
              }
              size={30}
            />
          )}
          <div className="ml-h">{credential?.name}</div>
        </div>
        <div className="credentials__grid__description">{credential?.description}</div>
        {credential?.addedByCredly && (
          <div className="credentials__grid--btn">
            <ExternalLinkButton btnText="Show Credentials" externalUrl={credential?.certificationURL} />
          </div>
        )}
      </Grid.Column>
    );
  };

  return (
    <Grid.Row
      draggable={isEditable}
      className={`credentials__grid__row-item ${selectedRow ? "selected__row" : ""}  ${
        !!featuredCredential && "featured"
      }  ${(isHiddenOrExpired && "hidden") || (isEditable && "credentials__grid__dragDropCursor")}`}
      onMouseOver={() => setSelectedRow(true)}
      onMouseOut={() => setSelectedRow(false)}
      key={credential?.name}
      onClick={onGridRowClick}
      data-testid="credentials_row"
      onDragStart={onDragStart}
      onDragEnter={onDragEnter}
    >
      {isHiddenOrExpired ? (
        <Popover
          trigger={renderRowDetails()}
          on="hover"
          inverted
          position="top center"
          popper={{ className: "credentials__grid__hiddenPopupMessage tags-tooltip" }}
          offset={[showSkills && credential?.skills ? 220 : 30, -35]}
          size="tiny"
        >
          {dragDropHoverMessage}
        </Popover>
      ) : (
        renderRowDetails()
      )}

      {!!showSkills && (
        <Grid.Column width={6} verticalAlign="middle">
          {credential?.skills && (
            <Tags
              tags={credential?.skills}
              numberOfTagsToShow={credential?.skills?.length > 2 ? 2 : credential?.skills?.length}
            />
          )}
        </Grid.Column>
      )}
      {isKeyToPurposeAward(credential) && !isEmptyOrNull(credential?.chosenPrinciple) && (
        <Grid.Column width={6} verticalAlign="middle">
          <Tags tags={[credential?.chosenPrinciple]} numberOfTagsToShow={1} />
        </Grid.Column>
      )}
      <Grid.Column
        width={1}
        verticalAlign="middle"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {!!isEditable && <Menu menuItems={menuItems} trigger={trigger} />}
      </Grid.Column>
    </Grid.Row>
  );
}

CredentialsGridRow.defaultProps = {
  showSkills: false,
  credential: {},
  credIconName: "",
  isEditable: true,
  onClickRow: () => {},
  setData: () => {},
  onEditClick: () => {},
  onDragStart: () => {},
  onDragEnter: () => {},
  featuredCredential: false,
  handleOnDelete: () => {},
  recordType: "",
  handleOnHideUnHide: () => {}
};

CredentialsGridRow.propTypes = {
  showSkills: PropTypes.bool,
  credential: PropTypes.shape({
    name: PropTypes.string,
    featured: PropTypes.bool,
    description: PropTypes.string,
    hidden: PropTypes.bool,
    certificationURL: PropTypes.string,
    addedByCredly: PropTypes.bool,
    skills: PropTypes.arrayOf(PropTypes.string),
    imageURL: PropTypes.string,
    expired: PropTypes.bool,
    chosenPrinciple: PropTypes.string
  }),
  credIconName: PropTypes.string,
  isEditable: PropTypes.bool,
  onClickRow: PropTypes.func,
  setData: PropTypes.func,
  onEditClick: PropTypes.func,
  onDragStart: PropTypes.func,
  onDragEnter: PropTypes.func,
  featuredCredential: PropTypes.bool,
  handleOnDelete: PropTypes.func,
  recordType: PropTypes.string,
  handleOnHideUnHide: PropTypes.func
};

export default CredentialsGridRow;
