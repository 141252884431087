/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from "moment-timezone";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import IconWithText from "components/common/IconWithText";
import IconWithDynamicText from "components/common/IconWithDynamicText";
import { Placeholder } from "semantic-ui-react";
import LABEL from "constants/label";
import CONFIG from "constants/config";
import sendEventData from "analytics/analytics";
import { EVENTS, TRIGGERS, NEW_TAB_NAMES } from "analytics/constants";
import styles from "./styles.module.scss";

const { directory } = CONFIG;

/**
 * Clock
 *
 * @param  {shape} profile user profile
 * @param  {bool}  showLocation flag to display location
 *
 * @return Banner clock
 *
 */

const Clock = ({ profile, profileV2Data, showLocation }) => {
  const [localTime, setLocalTime] = useState();
  const { name, timezone, id = "" } = profile;
  const { room } = profileV2Data;
  const officeShort = id;

  const officeUrl = (name && officeShort && `${directory.office}${officeShort}`) || "";

  const roomUrl =
    (officeShort &&
      room &&
      `${directory.room}`.replace(`o=office`, `o=${officeShort}`).replace(`r=room`, `r=${room}`)) ||
    "";

  const roomText = room ? ` (Room: ${room})` : "";

  // Declaring timezone

  // Local time calculation
  // Running clock
  useEffect(() => {
    const getLocalTime = () => {
      const currentDate = moment();
      const formattedDate = moment.utc(currentDate, "DD-MM-YYYY h:mm:ss A");
      return showLocation
        ? setLocalTime(formattedDate.clone().tz(timezone).format("hh:mm a"))
        : setLocalTime(formattedDate.clone().tz(timezone).format("hh:mm:ss a"));
    };

    let timerID;
    if (timezone) {
      timerID = setInterval(() => getLocalTime(), 1000);
    }

    return () => {
      clearInterval(timerID);
    };
  }, [profile]);

  return (
    <>
      {(localTime &&
        ((showLocation && (
          <IconWithDynamicText
            data-testid="local-time"
            imgSrc="/icons/loc_marker.svg"
            altText={`${LABEL.BANNER.OfficeLocation}${roomText} - ${LABEL.BANNER.LocalTime}`}
          >
            <span data-testid="clock-data" className={`${styles["banner-clock__details"]} ml-h`}>
              {profile?.name && (
                <a
                  target="_blank"
                  href={officeUrl}
                  data-testid="clock-data-office"
                  onClick={() => {
                    sendEventData(TRIGGERS.BANNER.LOCATION, EVENTS.BANNER_CLICKS, {
                      newTabName: NEW_TAB_NAMES.LOCATION
                    });
                  }}
                >
                  {profile?.name}
                </a>
              )}
              {[profile?.name, room].every((val) => !!val) ? " " : ""}
              {/* {room && (
                <>
                  (Room:
                  <a target="_blank" href={roomUrl} data-testid="clock-data-room">
                    {room}
                  </a>
                  )
                </>
              )} */}
              {[profile?.name, room].some((val) => !!val) ? " - " : ""}
              {localTime}
            </span>
          </IconWithDynamicText>
        )) || (
          <IconWithText
            data-testid="local-time"
            imgSrc="/icons/time_marker.svg"
            text={(profile && localTime) || ""}
            subText={profile && timezone}
            labelText={`${LABEL.BANNER.LocalTime}:`}
            altText={LABEL.BANNER.LocalTime}
          />
        ))) || (
        <Placeholder className="banner-meta-placeholder">
          <Placeholder.Line />
        </Placeholder>
      )}
    </>
  );
};

Clock.defaultProps = {
  showLocation: true
};

Clock.propTypes = {
  profile: PropTypes.shape().isRequired,
  profileV2Data: PropTypes.shape().isRequired,
  showLocation: PropTypes.bool
};

export default Clock;
