import { call, put } from "redux-saga/effects";
import { delete as del } from "redux/api";
import { setDeleteCertificateSuccess, setDeleteCertificateError } from "redux/actions/certificate";

function* handleDeleteCertificate({ payload }) {
  const { data, error } = yield call(del, payload);
  if (error) {
    yield put(setDeleteCertificateError(error));
  } else {
    yield put(setDeleteCertificateSuccess(data));
  }
}

export default handleDeleteCertificate;
