import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import LABEL from "constants/label";
import { Grid } from "semantic-ui-react";
import CredentialsGridRow from "components/Achievements/CredentialsGrid/CredentialsGridRow";
import "./styles.scss";
import {
  findRemainingArrayElements,
  sortBy,
  getAwardCredentialId,
  arrangeNonFeaturedRecords
} from "components/Achievements/helper/utils";
import { isEmptyOrNull } from "helpers/utils";

const { FeaturedCredentialsLabel, SortOrder, Award } = LABEL.ACHIEVEMENTS;

function FeaturedCredentials({
  featuredCreds,
  showSkills,
  credIconName,
  isEditable,
  onClickRow,
  setData,
  onEditClick,
  featuredRecordsCount,
  handleOnDelete,
  recordType,
  handleOnHideUnHide,
  saveSortOrder,
  setNonFeaturedCreds,
  setFeaturedCreds,
  allCredentials,
  setIsHiddenOrExpiredCred,
  isHiddenOrExpiredCred,
  draggedIntoFeaturedSection,
  setDraggedIntoFeaturedSection
}) {
  const dragOverItem = useRef();
  const [borderColorClass, setBorderColorClass] = useState(null);

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
    if (draggedIntoFeaturedSection) {
      setBorderColorClass(
        isHiddenOrExpiredCred ? "featured__credentials__redBorder" : "featured__credentials__greenBorder"
      );
    }
  };

  // logic to drag credential record in  non-featured section
  const dragStart = (e, position, cred) => {
    e.dataTransfer.setData("id", recordType !== Award ? cred?.certificationId : cred?.awardId);
    setDraggedIntoFeaturedSection(false);
  };

  // logic to drop credential record in featured section
  const onDrop = (ev) => {
    setBorderColorClass(null);
    const id = Number(ev.dataTransfer.getData("id"));
    if (!isEmptyOrNull(id) && id !== 0 && !isHiddenOrExpiredCred) {
      const newPosition = dragOverItem.current;
      let updatedSortOrder = [];
      const isFeaturedCredentialSorting = !isEmptyOrNull(
        featuredCreds?.filter((cred) => getAwardCredentialId(cred, recordType) === id)
      );
      // logic to sort featured section creds
      if (isFeaturedCredentialSorting) {
        setFeaturedCreds((prevState) => {
          const newData = [...prevState];
          updatedSortOrder = newData.map((cred, i) => {
            const newCred = { ...cred };
            newCred.featured = true;
            newCred.sortOrder = i === 0 ? 1 : 0;
            return newCred;
          });
          return sortBy(updatedSortOrder, SortOrder, false);
        });
      }
      // logic to drop cred in featured section from non featured section
      else {
        setFeaturedCreds((prevState) => {
          const newData = [...prevState];
          const dataToBeAdded = allCredentials?.filter((cred) => getAwardCredentialId(cred, recordType) === id);
          // when non featured cred is dragged at featured section first position
          if (newPosition === 0 && !isEmptyOrNull(dataToBeAdded)) {
            newData.unshift(dataToBeAdded[0]);
          }
          // when non featured cred is dragged at featured section second position
          if (newPosition === 1 && !isEmptyOrNull(dataToBeAdded)) {
            newData.splice(1, 0, dataToBeAdded[0]);
          }
          updatedSortOrder = [...newData].map((cred, i) => {
            const newCred = { ...cred };
            newCred.featured = i < featuredRecordsCount;
            newCred.sortOrder = i < featuredRecordsCount ? i : null;
            return newCred;
          });
          setNonFeaturedCreds(
            arrangeNonFeaturedRecords(
              findRemainingArrayElements(
                allCredentials,
                updatedSortOrder.slice(0, featuredRecordsCount),
                recordType === Award ? "awardId" : "certificationId"
              )
            )
          );
          return updatedSortOrder.slice(0, featuredRecordsCount);
        });
      }
      saveSortOrder(updatedSortOrder, recordType);
    }
    setIsHiddenOrExpiredCred(false);
  };

  return (
    <Grid
      className={`featured__credentials featured__credentials__defaultBorder ${borderColorClass}`}
      onDragOver={(e) => e.preventDefault()}
      onDrop={(e) => {
        onDrop(e);
      }}
      data-testid="featuredCredentials-DragDropZone"
    >
      <Grid.Row>
        <Grid.Column width={16} className="pl-0">
          <div className="featured__credentials-label">{FeaturedCredentialsLabel}</div>
        </Grid.Column>
      </Grid.Row>
      {featuredCreds?.map((cred, index) => (
        <CredentialsGridRow
          credential={cred}
          showSkills={showSkills}
          credIconName={credIconName}
          onClickRow={onClickRow}
          setData={setData}
          onEditClick={onEditClick}
          onDragStart={(e) => dragStart(e, index, cred)}
          onDragEnter={(e) => dragEnter(e, index)}
          featuredCredential
          handleOnDelete={handleOnDelete}
          recordType={recordType}
          handleOnHideUnHide={handleOnHideUnHide}
          isEditable={isEditable}
        />
      ))}
    </Grid>
  );
}

FeaturedCredentials.defaultProps = {
  showSkills: false,
  featuredCreds: [],
  credIconName: "",
  isEditable: false,
  onClickRow: () => {},
  setData: () => {},
  onEditClick: () => {},
  featuredRecordsCount: 2,
  handleOnDelete: () => {},
  recordType: "",
  handleOnHideUnHide: () => {},
  saveSortOrder: () => {},
  setNonFeaturedCreds: () => {},
  setFeaturedCreds: () => {},
  allCredentials: [],
  setIsHiddenOrExpiredCred: () => {},
  isHiddenOrExpiredCred: false,
  draggedIntoFeaturedSection: false,
  setDraggedIntoFeaturedSection: false
};

FeaturedCredentials.propTypes = {
  showSkills: PropTypes.bool,
  featuredCreds: PropTypes.arrayOf(PropTypes.shape()),
  credIconName: PropTypes.string,
  isEditable: PropTypes.bool,
  onClickRow: PropTypes.func,
  setData: PropTypes.func,
  onEditClick: PropTypes.func,
  featuredRecordsCount: PropTypes.number,
  handleOnDelete: PropTypes.func,
  recordType: PropTypes.string,
  handleOnHideUnHide: PropTypes.func,
  saveSortOrder: PropTypes.func,
  setNonFeaturedCreds: PropTypes.func,
  setFeaturedCreds: PropTypes.func,
  allCredentials: PropTypes.arrayOf(PropTypes.shape()),
  setIsHiddenOrExpiredCred: PropTypes.func,
  isHiddenOrExpiredCred: PropTypes.bool,
  draggedIntoFeaturedSection: PropTypes.bool,
  setDraggedIntoFeaturedSection: PropTypes.func
};

export default FeaturedCredentials;
