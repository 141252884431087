import { organization } from "redux/constants";

const orgReducer = (state = {}, action) => {
  switch (action.type) {
    case organization.FETCH_ORG_SUCCESS: {
      return action.data.data.data.employees[0];
    }
    default:
      return state;
  }
};

export default orgReducer;
