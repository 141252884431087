/* eslint-disable no-extra-boolean-cast */
import { Table, Icon as IconS, Button, Modal } from "semantic-ui-react";
import PropTypes from "prop-types";
import { Icon } from "components/common";

import { formatDateByMoment } from "helpers/utils";
import LABEL from "constants/label";
import CONFIG from "constants/config";

import "../styles.scss";

const { Education, TABLE_HEADER } = LABEL.EDUCATION;
const { NA, Present, Close } = LABEL.COMMON;

const { linkedInUrl } = CONFIG;

function LinkedInEducationViewAll({ open, onClose, linkedInEducation }) {
  return (
    <Modal open={open} className="education__modal popup-modal">
      <Modal.Header className="education__modal__title">
        {Education}
        <p className="fs-12 mb-1 fw-normal header-subHeading">
          Any changes to Education can only be updated in LinkedIn.
        </p>
      </Modal.Header>

      <Modal.Content className="education__modal__content">
        <Table striped fixed>
          <Table.Header className="education__table--head">
            <Table.Row>
              <Table.HeaderCell width={6} className="cursor-pointer">
                {TABLE_HEADER.School}
              </Table.HeaderCell>
              <Table.HeaderCell width={4} className="cursor-pointer">
                {TABLE_HEADER.Degree}
              </Table.HeaderCell>
              <Table.HeaderCell width={3} className="cursor-pointer">
                {TABLE_HEADER.Major}
              </Table.HeaderCell>
              <Table.HeaderCell width={3} className="cursor-pointer">
                {TABLE_HEADER.StartEndDate}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body className="education__table--body">
            {linkedInEducation?.length &&
              linkedInEducation?.map((educationObj) => (
                <Table.Row key={educationObj?.schoolName}>
                  <Table.Cell className="d-flex align-items-center">
                    <div className="education__icon d-flex justify-content-center mt-0">
                      <Icon name="graduateCap" />
                    </div>
                    <span className="td-label">{TABLE_HEADER.School}</span>
                    {educationObj?.schoolName ? educationObj?.schoolName : "-"}
                  </Table.Cell>
                  <Table.Cell>
                    <span className="td-label mt-3">{TABLE_HEADER.Degree}</span>{" "}
                    {educationObj?.degreeName ? educationObj?.degreeName : "-"}
                  </Table.Cell>
                  <Table.Cell>
                    <span className="td-label">{TABLE_HEADER.Major}</span>{" "}
                    {!!educationObj?.fieldsOfStudy?.length ? educationObj?.fieldsOfStudy[0] : "-"}
                  </Table.Cell>

                  <Table.Cell>
                    <span className="td-label">{TABLE_HEADER.Duration}</span>
                    <>
                      {(!!educationObj?.startYear &&
                        formatDateByMoment(
                          new Date(
                            educationObj?.startYear,
                            !!educationObj?.startMonth ? educationObj?.startMonth - 1 : educationObj?.startMonth
                          ),
                          educationObj?.startMonth > 0 ? "MMM, YYYY" : "YYYY"
                        )) ||
                        NA}{" "}
                      -{" "}
                      {(!!educationObj?.endYear &&
                        formatDateByMoment(
                          new Date(
                            educationObj?.endYear,
                            !!educationObj?.endMonth ? educationObj?.endMonth - 1 : educationObj?.endMonth
                          ),
                          educationObj?.endYear > 0 ? "MMM, YYYY" : "YYYY"
                        )) ||
                        Present}
                    </>
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      </Modal.Content>

      <Modal.Actions className="education_footer tab__footer d-flex justify-content-between align-items-center">
        <div className="education__content__small">
          <IconS name="info circle" className="education__content__icon" />
          Any changes to Education can only be updated in LinkedIn. Please{" "}
          <a href={linkedInUrl} target="_blank" rel="noopener noreferrer">
            click here
          </a>{" "}
          to access the LinkedIn portal.
        </div>
        <Button data-testid="close" basic className="button-sm mr-1" secondary onClick={() => onClose()}>
          {Close}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

LinkedInEducationViewAll.defaultProps = {
  linkedInEducation: []
};

LinkedInEducationViewAll.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  linkedInEducation: PropTypes.arrayOf(PropTypes.shape(PropTypes.any))
};

export default LinkedInEducationViewAll;
