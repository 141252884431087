import { cco } from "redux/constants";

const ccoReducer = (state = [], action) => {
  switch (action.type) {
    case cco.FETCH_CCO_SUCCESS: {
      return [...action?.data?.sort()];
    }
    default:
      return state;
  }
};

export default ccoReducer;
