import PropTypes from "prop-types";
import ReactHtmlParser from "react-html-parser";
import { Grid } from "semantic-ui-react";
import "./styles.scss";

/**
 * Description
 *
 * @param  {node} description case/materail description
 *
 * @return case/materail description
 *
 */

const Description = ({ description, noDescMsg }) => (
  <Grid className="mb-0">
    <Grid.Row>
      <Grid.Column className="pt-0 pb-0 " data-testid="description" computer={16}>
        <div className="description">
          {(description && ReactHtmlParser(description)) || <p className="no__case-desc">{noDescMsg}</p>}
        </div>
      </Grid.Column>
    </Grid.Row>
  </Grid>
);
Description.defaultProps = { noDescMsg: "" };
Description.propTypes = { description: PropTypes.node.isRequired, noDescMsg: PropTypes.string };

export default Description;
