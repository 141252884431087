import { takeLatest } from "redux-saga/effects";

import { user } from "redux/constants";
import handleFetchUser from "../worker/handleFetchUser";

function* watchUser() {
  yield takeLatest(user.FETCH_USER, handleFetchUser);
}

export default watchUser;
