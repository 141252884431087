import { profileViews } from "redux/constants";

const profileViewsDetailsLoadingReducer = (state = "", action) => {
  switch (action.type) {
    case profileViews.FETCH_PROFILE_VIEW_DETAILS:
      return true;
    case profileViews.FETCH_PROFILE_VIEW_DETAILS_SUCCESS:
    case profileViews.FETCH_PROFILE_VIEW_DETAILS_FAILURE:
      return false;
    default:
      return state;
  }
};

export default profileViewsDetailsLoadingReducer;
