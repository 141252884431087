/* eslint-disable default-case */
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { List } from "semantic-ui-react";
import LABEL from "constants/label";
import Icon from "components/common/Icon";
import { copyToClipboard, formatPhoneNumber } from "helpers/utils";
import ControlledPopover from "components/common/ControlledPopover";
import sendEventData from "analytics/analytics";
import { EVENTS, TRIGGERS, NEW_TAB_NAMES } from "analytics/constants";
import "./styles.scss";

const {
  BANNER: {
    TOOLTIPS: { ContactNumberCopy, ContactNumberCopyDone },
    PHONELINKS: { Office, Office1, Office2, Mobile }
  }
} = LABEL;

/**
 * PhoneList - contact details in banner contact popover
 *
 * @param  {array} phones array of phones
 *
 * @return List items with contact details
 *
 * @example
 *
 * <PhoneList phones={phones} />
 */

function PhoneList({ phones }) {
  const [copyHoverText, setCopyHoverText] = useState(ContactNumberCopy);

  const [office1, setOffice1] = useState(null);
  const [office2, setOffice2] = useState(null);
  const [mobile, setMobile] = useState(null);

  const [copyOfficeNumber1, setCopyOfficeNumber1] = useState(false);
  const [copyMobileNumber, setCopyMobileNumber] = useState(false);
  const [copyOfficeNumber2, setCopyOfficeNumber2] = useState(false);

  useEffect(() => {
    if (phones?.length > 0) {
      const mobileList = phones?.filter((phone) => phone?.deviceType === "Mobile" && phone?.usage === "Work");
      const landlineList = phones?.filter((phone) => phone?.deviceType === "Landline" && phone?.usage === "Work");
      setMobile(formatPhoneNumber(mobileList[0]?.phoneNumber, mobileList[0]?.countryCode));
      setOffice1(formatPhoneNumber(landlineList[0]?.phoneNumber, landlineList[0]?.countryCode));
      setOffice2(formatPhoneNumber(landlineList[1]?.phoneNumber, landlineList[1]?.countryCode));
    }
  }, [phones]);

  const bothOfficePhoneAvailable = office1 && office2;

  const popupOffset = ({ popper }) => {
    return [0, 2];
  };

  const onCopyNumber = (contactType, idx = null) => {
    let numberToCopy = "";
    let newTabName = NEW_TAB_NAMES.COPY_PHONE_OFFICE;

    switch (contactType) {
      case Office1:
        numberToCopy = office1;
        break;
      case Mobile:
        numberToCopy = mobile;
        newTabName = NEW_TAB_NAMES.COPY_PHONE_MOBILE;
        break;
      case Office2:
        numberToCopy = office2;
        break;
    }
    sendEventData(TRIGGERS.BANNER.PHONE_COPY, EVENTS.BANNER_CLICKS, { newTabName }); // Analytics Event
    copyToClipboard(numberToCopy);
    setCopyHoverText(ContactNumberCopyDone);
  };

  const onCopyNumberHoverOut = (contactType) => {
    switch (contactType) {
      case Office1:
        setCopyOfficeNumber1(false);
        break;
      case Mobile:
        setCopyMobileNumber(false);
        break;
      case Office2:
        setCopyOfficeNumber2(false);
        break;
    }
  };

  const onCopyNumberHover = (contactType, idx = null) => {
    setCopyHoverText(ContactNumberCopy);

    switch (contactType) {
      case Office1:
        setCopyOfficeNumber1(true);
        break;
      case Mobile:
        setCopyMobileNumber(true);
        break;
      case Office2:
        setCopyOfficeNumber2(true);
        break;
    }
  };

  return (
    <List className="phone-list fw-bold d-flex mb-0">
      <List.Item
        data-testid="office1"
        className={`${office1 ? "order-first" : "order-last"} phone-list__list`} // adding class to put them on top of list based on if field has value
      >
        <span>{bothOfficePhoneAvailable ? Office1 : Office}</span> {office1 || "--"}
        {office1 && (
          <div className="phone-list__copy-suffix">
            <div className="phone-list__copy-separator" />
            <ControlledPopover
              trigger={
                <span className="phone-list__copy-number" data-testid="phone-list--phone--copy">
                  <Icon name="copy" size={16} onIconClick={() => onCopyNumber(Office1)} />
                </span>
              }
              inverted
              position="right center"
              onOpen={() => onCopyNumberHover(Office1)}
              onClose={() => onCopyNumberHoverOut(Office1)}
              openState={copyOfficeNumber1}
              on="hover"
              offset={popupOffset}
              popper={{ className: "banner-number__popup--copy__tooltip" }}
            >
              {copyHoverText}
            </ControlledPopover>
          </div>
        )}
      </List.Item>

      {bothOfficePhoneAvailable && (
        <List.Item
          data-testid="office2"
          className={`${office2 ? "order-first" : "order-last"} phone-list__list`} // adding class to put them on top of list based on if field has value
        >
          <span>{bothOfficePhoneAvailable ? Office2 : Office}</span>
          {office2 || "--"}
          {office2 && (
            <div className="phone-list__copy-suffix">
              <div className="phone-list__copy-separator" />
              <ControlledPopover
                trigger={
                  <span className="phone-list__copy-number" data-testid="phone-list--work--copy">
                    <Icon name="copy" size={16} onIconClick={() => onCopyNumber(Office2)} />
                  </span>
                }
                inverted
                position="right center"
                onOpen={() => onCopyNumberHover(Office2)}
                onClose={() => onCopyNumberHoverOut(Office2)}
                openState={copyOfficeNumber2}
                on="hover"
                offset={popupOffset}
                popper={{ className: "banner-number__popup--copy__tooltip" }}
              >
                {copyHoverText}
              </ControlledPopover>
            </div>
          )}
        </List.Item>
      )}

      {!!mobile && (
        <List.Item
          data-testid="mobile"
          className={`${mobile ? "order-first" : "order-last"} phone-list__list`} // adding class to put them on top of list based on if field has value
        >
          <span>{Mobile}</span> {mobile || "--"}
          {mobile && (
            <div className="phone-list__copy-suffix">
              <div className="phone-list__copy-separator" />
              <ControlledPopover
                trigger={
                  <span className="phone-list__copy-number" data-testid="phone-list--mobile--copy">
                    <Icon name="copy" size={16} onIconClick={() => onCopyNumber(Mobile)} />
                  </span>
                }
                inverted
                position="right center"
                onOpen={() => onCopyNumberHover(Mobile)}
                onClose={() => onCopyNumberHoverOut(Mobile)}
                openState={copyMobileNumber}
                on="hover"
                offset={popupOffset}
                popper={{ className: "banner-number__popup--copy__tooltip" }}
              >
                {copyHoverText}
              </ControlledPopover>
            </div>
          )}
        </List.Item>
      )}
    </List>
  );
}

PhoneList.defaultProps = {
  phones: []
};

PhoneList.propTypes = {
  phones: PropTypes?.arrayOf(
    PropTypes.shape({
      deviceType: PropTypes?.string,
      usage: PropTypes.string
    })
  )
};

export default PhoneList;
