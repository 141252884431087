import { takeLatest } from "redux-saga/effects";

import { upload } from "redux/constants";
import handleUploadResumeToS3 from "../worker/handleUploadResumeToS3";

function* watchUploadResumeToS3() {
  yield takeLatest(upload.UPLOAD_RESUME_TO_S3, handleUploadResumeToS3);
}

export default watchUploadResumeToS3;
