import { takeLatest } from "redux-saga/effects";

import { editProfile } from "redux/constants";
import handleProposalFile from "../worker/handleProposalFile";

function* watchProposalFile() {
  yield takeLatest(editProfile.PROPOSAL_FILE, handleProposalFile);
}

export default watchProposalFile;
