/* eslint-disable no-extra-boolean-cast */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import VerticalEllipsis from "components/common/VerticalEllipsis";
import { formatDateByMoment, getDuration } from "helpers/utils";
import LABEL from "constants/label";
import "./styles.scss";

const {
  COMMON: { Present, NA }
} = LABEL;

function VerticalStepper({ orgRoles }) {
  const [companyRoles, setCompanyRoles] = useState([]);

  useEffect(() => {
    setCompanyRoles(orgRoles);
    if (orgRoles?.length > 4) setCompanyRoles([...orgRoles.slice(0, 2), ...orgRoles.slice(-2)]);
  }, [orgRoles]);

  return (
    <ul className="vertical__stepper">
      {companyRoles?.map((role, index) => (
        <li className="vertical__stepper-item" key={role?.title}>
          <h4>{role?.title}</h4>
          <p>
            <span className="mr-h">
              {(!!role?.startYear &&
                formatDateByMoment(
                  new Date(role?.startYear, !!role?.startMonth ? role?.startMonth - 1 : role?.startMonth),
                  "MMM, YYYY"
                )) ||
                NA}{" "}
              –{" "}
              {(role?.endYear &&
                formatDateByMoment(
                  new Date(role?.endYear, !!role?.endMonth ? role?.endMonth - 1 : role?.endMonth),
                  "MMM, YYYY"
                )) ||
                Present}
            </span>
            {!!role?.startYear && (
              <span data-testid="duration" className="d-inline-block">
                |
                <strong className="ml-h">
                  {getDuration(
                    (!!role?.endYear &&
                      new Date(role?.endYear, !!role?.endMonth ? role?.endMonth : role?.endMonth - 1)) ||
                      new Date(),
                    new Date(role?.startYear, !!role?.startMonth ? role?.startMonth - 1 : role?.startMonth)
                  )}
                </strong>
              </span>
            )}
          </p>
          {index === 1 && orgRoles?.length > 4 && <VerticalEllipsis />}
        </li>
      ))}
    </ul>
  );
}

VerticalStepper.defaultProps = {
  orgRoles: [
    {
      title: "Sr. Lead Analyst",
      startDate: "2021-03-3 00:00:00.000",
      endDate: null
    },
    {
      title: "Lead Analyst",
      startDate: "2018-11-3 00:00:00.000",
      endDate: "2021-03-3 00:00:00.000"
    },
    {
      title: "Sr. Analyst",
      startDate: "2017-10-1 00:00:00.000",
      endDate: "2018-11-3 00:00:00.000"
    },
    {
      title: "Analyst",
      startDate: "2015-12-31 00:00:00.000",
      endDate: "2017-10-1 00:00:00.000"
    },
    {
      title: "Jr. Analyst",
      startDate: "2012-12-31 00:00:00.000",
      endDate: "2015-12-31 00:00:00.000"
    }
  ]
};

VerticalStepper.propTypes = {
  orgRoles: PropTypes.arrayOf(PropTypes.shape({}))
};

export default VerticalStepper;
