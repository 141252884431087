import { userProfileImageHighRes } from "redux/constants";

const profileImageHighResReducer = (state = {}, action) => {
  switch (action.type) {
    case userProfileImageHighRes.FETCH_USER_IMAGE_HIGHRES_SUCCESS: {
      return { ...action.data };
    }
    default:
      return state;
  }
};

export default profileImageHighResReducer;
