import { organization } from "redux/constants";

const orgMemberImageLoadingReducer = (state = true, action) => {
  switch (action.type) {
    case organization.FETCH_ORG_MEMBER_IMAGE:
      return true;
    case organization.FETCH_ORG_MEMBER_IMAGE_SUCCESS:
    case organization.FETCH_ORG_MEMBER_IMAGE_FAILURE:
      return false;
    default:
      return state;
  }
};

export default orgMemberImageLoadingReducer;
