import { people } from "redux/constants";

const getPeople = (payload) => ({
  type: people.FETCH_PEOPLE,
  payload
});

const setPeople = (data) => ({
  type: people.FETCH_PEOPLE_SUCCESS,
  data
});

const setPeopleError = (error) => ({
  type: people.FETCH_PEOPLE_FAILURE,
  error
});

const getPeopleImage = (payload) => ({
  type: people.FETCH_PEOPLE_IMAGE,
  payload
});

const setPeopleImage = (data) => ({
  type: people.FETCH_PEOPLE_IMAGE_SUCCESS,
  data
});

const setPeopleImageError = (error) => ({
  type: people.FETCH_PEOPLE_IMAGE_FAILURE,
  error
});

export { getPeople, setPeople, setPeopleError, getPeopleImage, setPeopleImage, setPeopleImageError };
