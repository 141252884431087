const rootTerm = {
  id: "root",
  name: "root"
};
export const rootNode = {
  term: rootTerm,
  isOpen: true,
  isLeaf: false,
  children: [],
  error: null
};

export const searchReducer = (state, action) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case "SET_SEARCH_RESULT": {
      const { result } = action;
      return result;
    }

    default: {
      return state;
    }
  }
};
