import { topics } from "redux/constants";

const topicDeletingErrorReducer = (state = null, action) => {
  switch (action.type) {
    case topics.DELETE_TOPIC_FAILURE:
      return action.error;
    case topics.DELETE_TOPIC_SUCCESS:
    case topics.DELETE_TOPIC:
      return null;
    default:
      return state;
  }
};

export default topicDeletingErrorReducer;
