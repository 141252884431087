import PropTypes from "prop-types";
import qs from "qs";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import CONFIG from "constants/config";
import { saveProfileViews } from "redux/actions/profileViews";
import { useDispatch } from "react-redux";
import LABEL from "constants/label";

/**
 * useLogProfileViews
 *
 * @param  {object} user user data
 * @param  {object} profile profile data
 * @param {string} tab name on which user is landing
 * @param  {bool} profileLoading flag to indicate if profile is loading
 *
 * @return None - used as hook to log profile views
 */

const useLogProfileViews = (loggedInUserHrId, profileHrId, profileTab, profileLoading) => {
  const location = useLocation();
  const urlQueryParams = qs.parse(location?.search, { ignoreQueryPrefix: true });
  const applicationSource = urlQueryParams[LABEL.COMMON.SOURCE_NAME]?.toLowerCase();
  const dispatch = useDispatch();
  const { apiUrl, xApiKey } = CONFIG;
  useEffect(() => {
    if (profileHrId && loggedInUserHrId && profileHrId !== loggedInUserHrId && !profileLoading) {
      const profileViewsObject = {
        profileHrId,
        visitorHrId: Number(loggedInUserHrId),
        profileTab,
        source: applicationSource || ""
      };
      dispatch(
        saveProfileViews({
          endpoint: `${apiUrl.linkApi}profile/profile-view `,
          data: profileViewsObject,
          config: {
            headers: {
              "x-api-key": `${xApiKey.profile}`
            }
          }
        })
      );
    }
  }, [profileHrId, loggedInUserHrId]);
};

useLogProfileViews.defaultProps = {
  loggedInUserHrId: 0,
  profileHrId: 0,
  profileTab: ""
};

useLogProfileViews.propTypes = {
  loggedInUserHrId: PropTypes.number,
  profileHrId: PropTypes.number,
  profileTab: PropTypes.string
};

export default useLogProfileViews;
