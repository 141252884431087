import { cco } from "redux/constants";

const ccoLoadingReducer = (state = true, action) => {
  switch (action.type) {
    case cco.FETCH_CCO:
      return true;
    case cco.FETCH_CCO_SUCCESS:
    case cco.FETCH_CCO_FAILURE:
      return false;
    default:
      return state;
  }
};

export default ccoLoadingReducer;
