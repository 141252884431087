import { about } from "redux/constants";

const aboutReducer = (state = null, action) => {
  switch (action.type) {
    case about.FETCH_ABOUT_SUCCESS: {
      return action.data;
    }
    default:
      return state;
  }
};

export default aboutReducer;
