import { isNil } from "lodash";
import moment from "moment";
import shortenUrl from "helpers/shortenUrl";
import CONFIG from "constants/config";

export const getMonthYear = (date) => moment(date).format("MMMM YYYY");

const singleSubject = (fileName) => `Shared from Navigator: ${fileName}`;

function truncateDescription(materialDescription) {
  if (materialDescription && materialDescription.length > 500) {
    return encodeURIComponent(`${materialDescription.substring(0, 500)}...`);
  }
  return materialDescription;
}

const APP_NAME = "Navigator";

const SERVER_URL = "newnavigator.bcg.com";

const caseSearch = (term, attachmentId, showDocViz) =>
  `/cases?query=${term}&viewState=expanded&attachmentId=${attachmentId}${showDocViz ? "&showDocViz=true" : ""}`;

const knowledgeDownloadFile = (guid) => `${CONFIG.knowledge.download}/${guid}`;

const materialUrl = (guid) => `/material/${guid || ""}`;

const singleBodyVignetter = (caseType, shortDescription, casePage) =>
  `${caseType} - ${shortDescription}%0D%0A%0D%0AView in ${APP_NAME}: https://${casePage}`;

const multiVignettesSub = (caseNumber) =>
  `Shared from ${APP_NAME}: ${caseNumber !== "restricted" ? `Case Number ${caseNumber}` : "Case"}`;

const multiVignettes = (index, fileName, caseResultsUrl) =>
  `${index}. ${fileName !== "" && fileName} - View in ${APP_NAME} : ${caseResultsUrl}%0D%0A%0D%0A`;

const singleBodyAttachment = (downloadUrl, materialDescription, materialPageUrl) =>
  `Direct Download:%0D%0A${downloadUrl} %0D%0A%0D%0ADescription:%0D%0A${materialDescription}%0D%0A%0D%0AView full details on ${APP_NAME}:%0D%0A https://${materialPageUrl}`;

// Email Single Vignette
export function emailSingleVignette(fileName, projectId, attachmentId, caseType, shortDescription) {
  const subject = encodeURIComponent(singleSubject(fileName));
  const description = encodeURIComponent(shortDescription);
  const casePage = encodeURIComponent(`${SERVER_URL}`.concat(caseSearch(projectId, attachmentId, true)));
  const body = singleBodyVignetter(caseType, description, casePage);
  window.location.href = `mailto:?SUBJECT=${subject}&BODY=${body}`;
}

export async function emailSingleAttachment(fileName, attachmentId, materialId, materialDescription, kpCmsId) {
  const subject = encodeURIComponent(singleSubject(fileName));
  const description = truncateDescription(materialDescription);
  const downloadUrl = await shortenUrl(encodeURIComponent(knowledgeDownloadFile(kpCmsId)));
  const materialPageUrl = await shortenUrl(`${SERVER_URL}`.concat(encodeURIComponent(materialUrl(kpCmsId))));
  const body = singleBodyAttachment(downloadUrl, description, materialPageUrl);
  window.location.href = `mailto:?SUBJECT=${subject}&BODY=${body}`;
}
// Email Multiple Vignettes
export function emailMultipleVignettes(attachments) {
  const subject = multiVignettesSub(attachments[0].caseNumber);
  const caseVignetteLinks = attachments
    .map((a, index) => {
      const caseResultsPageUrl = `${SERVER_URL}`.concat(
        encodeURIComponent(caseSearch(a.projectId, a.attachmentId, false))
      );
      return multiVignettes(index + 1, a.fileName, caseResultsPageUrl);
    })
    .reduce((acc, x) => (isNil(acc) ? x : acc + x), null);
  const title = `${attachments[0].caseNumber !== "restricted" ? attachments[0].caseNumber : "Case"} - ${
    attachments[0].clientDescription
  } - ${getMonthYear(attachments[0].caseOpenDate)}%0D%0A%0D%0A`;
  const body = title.concat(caseVignetteLinks);
  window.location.href = `mailto:?SUBJECT=${subject}&BODY=${body}`;
}

export function getPipeSeparatedVignettesIds(vignettes) {
  if (vignettes.length === 1) return vignettes[0].kpCmsId;
  const vignettesIds = vignettes
    .map((vignette) => {
      return `${vignette.kpCmsId}`;
    })
    .join("|");

  return vignettesIds;
}
