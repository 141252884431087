import { call, put as putSaga } from "redux-saga/effects";
import { put } from "redux/api";
import { setUpdateAboutSASuccess, setUpdateAboutSAError } from "redux/actions/about";

function* handleUpdateAboutSA({ payload }) {
  const { data, error } = yield call(put, payload);
  if (error) {
    yield putSaga(setUpdateAboutSAError(error));
  } else {
    yield putSaga(setUpdateAboutSASuccess(data));
  }
}

export default handleUpdateAboutSA;
