import { memberList } from "redux/constants";
import { Buffer } from "buffer";

const memberListImageReducer = (state = {}, action) => {
  switch (action.type) {
    case memberList.FETCH_MEMBER_LIST_IMAGE_SUCCESS: {
      return {
        ...state,
        [action.data.payload.hrid]: [
          `data:image/png;base64,${Buffer.from(action.data.data, "binary").toString("base64")}`
        ]
      };
    }
    default:
      return state;
  }
};

export default memberListImageReducer;
