import { takeEvery } from "redux-saga/effects";

import { about } from "redux/constants";
import handleFetchAboutTopicDetails from "../worker/handleFetchAboutTopicDetails";

function* watchFetchAboutTopicDetails() {
  yield takeEvery(about.FETCH_ABOUT_TOPIC_DETAILS, handleFetchAboutTopicDetails);
}

export default watchFetchAboutTopicDetails;
