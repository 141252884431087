import { ssCases } from "redux/constants";

const getSSCases = (payload) => {
  return {
    type: ssCases.FETCH_SSCASE,
    payload
  };
};

const setSSCases = (data) => ({
  type: ssCases.FETCH_SSCASE_SUCCESS,
  data
});

const setError = (error) => ({
  type: ssCases.FETCH_SSCASE_FAILURE,
  error
});

export { getSSCases, setSSCases, setError };
