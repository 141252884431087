import { userProfileImageHighRes } from "redux/constants";

const profileImageHighResLoadingReducer = (state = false, action) => {
  switch (action.type) {
    case userProfileImageHighRes.FETCH_USER_IMAGE_HIGHRES:
      return true;
    case userProfileImageHighRes.FETCH_USER_IMAGE_HIGHRES_SUCCESS:
    case userProfileImageHighRes.FETCH_USER_IMAGE_HIGHRES_FAILURE:
      return false;
    default:
      return state;
  }
};

export default profileImageHighResLoadingReducer;
