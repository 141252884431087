import { profileUpdateHistory } from "redux/constants";

const profileUpdateHistoryErrorReducer = (state = {}, action) => {
  switch (action.type) {
    case profileUpdateHistory.FETCH_PROFILE_UPDATE_HISTORY_FAILURE:
      return action.error;
    case profileUpdateHistory.FETCH_PROFILE_UPDATE_HISTORY_SUCCESS:
    case profileUpdateHistory.FETCH_PROFILE_UPDATE_HISTORY:
      return null;
    default:
      return state;
  }
};

export default profileUpdateHistoryErrorReducer;
