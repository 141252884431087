import { takeLatest } from "redux-saga/effects";

import { education } from "redux/constants";
import handleFetchEducation from "../worker/handleFetchEducation";

function* watchEducation() {
  yield takeLatest(education.FETCH_EDUCATION, handleFetchEducation);
}

export default watchEducation;
