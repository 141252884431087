import { takeEvery } from "redux-saga/effects";

import { upload } from "redux/constants";
import handleUploadProposalBioStart from "../worker/handleUploadProposalBioStart";

function* watchUploadProposalBioStart() {
  yield takeEvery(upload.UPLOAD_PROPOSAL_BIO_START, handleUploadProposalBioStart);
}

export default watchUploadProposalBioStart;
