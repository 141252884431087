import { certificate } from "redux/constants";

const updateCertificate = (payload) => ({
  type: certificate.UPDATE_CERTIFICATE,
  payload
});

const setUpdateCertificateSuccess = (data) => ({
  type: certificate.UPDATE_CERTIFICATE_SUCCESS,
  data
});

const setUpdateCertificateError = (error) => ({
  type: certificate.UPDATE_CERTIFICATE_FAILURE,
  error
});

const deleteCertificate = (payload) => ({
  type: certificate.DELETE_CERTIFICATE,
  payload
});

const setDeleteCertificateSuccess = (data) => ({
  type: certificate.DELETE_CERTIFICATE_SUCCESS,
  data
});

const setDeleteCertificateError = (error) => ({
  type: certificate.DELETE_CERTIFICATE_FAILURE,
  error
});

export {
  updateCertificate,
  setUpdateCertificateSuccess,
  setUpdateCertificateError,
  deleteCertificate,
  setDeleteCertificateSuccess,
  setDeleteCertificateError
};
