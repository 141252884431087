import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Dimmer, Form, Loader, Modal, Segment, TextArea } from "semantic-ui-react";
import LABEL from "constants/label";
import CONFIG from "constants/config";
import "./styles.scss";
import { getUserLinkedInStatus, removeLinkedInAccess, setLinkedInData } from "redux/actions/linkedIn";
import { useDispatch, useSelector } from "react-redux";
import { toastError, toastSuccess } from "helpers/utils";

const { apiUrl, xApiKey } = CONFIG;
function LinkedInRemoveAccessConfirmation({ open, onModalClose, setComponentToShow }) {
  const {
    LINKEDIN: { REMOVAL_WARNING, REASON_FOR_REMOVAL, TEXTAREA_PLACEHOLDER, REMOVE_ACCESS },
    COMMON: { Cancel }
  } = LABEL;

  const dispatch = useDispatch();
  const [reasonForRemoval, setReasonForRemoval] = useState("");

  const { profile, removeLinkedInAccessSuccess, removeLinkedInAccessError, removeLinkedInAccessLoading } = useSelector(
    (state) => state
  );

  useEffect(() => {
    if (removeLinkedInAccessSuccess?.code === 200 || removeLinkedInAccessSuccess?.code === 204) {
      dispatch(setLinkedInData(null));
      onModalClose();
      toastSuccess();
      dispatch(
        getUserLinkedInStatus({
          endpoint: `${apiUrl.linkApi}linkedin/${profile?.hrId}/userOptinStatus`,
          config: {
            headers: {
              "x-api-key": `${xApiKey.profile}`
            }
          }
        })
      );
    }
  }, [removeLinkedInAccessSuccess]);

  useEffect(() => {
    if (removeLinkedInAccessError) {
      onModalClose();
      toastError();
    }
  }, [removeLinkedInAccessSuccess]);

  const handleOnResonForRemovalChange = (e, data) => {
    setReasonForRemoval(data?.value);
  };

  const handleOnCancelClick = () => {
    setComponentToShow(0);
  };

  const handleOnRemoveAccessClick = () => {
    dispatch(
      removeLinkedInAccess({
        endpoint: `${apiUrl?.linkApi}linkedin/${profile?.hrId}/optout`,
        data: {
          optOutReason: reasonForRemoval
        },
        config: {
          headers: {
            "x-api-key": `${xApiKey.profile}`
          }
        }
      })
    );
  };

  return (
    <Modal
      open={open}
      className="li__remove__access"
      data-testid="remove-linkedin-access-popup"
      closeOnDimmerClick={false}
    >
      <Modal.Content>
        {removeLinkedInAccessLoading && (
          <Segment className="loading__segment">
            <Dimmer active inverted>
              <Loader size="large">Removing Access...</Loader>
            </Dimmer>
          </Segment>
        )}
        <div className="d-flex align-items-center">
          <img src="/icons/linkedin-warning.svg" alt="warning icon" className="mr-1" />
          <p className="warn__header">{REMOVAL_WARNING}</p>
        </div>
        <div className="contents">
          <p>{REASON_FOR_REMOVAL}</p>
          <Form>
            <TextArea
              placeholder={TEXTAREA_PLACEHOLDER}
              data-testid="reason-for-removal-textarea"
              value={reasonForRemoval}
              onChange={handleOnResonForRemovalChange}
            />
          </Form>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button
          basic
          secondary
          size="medium"
          className="mr-1"
          onClick={handleOnCancelClick}
          data-testid="cancel-button"
        >
          {Cancel}
        </Button>
        <Button primary type="submit" data-testid="remove-access-button" onClick={handleOnRemoveAccessClick}>
          {REMOVE_ACCESS}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

LinkedInRemoveAccessConfirmation.propTypes = {
  open: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
  setComponentToShow: PropTypes.func.isRequired
};

export default LinkedInRemoveAccessConfirmation;
