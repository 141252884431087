import { takeLatest } from "redux-saga/effects";

import { about } from "redux/constants";
import handleUpdateAboutSA from "../worker/handleUpdateAboutSA";

function* watchUpdateAboutSA() {
  yield takeLatest(about.UPDATE_ABOUT_SA, handleUpdateAboutSA);
}

export default watchUpdateAboutSA;
