import { takeLatest } from "redux-saga/effects";

import { skills } from "redux/constants";
import handleDeleteSkillSummary from "../worker/handleDeleteSkillSummary";

function* watchDeleteSkillSummary() {
  yield takeLatest(skills.DELETE_SKILLSUMMARY, handleDeleteSkillSummary);
}

export default watchDeleteSkillSummary;
