import React, { useState, useEffect } from "react";
import { Button, Modal, Grid, Icon, Segment, Dimmer, Loader } from "semantic-ui-react";
import PropTypes from "prop-types";
import LABEL from "constants/label";
import PrimaryButton from "components/common/PrimaryButton";
import CredentialsGrid from "components/Achievements/CredentialsGrid";
import { toastDeleteSuccess, toastError, isEmptyOrNull, toastSuccess, debounce } from "helpers/utils";
import { getAchievements } from "redux/actions/achievements";
import { deleteCertificate, updateCertificate } from "redux/actions/certificate";
import { deleteAward } from "redux/actions/award";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import CONFIG from "constants/config";
import ConfirmationModal from "components/common/Modal/ConfirmationModal/ConfirmationModal";
import sendEventData from "analytics/analytics";
import { EVENTS, TRIGGERS } from "analytics/constants";
import ViewEditCredentialsPopup from "../Credentials/ViewEditCredentialsPopup";
import ViewEditAwardPopup from "../AllAwards/ViewEditAwardPopup/ViewEditAwardPopup";

const {
  Credentials,
  BCGCred,
  ExternalCred,
  Awards,
  Award,
  BCG,
  ExternalCredSource,
  BcgCredSource,
  ICONS: { ExternalCredential, InternalCred }
} = LABEL.ACHIEVEMENTS;
const {
  COMMON: { DeleteRecord, Close, Deleting, Saving }
} = LABEL;
const { apiUrl, xApiKey } = CONFIG;
/**
 *
 *  @param {bool} open indicates whether the model is open
 *  @param {func} onClose callback to be run on model close
 *  @param {boolean} isEditable indicates whether the page/component is editable or not
 *
 * @returns ViewEditCredentials component
 *
 */

function ViewEditCredentials({
  open,
  onClose,
  isEditable,
  bcgCredentials,
  externalCredentials,
  awards,
  setBcgCredentials,
  setExternalCredentials,
  setAwards
}) {
  const dispatch = useDispatch();
  const {
    certificateDeleting,
    awardDeleting,
    achievementsLoading,
    certificateDeletingError,
    awardDeletingError,
    peopleData,
    certificateUpdating,
    certificateUpdatingError
  } = useSelector((state) => state);
  const [showCredentialPopup, setShowCredentialPopup] = useState(false);
  const [isBcgCredential, setShowBcgCredential] = useState(false);
  const [showAwardPopup, setShowAwardPopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [data, setData] = useState(null);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [itemDragged, setItemDragged] = useState(false);
  const [deleteRecordType, setDeleteRecordType] = useState(null);

  const openCredentialPopup = (credentialType = "") => {
    setShowBcgCredential(credentialType === BCG);
    setShowCredentialPopup(true);
  };

  const handleAddAwards = () => {
    sendEventData(TRIGGERS.DIGITIAL_CREDENTIALS.ADD_AWARDS, EVENTS.CRED_SECTION); // Analytics Event
    setShowAwardPopup(true);
  };

  // logic to show delete confirmation popup
  const handleOnDelete = (rowdata, recordType) => {
    if (recordType === BcgCredSource || recordType === ExternalCredSource) {
      setDeleteId(rowdata?.certificationId);
    }
    if (recordType === Award) {
      setDeleteId(rowdata?.awardId);
    }
    setShowDeletePopup(true);
    setDeleteRecordType(recordType);
  };

  // logic to save hidden records
  const handleOnHideUnHide = (rowData) => {
    sendEventData(TRIGGERS.COMMON.HIDE, EVENTS.CRED_SECTION);
    setIsLoading(true);
    try {
      dispatch(
        updateCertificate({
          endpoint: `${apiUrl.linkApi}profile/${peopleData.id}/certification?addedBy=user&source=internal`,
          data: { ...rowData, hidden: !rowData?.hidden, featured: false, sortOrder: null },
          config: {
            headers: {
              "x-api-key": `${xApiKey.profile}`
            }
          }
        })
      );
    } catch (e) {
      setIsLoading(false);
      toastError();
    }
    return true;
  };

  // logic to delete record
  const deleteRecord = () => {
    sendEventData(TRIGGERS.COMMON.DELETE, EVENTS.CRED_SECTION);
    setShowDeletePopup(false);
    if (isLoading) return false;
    setIsLoading(true);
    try {
      dispatch(
        deleteRecordType === Award
          ? deleteAward({
              endpointUrl: `${apiUrl.linkApi}profile/${peopleData.id}/award/${deleteId}`,
              data: {},
              config: {
                headers: {
                  "x-api-key": `${xApiKey.profile}`
                }
              }
            })
          : deleteCertificate({
              endpointUrl: `${apiUrl.linkApi}profile/${peopleData.id}/certification/${deleteId}`,
              data: {},
              config: {
                headers: {
                  "x-api-key": `${xApiKey.profile}`
                }
              }
            })
      );
    } catch (e) {
      toastError();
      setDeleteId(null);
      setDeleteRecordType(null);
      setIsLoading(false);
    }
    return true;
  };

  const getQueryParams = (recordType) => {
    switch (recordType) {
      case BcgCredSource:
        return `certification?addedBy=user&source=internal`;
      case ExternalCredSource:
        return `certification?source=external`;
      case Award:
        return `award`;
      default:
        return null;
    }
  };

  // logic to save sort Order of featured records
  const saveSortOrder = debounce((dragAndDropData, recordType) => {
    setItemDragged(true);
    dragAndDropData?.forEach((record) => {
      try {
        dispatch(
          updateCertificate({
            endpoint: `${apiUrl.linkApi}profile/${peopleData.id}/${getQueryParams(recordType)}`,
            data: record,
            config: {
              headers: {
                "x-api-key": `${xApiKey.profile}`
              }
            }
          })
        );
      } catch (e) {
        toastError();
        setItemDragged(false);
      }
    });
  }, 500);

  useEffect(() => {
    if (!certificateUpdating && (isLoading || itemDragged)) {
      setItemDragged(false);
      if (isEmptyOrNull(certificateUpdatingError)) {
        dispatch(
          getAchievements({
            endpoint: `${apiUrl.linkApi}profile/${peopleData.id}/awardscerts`,
            config: {
              headers: {
                "x-api-key": `${xApiKey.profile}`
              }
            }
          })
        );
      } else {
        setIsLoading(false);
        toastError();
      }
    }
  }, [certificateUpdating]);

  useEffect(() => {
    if (!certificateDeleting && !awardDeleting && isLoading) {
      if (isEmptyOrNull(certificateDeletingError) && isEmptyOrNull(awardDeletingError)) {
        dispatch(
          getAchievements({
            endpoint: `${apiUrl.linkApi}profile/${peopleData.id}/awardscerts`,
            config: {
              headers: {
                "x-api-key": `${xApiKey.profile}`
              }
            }
          })
        );
      } else {
        setIsLoading(false);
        setDeleteId(null);
        setDeleteRecordType(null);
        toastError();
      }
    }
  }, [certificateDeleting, awardDeleting]);

  useEffect(() => {
    if (!achievementsLoading && isLoading) {
      setIsLoading(false);
      if (deleteId) {
        toastDeleteSuccess();
        setDeleteId(null);
        setDeleteRecordType(null);
      } else {
        toastSuccess();
      }
    }
  }, [achievementsLoading]);

  // to close edit/view popup
  const onCloseViewEditPopup = () => {
    setIsReadOnly(false);
    setShowAwardPopup(false);
    setData({});
    setShowBcgCredential(false);
    setShowCredentialPopup(false);
  };

  // to close all popups
  const closeAllPopups = () => {
    onClose();
    onCloseViewEditPopup();
  };

  // to open popup in view mode
  const viewReadOnlyRow = (rowType) => {
    switch (rowType) {
      case Award:
        setIsReadOnly(true);
        setShowAwardPopup(true);
        break;
      case InternalCred:
        setIsReadOnly(true);
        setShowBcgCredential(true);
        setShowCredentialPopup(true);
        break;
      case ExternalCredential:
        setIsReadOnly(true);
        setShowBcgCredential(false);
        setShowCredentialPopup(true);
        break;
      default:
        break;
    }
  };

  const deleteConfirmationPopup = (
    <ConfirmationModal
      isOpen={showDeletePopup}
      modalMessage={DeleteRecord}
      handleCancelClick={() => {
        setDeleteId(null);
        setDeleteRecordType(null);
        setShowDeletePopup(false);
      }}
      handleContinueClick={deleteRecord}
    />
  );

  const viewEditAwardPopup = (
    <ViewEditAwardPopup
      isOpen={showAwardPopup}
      close={onCloseViewEditPopup}
      closeAll={closeAllPopups}
      mode={!isReadOnly ? "edit" : null}
      award={data}
    />
  );

  const viewEditCredentialPopup = (
    <ViewEditCredentialsPopup
      isOpen={showCredentialPopup}
      mode={!isReadOnly ? "edit" : null}
      closeAll={closeAllPopups}
      isBcgCredential={isBcgCredential}
      close={onCloseViewEditPopup}
      credential={data}
    />
  );

  const viewEditCredentialsMainPopup = (
    <Modal open={open} className="credentials__modal" data-testid="view credential modal">
      {isLoading ? (
        <Segment className="loading__segment">
          <Dimmer active inverted>
            <Loader size="large">{deleteId ? Deleting : Saving}</Loader>
          </Dimmer>
        </Segment>
      ) : null}
      <Modal.Header>
        <div className="title">{Credentials}</div>
      </Modal.Header>
      <Modal.Content className="modal_cred" style={{ height: "630px", overflowY: "scroll", overflowX: "hidden" }}>
        <Grid className="p-0 m-0">
          <Grid.Row className="pb-0 mb-h">
            <Grid.Column width={12}>
              <div className="caption pb-h">{BCGCred}</div>
              <div className="sub_caption">
                <Icon name="exclamation circle" />
                {!bcgCredentials?.length && "You haven't added any credential yet. "}You can drag and drop upto{" "}
                <strong>two</strong> credentials to showcase on overview page.
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="p-0">
            <Grid.Column width={16}>
              <CredentialsGrid
                credentials={bcgCredentials}
                setCredentials={setBcgCredentials}
                setData={setData}
                showSkills
                credIconName={InternalCred}
                onClickRow={() => viewReadOnlyRow(InternalCred)}
                onEditClick={() => openCredentialPopup(BCG)}
                featuredRecordsCount={2}
                handleOnDelete={handleOnDelete}
                recordType={BcgCredSource}
                handleOnHideUnHide={handleOnHideUnHide}
                isEditable={isEditable}
                saveSortOrder={saveSortOrder}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="pb-0 mt-3 mb-h">
            <Grid.Column width={12}>
              <div className="caption pb-h">{ExternalCred}</div>
              <div className="sub_caption">
                <Icon name="exclamation circle" />
                {!externalCredentials?.length && "You haven't added any credential yet. "}You can drag and drop upto{" "}
                <strong>two</strong> credentials to showcase on overview page.
              </div>
            </Grid.Column>
            {isEditable && (
              <Grid.Column width={4} verticalAlign="middle" textAlign="right">
                <PrimaryButton btnText="Add Credentials" btnIcon="+" onBtnClick={() => openCredentialPopup()} />
              </Grid.Column>
            )}
          </Grid.Row>
          <Grid.Row className="p-0 ">
            <Grid.Column width={16}>
              <CredentialsGrid
                credentials={externalCredentials}
                setCredentials={setExternalCredentials}
                setData={setData}
                showSkills
                onClickRow={() => viewReadOnlyRow(ExternalCredential)}
                credIconName={ExternalCredential}
                onEditClick={() => openCredentialPopup()}
                featuredRecordsCount={2}
                handleOnDelete={handleOnDelete}
                recordType={ExternalCredSource}
                isEditable={isEditable}
                saveSortOrder={saveSortOrder}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="pb-0 mt-3 mb-h">
            <Grid.Column width={12}>
              <div className="caption pb-h">{Awards}</div>
              <div className="sub_caption">
                <Icon name="exclamation circle" />
                {!awards?.length && "You haven't added any credential yet. "}You can drag and drop upto{" "}
                <strong>one</strong> award to showcase on overview page.
              </div>
            </Grid.Column>
            {isEditable && (
              <Grid.Column width={4} verticalAlign="middle" textAlign="right">
                <PrimaryButton btnText="Add Awards" btnIcon="+" onBtnClick={() => handleAddAwards()} />
              </Grid.Column>
            )}
          </Grid.Row>
          <Grid.Row className="p-0 ">
            <Grid.Column width={16}>
              <CredentialsGrid
                credentials={awards}
                setCredentials={setAwards}
                onClickRow={() => viewReadOnlyRow(Award)}
                setData={setData}
                handleOnDelete={handleOnDelete}
                onEditClick={() => setShowAwardPopup(true)}
                featuredRecordsCount={1}
                recordType={Award}
                isEditable={isEditable}
                saveSortOrder={saveSortOrder}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
      <Modal.Actions className="tab__footer">
        <Button data-testid="close" basic className="button-sm mr-1" secondary onClick={() => onClose()}>
          {Close}
        </Button>
      </Modal.Actions>
    </Modal>
  );

  const renderPopup = () => {
    if (showCredentialPopup) return viewEditCredentialPopup;
    if (showAwardPopup) return viewEditAwardPopup;
    if (showDeletePopup) return deleteConfirmationPopup;
    return viewEditCredentialsMainPopup;
  };

  return <>{renderPopup()}</>;
}

ViewEditCredentials.defaultProps = {
  bcgCredentials: [],
  externalCredentials: [],
  awards: []
};

ViewEditCredentials.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
  bcgCredentials: PropTypes.arrayOf([PropTypes.shape]),
  externalCredentials: PropTypes.arrayOf([PropTypes.shape]),
  awards: PropTypes.arrayOf([PropTypes.shape]),
  setBcgCredentials: PropTypes.func.isRequired,
  setExternalCredentials: PropTypes.func.isRequired,
  setAwards: PropTypes.func.isRequired
};

export default ViewEditCredentials;
