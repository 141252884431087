import { skills } from "redux/constants";

const skillsToolsReducer = (state = {}, action) => {
  switch (action.type) {
    case skills.GET_SKILL_TOOLS_DATA_SUCCESS:
      return state?.terms
        ? {
            ...action.data,
            terms: [...action.data?.terms, ...state?.terms],
            total: Number(action.data?.total) + Number(state?.total)
          }
        : action.data;
    case skills.GET_SKILL_TOOLS_DATA_FAILURE:
      return null;
    default:
      return state;
  }
};

export default skillsToolsReducer;
