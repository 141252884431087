import { takeLatest } from "redux-saga/effects";

import { cases } from "redux/constants";
import handleDeleteCaseTopicsSkills from "../worker/handleDeleteCaseTopicsSkills";

function* watchDeleteCaseTopicsAnsSkills() {
  yield takeLatest(cases.DELETE_CASE_TOPICS_SKILLS, handleDeleteCaseTopicsSkills);
}

export default watchDeleteCaseTopicsAnsSkills;
