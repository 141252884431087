import { takeLatest } from "redux-saga/effects";

import { ssRecentCases } from "redux/constants";
import handleFetchSSRecentCases from "../worker/handleFetchSSRecentCases";

function* watchSSRecentCases() {
  yield takeLatest(ssRecentCases.FETCH_RECENT_SSCASE, handleFetchSSRecentCases);
}

export default watchSSRecentCases;
