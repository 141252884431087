import { topics } from "redux/constants";

const businessSubjectsErrorReducer = (state = {}, action) => {
  switch (action.type) {
    case topics.GET_BUSINESS_SUBJECTS_DATA_FAILURE:
      return action.error.response.status;
    case topics.GET_BUSINESS_SUBJECTS_DATA_SUCCESS:
      return null;
    default:
      return state;
  }
};

export default businessSubjectsErrorReducer;
