import {
  user,
  experience,
  cases,
  materials,
  education,
  achievement,
  profile,
  topics,
  skills,
  ssCases,
  userProfileImage,
  ssMaterials,
  awardNames,
  awardTypes,
  topicsFinder,
  searchTopicsSkills,
  topicsSkillFinder,
  profileUpdateHistory,
  userStaffId,
  expertiseList,
  award,
  certificate,
  editProfile,
  childTopicsFinder,
  manageExpertise,
  about,
  people,
  userData
} from "redux/constants";

const errorReducer = (state = {}, action) => {
  switch (action.type) {
    case user.FETCH_FAILURE:
    case cases.FETCH_CASES_FAILURE:
    case cases.UPDATE_CASE_FAILURE:
    case experience.FETCH_EXPERIENCE_FAILURE:
    case materials.FETCH_MATERIALS_FAILURE:
    case education.FETCH_EDUCATION_FAILURE:
    case achievement.FETCH_ACHIEVEMENT_FAILURE:
    case profile.FETCH_PROFILE_FAILURE:
    case topics.FETCH_TOPIC_FAILURE:
    case topics.UPDATE_TOPIC_FAILURE:
    case topics.DELETE_TOPIC_FAILURE:
    case topics.DELETE_TOPICSUMMARY_FAILURE:
    case skills.FETCH_SKILL_FAILURE:
    case skills.UPDATE_SKILL_FAILURE:
    case skills.DELETE_SKILL_FAILURE:
    case skills.DELETE_SKILLSUMMARY_FAILURE:
    case ssCases.FETCH_SSCASE_FAILURE:
    case userProfileImage.FETCH_USER_IMAGE_FAILURE:
    case ssMaterials.FETCH_SSMATERIALS_FAILURE:
    case awardNames.FETCH_AWARD_NAMES_FAILURE:
    case awardTypes.FETCH_AWARD_TYPES_FAILURE:
    case topicsFinder.FETCH_TOPICS_FINDER_FAILURE:
    case searchTopicsSkills.FETCH_TOPICS_SKILLS_FINDER_FAILURE:
    case topicsSkillFinder.FETCH_SKILL_TOPICS_FINDER_FAILURE:
    case profileUpdateHistory.FETCH_PROFILE_UPDATE_HISTORY_FAILURE:
    case userStaffId.FETCH_USER_STAFF_ID_FAILURE:
    case expertiseList.FETCH_EXPERTISE_SUMMARY_FAILURE:
    case award.UPDATE_AWARD_FAILURE:
    case award.DELETE_AWARD_FAILURE:
    case certificate.UPDATE_CERTIFICATE_FAILURE:
    case certificate.DELETE_CERTIFICATE_FAILURE:
    case editProfile.DELETE_FILE_FAILURE:
    case editProfile.UPDATE_PROPOSAL_FAILURE:
    case editProfile.PROPOSAL_FILE_FAILURE:
    case editProfile.UPDATE_RESUME_FAILURE:
    case editProfile.RESUME_FILE_FAILURE:
    case editProfile.UPDATE_PROFILE_FAILURE:
    case childTopicsFinder.FETCH_CHILD_TOPICS_FINDER_FAILURE:
    case manageExpertise.UPDATE_EXPERTISE_FAILURE:
    case about.UPDATE_ABOUT_KT_FAILURE:
    case people.FETCH_PEOPLE_FAILURE:
    case people.FETCH_PEOPLE_IMAGE_FAILURE:
    case userData.FETCH_USER_DATA_FAILURE:
      return action.error;
    case user.FETCH_SUCCESS:
    case user.FETCH_USER:
    case cases.FETCH_CASES_SUCCESS:
    case cases.FETCH_CASES:
    case cases.UPDATE_CASE_SUCCESS:
    case cases.UPDATE_CASE:
    case experience.FETCH_EXPERIENCE:
    case experience.FETCH_EXPERIENCE_SUCCESS:
    case materials.FETCH_MATERIALS:
    case materials.FETCH_MATERIALS_SUCCESS:
    case education.FETCH_EDUCATION:
    case education.FETCH_EDUCATION_SUCCESS:
    case achievement.FETCH_ACHIEVEMENT_SUCCESS:
    case achievement.FETCH_ACHIEVEMENT:
    case profile.FETCH_PROFILE_SUCCESS:
    case profile.FETCH_PROFILE:
    case topics.FETCH_TOPIC_SUCCESS:
    case topics.FETCH_TOPIC:
    case topics.UPDATE_TOPIC_SUCCESS:
    case topics.UPDATE_TOPIC:
    case topics.DELETE_TOPIC_SUCCESS:
    case topics.DELETE_TOPIC:
    case topics.DELETE_TOPICSUMMARY_SUCCESS:
    case topics.DELETE_TOPICSUMMARY:
    case skills.FETCH_SKILL:
    case skills.FETCH_SKILL_SUCCESS:
    case skills.UPDATE_SKILL_SUCCESS:
    case skills.UPDATE_SKILL:
    case skills.DELETE_SKILL_SUCCESS:
    case skills.DELETE_SKILL:
    case skills.DELETE_SKILLSUMMARY_SUCCESS:
    case skills.DELETE_SKILLSUMMARY:
    case ssCases.FETCH_SSCASE_SUCCESS:
    case userProfileImage.FETCH_USER_IMAGE:
    case userProfileImage.FETCH_USER_IMAGE_SUCCESS:
    case ssMaterials.FETCH_SSMATERIALS_SUCCESS:
    case ssMaterials.FETCH_SSMATERIALS:
    case awardNames.FETCH_AWARD_NAMES:
    case awardNames.FETCH_AWARD_NAMES_SUCCESS:
    case awardTypes.FETCH_AWARD_TYPES_SUCCESS:
    case awardTypes.FETCH_AWARD_TYPES:
    case topicsFinder.FETCH_TOPICS_FINDER:
    case topicsFinder.FETCH_TOPICS_FINDER_SUCCESS:
    case searchTopicsSkills.FETCH_TOPICS_SKILLS_FINDER:
    case searchTopicsSkills.FETCH_TOPICS_SKILLS_FINDER_SUCCESS:
    case topicsSkillFinder.FETCH_SKILL_TOPICS_FINDER:
    case topicsSkillFinder.FETCH_SKILL_TOPICS_FINDER_SUCCESS:
    case profileUpdateHistory.FETCH_PROFILE_UPDATE_HISTORY:
    case profileUpdateHistory.FETCH_PROFILE_UPDATE_HISTORY_SUCCESS:
    case userStaffId.FETCH_USER_STAFF_ID:
    case userStaffId.FETCH_USER_STAFF_ID_SUCCESS:
    case expertiseList.FETCH_EXPERTISE_SUMMARY:
    case expertiseList.FETCH_EXPERTISE_SUMMARY_SUCCESS:
    case award.UPDATE_AWARD_SUCCESS:
    case award.UPDATE_AWARD:
    case award.DELETE_AWARD_SUCCESS:
    case award.DELETE_AWARD:
    case certificate.UPDATE_CERTIFICATE_SUCCESS:
    case certificate.UPDATE_CERTIFICATE:
    case certificate.DELETE_CERTIFICATE_SUCCESS:
    case certificate.DELETE_CERTIFICATE:
    case editProfile.DELETE_FILE_SUCCESS:
    case editProfile.DELETE_FILE:
    case editProfile.UPDATE_PROPOSAL_SUCCESS:
    case editProfile.UPDATE_PROPOSAL:
    case editProfile.PROPOSAL_FILE_SUCCESS:
    case editProfile.PROPOSAL_FILE:
    case editProfile.UPDATE_RESUME_SUCCESS:
    case editProfile.UPDATE_RESUME:
    case editProfile.RESUME_FILE_SUCCESS:
    case editProfile.RESUME_FILE:
    case editProfile.UPDATE_PROFILE_SUCCESS:
    case editProfile.UPDATE_PROFILE:
    case childTopicsFinder.FETCH_CHILD_TOPICS_FINDER:
    case childTopicsFinder.FETCH_CHILD_TOPICS_FINDER_SUCCESS:
    case manageExpertise.UPDATE_EXPERTISE_SUCCESS:
    case manageExpertise.UPDATE_EXPERTISE:
    case about.UPDATE_ABOUT_KT_SUCCESS:
    case about.UPDATE_ABOUT_KT:
    case people.FETCH_PEOPLE_SUCCESS:
    case people.FETCH_PEOPLE:
    case people.FETCH_PEOPLE_IMAGE_SUCCESS:
    case people.FETCH_PEOPLE_IMAGE:
    case userData.FETCH_USER_DATA_SUCCESS:
      return null;
    default:
      return state;
  }
};

export default errorReducer;
