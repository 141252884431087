import { call, put as putSaga } from "redux-saga/effects";
import { put } from "redux/api";
import { setUploadBioSuccess, setUploadBioError } from "redux/actions/upload";

function* handleUploadProposalBioToProfile({ payload }) {
  try {
    const { res, error } = yield call(put, payload);
    if (error) {
      yield putSaga(setUploadBioError(error));
    } else {
      const { totalItems, currentItem } = payload;
      if (totalItems === currentItem) yield putSaga(setUploadBioSuccess(res));
    }
  } catch (error) {
    yield putSaga(setUploadBioError(error));
  }
}

export default handleUploadProposalBioToProfile;
