import { peopleData } from "redux/constants";

const getPeopleData = (payload) => ({
  type: peopleData.FETCH_PEOPLE_DATA,
  payload
});

const setPeopleData = (data) => ({
  type: peopleData.FETCH_PEOPLE_DATA_SUCCESS,
  data
});

const setPeopleDataError = (error) => ({
  type: peopleData.FETCH_PEOPLE_DATA_FAILURE,
  error
});

export { getPeopleData, setPeopleData, setPeopleDataError };
