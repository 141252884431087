import React, { useState } from "react";
import PropTypes from "prop-types";
import { Popover } from "components/common";
import LABEL from "constants/label";
import IconC from "components/common/Icon";
import { copyToClipboard } from "helpers/utils";
import "./styles.scss";

const { COMMON } = LABEL;

function CopyText({ text, prefix, cls, popUpText = COMMON.DefaultCopyText, onCopyCallback }) {
  const [copyStatus, setCopyStatus] = useState(popUpText);

  const handleOnCopyClick = (copyText) => {
    copyToClipboard(copyText);
    setCopyStatus(COMMON.CopySuccess);
    onCopyCallback();
  };

  return (
    <div className={`${cls} copy-text`}>
      <strong>
        {prefix} {text}
      </strong>
      <Popover
        trigger={
          <span>
            <IconC name="copy" size={16} onIconClick={() => handleOnCopyClick(text)} />
          </span>
        }
        on="hover"
        inverted
        position="bottom center"
        popper={{ className: "clipboard-tooltip" }}
        size="tiny"
        onOpen={() => setCopyStatus(popUpText)}
      >
        {copyStatus}
      </Popover>
    </div>
  );
}

CopyText.defaultProps = {
  prefix: "",
  cls: "",
  popUpText: COMMON.DefaultCopyText,
  onCopyCallback: () => {}
};

CopyText.propTypes = {
  text: PropTypes.string.isRequired,
  prefix: PropTypes.string,
  cls: PropTypes.string,
  popUpText: PropTypes.string,
  onCopyCallback: PropTypes.func
};

export default CopyText;
