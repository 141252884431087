import { upload } from "redux/constants";

const updateBioReducer = (state = false, action) => {
  switch (action.type) {
    case upload.UPDATE_PROPOSAL_BIO:
      return true;
    case upload.UPDATE_PROPOSAL_BIO_SUCCESS:
    case upload.UPDATE_PROPOSAL_BIO_FAILURE:
      return false;
    default:
      return state;
  }
};

export default updateBioReducer;
