import { manageExpertise } from "redux/constants";

const updateExpertise = (payload) => ({
  type: manageExpertise.UPDATE_EXPERTISE,
  payload
});

const setUpdateExpertiseSuccess = (data) => ({
  type: manageExpertise.UPDATE_EXPERTISE_SUCCESS,
  data
});

const setUpdateExpertiseError = (error) => ({
  type: manageExpertise.UPDATE_EXPERTISE_FAILURE,
  error
});

export { updateExpertise, setUpdateExpertiseSuccess, setUpdateExpertiseError };
