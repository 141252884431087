import { profileCompletion } from "redux/constants";

const profileCompletionVerbiageErrorReducer = (state = {}, action) => {
  switch (action.type) {
    case profileCompletion.FETCH_PROFILE_COMPLETION_VERBIAGE_FAILURE:
      return action.error;
    case profileCompletion.FETCH_PROFILE_COMPLETION_VERBIAGE:
    case profileCompletion.FETCH_PROFILE_COMPLETION_VERBIAGE_SUCCESS:
      return null;
    default:
      return state;
  }
};

export default profileCompletionVerbiageErrorReducer;
