import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";

import { getImageSizeStyle } from "../../../helpers/utils";

/**
 * @param {string} iconSize size of the image (medium, small)
 * @param {string} imgSrc source of the image
 * @param {string} backgroundSize size of the background (16px 16px)
 * @param {string} backgroundColor color of the background
 * @param {string} href href for the anchor
 *
 * @returns IconWithImage - a circular icon with supplied image.
 *
 * @example
 *
 * <IconWithImage
 *  iconSize="small"
 *  imgSrc="/icons/ic_cup.svg"
 *  backgroundSize = "16px 16px"
 *  backgroundColor = "#000000"
 * />
 */

const IconWithImage = ({ iconSize, imgSrc, backgroundSize, backgroundColor, href, callback }) => {
  const handleOnClick = () => {
    if (callback && typeof callback === "function") callback();
  };
  if (href) {
    return (
      <a href={href} target="_blank" rel="noreferrer noopener" data-testid="handle" onClick={handleOnClick}>
        <div
          className={`${styles["icon__circle"]} d-flex justify-content-center ${styles[getImageSizeStyle(iconSize)]}`}
          style={{
            backgroundColor: `${backgroundColor}`,
            backgroundSize: `${backgroundSize}`,
            backgroundImage: `url(${imgSrc})`
          }}
        />
      </a>
    );
  }
  return (
    <div
      className={`${styles["icon__circle"]} d-flex justify-content-center ${styles[getImageSizeStyle(iconSize)]}`}
      style={{
        backgroundColor: `${backgroundColor}`,
        backgroundSize: `${backgroundSize}`,
        backgroundImage: `url(${imgSrc})`
      }}
    />
  );
};

IconWithImage.defaultProps = {
  iconSize: "medium",
  backgroundSize: "16px 16px",
  backgroundColor: "",
  href: null,
  callback: () => {}
};

IconWithImage.propTypes = {
  iconSize: PropTypes.string,
  imgSrc: PropTypes.string.isRequired,
  backgroundSize: PropTypes.string,
  backgroundColor: PropTypes.string,
  href: PropTypes.string,
  callback: PropTypes.func
};

export default IconWithImage;
