import { takeLatest } from "redux-saga/effects";

import { skills } from "redux/constants";
import handleDeleteSkill from "../worker/handleDeleteSkill";

function* watchDeleteSkill() {
  yield takeLatest(skills.DELETE_SKILL, handleDeleteSkill);
}

export default watchDeleteSkill;
