import { takeLatest } from "redux-saga/effects";

import { pronunciation } from "redux/constants";
import handleFetchPronunciation from "../worker/handleFetchPronunciation";

function* watchFetchPronunciation() {
  yield takeLatest(pronunciation.FETCH_PRONUNCIATION, handleFetchPronunciation);
}

export default watchFetchPronunciation;
