/* eslint-disable no-extra-boolean-cast */
import { Card } from "semantic-ui-react";
import PropTypes from "prop-types";
import { Icon } from "components/common";

import { formatDateByMoment } from "helpers/utils";
import LABEL from "constants/label";

import "../styles.scss";

const { NA, Present } = LABEL.COMMON;

function LinkedInEducationCard({ degreeName, schoolName, fieldsOfStudy, startMonth, endMonth, startYear, endYear }) {
  return (
    <Card.Content>
      <div className="education__item edu__item" key={schoolName}>
        <div className="education__icon d-flex justify-content-center align-items-center">
          <Icon name="graduateCap" />
        </div>
        <div className="education__content">
          {schoolName && (
            <div className="education__heading edu__school">
              <strong>{schoolName}</strong>
            </div>
          )}
          {degreeName && <div className="education__text edu__degree">{degreeName}</div>}
          {!!fieldsOfStudy.length && <div className="education__text edu__major">{fieldsOfStudy[0]}</div>}
          <div className="education__text--sm edu__duration">
            {(!!startYear &&
              formatDateByMoment(
                new Date(startYear, !!startMonth ? startMonth - 1 : startMonth),
                startMonth > 0 ? "MMM, YYYY" : "YYYY"
              )) ||
              NA}{" "}
            -{" "}
            {(!!endYear &&
              formatDateByMoment(
                new Date(endYear, !!endMonth ? endMonth - 1 : endMonth),
                endMonth > 0 ? "MMM, YYYY" : "YYYY"
              )) ||
              Present}
          </div>
        </div>
      </div>
    </Card.Content>
  );
}

LinkedInEducationCard.defaultProps = {
  degreeName: "",
  schoolName: "",
  startMonth: "",
  endMonth: "",
  endYear: "",
  startYear: "",
  fieldsOfStudy: []
};

LinkedInEducationCard.propTypes = {
  degreeName: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  schoolName: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  startMonth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  endMonth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  endYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  startYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fieldsOfStudy: PropTypes.arrayOf(PropTypes.string)
};

export default LinkedInEducationCard;
