import LABEL from "constants/label";
import moment from "moment";
import PropTypes from "prop-types";
import { createParamsObject, isEmptyOrNull, sortArrObjectByDate, useQuery } from "helpers/utils";
import { useHistory, useLocation } from "react-router-dom";
import { useMemo, useState } from "react";
import sendEventData from "analytics/analytics";
import { EVENTS, TRIGGERS } from "analytics/constants";
import { useSelector } from "react-redux";
import EditProfile from "components/EditProfile";
import useUpdateQueryParams from "customHooks/useUpdateQueryParams";
import ProfileCompletionRow from "../ProfileCompletionRow/ProfileCompletionRow";
import { getProfileCompletionData } from "../helpers/utils";

const {
  PROFILE_OVERVIEW: { ProfileSummaryData, CvBioData, DateFormat, LastUpdateDate, CvBioFields, ProfileSummaryFields }
} = LABEL;

const ProfileSummaryCvBioCompletion = ({ isComplete, profileCompletionParams, isVisitorView, skipForNowClicked }) => {
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const { updateQueryParams, deleteQueryParam } = useUpdateQueryParams(location, history);
  // edit Popup
  const openEditPopup = () => {
    sendEventData(TRIGGERS.COMMON.EDIT, EVENTS.PROFILE_SUMMARY); // Analytics Event
    updateQueryParams({ ...createParamsObject(location?.search), edit: "profile-summary" });
    setOpen(true);
  };

  const closeEditPopup = () => {
    deleteQueryParam({}, "edit");
    setOpen(false);
  };

  const { profileProposalBios = [], profileResumes, profile } = useSelector((state) => state);
  const profileResume = profileResumes[0];

  const resume = useMemo(() => {
    if (profileResume?.resumeId) {
      return {
        resumeId: profileResume?.resumeId,
        title: profileResume?.title,
        filePath: profileResume?.filePath,
        language: profileResume?.language,
        creationDate: profileResume?.creationDate,
        lastModifiedBy: profileResume?.createdBy,
        isDeleted: profileResume?.isDeleted,
        type: "Resume"
      };
    }

    return {};
  }, [
    profileResume?.resumeId,
    profileResume?.title,
    profileResume?.filePath,
    profileResume?.language,
    profileResume?.creationDate,
    profileResume?.createdBy,
    profileResume?.isDeleted
  ]);

  // logic to calculate profile summary completion and last updated date
  const profileSummaryCompletionData = getProfileCompletionData(ProfileSummaryFields, profileCompletionParams);
  const isProfileSummaryCompleted = profileSummaryCompletionData?.isCompleted || isComplete;
  const profileSummaryLastUpdatedDate =
    !isEmptyOrNull(profileSummaryCompletionData?.fieldData[0]?.lastUpdateDate) &&
    moment(profileSummaryCompletionData?.fieldData[0]?.lastUpdateDate).format(DateFormat);

  // logic to calculate cv bio completion and last updated date
  const cvBioCompletionData = getProfileCompletionData(CvBioFields, profileCompletionParams);
  const isCvBioCompleted = cvBioCompletionData?.isCompleted || isComplete;
  const sortedCvBioLastUpDatedDate = sortArrObjectByDate(cvBioCompletionData?.fieldData, LastUpdateDate)[0]
    ?.lastUpdateDate;
  const cvBioLastUpDatedDate =
    !isEmptyOrNull(sortedCvBioLastUpDatedDate) && moment(sortedCvBioLastUpDatedDate).format(DateFormat);
  return (
    <>
      <ProfileCompletionRow
        isCompleted={isProfileSummaryCompleted}
        lastUpdateDate={profileSummaryLastUpdatedDate}
        listData={ProfileSummaryData}
        rowName="Profile Summary"
        testId="profileSummaryCompletionRow"
        isVisitorView={isVisitorView}
        onUpdate={openEditPopup}
      />
      <ProfileCompletionRow
        isCompleted={isCvBioCompleted}
        skipForNowClicked={skipForNowClicked}
        lastUpdateDate={cvBioLastUpDatedDate}
        listData={CvBioData}
        rowName="CV/Bio"
        isSectionNotCompleted={!isCvBioCompleted || !isProfileSummaryCompleted}
        testId="cvBioCompletionRow"
        onUpdate={openEditPopup}
        isVisitorView={isVisitorView}
      />
      {open && (
        <EditProfile
          open={open}
          onClose={closeEditPopup}
          onOpen={openEditPopup}
          isProfileCompletionMode
          resume={resume}
          user={profile}
          proposalBios={profileProposalBios}
        />
      )}
    </>
  );
};

ProfileSummaryCvBioCompletion.defaultProps = {
  isComplete: false,
  profileCompletionParams: [],
  isVisitorView: false
};

ProfileSummaryCvBioCompletion.propTypes = {
  isComplete: PropTypes.bool,
  profileCompletionParams: PropTypes.arrayOf(PropTypes.shape({})),
  isVisitorView: PropTypes.bool,
  skipForNowClicked: PropTypes.bool.isRequired
};

export default ProfileSummaryCvBioCompletion;
