import { skills } from "redux/constants";

const skillsLoadingReducer = (state = true, action) => {
  switch (action.type) {
    case skills.FETCH_SKILL:
      return true;
    case skills.FETCH_SKILL_FAILURE:
    case skills.FETCH_SKILL_SUCCESS:
      return false;
    default:
      return state;
  }
};

export default skillsLoadingReducer;
