import { call, put } from "redux-saga/effects";
import { get } from "redux/api";
import { setLinkedInData, setLinkedInDataError } from "redux/actions/linkedIn";

function* handleFetchLinkedInData({ payload }) {
  const { data, error } = yield call(get, payload);
  if (error) {
    yield put(setLinkedInDataError(error));
  } else {
    yield put(setLinkedInData(data));
  }
}

export default handleFetchLinkedInData;
