/* eslint-disable no-extra-boolean-cast */
import React, { useReducer, useEffect, useState, useRef } from "react";
import { Input, List, Placeholder, Icon, Popup, Image } from "semantic-ui-react";
import PropTypes from "prop-types";
import Cookies from "js-cookies";
import { setDisabledGuid, copyObject } from "pages/helpers/utils";
import { get } from "redux/api";

import LABEL from "constants/label";
import CONFIG from "constants/config";
import { debounce } from "helpers/utils";
import useIsMounted from "components/TaxonomySelector/useIsMounted";
import { searchReducer } from "./searchReducer";
import styles from "./style.module.scss";

const { cookies } = CONFIG;

const { TOPICS_FINDER } = LABEL;

/**
 * Search Taxonomy
 *
 * This component allows you to search through a given taxonomy.
 *
 */

const SearchTaxonomy = ({
  endpoint,
  tbdb,
  placeholder,
  onItemSelect,
  onError,
  disabledGuids,
  disabledTopicName,
  disabledGuidMsg,
  disabledLevelStartIndex
}) => {
  const smartLogicToken = JSON.parse(Cookies.getItem(cookies.SMART_LOGIC_TOKEN)) || {};
  const [searchTerms, dispatch] = useReducer(searchReducer, []);
  const [searchInput, setSerachInput] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [showSearchRes, setShowSearchRes] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [displayData, setDisplayData] = useState([]);
  const [allTermData, setAllTermData] = useState({});

  const isMounted = useIsMounted();

  /**
   * Convert HierarchyTerm from SES response into a TermNode suitable to be used in the tree.
   *
   * @param sesTerm
   */

  const toTermNode = async (sesTerm) => {
    const { id, name } = sesTerm;
    let paths = [];
    const { term } = allTermData?.terms?.find((item) => item?.term?.id === sesTerm?.id);
    if (term?.paths?.length) paths = term.paths;
    return {
      term: {
        id,
        name
      },
      error: null,
      paths
    };
  };

  const decorateGuidData = () => {
    const oldState = copyObject(searchTerms);
    const newState = setDisabledGuid(oldState, disabledGuids, disabledTopicName);
    if (isMounted) setDisplayData(newState);
  };

  useEffect(async () => {
    const responseJson = await get({
      endpoint: `${endpoint}?TBDB=${tbdb}&service=allterms&template=service.json`,
      config: {
        headers: {
          authorization: `Bearer ${smartLogicToken.access_token}`,
          noAuthHeader: true
        }
      }
    });
    if (isMounted) setAllTermData(responseJson?.data);
  }, []);

  useEffect(() => {
    if (isMounted) {
      if ((disabledGuids?.length > 0 || disabledTopicName?.length > 0) && searchTerms?.length > 0) decorateGuidData();
      else {
        setDisplayData(searchTerms);
      }
      setIsSearchLoading(false);
    }
  }, [searchTerms]);

  useEffect(async () => {
    let action = {
      type: "SET_SEARCH_RESULT",
      result: []
    };
    if (searchResult?.length && allTermData && allTermData?.terms?.length) {
      action = {
        type: "SET_SEARCH_RESULT",
        result: await Promise.all(searchResult.map(async (term) => toTermNode(term)))
      };
    }
    dispatch(action);
  }, [allTermData, searchResult]);

  const searchTaxonomy = async (searchTerm) => {
    setIsSearchLoading(true);
    if (searchTerm) {
      const responseJson = await get({
        endpoint: `${endpoint}?TBDB=${tbdb}&service=prefix&term_prefix=${encodeURIComponent(
          searchTerm
        )}&template=service.json`,
        config: {
          headers: {
            authorization: `Bearer ${smartLogicToken.access_token}`,
            noAuthHeader: true
          }
        }
      });
      if (responseJson?.data?.termHints) {
        return responseJson.data.termHints;
      }
    }
    return [];
  };

  useEffect(async () => {
    if (isMounted) setSearchResult(await searchTaxonomy(searchInput));
  }, [searchInput]);

  const setSearchKeyword = debounce((e) => {
    if (isMounted) {
      setSerachInput(e.target.value);
      setShowSearchRes(true);
      if (e.target.value === "") {
        setShowSearchRes(false);
      }
    }
  });

  // Detect Click outside
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowSearchRes(false);
          setSerachInput("");
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const handleOnItemClick = (item) => {
    if (!item.isDisabled && item?.paths[0]?.path?.length > disabledLevelStartIndex + 1) onItemSelect(item);
  };

  return (
    <div className="row">
      <div className="sixteen wide column position-relative">
        <Input
          fluid
          size="small"
          icon="search"
          iconPosition="left"
          placeholder={placeholder}
          onChange={setSearchKeyword}
          className={styles["finder__searchBox"]}
          onClick={(e) => e.stopPropagation()}
          onKeyUp={(e) => {
            if (e.keyCode === 32) {
              e.target.value = `${e.target.value} `;
              e.stopPropagation();
            }
          }}
        />

        {showSearchRes && (
          <div className={styles["finder__searchResiltBox"]} ref={wrapperRef}>
            {isSearchLoading && (
              <Placeholder>
                <Placeholder.Paragraph>
                  <Placeholder.Line length="medium" />
                  <Placeholder.Line length="medium" />
                  <Placeholder.Line length="medium" />
                  <Placeholder.Line length="medium" />
                </Placeholder.Paragraph>
              </Placeholder>
            )}
            {displayData?.length && !isSearchLoading && (
              <div className="position-relative">
                <List divided relaxed className="searchResult">
                  {displayData?.map((item, index) => (
                    <List.Item key={item.term.id} onClick={() => handleOnItemClick(item)}>
                      <List.Content
                        className={
                          (item.isDisabled || item?.paths[0]?.path?.length <= disabledLevelStartIndex + 1) &&
                          styles["finder__disabled"]
                        }
                      >
                        <List.Header as="h5">
                          <span> {item.term.name} </span>
                          {item.isDisabled && disabledGuidMsg && (
                            <Popup
                              position="right center"
                              inverted
                              content={disabledGuidMsg}
                              disabled={!item.isDisabled}
                              trigger={<Image style={{ marginLeft: "0.2rem" }} inline src="/icons/alert icon.svg" />}
                              className="clipboard-tooltip"
                            />
                          )}
                        </List.Header>
                        <List.Description as="p">
                          <span>
                            {item?.paths && `${item.paths[0]?.path[0]?.field?.name} `}
                            <i aria-hidden="true" className="right triangle icon" />
                            {`${item.paths[0]?.path[1]?.field?.name}`}
                          </span>
                        </List.Description>
                      </List.Content>
                    </List.Item>
                  ))}
                </List>
              </div>
            )}
            {!isSearchLoading && !displayData?.length && (
              <p className={styles["finder__noResult"]}>
                <Icon name="info circle" /> {TOPICS_FINDER.NotFound}
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

SearchTaxonomy.defaultProps = {
  placeholder: "Search Taxonomy",
  onError: () => {},
  disabledGuids: [],
  disabledTopicName: [],
  disabledGuidMsg: "",
  disabledLevelStartIndex: 0
};

SearchTaxonomy.propTypes = {
  endpoint: PropTypes.string.isRequired,
  tbdb: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onItemSelect: PropTypes.func.isRequired,
  onError: PropTypes.func,
  disabledGuidMsg: PropTypes.string,
  disabledGuids: PropTypes.arrayOf(PropTypes.shape({})),
  disabledTopicName: PropTypes.arrayOf(PropTypes.shape({})),
  disabledLevelStartIndex: PropTypes.number
};

export default SearchTaxonomy;
