import { takeLatest } from "redux-saga/effects";

import { topics } from "redux/constants";
import handleFetchTopics from "../worker/handleFetchTopics";

function* watchTopics() {
  yield takeLatest(topics.FETCH_TOPIC, handleFetchTopics);
}

export default watchTopics;
