import { takeLatest } from "redux-saga/effects";

import { ssRecentInternalMaterials } from "redux/constants";
import handleFetchSSRecentInternalMaterials from "../worker/handleFetchSSRecentInternalMaterials";

function* watchSSRecentInternalMaterials() {
  yield takeLatest(ssRecentInternalMaterials.FETCH_RECENT_SS_INTERNAL_MATERIALS, handleFetchSSRecentInternalMaterials);
}

export default watchSSRecentInternalMaterials;
