import { takeLatest } from "redux-saga/effects";

import { upload } from "redux/constants";
import handleUpdateProposalBio from "../worker/handleUpdateProposalBio";

function* watchUploadResumeToProfile() {
  yield takeLatest(upload.UPDATE_PROPOSAL_BIO, handleUpdateProposalBio);
}

export default watchUploadResumeToProfile;
