import { profileCompletion } from "redux/constants";

const updateProfileCompletionErrorReducer = (state = {}, action) => {
  switch (action.type) {
    case profileCompletion.UPDATE_PROFILE_COMPLETE_FAILURE:
      return action.error;
    case profileCompletion.UPDATE_PROFILE_COMPLETE:
    case profileCompletion.UPDATE_PROFILE_COMPLETE_SUCCESS:
      return null;
    default:
      return state;
  }
};

export default updateProfileCompletionErrorReducer;
