import { call, put } from "redux-saga/effects";
import { get } from "redux/api";
import { setChildTopicsFinder, setError } from "redux/actions/finder/childTopicsFinder";

function* handleFetchChildTopicsFinder(endpoint) {
  const { data, error } = yield call(get, endpoint);
  if (error) {
    yield put(setError(error));
  } else {
    yield put(setChildTopicsFinder(data));
  }
}

export default handleFetchChildTopicsFinder;
