/* eslint-disable no-extra-boolean-cast */
import React, { useReducer, useEffect, useCallback, useState } from "react";
import Cookies from "js-cookies";
import PropTypes from "prop-types";

import { Card } from "semantic-ui-react";
import { get } from "redux/api";
import { setDisabledGuid } from "pages/helpers/utils";
import CONFIG from "constants/config";
import TaxonomyDropDown from "components/common/TaxonomyDropDown";
import SearchTaxonomy from "components/TaxonomySelector/common/SearchTaxonomy";
import ListSegment from "components/TaxonomySelector/common/ListSegment";
import useIsMounted from "./useIsMounted";
import { taxonomySelectorReducer, rootNode, findNodeById } from "./taxonomySelectorReducer";
import styles from "./style.module.scss";

const { cookies } = CONFIG;

/**
 * TaxonomySelector
 *
 * Taxonomy are showed in a horizontally expanding columns
 * @param  {Element} trigger - Element which is used as a trigger to show the taxonomy selector
 * @param  {string} placeholder - Placeholder value for search box
 * @param  {string} endpoint - taxonomy endpoint
 * @param  {string} tbdb - tbdb value for taxonomy. It refers to the name of the taxonomy which we want to fetch
 * @param  {string} headingList - List of headings to be shown for taxoomy.
 * @param  {string} headingPos
 * @param  {func} cbOnSelection - callback function which is called on selection of a taxonomy term.
 * @param  {func} onError - callback which is called when some error has occured.
 * @param  {arrayOf(string)} disabledGuids - List of taxonomy guids which are to be shown as disabled.
 * @param  {string} disabledGuidMsg - Message to be shown on disable guids hover action.
 * @param  {string} parentTermId - Id of the parent node.
 * @param  {number} disabledLevelStartIndex - It defines the index of the level in the taxonomy upto which the taxonomy terms are disabled.
 * @param  {arrayOf(number)} offset - Opening position of the taxonomy selector popup.
 *
 */

/**
 * Convert HierarchyTerm from SES response into a TermNode suitable to be used in the tree.
 *
 * @param sesTerm
 */

const toTermNode = (sesTerm) => {
  const { id, name, paths } = sesTerm;
  const isLeaf =
    sesTerm.hierarchy == null ||
    !sesTerm.hierarchy.some((hierarchy) => hierarchy.name === "Narrower Term" && hierarchy.fields.length > 0);
  return {
    term: {
      id,
      name
    },
    isLeaf,
    isOpen: false,
    children: [],
    error: null,
    paths
  };
};

const TaxonomySelector = ({
  trigger,
  placeholder,
  endpoint,
  tbdb,
  headingList,
  headingPos,
  cbOnSelection,
  onError,
  disabledGuids,
  disabledGuidMsg,
  disabledTopicName,
  parentTermId,
  disabledLevelStartIndex = 0,
  hideSearch,
  offset = [-15, -30]
}) => {
  const [openPopup, setOpenPopup] = useState(false);

  const togglePopup = () => {
    setOpenPopup((prev) => !prev);
  };

  const smartLogicToken = Cookies.getItem(cookies.SMART_LOGIC_TOKEN)
    ? JSON.parse(Cookies.getItem(cookies.SMART_LOGIC_TOKEN))
    : {};
  const isMounted = useIsMounted();
  const [root, dispatch] = useReducer(taxonomySelectorReducer, rootNode);
  const [taxonomyList, setTaxonomyList] = useState([]);
  const [expandedNode, setExpandedNode] = useState(null);
  const [selectedNode, setSelectedNode] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [loadingData, setLoadingData] = useState({ colIndex: 0, itemIndex: 0 });
  const [childrenLoading, setChildrenLoading] = useState(false);

  const handleOnTermSelect = (node) => {
    cbOnSelection({
      term: { ...node?.term, parentId: node?.paths[0]?.path[1]?.field.id }
    });
    setSelectedNode(node);
    setOpenPopup(false);
  };

  const decorateGuidData = () => {
    setTaxonomyList((prevState) => {
      const newState = [...prevState];
      newState?.forEach((record, index) => {
        newState[index] = setDisabledGuid(record, disabledGuids, disabledTopicName);
      });
      return newState;
    });
  };

  // here the isSelected property of the taxonmy tree is updated
  useEffect(() => {
    if (!!selectedNode) {
      const node = findNodeById(root, selectedNode?.term?.id);

      // path array - list of nodes visited.
      let pathArray = [];

      if (node?.paths?.length) {
        pathArray = node.paths[0].path.map((path) => path?.field?.id);
      } else {
        pathArray = [node?.term?.id];
      }

      setTaxonomyList((prevState) => {
        const newState = [...prevState];

        for (let index = 0; index < prevState.length; index += 1) {
          let secondDepthArray = [];
          prevState[index].forEach((element) => {
            const item = { ...element };
            item.isOpen = false;
            if (pathArray?.indexOf(item.term.id) >= 0) item.isSelected = true;
            else item.isSelected = false;
            secondDepthArray = [...secondDepthArray, item];
          });
          newState[index] = [...secondDepthArray];
        }
        return newState.slice(0, selectedIndex + 2);
      });
    }
  }, [selectedNode, root]);

  // here the isOpen property of the taxonmy tree is updated. All nodes in the tree path which lead upto the last node are marked as opened.
  useEffect(() => {
    if (!!expandedNode) {
      const node = findNodeById(root, expandedNode?.term?.id);

      // path array - list of nodes visited.
      let pathArray = [];

      if (node?.paths?.length) {
        pathArray = node.paths[0].path.map((path) => path?.field?.id);
      } else {
        pathArray = [node?.term?.id];
      }

      setTaxonomyList((prevState) => {
        const newState = [...prevState];

        for (let index = 0; index < prevState.length; index += 1) {
          let secondDepthArray = [];
          prevState[index].forEach((element) => {
            const item = { ...element };
            item.isSelected = false;
            if (pathArray?.indexOf(item.term.id) >= 0) item.isOpen = true;
            else item.isOpen = false;
            secondDepthArray = [...secondDepthArray, item];
          });
          newState[index] = [...secondDepthArray];
        }
        if (node?.children?.length > 0) newState[selectedIndex + 1] = node.children;
        const el = document.querySelector(".finderBox");
        setTimeout(() => {
          el?.scrollTo({ left: el.scrollLeft + 250, behavior: "smooth" }); // code to autoscroll to right when a non-leaf node is opened in the taxonomy selector.
        }, 10);
        return newState.slice(0, selectedIndex + 2);
      });
    } else {
      setTaxonomyList([root.children]);
    }
    if ((disabledGuids?.length > 0 || disabledTopicName?.length > 0) && taxonomyList?.length > 0) {
      decorateGuidData();
    }
  }, [expandedNode, root]);

  // load children on Open
  const loadChildTerms = useCallback(
    async (nodePath) => {
      const termId = nodePath || null;
      let action;
      setChildrenLoading(true);
      try {
        let responseJson;
        if (termId) {
          responseJson = await get({
            endpoint: `${endpoint}?TBDB=${tbdb}&service=browse&template=service.json&id=${termId}`,
            config: {
              headers: {
                authorization: `Bearer ${smartLogicToken.access_token}`,
                noAuthHeader: true
              }
            }
          });
        } else {
          responseJson = await get({
            endpoint: `${endpoint}?TBDB=${tbdb}&service=browse&template=service.json`,
            config: {
              headers: {
                authorization: `Bearer ${smartLogicToken.access_token}`,
                noAuthHeader: true
              }
            }
          });
        }
        action = {
          type: "SET_CHILDREN",
          nodePath,
          children: responseJson.data.terms.map((termWrapper) => toTermNode(termWrapper.term))
        };
      } catch (error) {
        const typedError = error;

        if (onError) {
          onError(typedError);
        }

        action = {
          type: "SET_ERROR",
          nodePath,
          error
        };
      }

      if (isMounted.current) {
        dispatch(action);
      }
      setChildrenLoading(false);
    },
    [endpoint, dispatch, isMounted, onError]
  );

  useEffect(() => {
    loadChildTerms(root?.term?.id === "root" ? parentTermId : "");
  }, [loadChildTerms, parentTermId]);

  const handleOpen = useCallback(
    (nodePath) => {
      const node = findNodeById(root, nodePath);
      if (node != null && node?.children?.length === 0) {
        loadChildTerms(nodePath);
      }
    },
    [root, dispatch, loadChildTerms]
  );

  const handleClose = useCallback(
    (nodePath, colIndex) => {
      const node = findNodeById(root, nodePath);

      // path array - list of nodes visited.
      let pathArray = [];

      if (node?.paths) {
        pathArray = node?.paths[0]?.path?.map((path) => path?.field?.id);
      } else {
        pathArray = [node?.term?.id];
      }
      if (pathArray?.length) pathArray.splice(-1);

      setTaxonomyList((prevState) => {
        const newState = [...prevState];
        for (let index = 0; index < prevState.length; index += 1) {
          let secondDepthArray = [];
          prevState[index].forEach((element) => {
            const item = { ...element };
            if (pathArray?.indexOf(item.term.id) >= 0) item.isOpen = true;
            else item.isOpen = false;
            secondDepthArray = [...secondDepthArray, item];
          });
          newState[index] = [...secondDepthArray];
        }
        return newState.slice(0, colIndex + 1);
      });
    },
    [root, dispatch, onError]
  );

  useEffect(() => {
    if ((disabledGuids?.length > 0 || disabledTopicName?.length > 0) && openPopup && taxonomyList?.length > 0)
      decorateGuidData();
  }, [openPopup]);

  if (!taxonomyList[0]?.length > 0 && childrenLoading)
    return (
      <TaxonomyDropDown
        open={openPopup}
        onClose={() => setOpenPopup(false)}
        trigger={React.cloneElement(trigger, { onClick: togglePopup })}
        offset={offset}
      >
        <div className="ui grid mx-0 mt-1 mb-0 taxonomy__selector">
          {!hideSearch && (
            <SearchTaxonomy
              placeholder={placeholder}
              endpoint={endpoint}
              tbdb={tbdb}
              onItemSelect={handleOnTermSelect}
              disabledGuids={disabledGuids}
              disabledGuidMsg={disabledGuidMsg}
              disabledTopicName={disabledTopicName}
              disabledLevelStartIndex={disabledLevelStartIndex}
            />
          )}

          <div className="row">
            <div className="sixteen wide column position-relative">
              <div className={`${styles["finder__column__wrapper"]} finderBox`}>
                {!taxonomyList[0]?.length > 0 && childrenLoading && (
                  <Card className="taxonomy__card">
                    <Card.Content textAlign="center" className="content--no-info">
                      <h4>Loading...</h4>
                    </Card.Content>
                  </Card>
                )}
              </div>
            </div>
          </div>
        </div>
      </TaxonomyDropDown>
    );

  return (
    <TaxonomyDropDown
      open={openPopup}
      onClose={() => setOpenPopup(false)}
      trigger={React.cloneElement(trigger, { onClick: togglePopup })}
      offset={offset}
    >
      <div className="ui grid mx-0 mt-1 mb-0 taxonomy__selector">
        {!hideSearch && (
          <SearchTaxonomy
            placeholder={placeholder}
            endpoint={endpoint}
            tbdb={tbdb}
            onItemSelect={handleOnTermSelect}
            disabledGuids={disabledGuids}
            disabledGuidMsg={disabledGuidMsg}
            disabledTopicName={disabledTopicName}
            disabledLevelStartIndex={disabledLevelStartIndex}
          />
        )}

        <div className="row">
          <div className="sixteen wide column position-relative">
            <div className={`${styles["finder__column__wrapper"]} finderBox`}>
              {taxonomyList[0]?.length > 0 ? (
                taxonomyList?.map((taxonomy, index) => (
                  <div className={styles["finder__column"]} key={`finder__column__${taxonomy[0]?.term?.id}`}>
                    <ListSegment
                      list={taxonomy}
                      updateCol={index}
                      headingList={headingList}
                      headingPos={headingPos}
                      onNodeOpen={handleOpen}
                      onNodeClose={handleClose}
                      setExpandedNode={setExpandedNode}
                      setSelectedIndex={setSelectedIndex}
                      onNodeSelect={handleOnTermSelect}
                      setLoadingData={setLoadingData}
                      loadingData={loadingData}
                      loading={childrenLoading}
                      disabledGuidMsg={disabledGuidMsg}
                      disabledTopicName={disabledTopicName}
                      disabledLevelStartIndex={disabledLevelStartIndex}
                    />
                  </div>
                ))
              ) : (
                <Card className="taxonomy__card">
                  <Card.Content textAlign="center" className="content--no-info">
                    <h4>No Items Found</h4>
                  </Card.Content>
                </Card>
              )}
            </div>
          </div>
        </div>
      </div>
    </TaxonomyDropDown>
  );
};

TaxonomySelector.defaultProps = {
  placeholder: "Search Taxonomy",
  onError: () => {},
  disabledGuids: [],
  disabledGuidMsg: "",
  disabledTopicName: [],
  parentTermId: "",
  disabledLevelStartIndex: 0,
  hideSearch: false,
  offset: [-5, -30]
};

TaxonomySelector.propTypes = {
  trigger: PropTypes.element.isRequired,
  endpoint: PropTypes.string.isRequired,
  tbdb: PropTypes.string.isRequired,
  disabledTopicName: PropTypes.arrayOf(PropTypes.shape({})),
  placeholder: PropTypes.string,
  headingList: PropTypes.shape({}).isRequired,
  headingPos: PropTypes.arrayOf(Number).isRequired,
  cbOnSelection: PropTypes.func.isRequired,
  onError: PropTypes.func,
  disabledGuids: PropTypes.arrayOf(Object),
  disabledGuidMsg: PropTypes.string,
  parentTermId: PropTypes.string,
  disabledLevelStartIndex: PropTypes.number,
  hideSearch: PropTypes.bool,
  offset: PropTypes.arrayOf(PropTypes.number)
};

export default TaxonomySelector;
