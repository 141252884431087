import { profileViews } from "redux/constants";

const cvBioMaterialsViewsErrorReducer = (state = {}, action) => {
  switch (action.type) {
    case profileViews.FETCH_CV_BIO_MATERIALS_VIEWS_DETAILS_FAILURE:
      return action.error;
    case profileViews.FETCH_CV_BIO_MATERIALS_VIEWS_DETAILS:
    case profileViews.FETCH_CV_BIO_MATERIALS_VIEWS_DETAILS_SUCCESS:
      return null;
    default:
      return state;
  }
};

export default cvBioMaterialsViewsErrorReducer;
