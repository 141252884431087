import { profileViews } from "redux/constants";

const profileViewsLoadingReducer = (state = false, action) => {
  switch (action.type) {
    case profileViews.SAVE_PROFILE_VIEWS:
      return true;
    case profileViews.SAVE_PROFILE_VIEWS_SUCCESS:
    case profileViews.SAVE_PROFILE_VIEWS_FAILURE:
      return false;
    default:
      return state;
  }
};

export default profileViewsLoadingReducer;
