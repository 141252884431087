import { Container, Icon } from "semantic-ui-react";
import LABEL from "constants/label";
import "./styles.scss";

const { BANNER } = LABEL;

/**
 * Anonymized
 *
 * @return Alumni Component
 *
 */

const Anonymized = () => {
  return (
    <div className="anonymized position-relative">
      <Container className="anonymized__container position-static">
        <Icon name="info circle mr-h" />
        <strong>{BANNER.Update} </strong> <span className="pl-h pr-h">|</span> {BANNER.Anonymized}
      </Container>
    </div>
  );
};

export default Anonymized;
