import { award } from "redux/constants";

const awardUpdatingErrorReducer = (state = null, action) => {
  switch (action.type) {
    case award.UPDATE_AWARD_FAILURE:
      return action.error;
    case award.UPDATE_AWARD_SUCCESS:
    case award.UPDATE_AWARD:
      return null;
    default:
      return state;
  }
};

export default awardUpdatingErrorReducer;
