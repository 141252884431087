import { cases } from "redux/constants";

const updateCaseSkillsErrorReducer = (state = null, action) => {
  switch (action.type) {
    case cases.UPDATE_CASE_SKILLS_ERROR:
      return action.error;
    case cases.UPDATE_CASE_SKILLS_SUCCESS:
      return null;
    default:
      return state;
  }
};

export default updateCaseSkillsErrorReducer;
