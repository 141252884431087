import { takeLatest } from "redux-saga/effects";

import { cco } from "redux/constants";
import handleFetchCCO from "../worker/handleFetchCCO";

function* watchFetchCCO() {
  yield takeLatest(cco.FETCH_CCO, handleFetchCCO);
}

export default watchFetchCCO;
