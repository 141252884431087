import { manageExpertise } from "redux/constants";

const expertiseUpdatingErrorReducer = (state = null, action) => {
  switch (action.type) {
    case manageExpertise.UPDATE_EXPERTISE_FAILURE:
      return action.error;
    case manageExpertise.UPDATE_EXPERTISE_SUCCESS:
    case manageExpertise.UPDATE_EXPERTISE:
      return null;
    default:
      return state;
  }
};

export default expertiseUpdatingErrorReducer;
