import { call, put } from "redux-saga/effects";
import { get } from "redux/api";
import { setOrgMemberImageList, setErrorImage } from "redux/actions/organization";

function* handleFetchOrgMemberImage({ payload }) {
  const { data, error } = yield call(get, payload);
  if (error) {
    yield put(setErrorImage(error));
  } else {
    yield put(setOrgMemberImageList({ data, payload }));
  }
}

export default handleFetchOrgMemberImage;
