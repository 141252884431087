import { takeLatest } from "redux-saga/effects";

import { ssCases } from "redux/constants";
import handleFetchSSCases from "../worker/handleFetchSSCases";

function* watchSSCases() {
  yield takeLatest(ssCases.FETCH_SSCASE, handleFetchSSCases);
}

export default watchSSCases;
