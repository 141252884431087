import { takeEvery } from "redux-saga/effects";

import { memberList } from "redux/constants";
import handleFetchMemberList from "../worker/handleFetchMemberList";

function* watchMemberList() {
  yield takeEvery(memberList.FETCH_MEMBER_LIST, handleFetchMemberList);
}

export default watchMemberList;
