import { externalCourseId } from "redux/constants";

const checkIfCourseIdExists = (payload) => {
  return {
    type: externalCourseId.CHECK_COURSE_ID,
    payload
  };
};

const checkIfCourseIdExistsSuccess = (data) => ({
  type: externalCourseId.CHECK_COURSE_ID_SUCCESS,
  data
});

const checkIfCourseIdExistsError = (error) => ({
  type: externalCourseId.CHECK_COURSE_ID_FAILURE,
  error
});

export { checkIfCourseIdExists, checkIfCourseIdExistsSuccess, checkIfCourseIdExistsError };
