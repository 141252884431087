import { childTopicsFinder } from "redux/constants";

const getChildTopicsFinder = (endpoint) => {
  return {
    type: childTopicsFinder.FETCH_CHILD_TOPICS_FINDER,
    endpoint
  };
};

const setChildTopicsFinder = (data) => ({
  type: childTopicsFinder.FETCH_CHILD_TOPICS_FINDER_SUCCESS,
  data
});

const setError = (error) => ({
  type: childTopicsFinder.FETCH_CHILD_TOPICS_FINDER_FAILURE,
  error
});

export { getChildTopicsFinder, setChildTopicsFinder, setError };
