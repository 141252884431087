import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { Grid } from "semantic-ui-react";
import LABEL from "constants/label";
import CredentialsGridRow from "components/Achievements/CredentialsGrid/CredentialsGridRow";
import FeaturedCredentials from "components/common/FeaturedCredentials";
import "./styles.scss";
import NoContent from "components/common/NoContent";
import { isEmptyOrNull } from "helpers/utils";
import { sortBy, findRemainingArrayElements, getAwardCredentialId, arrangeNonFeaturedRecords } from "../helper/utils";

const { GridHeader, NoCredentialsMsg, SortOrder, Award } = LABEL.ACHIEVEMENTS;

function CredentialsGrid({
  showSkills,
  credentials,
  credIconName,
  onClickRow,
  setData,
  onEditClick,
  setCredentials,
  featuredRecordsCount,
  handleOnDelete,
  recordType,
  handleOnHideUnHide,
  isEditable,
  saveSortOrder
}) {
  const [featuredCreds, setFeaturedCreds] = useState([]);
  const [nonFeaturedCreds, setNonFeaturedCreds] = useState([]);
  const [isHiddenOrExpiredCred, setIsHiddenOrExpiredCred] = useState(false);
  const [draggedIntoFeaturedSection, setDraggedIntoFeaturedSection] = useState(false);
  const dragItem = useRef();

  // to show featured/non featured credential
  useEffect(() => {
    let featuredCredentialData = credentials
      ?.filter((cred) => cred?.featured && !cred?.hidden && !cred.expired)
      .slice(0, featuredRecordsCount);
    let nonFeaturedCredentialData = credentials?.filter((cred) => !cred?.featured);
    if (featuredCredentialData?.length < featuredRecordsCount) {
      if (isEmptyOrNull(credentials.filter((cred) => cred.featured))) {
        featuredCredentialData = credentials
          ?.filter((cred) => !cred?.hidden && !cred.expired)
          .slice(0, featuredRecordsCount);
      } else {
        featuredCredentialData = [
          ...credentials.filter((cred) => cred?.featured && !cred?.hidden && !cred.expired),
          ...credentials?.filter((cred) => !cred?.hidden && !cred.featured && !cred.expired)
        ].slice(0, featuredRecordsCount);
      }
      nonFeaturedCredentialData = !isEmptyOrNull(featuredCredentialData)
        ? findRemainingArrayElements(
            credentials,
            featuredCredentialData,
            recordType === Award ? "awardId" : "certificationId"
          )
        : credentials;
    }
    setFeaturedCreds(sortBy(featuredCredentialData, SortOrder, false));
    setNonFeaturedCreds(arrangeNonFeaturedRecords(nonFeaturedCredentialData));
  }, [credentials]);

  // logic to drag credential record in  featured section
  const dragStart = (e, position, cred) => {
    setIsHiddenOrExpiredCred(cred?.hidden || cred?.expired);
    dragItem.current = position;
    e.dataTransfer.setData("id", recordType !== Award ? cred?.certificationId : cred?.awardId);
    setDraggedIntoFeaturedSection(true);
  };

  // logic to drop credential record in non featured section
  const onDrop = (ev) => {
    const id = Number(ev.dataTransfer.getData("id"));
    if (!isEmptyOrNull(id) && id !== 0) {
      const dataToBeAddedInFeaturedSection = [];
      let dataToBeAddedInNonFeaturedSection = [];
      if (
        isEmptyOrNull(nonFeaturedCreds?.filter((cred) => getAwardCredentialId(cred, recordType) === id)) &&
        nonFeaturedCreds.filter((el) => el.hidden || el.expired).length !== nonFeaturedCreds?.length
      ) {
        setNonFeaturedCreds((prevState) => {
          const newData = [...prevState];
          const removedFeaturedSectionIndex = featuredCreds.findIndex(
            (cred) => getAwardCredentialId(cred, recordType) === id
          );
          // when first featured cred is dragged in non featured Section
          if (removedFeaturedSectionIndex === 0 && featuredRecordsCount > 1) {
            dataToBeAddedInFeaturedSection.push({ ...featuredCreds[1], featured: true, sortOrder: 0 });
          }
          // when second featured cred is dragged in non featured Section
          if (removedFeaturedSectionIndex === 1) {
            dataToBeAddedInFeaturedSection.push({ ...featuredCreds[0], featured: true, sortOrder: 0 });
          }
          const nonHiddenExpiredData = newData.filter((cred) => !cred?.hidden && !cred.expired);
          // logic to push latest non featured, non hidden & non expired cred into featured section
          if (!isEmptyOrNull(nonHiddenExpiredData)) {
            dataToBeAddedInFeaturedSection.push({
              ...nonHiddenExpiredData[0],
              featured: true,
              sortOrder: featuredRecordsCount > 1 ? 1 : 0
            });
            const indexToBeRemoved = newData.findIndex(
              (cred) =>
                getAwardCredentialId(cred, recordType) === getAwardCredentialId(nonHiddenExpiredData[0], recordType)
            );
            newData.splice(indexToBeRemoved, 1);
          }
          const nonFeaturedData = featuredCreds?.filter((cred) => getAwardCredentialId(cred, recordType) === id)[0];
          newData.push({
            ...nonFeaturedData,
            sortOrder: null,
            featured: false
          });
          dataToBeAddedInNonFeaturedSection = [...newData];
          return arrangeNonFeaturedRecords(newData);
        });
        setFeaturedCreds(dataToBeAddedInFeaturedSection);
        saveSortOrder(
          [
            ...dataToBeAddedInFeaturedSection,
            ...dataToBeAddedInNonFeaturedSection.filter((cred) => getAwardCredentialId(cred, recordType) === id)
          ],
          recordType
        );
      }
    }
  };

  let itemToRender = (
    <Grid className="p-0 m-0 credentials__grid">
      <Grid.Row className="mt-h credentials__grid--header">
        <Grid.Column width={(showSkills && 9) || 15}>{GridHeader.CredentialDetails}</Grid.Column>
        {showSkills && (
          <Grid.Column width={6} data-testid="skills-column">
            {GridHeader.Skills}
          </Grid.Column>
        )}
        <Grid.Column width={1} className="pl-0">
          {GridHeader.Actions}
        </Grid.Column>
      </Grid.Row>
      {!!featuredCreds?.length && (
        <Grid.Row className="mb-h" data-testid="featured_credentials_row">
          <Grid.Column width={16}>
            <FeaturedCredentials
              featuredCreds={featuredCreds}
              setCredentials={setCredentials}
              showSkills={showSkills}
              credIconName={credIconName}
              onClickRow={onClickRow}
              setData={setData}
              onEditClick={onEditClick}
              setFeaturedCreds={setFeaturedCreds}
              featuredRecordsCount={featuredRecordsCount}
              handleOnDelete={handleOnDelete}
              recordType={recordType}
              handleOnHideUnHide={handleOnHideUnHide}
              isEditable={isEditable}
              saveSortOrder={saveSortOrder}
              setNonFeaturedCreds={setNonFeaturedCreds}
              allCredentials={credentials}
              setIsHiddenOrExpiredCred={setIsHiddenOrExpiredCred}
              isHiddenOrExpiredCred={isHiddenOrExpiredCred}
              draggedIntoFeaturedSection={draggedIntoFeaturedSection}
              setDraggedIntoFeaturedSection={setDraggedIntoFeaturedSection}
            />
          </Grid.Column>
        </Grid.Row>
      )}
      <Grid
        className="mt-h credentials__grid__customWidth"
        onDragOver={(e) => e.preventDefault()}
        onDrop={(e) => {
          onDrop(e);
        }}
      >
        {!!nonFeaturedCreds.length &&
          nonFeaturedCreds?.map((cred, index) => (
            <CredentialsGridRow
              credential={cred}
              showSkills={!!cred?.skills?.length}
              credIconName={credIconName}
              onClickRow={onClickRow}
              setData={setData}
              onEditClick={onEditClick}
              onDragStart={(e) => dragStart(e, index, cred)}
              handleOnDelete={handleOnDelete}
              recordType={recordType}
              handleOnHideUnHide={handleOnHideUnHide}
              isEditable={isEditable}
            />
          ))}
      </Grid>
    </Grid>
  );

  if (!credentials?.length) {
    itemToRender = (
      <div className="no__credentials" data-testid="credentials_grid-no-items">
        <NoContent message={NoCredentialsMsg} />
      </div>
    );
  }
  return <>{itemToRender}</>;
}

CredentialsGrid.defaultProps = {
  showSkills: false,
  credentials: [],
  credIconName: "",
  onClickRow: () => {},
  setData: () => {},
  onEditClick: () => {},
  setCredentials: () => {},
  featuredRecordsCount: 2,
  handleOnDelete: () => {},
  recordType: "",
  handleOnHideUnHide: () => {},
  isEditable: false,
  saveSortOrder: () => {}
};

CredentialsGrid.propTypes = {
  showSkills: PropTypes.bool,
  credentials: PropTypes.arrayOf(PropTypes.shape({})),
  credIconName: PropTypes.string,
  onClickRow: PropTypes.func,
  setData: PropTypes.func,
  onEditClick: PropTypes.func,
  setCredentials: PropTypes.func,
  featuredRecordsCount: PropTypes.number,
  handleOnDelete: PropTypes.func,
  recordType: PropTypes.string,
  handleOnHideUnHide: PropTypes.func,
  isEditable: PropTypes.bool,
  saveSortOrder: PropTypes.func
};

export default CredentialsGrid;
