import { materials } from "redux/constants";

const materialsReducer = (state = [], action) => {
  switch (action.type) {
    case materials.FETCH_MATERIALS_SUCCESS: {
      return [...action.data];
    }
    default:
      return state;
  }
};

export default materialsReducer;
