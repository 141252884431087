import { memberList } from "redux/constants";

const getMemberList = (payload) => ({
  type: memberList.FETCH_MEMBER_LIST,
  payload
});

const setMemberList = (data) => ({
  type: memberList.FETCH_MEMBER_LIST_SUCCESS,
  data
});

const getMemberListImage = (payload) => ({
  type: memberList.FETCH_MEMBER_LIST_IMAGE,
  payload
});

const setMemberListImage = (data) => ({
  type: memberList.FETCH_MEMBER_LIST_IMAGE_SUCCESS,
  data
});

const setError = (error) => ({
  type: memberList.FETCH_MEMBER_LIST_FAILURE,
  error
});

const setErrorImage = (error) => ({
  type: memberList.FETCH_MEMBER_LIST_IMAGE_FAILURE,
  error
});
const getV2MemberList = (payload) => ({
  type: memberList.FETCH_MEMBER_LIST,
  payload
});

const setV2MemberList = (data) => ({
  type: memberList.FETCH_MEMBER_LIST_SUCCESS,
  data
});
const setV2Error = (error) => ({
  type: memberList.FETCH_MEMBER_LIST_FAILURE,
  error
});

export {
  getMemberList,
  setMemberList,
  getMemberListImage,
  setMemberListImage,
  setError,
  setErrorImage,
  getV2MemberList,
  setV2MemberList,
  setV2Error
};
