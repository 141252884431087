import { userProfileImage } from "redux/constants";

const profileImageLoadingReducer = (state = false, action) => {
  switch (action.type) {
    case userProfileImage.FETCH_USER_IMAGE:
      return true;
    case userProfileImage.FETCH_USER_IMAGE_SUCCESS:
    case userProfileImage.FETCH_USER_IMAGE_FAILURE:
      return false;
    default:
      return state;
  }
};

export default profileImageLoadingReducer;
