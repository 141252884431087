import { cases } from "redux/constants";

const caseResponsibilityReducer = (state = {}, action) => {
  switch (action.type) {
    case cases.SET_RESPONSIBILITY_SUCCESS: {
      return action.data;
    }
    case cases.SET_RESPONSIBILITY_FAILURE: {
      return false;
    }
    default:
      return state;
  }
};

export default caseResponsibilityReducer;
