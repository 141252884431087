import { skills } from "redux/constants";

const skillsToolsErrorReducer = (state = {}, action) => {
  switch (action.type) {
    case skills.GET_SKILL_TOOLS_DATA_FAILURE:
      return action.error.response.status;
    case skills.GET_SKILL_TOOLS_DATA_SUCCESS:
      return null;
    default:
      return state;
  }
};

export default skillsToolsErrorReducer;
