import { certificate } from "redux/constants";

const certificateUpdatingReducer = (state = false, action) => {
  switch (action.type) {
    case certificate.UPDATE_CERTIFICATE:
      return true;
    case certificate.UPDATE_CERTIFICATE_SUCCESS:
    case certificate.UPDATE_CERTIFICATE_FAILURE:
      return false;
    default:
      return state;
  }
};

export default certificateUpdatingReducer;
