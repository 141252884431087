import { experience } from "redux/constants";

const experienceLoadingReducer = (state = false, action) => {
  switch (action.type) {
    case experience.FETCH_EXPERIENCE:
      return true;
    case experience.FETCH_EXPERIENCE_SUCCESS:
    case experience.FETCH_EXPERIENCE_FAILURE:
      return false;
    default:
      return state;
  }
};

export default experienceLoadingReducer;
