import { takeLatest } from "redux-saga/effects";

import { userAccessRoles } from "redux/constants";
import { handleSaveAccessRoles } from "../worker/handleFetchAccessRoles";

function* watchAchievements() {
  yield takeLatest(userAccessRoles.SAVE_USER_ACCESS_ROLES, handleSaveAccessRoles);
}

export default watchAchievements;
