import { call, put } from "redux-saga/effects";
import { get } from "redux/api";
import { setError, setUser } from "redux/actions/user";

function setPermissions(userData) {
  const permissions = {
    canLogon: !userData.hasNoAccess,
    canViewPersonalProfile: !userData.hasNoAccess,
    canViewColleagueProfile: !userData.hasNoAccess,
    canViewAboutTile: !userData.hasNoAccess,
    canViewAdminOption: userData.isAdmin,

    canEditPersonalProfile: userData.canEditProfile,
    canEditColleagueProfile: userData.canEditProfile,
    canEditLimitedProfile: userData.canEditProfile,
    canEditAboutEct: userData.hasEditEct,
    canEditAboutSA: userData.hasEditSeniorAdvisor,
    canEditAboutKT: userData.isAdmin,
    canEditAboutCCO: false
  };

  return { ...userData, permissions };
}

function* handleFetchUser(endpoint) {
  const { data, error } = yield call(get, endpoint);
  if (error) {
    yield put(setError(error));
  } else {
    const userData = setPermissions(data);
    yield put(setUser(userData));
  }
}

export default handleFetchUser;
