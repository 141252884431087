import { userData } from "redux/constants";

const getUserData = (payload) => ({
  type: userData.FETCH_USER_DATA,
  payload
});

const setUserData = (data) => ({
  type: userData.FETCH_USER_DATA_SUCCESS,
  data
});

const setUserDataError = (error) => ({
  type: userData.FETCH_USER_DATA_FAILURE,
  error
});

export { getUserData, setUserData, setUserDataError };
