import { people } from "redux/constants";

const peopleReducer = (state = [], action) => {
  switch (action.type) {
    case people.FETCH_PEOPLE_SUCCESS: {
      return [...action?.data?.data?.data?.searchFilter?.employees];
    }
    default:
      return state;
  }
};

export default peopleReducer;
