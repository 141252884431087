import { call, put } from "redux-saga/effects";
import { post } from "redux/api";
import { setPeople, setPeopleError } from "redux/actions/people";

function* handleFetchPeople({ payload }) {
  const { res, error } = yield call(post, payload);
  if (error) {
    yield put(setPeopleError(error));
  } else {
    yield put(setPeople(res));
  }
}

export default handleFetchPeople;
