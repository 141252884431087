import { call, put } from "redux-saga/effects";
import { get } from "redux/api";
import { setDesignatedBy, setDesignatedByError } from "redux/actions/designatedBy";

function* handleDesignatedBy({ payload }) {
  const { data, error } = yield call(get, payload);
  if (error) {
    yield put(setDesignatedByError(error));
  } else {
    yield put(setDesignatedBy(data));
  }
}

export default handleDesignatedBy;
