import { award } from "redux/constants";

const awardDeletingReducer = (state = false, action) => {
  switch (action.type) {
    case award.DELETE_AWARD:
      return true;
    case award.DELETE_AWARD_SUCCESS:
    case award.DELETE_AWARD_FAILURE:
      return false;
    default:
      return state;
  }
};

export default awardDeletingReducer;
