import { profileCompletion } from "redux/constants";

const updateProfileCompletionLoadingReducer = (state = false, action) => {
  switch (action.type) {
    case profileCompletion.UPDATE_PROFILE_COMPLETE:
      return true;
    case profileCompletion.UPDATE_PROFILE_COMPLETE_SUCCESS:
    case profileCompletion.UPDATE_PROFILE_COMPLETE_FAILURE:
      return false;
    default:
      return state;
  }
};

export default updateProfileCompletionLoadingReducer;
