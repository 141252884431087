import { takeLatest } from "redux-saga/effects";

import { userStaffId } from "redux/constants";
import handleFetchUserStaffId from "../worker/handleFetchUserStaffId";

function* watchUserStaffId() {
  yield takeLatest(userStaffId.FETCH_USER_STAFF_ID, handleFetchUserStaffId);
}

export default watchUserStaffId;
