import { useState, useEffect } from "react";
import { Table, Icon as IconS, Button, Modal } from "semantic-ui-react";
import PropTypes from "prop-types";

import useSortableData from "pages/Cases/Vignettes/useSortableData";
import { Icon } from "components/common";

import { formatDateByMoment } from "helpers/utils";
import LABEL from "constants/label";

import "./styles.scss";

const { Education, TABLE_HEADER } = LABEL.EDUCATION;
const { Close } = LABEL.COMMON;

function EducationViewAll({ open, onClose, education, workdayURL }) {
  const [educationList] = useState(education);
  const { items, requestSort, sortConfig } = useSortableData(educationList, {
    key: "school",
    direction: "up"
  });
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return false;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  useEffect(() => {
    requestSort("school");
  }, []);

  return (
    <Modal open={open} className="education__modal popup-modal">
      <Modal.Header className="education__modal__title">
        {Education}
        <p className="fs-12 mb-1 fw-normal header-subHeading">
          Any changes to Education can only be updated in Workday.
        </p>
      </Modal.Header>

      <Modal.Content className="education__modal__content">
        <Table striped fixed>
          <Table.Header className="education__table--head">
            <Table.Row>
              <Table.HeaderCell width={6} className="cursor-pointer" onClick={() => requestSort("school")}>
                {TABLE_HEADER.School}
                {getClassNamesFor("school") && <IconS name={`arrow ${getClassNamesFor("school")}`} />}
              </Table.HeaderCell>
              <Table.HeaderCell width={4} className="cursor-pointer" onClick={() => requestSort("degree")}>
                {TABLE_HEADER.Degree}
                {getClassNamesFor("degree") && <IconS name={`arrow ${getClassNamesFor("degree")}`} />}
              </Table.HeaderCell>
              <Table.HeaderCell width={3} className="cursor-pointer" onClick={() => requestSort("major")}>
                {TABLE_HEADER.Major}
                {getClassNamesFor("major") && <IconS name={`arrow ${getClassNamesFor("major")}`} />}
              </Table.HeaderCell>
              <Table.HeaderCell width={3} className="cursor-pointer" onClick={() => requestSort("startDate")}>
                {TABLE_HEADER.Duration}
                {getClassNamesFor("startDate") && <IconS name={`arrow ${getClassNamesFor("startDate")}`} />}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body className="education__table--body">
            {items?.length > 0 &&
              items.map((educationObj) => (
                <Table.Row key={educationObj.id}>
                  <Table.Cell className="d-flex align-items-center">
                    <div className="education__icon d-flex justify-content-center mt-0">
                      <Icon name="graduateCap" />
                    </div>
                    <span className="td-label">{TABLE_HEADER.School}</span>
                    {educationObj.school ? educationObj.school : "-"}
                  </Table.Cell>
                  <Table.Cell>
                    <span className="td-label mt-3">{TABLE_HEADER.Degree}</span>{" "}
                    {educationObj.degree && educationObj.degree !== "NULL" ? educationObj.degree : "-"}
                  </Table.Cell>
                  <Table.Cell>
                    <span className="td-label">{TABLE_HEADER.Major}</span>{" "}
                    {educationObj.major && educationObj.major !== "NULL" ? educationObj.major : "-"}
                  </Table.Cell>

                  <Table.Cell>
                    <span className="td-label">{TABLE_HEADER.Duration}</span>{" "}
                    {educationObj.startDate !== "NULL" && educationObj.endDate !== "NULL" ? (
                      <>
                        {formatDateByMoment(educationObj?.startDate, "MMM YYYY")} -{" "}
                        {formatDateByMoment(educationObj?.endDate, "MMM YYYY")}
                      </>
                    ) : (
                      "-"
                    )}
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      </Modal.Content>

      <Modal.Actions className="education_footer tab__footer d-flex justify-content-between align-items-center">
        <div className="education__content__small">
          <IconS name="info circle" className="education__content__icon" />
          Any changes to Education can only be updated in Workday. Please{" "}
          <a href={workdayURL} target="_blank" rel="noopener noreferrer">
            click here
          </a>{" "}
          to access the Workday portal.
        </div>
        <Button data-testid="close" basic className="button-sm mr-1" secondary onClick={() => onClose()}>
          {Close}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

EducationViewAll.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  workdayURL: PropTypes.string
};

export default EducationViewAll;
