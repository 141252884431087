import { call, put } from "redux-saga/effects";
import { delete as del } from "redux/api";
import { setDeleteSkillSuccess, setDeleteSkillError } from "redux/actions/skills";

function* handleDeleteSkill({ payload }) {
  const { data, error } = yield call(del, payload);
  if (error) {
    yield put(setDeleteSkillError(error));
  } else {
    yield put(setDeleteSkillSuccess(data));
  }
}

export default handleDeleteSkill;
