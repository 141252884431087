import { userData } from "redux/constants";

const userDataErrorReducer = (state = null, action) => {
  switch (action.type) {
    case userData.FETCH_USER_DATA_FAILURE:
      return action.error;
    case userData.FETCH_USER_DATA:
    case userData.FETCH_USER_DATA_SUCCESS:
      return null;
    default:
      return state;
  }
};

export default userDataErrorReducer;
