import { userData } from "redux/constants";

const userDataLoadingReducer = (state = true, action) => {
  switch (action.type) {
    case userData.FETCH_USER_DATA:
      return true;
    case userData.FETCH_USER_DATA_SUCCESS:
    case userData.FETCH_USER_DATA_FAILURE:
      return false;
    default:
      return state;
  }
};

export default userDataLoadingReducer;
