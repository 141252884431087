import { pronunciation } from "redux/constants";
import LABEL from "constants/label";

const { COMMON } = LABEL;

const pronunciationSavingReducer = (state = "", action) => {
  switch (action.type) {
    case pronunciation.SAVE_PRONUNCIATION:
      return COMMON.StatusProcessing;
    case pronunciation.SAVE_PRONUNCIATION_SUCCESS:
      return COMMON.StatusSuccess;
    case pronunciation.SAVE_PRONUNCIATION_FAILURE:
      return COMMON.StatusFailure;
    case pronunciation.RESET_PRONUNCIATION_STATE:
      return "";
    default:
      return state;
  }
};

export default pronunciationSavingReducer;
