import { cases } from "redux/constants";

const caseResponsibilityLoadingReducer = (state = true, action) => {
  switch (action.type) {
    case cases.SET_RESPONSIBILITY_SUCCESS:
    case cases.SET_RESPONSIBILITY_FAILURE:
      return false;
    case cases.GET_RESPONSIBILITIES:
      return true;

    default:
      return state;
  }
};

export default caseResponsibilityLoadingReducer;
