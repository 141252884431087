import React from "react";
import PropTypes from "prop-types";
import { nanoid } from "nanoid/non-secure";
import { getDuration, letterLimit, formatDateByMoment } from "helpers/utils";

import { Card } from "semantic-ui-react";

function ExperienceCard({ experience }) {
  return (
    <>
      {experience?.map((item) => (
        <Card.Content data-testid="expList" key={nanoid()}>
          <div className="experience__item">
            <span className="experience__item__image justify-content-center align-items-center">
              <img src="/icons/ic_experience.svg" width="11" height="26" alt="experience" />
            </span>

            <div className="experience__item__content">
              {/* Experience title */}
              {!!item.title && (
                <h3 data-testid="title" className="experience-item__text experience__item__text--bold mb-0">
                  {item.title}
                </h3>
              )}
              {/* Experience company */}
              {!!item.company && (
                <h4 data-testid="company" className="experience__item__text experience__item__text--gray mt-0 mb-0">
                  {item.company}
                </h4>
              )}
              {/* startDate, endDate and duration */}
              {!!item.startDate && (
                <p data-testid="startDate" className="experience__item__text experience__item__text--small-gray mb-0">
                  {formatDateByMoment(item?.startDate, "MMM DD, YYYY")} –{" "}
                  {formatDateByMoment(item?.endDate, "MMM DD, YYYY")} |
                  {!!item.endDate && (
                    <span data-testid="duration" className="d-inline-block">
                      <strong>{getDuration(item.endDate, item.startDate)}</strong>
                    </span>
                  )}
                </p>
              )}
              {/* Resposibility */}
              {!!item.responsibility && (
                <p data-testid="responsibility" className="experience__item__textexperience__item__text--small">
                  {letterLimit(item.responsibility, 154)}
                </p>
              )}
            </div>
          </div>
        </Card.Content>
      ))}
    </>
  );
}

ExperienceCard.defaultProps = {
  experience: []
};

ExperienceCard.propTypes = {
  experience: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      company: PropTypes.string,
      companyDesc: PropTypes.string,
      endDate: PropTypes.string,
      startDate: PropTypes.string,
      location: PropTypes.string
    })
  )
};

export default ExperienceCard;
