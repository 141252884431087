import { takeLatest } from "redux-saga/effects";

import { cases } from "redux/constants";
import handleFetchCases from "../worker/handleFetchCases";

function* watchCases() {
  yield takeLatest(cases.FETCH_CASES, handleFetchCases);
}

export default watchCases;
