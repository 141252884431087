import React from "react";
import PropTypes from "prop-types";

function WorkdayTooltip({ keyword, className, workdayWorkerUrl }) {
  return (
    <div className={`popup__content--small ${className}`}>
      {`Any changes to ${keyword} can only be updated in Workday. Please `}
      <a href={workdayWorkerUrl} target="_blank" rel="noopener noreferrer">
        {`click here `}
      </a>
      to access the Workday portal.
    </div>
  );
}

WorkdayTooltip.defaultProps = {
  className: ""
};

WorkdayTooltip.propTypes = {
  keyword: PropTypes.string.isRequired,
  className: PropTypes.string,
  workdayWorkerUrl: PropTypes.string.isRequired
};

export default WorkdayTooltip;
