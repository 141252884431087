import React from "react";
import { Card, Button } from "semantic-ui-react";
import PropTypes from "prop-types";

/**
 * This component can be used to when there is no content to show
 * @param {string} message text be be added in the component
 * @param {string} buttonText text of the button
 *  @param {boolean} isEditable indicates whether the page is editable or not
 *  @param {Function} onClick callback function
 *
 * @returns NoContent component
 *
 * @example
 *
 * <NoContent message="No Award Added" buttonText="Add Award" />
 */

function NoContent({ message, buttonText, isEditable, onClick }) {
  return (
    <Card.Content textAlign="center" className="content--no-info">
      <h4 data-testid="noContentText">{message}</h4>
      {isEditable && (
        <Button
          secondary
          className="text-uppercase"
          target="_blank"
          rel="noopener noreferrer"
          data-testid="btnAddContent"
          onClick={onClick && typeof onClick === "function" && onClick}
        >
          {buttonText}
        </Button>
      )}
    </Card.Content>
  );
}

NoContent.defaultProps = {
  isEditable: false,
  onClick: () => {},
  buttonText: ""
};

NoContent.propTypes = {
  message: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  isEditable: PropTypes.bool,
  onClick: PropTypes.func
};

export default NoContent;
