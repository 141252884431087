import { call, put } from "redux-saga/effects";
import { post } from "redux/api";
import { setAboutAdditonalData, setAboutSponserDetails, setAboutSponserDetailsError } from "redux/actions/about";

function* handleFetchAboutSponsersDetails({ payload }) {
  const { res, error } = yield call(post, payload);
  if (error) {
    yield put(setAboutSponserDetailsError(error));
  } else {
    yield put(
      setAboutAdditonalData({
        [payload.sponserType]: `${res?.data?.data.employees[0]?.preferredFirstName} ${res?.data?.data.employees[0]?.preferredLastName}`
      })
    );
    yield put(setAboutSponserDetails());
  }
}

export default handleFetchAboutSponsersDetails;
