import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Dropdown } from "semantic-ui-react";
import Popover from "components/common/Popover";

/**
 * Menu component
 *
 * @param  {array} menuItems
 * @param  {node} trigger
 *
 * @return returns menu with options
 *
 * @example
 *
 * <Menu />
 */
function Menu({ menuItems, trigger, origin }) {
  const [menu, setMenu] = useState({
    items: menuItems,
    show: false
  });

  useEffect(() => {
    setMenu({ ...menu, items: menuItems });
  }, [menuItems]);

  const generateMenu = (index) => {
    const items = [...menuItems];
    let show = false;

    if (items[index]) {
      const newItem = { ...items[index] };
      newItem.name = newItem.copiedText;
      items[index] = newItem;

      show = true;
    }

    setMenu({ items, show, trigger });

    setTimeout(() => {
      setMenu({ items: menuItems, show: false, trigger });
      document.querySelector("body").click();
    }, 500);
  };

  const onMenuItemClickHandler = (e, item) => {
    const { text } = item;

    menu.items.some((option, index) => {
      if (option.name === text) {
        option.onClick();
        if (option.copiedText) {
          generateMenu(index);
        }
        return true;
      }

      return false;
    });
  };

  const { items, show } = menu;

  if (!items || items.length === 0) {
    return null;
  }

  return (
    <Popover
      on="click"
      inverted
      trigger={trigger}
      basic
      position="bottom right"
      offset={[10, -25]}
      popper={{ className: "dropdown-popup" }}
    >
      <Dropdown icon={null} direction="left" className="customCase-dropdown" open>
        <Dropdown.Menu className={show ? "visible" : null}>
          {items.map((item) => (
            <Dropdown.Item text={item.name} onClick={onMenuItemClickHandler} key={item.name} icon={item?.icon} />
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </Popover>
  );
}

Menu.defaultProps = {
  menuItems: [],
  trigger: <span>Select</span>,
  origin: ""
};

Menu.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      onClick: PropTypes.func,
      copiedText: PropTypes.string
    })
  ),
  trigger: PropTypes.node,
  origin: PropTypes.string
};

export default Menu;
