import { call, put } from "redux-saga/effects";
import { get } from "redux/api";
import { setTopics, setError } from "redux/actions/topics";

function* handleFetchTopics({ endpoint }) {
  const { data } = yield call(get, endpoint);
  if (data?.error?.code !== 200) {
    yield put(setError(data?.error?.message));
  } else {
    yield put(setTopics(data?.expertise ? data?.expertise : []));
  }
}

export default handleFetchTopics;
