import { call, put as putSaga } from "redux-saga/effects";
import { put } from "redux/api";
import { setLinkedInDeclineStatus, setLinkedInDeclineError } from "redux/actions/linkedIn";

function* handleUpdateDeclineStatus({ payload }) {
  const { res, error } = yield call(put, payload);
  if (error) {
    yield putSaga(setLinkedInDeclineError(error));
  } else {
    yield putSaga(setLinkedInDeclineStatus(res?.data));
  }
}

export default handleUpdateDeclineStatus;
