import { upload } from "redux/constants";

const uploadResumeReducer = (state = false, action) => {
  switch (action.type) {
    case upload.UPLOAD_RESUME_START:
    case upload.UPLOAD_RESUME_TO_PROFILE:
      return true;
    case upload.UPLOAD_RESUME_SUCCESS:
      return false;
    case upload.UPLOAD_RESUME_ERROR:
      return false;
    default:
      return state;
  }
};

export default uploadResumeReducer;
