import { takeLatest } from "redux-saga/effects";

import { manageExpertise } from "redux/constants";
import handleUpdateExpertise from "../worker/handleUpdateExpertise";

function* watchUpdateExpertise() {
  yield takeLatest(manageExpertise.UPDATE_EXPERTISE, handleUpdateExpertise);
}

export default watchUpdateExpertise;
