import { certificate } from "redux/constants";

const certificateDeletingReducer = (state = false, action) => {
  switch (action.type) {
    case certificate.DELETE_CERTIFICATE:
      return true;
    case certificate.DELETE_CERTIFICATE_SUCCESS:
    case certificate.DELETE_CERTIFICATE_FAILURE:
      return false;
    default:
      return state;
  }
};

export default certificateDeletingReducer;
