import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Placeholder } from "semantic-ui-react";
import PropTypes from "prop-types";
import CalendarContainer from "./CalendarContainer";

/**
 *
 * @param {string} date default date to be selected
 * @param {string} maxDate maximum date allowed for selection
 * @param {string} dateFormat format of the date
 * @param {string} popperPosition position of the calendar (bottom-start, bottom-end, top-start, top-end, left-start, left-end, right-start, right-end)
 * @param {func} callBack callback to be called on date change
 *
 * @returns Calendar component
 *
 * @example
 * <Calender
    date={award.dateReceived}
    maxDate={new Date().toLocaleDateString()}
    dateFormat="MM/dd/yyyy"
   />
 */
function Calender({ date, maxDate, dateFormat, popperPosition, isLoading, callBack, minDate }) {
  const [selectedDate, setSelectedDate] = useState(date);

  const handleOnChange = (dateSelected) => {
    setSelectedDate(dateSelected);
    if (callBack && typeof callBack === "function") callBack(dateSelected);
  };

  let returnVal = (
    <DatePicker
      selected={selectedDate}
      onChange={handleOnChange}
      dateFormat={dateFormat}
      maxDate={maxDate}
      minDate={minDate}
      showYearDropdown
      popperPlacement={popperPosition}
      popperContainer={CalendarContainer}
      onChangeRaw={(e) => e.preventDefault()} // to make the date input readonly
    />
  );

  if (isLoading) {
    returnVal = (
      <Placeholder fluid>
        <Placeholder.Header>
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Header>
      </Placeholder>
    );
  }

  return returnVal;
}

Calender.defaultProps = {
  date: new Date(),
  maxDate: new Date(),
  minDate: new Date(),
  dateFormat: "MM/dd/yyyy",
  popperPosition: "bottom-start",
  callBack: null
};

Calender.propTypes = {
  date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  minDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  maxDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  dateFormat: PropTypes.string,
  popperPosition: PropTypes.string,
  callBack: PropTypes.func
};

export default Calender;
