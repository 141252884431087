import { profileUpdateHistory } from "redux/constants";

const profileUpdateHistoryLoadingReducer = (state = false, action) => {
  switch (action.type) {
    case profileUpdateHistory.FETCH_PROFILE_UPDATE_HISTORY:
      return true;
    case profileUpdateHistory.FETCH_PROFILE_UPDATE_HISTORY_SUCCESS:
    case profileUpdateHistory.FETCH_PROFILE_UPDATE_HISTORY_FAILURE:
      return false;
    default:
      return state;
  }
};

export default profileUpdateHistoryLoadingReducer;
