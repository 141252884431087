import React from "react";
import PropTypes from "prop-types";
import ManagePronunciation from "components/ManagePronunciation";
import styles from "./styles.module.scss";
import Popover from "../common/Popover";
import Pipe from "../common/Pipe";

/**
 * @param {string} name name of the person
 * @param {string} localTitle  local title of the person
 * @param {array} keyToPurposeYears list of key to purpose awards years
 *
 * @returns ProfileInfo component
 *
 * @example
 * <ProfileInfo
 *  name="James Bond"
 *  localTitle="007"
 *  keyToPurposeAwardYears={[2018, 2019]}
 * />
 */

function ProfileInfo({ name, localTitle, keyToPurposeYears, hrId, audioId, audioUrl, loggedInUserHrId }) {
  return (
    <>
      <div className="d-flex align-items-center">
        <div className={styles["profile__title"]}>{name}</div>
        <ManagePronunciation
          hrId={hrId}
          hasPronunciation={!!audioId}
          audioUrl={audioUrl}
          loggedInUserHrId={loggedInUserHrId}
        />
      </div>
      <div className={`${styles["profile__designation"]} d-flex`}>
        <div className={styles["profile__designation--title"]}>{localTitle}</div>
        {keyToPurposeYears && keyToPurposeYears.length > 0 && (
          <>
            <span className="mr-1">
              <Pipe />
            </span>
            <div className={`${styles["profile__designation--awards"]} d-flex`}>
              <Popover
                trigger={<img src="/icons/ic_key_to_purpose.svg" alt="key to purpose award" />}
                inverted
                on="hover"
                position="right center"
              >
                {`Key to Purpose ${keyToPurposeYears}`}
              </Popover>
            </div>
          </>
        )}
      </div>
    </>
  );
}

ProfileInfo.defaultProps = {
  keyToPurposeYears: [],
  audioId: "",
  audioUrl: ""
};

ProfileInfo.propTypes = {
  name: PropTypes.string.isRequired,
  localTitle: PropTypes.string.isRequired,
  keyToPurposeYears: PropTypes.arrayOf(PropTypes.string),
  hrId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  audioId: PropTypes.string,
  audioUrl: PropTypes.string
};

export default ProfileInfo;
