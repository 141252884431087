import { ssInternalMaterials } from "redux/constants";

const ssInternalMaterialsReducer = (state = {}, action) => {
  switch (action.type) {
    case ssInternalMaterials.FETCH_SSINTERNAL_MATERIALS_SUCCESS: {
      if (Object.keys(state).length === 0) {
        return { ...state, ...action.data };
      }
      const prevDoc = state.doc;
      const prevRefiners = state.refiners;
      const temp = { ...state, ...action.data };
      temp.doc.unshift(...prevDoc);
      temp.refiners.unshift(...prevRefiners);
      return temp;
    }
    default:
      return state;
  }
};

export default ssInternalMaterialsReducer;
