import { about } from "redux/constants";

const aboutUpdatingReducer = (state = false, action) => {
  switch (action.type) {
    case about.UPDATE_ABOUT_KT:
    case about.UPDATE_ABOUT_ECT:
    case about.UPDATE_ABOUT_SA:
      return true;
    case about.UPDATE_ABOUT_KT_SUCCESS:
    case about.UPDATE_ABOUT_KT_FAILURE:
    case about.UPDATE_ABOUT_ECT_SUCCESS:
    case about.UPDATE_ABOUT_ECT_FAILURE:
    case about.UPDATE_ABOUT_SA_SUCCESS:
    case about.UPDATE_ABOUT_SA_FAILURE:
      return false;
    default:
      return state;
  }
};

export default aboutUpdatingReducer;
