import { topics } from "redux/constants";

const topicsReducer = (state = [], action) => {
  switch (action.type) {
    case topics.FETCH_TOPIC_SUCCESS: {
      return [...action.data];
    }
    default:
      return state;
  }
};

export default topicsReducer;
