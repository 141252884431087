import { useRef, useEffect } from "react";

/**
 * Custom React hook to determine if a component is currently mounted or not.
 *
 * @returns {object} isMounted - A mutable ref object indicating if the component is mounted.
 */

const useIsMounted = () => {
  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);
  return isMounted;
};

export default useIsMounted;
