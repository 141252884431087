import { call, put } from "redux-saga/effects";
import { get } from "redux/api";
import { setAboutMeAllTerms, setAboutMeAllTermsError } from "redux/actions/about";

function* handleFetchAboutMeAllTerms({ payload }) {
  const { data, error } = yield call(get, payload);
  if (error) {
    yield put(setAboutMeAllTermsError(error));
  } else {
    yield put(setAboutMeAllTerms(data));
  }
}

export default handleFetchAboutMeAllTerms;
