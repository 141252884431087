import { takeLatest } from "redux-saga/effects";

import { skills } from "redux/constants";
import handleUpdateSkill from "../worker/handleUpdateSkill";

function* watchUpdateSkill() {
  yield takeLatest(skills.UPDATE_SKILL, handleUpdateSkill);
}

export default watchUpdateSkill;
