import React, { useEffect, useState } from "react";
import { Dropdown, Popup } from "semantic-ui-react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";

/**
 *
 * @param {Array} options array of options to be shown
 * @param {number} selectedIndex array of options to be shown
 * @param {string} id id of the component
 * @param {string} cls expernal css class to be added
 * @param {Function} onClick callback function to send back the selected value
 * @param {boolean} invalid boolean flag for validation
 * @param {boolean} disabled boolean flag for make dropdown disabled
 *
 * @returns
 * PopupDropDown Component
 * @example
 *  <PopupDropDown onClick={onAwardTypeChange} options={awardTypeOptions} selectedIndex={0} id="dd_award_type" />
 */
function PopupDropDown({ options, selectedIndex, id, cls, onClick, invalid, disabled }) {
  const [isOpen, setIsOpen] = useState(false);
  const [text, setText] = useState("");

  const input = document.getElementById(id);

  const offsetX = input?.clientWidth;

  const onItemClick = (e, data) => {
    setIsOpen(false);
    setText(data.text);
    onClick(e, data);
  };

  useEffect(() => {
    setText(options[selectedIndex]?.text);
  }, [options]);

  return (
    <Popup
      inverted
      on="click"
      trigger={
        <button
          disabled={disabled}
          type="button"
          onClick={() => setIsOpen(true)}
          className={`${styles["input__dd"]} cursor-pointer d-block overflow-hidden loading__text ${cls} ${
            invalid ? styles["input__dd__invalid"] : ""
          } ${disabled ? "btn-disabled" : ""}`}
          readOnly
          id={id}
        >
          {text}
        </button>
      }
      popper={{ className: "dropdown-popup" }}
      position="bottom right"
      basic
      offset={[-offsetX + 5, -30]}
      onClose={() => setIsOpen(false)}
      open={isOpen}
    >
      <Dropdown icon={null} direction="right" open={isOpen} className="custom__dropdown">
        <Dropdown.Menu style={{ minWidth: `${offsetX + 3}px` }}>
          {options.map((item) => (
            <Dropdown.Item text={item.text} key={item.key} value={item.key} onClick={onItemClick} />
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </Popup>
  );
}

PopupDropDown.defaultProps = {
  cls: "",
  disabled: false,
  invalid: false
};

PopupDropDown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      text: PropTypes.string
    })
  ).isRequired,
  onClick: PropTypes.func.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  cls: PropTypes.string,
  invalid: PropTypes.bool,
  disabled: PropTypes.bool
};

export default PopupDropDown;
