import { call, put } from "redux-saga/effects";
import { delete as del } from "redux/api";
import { setDeleteTopicSuccess, setDeleteTopicError } from "redux/actions/topics";

function* handleDeleteTopic({ payload }) {
  const { data, error } = yield call(del, payload);
  if (error) {
    yield put(setDeleteTopicError(error));
  } else {
    yield put(setDeleteTopicSuccess(data));
  }
}

export default handleDeleteTopic;
