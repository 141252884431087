import { takeEvery } from "redux-saga/effects";

import { impersonate } from "redux/constants";
import handleFetchImpersonateImage from "../worker/handleFetchImpersonateImage";

function* watchImpersonateImage() {
  yield takeEvery(impersonate.FETCH_IMAGE, handleFetchImpersonateImage);
}

export default watchImpersonateImage;
