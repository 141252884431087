import { takeEvery } from "redux-saga/effects";

import { about } from "redux/constants";
import handleFetchAboutSponsersDetails from "../worker/handleFetchAboutSponsersDetails";

function* watchFetchAboutSponsersDetails() {
  yield takeEvery(about.FETCH_ABOUT_SPONSER_DETAILS, handleFetchAboutSponsersDetails);
}

export default watchFetchAboutSponsersDetails;
