import { award } from "redux/constants";

const updateAward = (payload) => ({
  type: award.UPDATE_AWARD,
  payload
});

const setUpdateAwardSuccess = (data) => ({
  type: award.UPDATE_AWARD_SUCCESS,
  data
});

const setUpdateAwardError = (error) => ({
  type: award.UPDATE_AWARD_FAILURE,
  error
});

const deleteAward = (payload) => ({
  type: award.DELETE_AWARD,
  payload
});

const setDeleteAwardSuccess = (data) => ({
  type: award.DELETE_AWARD_SUCCESS,
  data
});

const setDeleteAwardError = (error) => ({
  type: award.DELETE_AWARD_FAILURE,
  error
});

export {
  updateAward,
  setUpdateAwardSuccess,
  setUpdateAwardError,
  deleteAward,
  setDeleteAwardSuccess,
  setDeleteAwardError
};
