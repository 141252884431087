import { achievement } from "redux/constants";

const getAchievements = (payload) => {
  return {
    type: achievement.FETCH_ACHIEVEMENT,
    payload
  };
};

const setAchievements = (data) => ({
  type: achievement.FETCH_ACHIEVEMENT_SUCCESS,
  data
});

const setError = (error) => ({
  type: achievement.FETCH_ACHIEVEMENT_FAILURE,
  error
});

export { getAchievements, setAchievements, setError };
