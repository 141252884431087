import { takeLatest } from "redux-saga/effects";

import { cases } from "redux/constants";
import handleUpdateCaseTopics from "../worker/handleUpdateCaseTopics";

function* watchUpdateCaseTopics() {
  yield takeLatest(cases.UPDATE_CASE_TOPICS, handleUpdateCaseTopics);
}

export default watchUpdateCaseTopics;
