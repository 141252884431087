import { userProfileImageHighRes } from "redux/constants";

const getProfileImageHighRes = (payload) => {
  return {
    type: userProfileImageHighRes.FETCH_USER_IMAGE_HIGHRES,
    payload
  };
};

const setProfileImageHighRes = (data) => {
  return {
    type: userProfileImageHighRes.FETCH_USER_IMAGE_HIGHRES_SUCCESS,
    data
  };
};
const setProfileImageHighResError = (error) => {
  return {
    type: userProfileImageHighRes.FETCH_USER_IMAGE_HIGHRES_FAILURE,
    error
  };
};

export { getProfileImageHighRes, setProfileImageHighRes, setProfileImageHighResError };
