import { ssInternalMaterials } from "redux/constants";

const ssInternalMaterialsErrorReducer = (state = {}, action) => {
  switch (action.type) {
    case ssInternalMaterials.FETCH_SSINTERNAL_MATERIALS_FAILURE:
      return action.error;
    case ssInternalMaterials.FETCH_SSINTERNAL_MATERIALS_SUCCESS:
    case ssInternalMaterials.FETCH_SSINTERNAL_MATERIALS:
      return null;
    default:
      return state;
  }
};

export default ssInternalMaterialsErrorReducer;
