import { education } from "redux/constants";

const educationErrorReducer = (state = {}, action) => {
  switch (action.type) {
    case education.FETCH_EDUCATION_FAILURE:
      return action.error;
    case education.FETCH_EDUCATION_SUCCESS:
    case education.FETCH_EDUCATION:
      return null;
    default:
      return state;
  }
};

export default educationErrorReducer;
