import { skills } from "redux/constants";

const skillsReducer = (state = [], action) => {
  switch (action.type) {
    case skills.FETCH_SKILL_SUCCESS: {
      return [...action.data];
    }
    default:
      return state;
  }
};

export default skillsReducer;
