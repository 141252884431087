import { call, put } from "redux-saga/effects";
import { get } from "redux/api";
import { fetchCvBioDownloadDetailsSuccess, fetchCvBioDownloadDetailsFailure } from "redux/actions/cvBioDownloadDetails";

function* handleCvBiodownloadDetails({ payload }) {
  const { data, error } = yield call(get, payload);

  if (error) {
    yield put(fetchCvBioDownloadDetailsFailure(error));
  } else {
    yield put(fetchCvBioDownloadDetailsSuccess(data));
  }
}

export default handleCvBiodownloadDetails;
