import { call, put } from "redux-saga/effects";
import { setUserData, setUserDataError } from "redux/actions/userData";
import { post } from "redux/api";

function* handleFetchUserData({ payload }) {
  const { res, error } = yield call(post, payload);
  if (error) {
    yield put(setUserDataError(error));
  } else {
    yield put(setUserData(res));
  }
}

export default handleFetchUserData;
