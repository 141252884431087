import { awardTypes } from "redux/constants";

const awardTypesErrorReducer = (state = {}, action) => {
  switch (action.type) {
    case awardTypes.FETCH_AWARD_TYPES_FAILURE:
      return action.error;
    case awardTypes.FETCH_AWARD_TYPES_SUCCESS:
    case awardTypes.FETCH_AWARD_TYPES:
      return null;
    default:
      return state;
  }
};

export default awardTypesErrorReducer;
