import { takeLatest } from "redux-saga/effects";

import { userProfileImageHighRes } from "redux/constants";
import handleFetchProfileImageHighRes from "../worker/handleFetchProfileImageHighRes";

function* watchProfileImageHighREs() {
  yield takeLatest(userProfileImageHighRes.FETCH_USER_IMAGE_HIGHRES, handleFetchProfileImageHighRes);
}

export default watchProfileImageHighREs;
