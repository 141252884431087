import { call, put as putSaga } from "redux-saga/effects";
import { put } from "redux/api";
import { setUpdateSkillSuccess, setUpdateSkillError } from "redux/actions/skills";

function* handleUpdateSkill({ payload }) {
  const { res, error } = yield call(put, payload);
  if (error) {
    yield putSaga(setUpdateSkillError(error));
  } else {
    yield putSaga(setUpdateSkillSuccess(res));
  }
}

export default handleUpdateSkill;
