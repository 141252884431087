import { profileViews } from "redux/constants";

const profileViewsDetailsReducer = (state = [], action) => {
  switch (action.type) {
    case profileViews.FETCH_PROFILE_VIEW_DETAILS_SUCCESS: {
      return [...state, { ...action.data.profileVisits }];
    }
    default:
      return state;
  }
};

export default profileViewsDetailsReducer;
