import { takeEvery } from "redux-saga/effects";

import { people } from "redux/constants";
import handleFetchPeopleImage from "../worker/handleFetchPeopleImage";

function* watchPeopleImage() {
  yield takeEvery(people.FETCH_PEOPLE_IMAGE, handleFetchPeopleImage);
}

export default watchPeopleImage;
