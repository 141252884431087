import { userStaffId } from "redux/constants";

const staffIdReducer = (state = null, action) => {
  switch (action.type) {
    case userStaffId.FETCH_USER_STAFF_ID_SUCCESS: {
      return action.data;
    }
    default:
      return state;
  }
};

export default staffIdReducer;
