import axios from "axios";
import CONFIG from "constants/config";

const KNOWLEDGE_DOWNLOAD_FILE = (guid) => `${CONFIG.knowledge.download}/${guid}`;

const DOWNLOAD_FILE = (materialId, attachmentId) =>
  `${process.env.REACT_APP_MATERIAL_DOWNLOAD_SINGLE}${materialId}&Material_Attachment_ID=${attachmentId}`;

const DOWNLOAD_MULTIPLE_FILES = (attachmentIds) =>
  `${process.env.REACT_APP_MATERIAL_DOWNLOAD_MULTIPLE}${attachmentIds}`;

// Download single file
export function downloadFile(materialId, attachmentId, kpCmsId) {
  let downloadUrl = "";
  const sources = [];
  const newSource = axios.CancelToken.source();
  sources.push(newSource);
  if (kpCmsId) {
    downloadUrl = KNOWLEDGE_DOWNLOAD_FILE(kpCmsId);
  } else {
    downloadUrl = DOWNLOAD_FILE(materialId, attachmentId);
  }

  window.open(downloadUrl, "_blank");
}

// Download multiple file
export function downloadMultipleFiles(attachmentIds) {
  // attachmentIds should be comma separated
  const downloadUrl = DOWNLOAD_MULTIPLE_FILES(attachmentIds);
  window.open(downloadUrl, "_blank");
}
