import { upload } from "redux/constants";

const uploadBioReducer = (state = false, action) => {
  switch (action.type) {
    case upload.UPLOAD_PROPOSAL_BIO_START:
    case upload.UPLOAD_PROPOSAL_BIO_T0_PROFILE:
      return true;
    case upload.UPLOAD_BIO_SUCCESS:
      return false;
    case upload.UPLOAD_BIO_ERROR:
      return false;
    default:
      return state;
  }
};

export default uploadBioReducer;
