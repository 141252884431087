/* eslint-disable no-extra-boolean-cast */
import React from "react";
import PropTypes from "prop-types";
import { nanoid } from "nanoid/non-secure";
import { getDuration, formatDateByMoment } from "helpers/utils";

import LABEL from "constants/label";
import { Card } from "semantic-ui-react";
import VerticalStepper from "components/VerticalStepper";

const {
  COMMON: { Present }
} = LABEL;

function LinkedInExperienceCard({ linkedInExperience }) {
  return (
    <>
      {linkedInExperience?.map((item) => (
        <Card.Content data-testid="expList" key={nanoid()}>
          <div className="experience__item">
            <span className="experience__item__image justify-content-center align-items-center">
              <img src="/icons/ic_experience.svg" width="11" height="26" alt="experience" />
            </span>

            <div className="experience__item__content">
              {/* Experience title */}
              {!!item.companyName && (
                <h3 data-testid="title" className="experience-item__text experience__item__text--bold mb-0">
                  {item?.companyName}
                </h3>
              )}
              {/* Experience company */}
              {(item?.roles?.length > 1 && <VerticalStepper orgRoles={item?.roles} />) || (
                <>
                  {!!item?.roles[0]?.title && (
                    <h4 data-testid="company" className="experience__item__text experience__item__text--gray mt-0 mb-0">
                      {item?.roles[0]?.title}
                    </h4>
                  )}
                  {/* startDate, endDate and duration */}
                  {!!item?.roles[0]?.startYear && (
                    <p
                      data-testid="startDate"
                      className="experience__item__text experience__item__text--small-gray mb-0"
                    >
                      {formatDateByMoment(
                        new Date(
                          item?.roles[0]?.startYear,
                          !!item?.roles[0]?.startMonth ? item?.roles[0]?.startMonth - 1 : item?.roles[0]?.startMonth
                        ),
                        "MMM, YYYY"
                      )}{" "}
                      –{" "}
                      {(!!item?.roles[0]?.endYear &&
                        formatDateByMoment(
                          new Date(
                            item?.roles[0]?.endYear,
                            !!item?.roles[0]?.endMonth ? item?.roles[0]?.endMonth - 1 : item?.roles[0]?.endMonth
                          ),
                          "MMM, YYYY"
                        )) || <span data-testid="ongoing">{Present}</span>}
                      <span data-testid="duration" className="d-inline-block">
                        |
                        <strong className="ml-h">
                          {getDuration(
                            (!!item?.roles[0]?.endYear &&
                              new Date(
                                item?.roles[0]?.endYear,
                                !!item?.roles[0]?.endMonth ? item?.roles[0]?.endMonth : item?.roles[0]?.endMonth - 1
                              )) ||
                              new Date(),
                            new Date(
                              item?.roles[0]?.startYear,
                              !!item?.roles[0]?.startMonth ? item?.roles[0]?.startMonth - 1 : item?.roles[0]?.startMonth
                            )
                          )}
                        </strong>
                      </span>
                    </p>
                  )}
                </>
              )}
            </div>
          </div>
        </Card.Content>
      ))}
    </>
  );
}

LinkedInExperienceCard.defaultProps = {
  linkedInExperience: []
};

LinkedInExperienceCard.propTypes = {
  linkedInExperience: PropTypes.arrayOf(
    PropTypes.shape({
      company: PropTypes.string,
      companyDesc: PropTypes.string,
      roles: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          endYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          startYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          endMonth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          startMonth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          location: PropTypes.string
        })
      )
    })
  )
};

export default LinkedInExperienceCard;
