import { takeLatest } from "redux-saga/effects";

import { ssInternalMaterials } from "redux/constants";
import handleFetchSSInternalMaterials from "../worker/handleFetchSSInternalMaterials";

function* watchSSInternalMaterials() {
  yield takeLatest(ssInternalMaterials.FETCH_SSINTERNAL_MATERIALS, handleFetchSSInternalMaterials);
}

export default watchSSInternalMaterials;
