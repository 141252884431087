import { about } from "redux/constants";

const aboutLoadingReducer = (state = false, action) => {
  switch (action.type) {
    case about.FETCH_ABOUT:
      return true;
    case about.FETCH_ABOUT_SUCCESS:
    case about.FETCH_ABOUT_FAILURE:
      return false;
    default:
      return state;
  }
};

export default aboutLoadingReducer;
