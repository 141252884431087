import { takeLatest } from "redux-saga/effects";

import { about } from "redux/constants";
import handleUpdateAboutECT from "../worker/handleUpdateAboutECT";

function* watchUpdateAboutECT() {
  yield takeLatest(about.UPDATE_ABOUT_ECT, handleUpdateAboutECT);
}

export default watchUpdateAboutECT;
