import { takeLatest } from "redux-saga/effects";

import { about } from "redux/constants";
import handleUpdateAboutKT from "../worker/handleUpdateAboutKT";

function* watchUpdateAboutKT() {
  yield takeLatest(about.UPDATE_ABOUT_KT, handleUpdateAboutKT);
}

export default watchUpdateAboutKT;
