import { takeLatest } from "redux-saga/effects";

import { expertiseList } from "redux/constants";
import handleFetchExpertiseList from "../worker/handleFetchExpertiseList";

function* watchExpertiseList() {
  yield takeLatest(expertiseList.FETCH_EXPERTISE_SUMMARY, handleFetchExpertiseList);
}

export default watchExpertiseList;
