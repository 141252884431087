export async function startRecording(setRecorderState, setIsDirty) {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

    setRecorderState((prevState) => {
      return {
        ...prevState,
        initRecording: true,
        mediaStream: stream
      };
    });
    // setIsDirty(true);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
}

export function stopRecording(recorder, setIsDirty) {
  if (recorder.state !== "inactive") recorder.stop();
  setIsDirty(true);
}
