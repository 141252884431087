import { takeLatest } from "redux-saga/effects";

import { userProfileImage } from "redux/constants";
import handleFetchProfileImage from "../worker/handleFetchProfileImage";

function* watchProfileImage() {
  yield takeLatest(userProfileImage.FETCH_USER_IMAGE, handleFetchProfileImage);
}

export default watchProfileImage;
