import { linkedIn } from "redux/constants";

const removeLinkedInAccessErrorReducer = (state = null, action) => {
  switch (action.type) {
    case linkedIn.REMOVE_LINKEDIN_ACCESS_FAILURE:
      return action.error;
    case linkedIn.REMOVE_LINKEDIN_ACCESS:
    case linkedIn.REMOVE_LINKEDIN_ACCESS_SUCCESS:
      return null;
    default:
      return state;
  }
};

export default removeLinkedInAccessErrorReducer;
