import { userAccessRoles } from "redux/constants";

const initialState = {
  accessRoles: [],
  savingAccessRoles: false,
  openAccessRoleModal: false
};

const accessRolesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case userAccessRoles.OPEN_ACCESS_ROLE_MODAL:
      return { ...state, openAccessRoleModal: payload };
    case userAccessRoles.FETCH_USER_ACCESS_ROLES_SUCCESS: {
      return { ...state, accessRoles: payload };
    }
    case userAccessRoles.SAVE_USER_ACCESS_ROLES:
      return { ...state, savingAccessRoles: true };
    case userAccessRoles.SAVE_USER_ACCESS_ROLES_SUCCESS:
      return { ...state, savingAccessRoles: false, openAccessRoleModal: false };
    case userAccessRoles.SAVE_USER_ACCESS_ROLES_FAILURE:
      return { ...state, savingAccessRoles: false };
    default:
      return state;
  }
};

export default accessRolesReducer;
