import React from "react";
import LABEL from "constants/label";
import Icon from "components/common/Icon";
import Popover from "components/common/Popover";
import "./styles.scss";

const {
  LINKEDIN: { LINKED_IN_HELP_INFO }
} = LABEL;

function PowerByLinkedIn() {
  return (
    <div className="powered__by__linkedin">
      <p>Powered by</p>
      <Popover
        inverted
        trigger={
          <div className="cursor-pointer">
            <Icon name="info" size={16} />
          </div>
        }
        position="center bottom"
        offset={[36, -20]}
      >
        {LINKED_IN_HELP_INFO}
      </Popover>
    </div>
  );
}

export default PowerByLinkedIn;
