import { call, put as putSaga } from "redux-saga/effects";
import { put } from "redux/api";
import { setUpdateProposalSuccess, setUpdateProposalError } from "redux/actions/editProfile";

function* handleUpdateProposal({ payload }) {
  const { data, error } = yield call(put, payload);
  if (error) {
    yield putSaga(setUpdateProposalError(error));
  } else {
    yield putSaga(setUpdateProposalSuccess(data));
  }
}

export default handleUpdateProposal;
