import { ssInternalMaterials } from "redux/constants";

const ssInternalMaterialsLoadingReducer = (state = true, action) => {
  switch (action.type) {
    case ssInternalMaterials.FETCH_SSINTERNAL_MATERIALS:
      return true;
    case ssInternalMaterials.FETCH_SSINTERNAL_MATERIALS_SUCCESS:
    case ssInternalMaterials.FETCH_SSINTERNAL_MATERIALS_FAILURE:
      return false;
    default:
      return state;
  }
};

export default ssInternalMaterialsLoadingReducer;
