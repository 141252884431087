import { linkedIn } from "redux/constants";

const linkedInDeclineStatusErrorReducer = (state = null, action) => {
  switch (action.type) {
    case linkedIn.UPDATE_LINKEDIN_DECLINE_STATUS_FAILURE:
      return action.error;
    case linkedIn.UPDATE_LINKEDIN_DECLINE_STATUS_SUCCESS:
    case linkedIn.UPDATE_LINKEDIN_DECLINE_STATUS:
      return null;
    default:
      return state;
  }
};

export default linkedInDeclineStatusErrorReducer;
