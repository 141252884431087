import { ssRecentCases } from "redux/constants";

const ssRecentCasesErrorReducer = (state = {}, action) => {
  switch (action.type) {
    case ssRecentCases.FETCH_RECENT_SSCASE_FAILURE:
      return action.error;
    case ssRecentCases.FETCH_RECENT_SSCASE_SUCCESS:
    case ssRecentCases.FETCH_RECENT_SSCASE:
      return null;
    default:
      return state;
  }
};

export default ssRecentCasesErrorReducer;
