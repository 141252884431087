import { resume } from "redux/constants";

const resumeLoadingReducer = (state = true, action) => {
  switch (action.type) {
    case resume.FETCH_RESUME:
      return true;
    case resume.FETCH_RESUME_SUCCESS:
    case resume.FETCH_RESUME_FAILURE:
      return false;
    default:
      return state;
  }
};

export default resumeLoadingReducer;
