import { designatedBy } from "redux/constants";

const getDesignatedBy = (payload) => {
  return {
    type: designatedBy.FETCH_DESIGNATEDBY,
    payload
  };
};
const setDesignatedBy = (data) => {
  return {
    type: designatedBy.FETCH_DESIGNATEDBY_SUCCESS,
    data
  };
};
const setDesignatedByError = (error) => {
  return {
    type: designatedBy.FETCH_DESIGNATEDBY_FAILURE,
    error
  };
};
export { getDesignatedBy, setDesignatedBy, setDesignatedByError };
