import { memberList } from "redux/constants";

const memberListLoadingReducer = (state = true, action) => {
  switch (action.type) {
    case memberList.FETCH_MEMBER_LIST:
    case memberList.FETCH_MEMBER_LIST_IMAGE:
      return true;
    case memberList.FETCH_MEMBER_LIST_IMAGE_SUCCESS:
    case memberList.FETCH_MEMBER_LIST_SUCCESS:
    case memberList.FETCH_MEMBER_LIST_FAILURE:
    case memberList.FETCH_MEMBER_LIST_IMAGE_FAILURE:
      return false;
    default:
      return state;
  }
};

export default memberListLoadingReducer;
