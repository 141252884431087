import { download } from "redux/constants";

const downloadStart = (payload) => {
  return {
    type: download.DOWNLOAD_START,
    payload
  };
};

const setDownloadSuccess = (data) => ({
  type: download.DOWNLOAD_SUCCESS,
  data
});

const setDownloadError = (error) => ({
  type: download.DOWNLOAD_FAILURE,
  error
});

export { downloadStart, setDownloadSuccess, setDownloadError };
