import { profileCompletion } from "redux/constants";

const profileCompletionReducer = (state = {}, action) => {
  switch (action.type) {
    case profileCompletion.FETCH_PROFILE_COMPLETION_SUCCESS: {
      return { ...action.data };
    }
    default:
      return state;
  }
};

export default profileCompletionReducer;
