import { call, put } from "redux-saga/effects";
import { delete as del } from "redux/api";
import { setDeleteAwardSuccess, setDeleteAwardError } from "redux/actions/award";

function* handleDeleteAward({ payload }) {
  const { data, error } = yield call(del, payload);
  if (error) {
    yield put(setDeleteAwardError(error));
  } else {
    yield put(setDeleteAwardSuccess(data));
  }
}

export default handleDeleteAward;
