import { call, put as putSaga } from "redux-saga/effects";
import { put } from "redux/api";
import { setUpdateCertificateSuccess, setUpdateCertificateError } from "redux/actions/certificate";

function* handleUpdateCertification({ payload }) {
  const { data, error } = yield call(put, payload);
  if (error) {
    yield putSaga(setUpdateCertificateError(error));
  } else {
    yield putSaga(setUpdateCertificateSuccess(data));
  }
}

export default handleUpdateCertification;
