import PropTypes from "prop-types";
import { Button } from "semantic-ui-react";
import "./styles.scss";

/**
 * Tag ButtonWithText
 *
 * @param  {string} text
 *
 * @return element
 *
 * @example
 *
 * <ButtonWithText text="Add Expertise" icon="add"/>
 */
function ButtonWithText({ text, icon, ...restProps }) {
  return (
    <>
      {text ? (
        <Button className="buttonWithText" {...restProps}>
          {icon ? <span /> : null}
          {text || ""}
        </Button>
      ) : null}
    </>
  );
}

ButtonWithText.defaultProps = {
  text: "",
  icon: ""
};

ButtonWithText.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.string
};

export default ButtonWithText;
