import { call, put as putSaga } from "redux-saga/effects";
import { put } from "redux/api";
import { setUpdateCaseSkillsSuccess, setUpdateCaseSkillsError } from "redux/actions/cases";
import { apiErrorCodes } from "constants/index";

function* handleUpdateCaseSkills({ payload }) {
  const { res, error } = yield call(put, payload);
  if (apiErrorCodes.includes(res?.data?.error?.code || error)) {
    yield putSaga(setUpdateCaseSkillsError(res?.data?.error));
    return;
  }
  yield putSaga(setUpdateCaseSkillsSuccess(res));
}

export default handleUpdateCaseSkills;
