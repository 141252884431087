import { isNil } from "lodash";
import { useState, useMemo } from "react";

/**
 * useSortableData - to sort table
 *
 * @param  {array} items passed items
 * @param  {string} config config
 */

const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = useState(config);

  const sortedItems = useMemo(() => {
    const sortableItems = [...items];
    if (!isNil(sortConfig)) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "down" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "down" ? 1 : -1;
        }
        if (isNil(a[sortConfig.key])) {
          return 1;
        }
        if (isNil(b[sortConfig.key])) {
          return -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key) => {
    let direction = "down";
    if (sortConfig && sortConfig.key === key && sortConfig.direction === "down") {
      direction = "up";
    }
    setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
};

export default useSortableData;
