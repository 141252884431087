import { userProfileImage } from "redux/constants";

const profileImageErrorReducer = (state = {}, action) => {
  switch (action.type) {
    case userProfileImage.FETCH_USER_IMAGE_FAILURE:
      return action.error;
    case userProfileImage.FETCH_USER_IMAGE_SUCCESS:
    case userProfileImage.FETCH_USER_IMAGE:
      return null;
    default:
      return state;
  }
};

export default profileImageErrorReducer;
