import { takeLatest } from "redux-saga/effects";

import { topics } from "redux/constants";
import handleFetchBusinessSubjects from "../worker/handleFetchBusinessSubjects";

function* watchBussinessAndSubjects() {
  yield takeLatest(topics.GET_BUSINESS_SUBJECTS_DATA, handleFetchBusinessSubjects);
}

export default watchBussinessAndSubjects;
