import { achievement } from "redux/constants";

const achievementsLoadingReducer = (state = false, action) => {
  switch (action.type) {
    case achievement.FETCH_ACHIEVEMENT:
      return true;
    case achievement.FETCH_ACHIEVEMENT_SUCCESS:
    case achievement.FETCH_ACHIEVEMENT_FAILURE:
      return false;
    default:
      return state;
  }
};

export default achievementsLoadingReducer;
