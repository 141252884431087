import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import LABEL from "constants/label";
import { Label } from "semantic-ui-react";
import Popover from "../../common/Popover";
import "./styles.scss";

const { LANGUAGE_PROFICIENCIES } = LABEL;
/**
 * @param {string} elementParentKey unique identifier to associate indicator into a language-skill collection
 * @param {number} proficiency proficiency level of associated language skill
 *
 * @returns ProficiencyIndicator Component
 *
 * @example
 * <ProficiencyIndicator elementParentKey={`language001-skill001`} proficiency={5} />
 */
function ProficiencyIndicator({ elementParentKey, proficiency }) {
  const [proficiencyLevels, setProficiencyLevels] = useState(new Map());

  const proficiencyEnum = { BEGINNER: 1, INTERMEDIATE: 2, ADVANCED: 3, FLUENT: 4, NATIVE: 5 };

  useEffect(() => {
    // on initial load, create map for Proficiency Levels,
    const proficienciesMap = new Map();

    // NOTE: use following block while Profile Languages and Proficiencies data is still sourced from Transitional DB
    // proficienciesMap.set(0, LANGUAGE_PROFICIENCIES.PROFICIENCY_LEVELS.NONE);
    // proficienciesMap.set(1, LANGUAGE_PROFICIENCIES.PROFICIENCY_LEVELS.BEGINNER);
    // proficienciesMap.set(2, LANGUAGE_PROFICIENCIES.PROFICIENCY_LEVELS.ADVANCED);
    // proficienciesMap.set(3, LANGUAGE_PROFICIENCIES.PROFICIENCY_LEVELS.FLUENT);
    // proficienciesMap.set(4, LANGUAGE_PROFICIENCIES.PROFICIENCY_LEVELS.NATIVE);

    // NOTE: use following block when Profile Languages and Proficiencies data is sourced from Workday

    proficienciesMap.set(1, LANGUAGE_PROFICIENCIES.PROFICIENCY_LEVELS.BEGINNER);
    proficienciesMap.set(2, LANGUAGE_PROFICIENCIES.PROFICIENCY_LEVELS.INTERMEDIATE);
    proficienciesMap.set(3, LANGUAGE_PROFICIENCIES.PROFICIENCY_LEVELS.ADVANCED);
    proficienciesMap.set(4, LANGUAGE_PROFICIENCIES.PROFICIENCY_LEVELS.FLUENT);
    proficienciesMap.set(5, LANGUAGE_PROFICIENCIES.PROFICIENCY_LEVELS.NATIVE);

    setProficiencyLevels(proficienciesMap);
  }, []);

  const getProficiencyIndicatorClass = (skillLevel, proficiencyLevel) => {
    if (!skillLevel) return false;

    if (skillLevel === 1 && proficiencyLevel <= skillLevel) return true;

    if (skillLevel !== 1 && proficiencyLevel <= skillLevel) return true;

    return false;
  };
  return (
    (proficiency && proficiencyLevels?.get(proficiencyEnum[proficiency.toUpperCase()]) && (
      <Popover
        trigger={
          <div className="proficiency-level" data-testid="proficiency-level-container">
            {Array.from(proficiencyLevels.keys(1)).map((level) => (
              <Label
                key={`${elementParentKey}-${proficiencyLevels?.get(level)}`}
                data-testid="proficiency-level-container-indicator"
                className={`proficiency-level__indicator${
                  getProficiencyIndicatorClass(proficiencyEnum[proficiency?.toUpperCase()], level) ? "--enabled" : ""
                }`}
                size="mini"
                as="span"
                circular
              />
            ))}
          </div>
        }
        on="hover"
        inverted
        position="bottom left"
        offset={[0, 2]}
        wide
        popper={{ className: "proficiency-tooltip" }}
      >
        {proficiencyLevels?.get(proficiencyEnum[proficiency.toUpperCase()]) || null}
      </Popover>
    )) ||
    null
  );
}

ProficiencyIndicator.propTypes = {
  elementParentKey: PropTypes.string.isRequired,
  proficiency: PropTypes.string.isRequired
};

export default ProficiencyIndicator;
