import { call, put as putSaga } from "redux-saga/effects";
import { put } from "redux/api";
import { setUpdateAwardSuccess, setUpdateAwardError } from "redux/actions/award";

function* handleUpdateAward({ payload }) {
  const { data, error } = yield call(put, payload);
  if (error) {
    yield putSaga(setUpdateAwardError(error));
  } else {
    yield putSaga(setUpdateAwardSuccess(data));
  }
}

export default handleUpdateAward;
