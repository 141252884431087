import React, { useEffect, useState } from "react";
import { Placeholder, Input } from "semantic-ui-react";
import PropTypes from "prop-types";
import validator from "validator";

/**
 *
 * @param {array} protocolOptions options to be selected in the dropdown.
 * @param {boolean} isLoading boolean flag for showing placeholder
 * @param {string} awardSiteUrl having URL string
 * @param {function} setAwardSiteUrl to set URL
 * @param {string} awardSiteProtocol having URL protocol
 * @param {function} setAwardSiteProtocol to set URL protocol
 * @param {function} setAwardSiteIsValid to set boolean flag for URL validation
 * @param {function} setIsAwardDirty to set boolean if user makes any changes
 *
 * @returns Hyperlink Component
 *
 * @example
 *
 * <Hyperlink protocolOptions={protocols} />
 */

const Hyperlink = ({ isLoading, awardSiteUrl, setAwardSiteUrl, setAwardSiteIsValid, onTextChange }) => {
  const [errorClassName, setErrorClassName] = useState("error");

  const validateUrl = (value) => {
    if (validator.isURL(value)) {
      setErrorClassName("");
      setAwardSiteIsValid(true);
      return true;
    }
    setErrorClassName("error");
    setAwardSiteIsValid(false);
    return false;
  };

  useEffect(() => {
    validateUrl(awardSiteUrl);
  }, [awardSiteUrl]);

  const handleOnTextChange = (e) => {
    setAwardSiteUrl(e.target.value);
    validateUrl(awardSiteUrl);
    onTextChange(true);
  };

  let returnVal = (
    <>
      <Input
        className={awardSiteUrl?.length > 0 ? `hyperlink ${errorClassName}` : "hyperlink_blank"}
        labelPosition="left"
        type="text"
        onChange={handleOnTextChange}
        value={awardSiteUrl}
      />
      {awardSiteUrl?.length > 0 && errorClassName === "error" && "Incorrect hyperlink will be saved as blank."}
    </>
  );

  if (isLoading)
    returnVal = (
      <Placeholder fluid>
        <Placeholder.Header>
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Header>
      </Placeholder>
    );

  return returnVal;
};

Hyperlink.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  awardSiteUrl: PropTypes.string.isRequired,
  setAwardSiteUrl: PropTypes.func.isRequired,
  awardSiteProtocol: PropTypes.string.isRequired,
  setAwardSiteProtocol: PropTypes.func.isRequired,
  setAwardSiteIsValid: PropTypes.func.isRequired,
  setIsAwardDirty: PropTypes.func.isRequired
};

export default Hyperlink;
