import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "core-js/features/function";
import "core-js/features/array";
import "core-js/features/string";
import "core-js/features/set";
import "core-js/features/symbol";
import "core-js/features/typed-array/uint8-array";

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { makeStore } from "redux/store";
import "styles/globals.scss";
import App from "components/App/App";
import "datadog/datadog";

const store = makeStore();

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
