import { sortArrObjectByDate } from "helpers/utils";
import { proposalBios } from "redux/constants";

const proposalBiosReducer = (state = [], action) => {
  switch (action.type) {
    case proposalBios.FETCH_PROPOSAL_BIOS_SUCCESS: {
      return sortArrObjectByDate([...action.data], "creationDate");
    }
    default:
      return state;
  }
};

export default proposalBiosReducer;
