import { takeLatest } from "redux-saga/effects";

import { ssMaterials } from "redux/constants";
import handleFetchSSMaterials from "../worker/handleFetchSSMaterials";

function* watchSSMaterials() {
  yield takeLatest(ssMaterials.FETCH_SSMATERIALS, handleFetchSSMaterials);
}

export default watchSSMaterials;
