import PropTypes from "prop-types";
import { Popup } from "semantic-ui-react";

/**
 * Popover - tooltip and popover
 * @param  {node} children Popover/tooltip content
 * @param  {node} trigger Element on which by clicking/hover Popover will appear
 * @param  {string} position Popover position
 * @param  {bool} inverted Show Inverted (black) color.
 * @param  {string} on Triggers on click or hover
 * @param  {string} className Custom classes
 * @param  {object} popper Popper object to pass custom id
 * @param  {func} onClose function on closing of popover
 * @param  {func} onOpen function on opening on popover
 * @param  {func|arrayOf} offset Popper popover offset
 * @param  {string} size
 *
 * @return Popup Component
 *
 * @example
 *
 * <Popover trigger={<Icon name="phone" circular size="large" />} on="click" inverted>
    <span>Popover content</span>
  </Popover>
 *
 */

const Popover = ({
  children,
  trigger,
  position,
  inverted,
  on,
  className,
  popper,
  basic,
  onClose,
  onOpen,
  offset,
  size,
  hideOnScroll
}) => (
  <Popup
    content={children}
    trigger={trigger}
    on={on}
    position={position}
    inverted={inverted}
    className={className}
    popper={popper}
    basic={basic}
    onClose={onClose}
    onOpen={onOpen}
    offset={offset}
    size={size}
    hideOnScroll={hideOnScroll}
    hoverable
  />
);

Popover.defaultProps = {
  position: "top left",
  inverted: false,
  className: "",
  popper: {},
  basic: false,
  onClose: () => {},
  onOpen: () => {},
  offset: [null, null],
  size: "small",
  hideOnScroll: false
};

Popover.propTypes = {
  children: PropTypes.node.isRequired,
  trigger: PropTypes.node.isRequired,
  position: PropTypes.string,
  inverted: PropTypes.bool,
  on: PropTypes.string.isRequired,
  className: PropTypes.string,
  popper: PropTypes.objectOf(PropTypes.string),
  basic: PropTypes.bool,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  offset: PropTypes.oneOfType([PropTypes.func, PropTypes.arrayOf(PropTypes.number)]),
  size: PropTypes.string,
  hideOnScroll: PropTypes.bool
};

export default Popover;
