import { call, put } from "redux-saga/effects";
import { get } from "redux/api";
import { setProposalBios, setProposalBiosError } from "redux/actions/proposalbios";

function* handleFetchProfileProposalBios({ payload }) {
  const { data, error } = yield call(get, payload);
  if (error) {
    yield put(setProposalBiosError(error));
  } else {
    yield put(setProposalBios(data));
  }
}

export default handleFetchProfileProposalBios;
