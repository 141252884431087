import ProgressBar from "components/common/ProgressBar";
import React from "react";
import { Checkbox, Grid } from "semantic-ui-react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { EVENTS, TRIGGERS, NOTIFICATION_EVENTS } from "analytics/constants";
import sendEventData from "analytics/analytics";
import { useSelector } from "react-redux";
import LABEL from "constants/label";
import { useQuery } from "helpers/utils";

const {
  PROFILE_OVERVIEW: { ProfileConfirmation },
  COMMON: { NA }
} = LABEL;

const {
  PROFILE_COMPLETION: { I_CONFIRM }
} = TRIGGERS;

const { OVERVIEW_EDIT } = EVENTS;

const OwnerPercentage = ({ percentCompleted, toggelPercentCompleted, profileCompletionData }) => {
  const { profile } = useSelector((state) => state);
  const { confirm, allAttributeComplete, profileStale } = profileCompletionData || {};
  const { hrId } = profile || {};
  const location = useLocation();
  const query = useQuery(location?.search);
  const campaignSource = query.get(NOTIFICATION_EVENTS.CAMPAIGN_SOURCE);
  const campaignText = query.get(NOTIFICATION_EVENTS.CAMPAIGN_TEXT);

  const handleOnCheckboxClick = () => {
    window.$tabName = OVERVIEW_EDIT;
    toggelPercentCompleted(hrId, !confirm);
    sendEventData(I_CONFIRM, OVERVIEW_EDIT, {
      subCategory: ProfileConfirmation,
      sortOrder: NA,
      campaignSource,
      campaignText
    });
  };

  return (
    <>
      <Grid.Row className="mt-1 pb-0">
        <Grid.Column>
          <ProgressBar percentCompleted={percentCompleted} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row className="pt-0">
        <Grid.Column>
          {allAttributeComplete && profileStale ? (
            <>
              <Checkbox
                checked={confirm}
                label="I confirm my profile is current"
                className="profile__completion--checkbox"
                onClick={handleOnCheckboxClick}
                data-testId="confirm-checkbox"
              />
            </>
          ) : null}
          <p className="mt-1">
            Update your profile every <strong>6</strong> months
          </p>
          <p className="mt-2">Profiles inform expert search, staffing and case work</p>
        </Grid.Column>
      </Grid.Row>
    </>
  );
};

OwnerPercentage.defaultProps = {
  toggelPercentCompleted: () => {}
};

OwnerPercentage.propTypes = {
  percentCompleted: PropTypes.number.isRequired,
  toggelPercentCompleted: PropTypes.func,
  profileCompletionData: PropTypes.shape({
    aboutSkip: PropTypes.bool,
    completionResponseList: PropTypes.instanceOf(Array),
    confirm: PropTypes.bool,
    profileStale: PropTypes.bool,
    summarySkip: PropTypes.bool,
    totalPercentage: PropTypes.number
  }).isRequired
};

export default OwnerPercentage;
