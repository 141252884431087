import { isEmptyOrNull } from "helpers/utils";
import moment from "moment";

export const getProfileCompletionData = (fields, profileCompletionParams) => {
  const fieldCompletionData = [];
  fields?.forEach((field) => {
    const fieldData = profileCompletionParams?.filter((record) => record?.attributeName === field);
    if (!isEmptyOrNull(fieldData)) {
      fieldCompletionData.push(fieldData[0]);
    }
  });
  return {
    isCompleted:
      !isEmptyOrNull(fieldCompletionData) &&
      !fieldCompletionData
        ?.map((field) => field?.attributeName === "resume" || (field?.completed && !field?.attributeStale))
        .includes(false),
    fieldData: fieldCompletionData
  };
};

export const makeProfileCompletionVerbiage = (verbiageArray) => {
  const verbiageList = [];
  const verbiageObj = verbiageArray?.reduce((acc, item) => {
    const { attributeName, ...rest } = item;
    acc[attributeName.toLowerCase()] = rest;
    return acc;
  }, {});

  if (verbiageObj["summary"]) verbiageList.push(`Profile Summary : + ${verbiageObj["summary"]?.percentageValue}%`);

  if (verbiageObj["about"]) verbiageList.push(`About Section : + ${verbiageObj["about"]?.percentageValue}%`);

  if (verbiageObj["proposal bio"])
    verbiageList.push(
      `Proposal Bio / Proposal Bio + Resume : + ${verbiageObj["proposal bio"]?.percentageValue}% (Resume Only: + ${verbiageObj["resume"]?.percentageValue}%)`
    );

  if (verbiageObj["time"])
    verbiageList.push(`Inactivity (${verbiageObj["time"]?.month} months) : - ${verbiageObj["time"]?.percentageValue}%`);

  return verbiageList;
};

export const getUpdateDateDifference = (timeStamp) => {
  const today = moment();
  const someday = moment(timeStamp);
  const diff = today.diff(someday, "day");
  return diff;
};
