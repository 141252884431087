import { editProfile } from "redux/constants";

const resumeUpdatingReducer = (state = false, action) => {
  switch (action.type) {
    case editProfile.UPDATE_RESUME:
      return true;
    case editProfile.UPDATE_RESUME_SUCCESS:
    case editProfile.UPDATE_RESUME_FAILURE:
      return false;
    default:
      return state;
  }
};

export default resumeUpdatingReducer;
