import { editProfile } from "redux/constants";

const proposalFileUploadingReducer = (state = false, action) => {
  switch (action.type) {
    case editProfile.PROPOSAL_FILE:
      return true;
    case editProfile.PROPOSAL_FILE_SUCCESS:
    case editProfile.PROPOSAL_FILE_FAILURE:
      return false;
    default:
      return state;
  }
};

export default proposalFileUploadingReducer;
