import { expertiseList } from "redux/constants";

const expertiseListReducer = (state = [], action) => {
  switch (action.type) {
    case expertiseList.FETCH_EXPERTISE_SUMMARY_SUCCESS: {
      return [...action.data];
    }
    default:
      return state;
  }
};

export default expertiseListReducer;
