import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
import LABEL from "constants/label";
import OrgMember from "components/common/OrgMember.js";
import { Card } from "semantic-ui-react";

import { Loading } from "components/common";
import { toastError } from "helpers/utils";
import OrgStructureViewAll from "./OrgStructureViewAll";
import "./styles.scss";

/**
 * OrgStructure - Organization Structure
 *
 * @param  {Object} profile user's profile object
 * @param  {boolean} isLoading tells if the user profile is loading
 *
 *
 * @return OrgStructure Component
 *
 * @example  <OrgStructure profile={profile} isLoading={true} />
 */

const { ORG_STRUCTURE, COMMON } = LABEL;
function OrgStructure({ profile, isLoading, organization, orgError }) {
  // const dispatch = useDispatch();
  // const { orgLoading } = useSelector((state) => state);
  // const { organization } = useSelector((state) => state);
  // const { orgError } = useSelector((state) => state);
  const [open, setOpen] = useState(false);
  const [sortedReportingLine, setSortedReportingLine] = useState(null);

  const { firstName, lastName } = profile;

  const { reportingLine, lineManager } = organization;

  // fetch organization details from People API
  // useEffect(() => {
  //   if (hrId) fetchOrganization(hrId, dispatch);
  // }, [hrId]);

  useEffect(() => {
    if (orgError) toastError(0, LABEL.ERRORS.NetworkError, LABEL.ERRORS.PeopleErrMsg);
  }, [orgError]);

  // Sort the Reporting Line by name in ascending order
  useEffect(() => {
    const reportees = reportingLine
      ?.filter((reportee) => {
        return reportee.alumni !== "YES";
      })
      .map((reportee) => {
        return { ...reportee, fullName: `${reportee.preferredFirstName} ${reportee.preferredLastName}` };
      });
    const sortedReportees = reportees?.sort((a, b) => {
      if (a?.fullName < b?.fullName) {
        return -1;
      }
      if (a?.fullName > b?.fullName) {
        return 1;
      }
      return 0;
    });
    setSortedReportingLine(sortedReportees);
  }, [reportingLine]);

  const handleOnOpen = () => {
    setOpen(true);
  };

  const handleOnClose = () => {
    setOpen(false);
  };

  return (
    <>
      {isLoading ? (
        <Loading title={ORG_STRUCTURE.Title} />
      ) : (
        <>
          <Card fluid>
            <Card.Content>
              <Card.Header>
                <div className="d-flex justify-content-between align-items-center">{ORG_STRUCTURE.Title}</div>
              </Card.Header>
            </Card.Content>
            {lineManager && (
              <>
                <div className="org-structure__header">{`${firstName} ${lastName}'s ${ORG_STRUCTURE.Manager}`}</div>
                <Card.Content>
                  <OrgMember member={organization?.lineManager} orgLoading={isLoading} />
                </Card.Content>
              </>
            )}
            {sortedReportingLine && sortedReportingLine.length > 0 && (
              <>
                <div className="org-structure__header">
                  {`${firstName} ${lastName}'s ${ORG_STRUCTURE.Organization}`} {`(${sortedReportingLine?.length})`}
                </div>
                {sortedReportingLine.slice(0, 5).map((reportee) => (
                  <Card.Content key={reportee.id}>
                    <OrgMember member={reportee} key={reportee.id} />
                  </Card.Content>
                ))}
              </>
            )}
            {sortedReportingLine?.length > 3 && (
              <Card.Content textAlign="center" extra>
                <span
                  role="button"
                  className="cursor-pointer"
                  tabIndex={0}
                  onClick={handleOnOpen}
                  onKeyPress={handleOnOpen}
                >
                  {COMMON.ViewAll}
                </span>
              </Card.Content>
            )}
          </Card>
          {open && (
            <OrgStructureViewAll
              open={open}
              onClose={handleOnClose}
              onOpen={handleOnOpen}
              reportingLine={sortedReportingLine}
              lineManager={lineManager}
              fullName={`${firstName} ${lastName}`}
            />
          )}
        </>
      )}
    </>
  );
}

OrgStructure.defaultProps = {
  profile: {},
  isLoading: false
};

OrgStructure.propTypes = {
  profile: PropTypes.shape({
    hrId: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string
  }),
  isLoading: PropTypes.bool
};

export default OrgStructure;
