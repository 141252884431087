import { expertiseList } from "redux/constants";

const expertiseListLoadingReducer = (state = false, action) => {
  switch (action.type) {
    case expertiseList.FETCH_EXPERTISE_SUMMARY:
      return true;
    case expertiseList.FETCH_EXPERTISE_SUMMARY_SUCCESS:
    case expertiseList.FETCH_EXPERTISE_SUMMARY_FAILURE:
      return false;
    default:
      return state;
  }
};

export default expertiseListLoadingReducer;
