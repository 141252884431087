import { profileViews } from "redux/constants";

const cvBioMaterialsViewsLoadingReducer = (state = false, action) => {
  switch (action.type) {
    case profileViews.FETCH_CV_BIO_MATERIALS_VIEWS_DETAILS:
      return true;
    case profileViews.FETCH_CV_BIO_MATERIALS_VIEWS_DETAILS_SUCCESS:
    case profileViews.FETCH_CV_BIO_MATERIALS_VIEWS_DETAILS_FAILURE:
      return false;
    default:
      return state;
  }
};

export default cvBioMaterialsViewsLoadingReducer;
