import React from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "semantic-ui-react";
import LABEL from "constants/label";
import "./styles.scss";

const {
  COMMON: { Close },
  LINKEDIN: { REGRET, FAILURE_INTEGRATION_MESSAGE, FAILURE_INTEGRATION_HELP_TEXT }
} = LABEL;

const FailureNotificationModal = ({ isOpen, onModalClose }) => {
  return (
    <Modal open={isOpen} centered closeOnDimmerClick={false} className="notification__custom__modal">
      <Modal.Content className="text-right p-1 d-flex flex-column">
        <div className="d-flex justify-content-center">
          <img src="/icons/exclamation-circle.svg" className="" alt="avatar" data-testid="avatar-image" />
        </div>
        <div className=" d-flex warningpopupcontent justify-content-center mt-2 fw-bold">{REGRET}</div>
        <div className="d-flex warningpopupcontent justify-content-center">{FAILURE_INTEGRATION_MESSAGE}</div>
        <div className="text-align-center mt-2 fs-12">{FAILURE_INTEGRATION_HELP_TEXT}</div>

        <div className="d-flex justify-content-center mt-2">
          <Button data-testid="close" primary className="button-sm mr-1" onClick={onModalClose}>
            {Close}
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  );
};

FailureNotificationModal.defaultProps = {
  isOpen: false
};

FailureNotificationModal.propTypes = {
  isOpen: PropTypes.bool,
  onModalClose: PropTypes.func.isRequired
};

export default FailureNotificationModal;
