import { childTopicsFinder } from "redux/constants";

const childTopicsFinderReducer = (state = {}, action) => {
  switch (action.type) {
    case childTopicsFinder.FETCH_CHILD_TOPICS_FINDER_SUCCESS: {
      return [...action.data];
    }
    default:
      return state;
  }
};

export default childTopicsFinderReducer;
