import { call, put } from "redux-saga/effects";
import { get } from "redux/api";
import { setResume, setResumeError } from "redux/actions/resume";

function* handleFetchProfileResumes({ payload }) {
  const { data, error } = yield call(get, payload);
  if (error) {
    yield put(setResumeError(error));
  } else {
    yield put(setResume(data));
  }
}

export default handleFetchProfileResumes;
