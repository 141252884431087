import { about } from "redux/constants";

const aboutAdditionalDataReducer = (state = null, action) => {
  switch (action.type) {
    case about.SET_ABOUT_ADDITIONAL_DATA: {
      return { ...state, ...action.data };
    }
    default:
      return state;
  }
};

export default aboutAdditionalDataReducer;
