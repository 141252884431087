import { pronunciation } from "redux/constants";

const savePronunciation = (payload) => ({
  type: pronunciation.SAVE_PRONUNCIATION,
  payload
});

const setSavePronunciationSuccess = (data) => ({
  type: pronunciation.SAVE_PRONUNCIATION_SUCCESS,
  data
});

const setPronunciationAudioId = (data) => ({
  type: pronunciation.SET_PRONUNCIATION_ID,
  data
});

const setSavePronunciationError = (error) => ({
  type: pronunciation.SAVE_PRONUNCIATION_FAILURE,
  error
});

const fetchPronunciation = (payload) => ({
  type: pronunciation.FETCH_PRONUNCIATION,
  payload
});

const setFetchPronunciationSuccess = (data) => ({
  type: pronunciation.FETCH_PRONUNCIATION_SUCCESS,
  data
});

const setFetchPronunciationError = (error) => ({
  type: pronunciation.FETCH_PRONUNCIATION_FAILURE,
  error
});

const deletePronunciation = (payload) => ({
  type: pronunciation.DELETE_PRONUNCIATION,
  payload
});

const setDeletePronunciationSuccess = (data) => ({
  type: pronunciation.DELETE_PRONUNCIATION_SUCCESS,
  data
});

const setDeletePronunciationError = (error) => ({
  type: pronunciation.DELETE_PRONUNCIATION_FAILURE,
  error
});

const resetPronunciationState = () => ({
  type: pronunciation.RESET_PRONUNCIATION_STATE
});

export {
  savePronunciation,
  fetchPronunciation,
  setSavePronunciationSuccess,
  setFetchPronunciationSuccess,
  setSavePronunciationError,
  setFetchPronunciationError,
  deletePronunciation,
  setDeletePronunciationSuccess,
  setDeletePronunciationError,
  resetPronunciationState,
  setPronunciationAudioId
};
