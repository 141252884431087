import { call, put } from "redux-saga/effects";
import { get } from "redux/api";
import { setError, setSkillTopicsFinder } from "redux/actions/finder/topicsSkillFinder";

function* handleFetchSkillTopicsFinder(endpoint) {
  const { data, error } = yield call(get, endpoint);
  if (error) {
    yield put(setError(error));
  } else {
    yield put(setSkillTopicsFinder(data));
  }
}

export default handleFetchSkillTopicsFinder;
