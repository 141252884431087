import { linkedIn } from "redux/constants";

const linkedInAuthStatusErrorReducer = (state = {}, action) => {
  switch (action.type) {
    case linkedIn.FETCH_LINKEDIN_AUTH_STATUS_FAILURE:
      return action.error;
    case linkedIn.FETCH_LINKEDIN_AUTH_STATUS_SUCCESS:
    case linkedIn.FETCH_LINKEDIN_AUTH_STATUS:
      return null;
    default:
      return state;
  }
};

export default linkedInAuthStatusErrorReducer;
