import { manageExpertise } from "redux/constants";

const expertiseUpdatingReducer = (state = false, action) => {
  switch (action.type) {
    case manageExpertise.UPDATE_EXPERTISE:
      return true;
    case manageExpertise.UPDATE_EXPERTISE_SUCCESS:
    case manageExpertise.UPDATE_EXPERTISE_FAILURE:
      return false;
    default:
      return state;
  }
};

export default expertiseUpdatingReducer;
