import { profileCompletion } from "redux/constants";

const profileCompletionLoadingReducer = (state = false, action) => {
  switch (action.type) {
    case profileCompletion.FETCH_PROFILE_COMPLETION:
      return true;
    case profileCompletion.FETCH_PROFILE_COMPLETION_SUCCESS:
    case profileCompletion.FETCH_PROFILE_COMPLETION_FAILURE:
      return false;
    default:
      return state;
  }
};

export default profileCompletionLoadingReducer;
