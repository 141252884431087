/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from "prop-types";
import styles from "./styles.module.scss";
import ICONS from "./icons.json";

/**
 * Icon component
 *
 * @param  {string} name
 * @param  {number} size - default: 16
 * @param  {string} defaultIcon - default: defaultDoc
 * @param  {string} className
 *
 * @return returns image with given icon name
 *
 * @example
 *
 * <Icon />
 */
function Icon({ name, size, defaultIcon, className, onIconClick, isValid }) {
  const file = ICONS[name] ? ICONS[name] : ICONS[defaultIcon];
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <img
      src={`/icons/${file}`}
      width={size}
      className={`${styles["bcg__icon"]} ${className} ${isValid ? "" : styles["disabled_icon"]}`}
      alt={file}
      style={{ minWidth: `${size}px` }}
      onClick={onIconClick}
    />
  );
}

Icon.defaultProps = {
  name: "defaultDoc",
  size: 16,
  defaultIcon: "defaultDoc",
  className: "",
  onIconClick: () => {},
  isValid: true
};

Icon.propTypes = {
  name: PropTypes.string,
  size: PropTypes.number,
  defaultIcon: PropTypes.string,
  className: PropTypes.string,
  onIconClick: PropTypes.func,
  isValid: PropTypes.bool
};

export default Icon;
