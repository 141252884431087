import { takeLatest } from "redux-saga/effects";

import { about } from "redux/constants";
import handleFetchAboutMeAllTerms from "../worker/handleFetchAboutMeAllTerms";

function* watchFetchAboutMeAllTerms() {
  yield takeLatest(about.FETCH_ABOUT_ME_ALL_TERMS, handleFetchAboutMeAllTerms);
}

export default watchFetchAboutMeAllTerms;
