import { resume } from "redux/constants";

const resumeReducer = (state = [], action) => {
  switch (action.type) {
    case resume.FETCH_RESUME_SUCCESS: {
      return [...action.data];
    }
    default:
      return state;
  }
};

export default resumeReducer;
