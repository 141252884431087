import { takeLatest } from "redux-saga/effects";

import { upload } from "redux/constants";
import handleUploadResumeToProfile from "../worker/handleUploadResumeToProfile";

function* watchUploadResumeToProfile() {
  yield takeLatest(upload.UPLOAD_RESUME_TO_PROFILE, handleUploadResumeToProfile);
}

export default watchUploadResumeToProfile;
