import { awardTypes } from "redux/constants";

const awardTypesLoadingReducer = (state = false, action) => {
  switch (action.type) {
    case awardTypes.FETCH_AWARD_TYPES:
      return true;
    case awardTypes.FETCH_AWARD_TYPES_SUCCESS:
    case awardTypes.FETCH_AWARD_TYPES_FAILURE:
      return false;
    default:
      return state;
  }
};

export default awardTypesLoadingReducer;
