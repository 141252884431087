import { takeLatest } from "redux-saga/effects";

import { pronunciation } from "redux/constants";
import handleDeletePronunciation from "../worker/handleDeletePronunciation";

function* watchDeletePronunciation() {
  yield takeLatest(pronunciation.DELETE_PRONUNCIATION, handleDeletePronunciation);
}

export default watchDeletePronunciation;
