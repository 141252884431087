import { cvBioDownloadDetails } from "redux/constants";

const cvBioDownloadDetailsLoadingReducer = (state = false, action) => {
  switch (action.type) {
    case cvBioDownloadDetails.FETCH_CV_BIO_DOWNLOAD_DETAILS:
      return true;
    case cvBioDownloadDetails.FETCH_CV_BIO_DOWNLOAD_DETAILS_SUCCESS:
    case cvBioDownloadDetails.FETCH_CV_BIO_DOWNLOAD_DETAILS_FAILURE:
      return false;
    default:
      return state;
  }
};

export default cvBioDownloadDetailsLoadingReducer;
