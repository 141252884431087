import { Card, Placeholder } from "semantic-ui-react";
import PropTypes from "prop-types";

/**
 * Loading
 * This is a loading screen
 *
 * @param  {string} title
 *
 * @return element containing loading animation
 *
 * @example
 *
 * <Loading title={title}  />
 */
const Loading = ({ title, className }) => {
  return (
    <Card fluid className={className}>
      {title ? (
        <Card.Content>
          <Card.Header>
            <div className="d-flex justify-content-between">{title}</div>
          </Card.Header>
        </Card.Content>
      ) : (
        <></>
      )}

      <Card.Content textAlign="center" className="content--no-info">
        <Placeholder fluid>
          <Placeholder.Header image>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Header>
          <Placeholder.Paragraph>
            <Placeholder.Line length="full" />
            <Placeholder.Line length="medium" />
          </Placeholder.Paragraph>
        </Placeholder>
      </Card.Content>
    </Card>
  );
};

Loading.defaultProps = {
  className: "",
  title: ""
};

Loading.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string
};

export default Loading;
