import { linkedIn } from "redux/constants";

const linkedInUserStatusErrorReducer = (state = null, action) => {
  switch (action.type) {
    case linkedIn.FETCH_USER_LINKEDIN_STATUS_FAILURE:
      return action.error;
    case linkedIn.FETCH_USER_LINKEDIN_STATUS:
    case linkedIn.FETCH_USER_LINKEDIN_STATUS_SUCCESS:
      return null;
    default:
      return state;
  }
};

export default linkedInUserStatusErrorReducer;
