import { takeLatest } from "redux-saga/effects";

import { awardNames } from "redux/constants";
import handleFetchAwardNames from "../worker/handleFetchAwardNames";

function* watchAwardNames() {
  yield takeLatest(awardNames.FETCH_AWARD_NAMES, handleFetchAwardNames);
}

export default watchAwardNames;
