import { upload } from "redux/constants";

const uploadResumeStart = (payload) => ({
  type: upload.UPLOAD_RESUME_START,
  payload
});

const uploadProposalBioStart = (payload) => ({
  type: upload.UPLOAD_PROPOSAL_BIO_START,
  payload
});

const uploadResumeToS3 = (payload) => ({
  type: upload.UPLOAD_RESUME_TO_S3,
  payload
});

const uploadProposalBioToS3 = (payload) => ({
  type: upload.UPLOAD_PROPOSAL_BIO_T0_S3,
  payload
});

const uploadResumeToProfile = (payload) => ({
  type: upload.UPLOAD_RESUME_TO_PROFILE,
  payload
});

const uploadProposalBioToProfile = (payload) => ({
  type: upload.UPLOAD_PROPOSAL_BIO_T0_PROFILE,
  payload
});

const setUploadResumeSuccess = (payload) => ({
  type: upload.UPLOAD_RESUME_SUCCESS,
  payload
});

const setUploadBioSuccess = (payload) => ({
  type: upload.UPLOAD_BIO_SUCCESS,
  payload
});

const setUploadResumeError = (payload) => ({
  type: upload.UPLOAD_RESUME_ERROR,
  payload
});

const setUploadBioError = (payload) => ({
  type: upload.UPLOAD_BIO_ERROR,
  payload
});

const updateProposalBio = (payload) => ({
  type: upload.UPDATE_PROPOSAL_BIO,
  payload
});

const setUpdateBioSuccess = (payload) => ({
  type: upload.UPDATE_PROPOSAL_BIO_SUCCESS,
  payload
});

const setUpdateBioError = (payload) => ({
  type: upload.UPDATE_PROPOSAL_BIO_FAILURE,
  payload
});

const updateResume = (payload) => ({
  type: upload.UPDATE_RESUME,
  payload
});

const setUpdateResumeSuccess = (payload) => ({
  type: upload.UPDATE_RESUME_SUCCESS,
  payload
});

const setUpdateResumeError = (payload) => ({
  type: upload.UPDATE_RESUME_FAILURE,
  payload
});

export {
  uploadResumeStart,
  uploadProposalBioStart,
  uploadResumeToS3,
  uploadProposalBioToS3,
  uploadResumeToProfile,
  uploadProposalBioToProfile,
  setUploadResumeSuccess,
  setUploadBioSuccess,
  setUploadResumeError,
  setUploadBioError,
  updateProposalBio,
  setUpdateBioSuccess,
  setUpdateBioError,
  updateResume,
  setUpdateResumeSuccess,
  setUpdateResumeError
};
