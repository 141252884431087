import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Grid, Placeholder } from "semantic-ui-react";
import { isEmptyOrNull } from "helpers/utils";

import CONFIG from "constants/config";
import ViewEditProfileCompletion from "components/ProfileCompletion/ViewEditProfileCompletion";
import { ViewerPercentage, OwnerPercentage } from "components/ProfileCompletion/ProfilePercentageCompletion";

import "./styles.scss";

const { exceptions } = CONFIG;

const ProfileCompletion = ({
  percentCompleted,
  toggelPercentCompleted,
  loggedInUserHrId,
  isVisitorView,
  isConfirmationInProgress
}) => {
  const {
    ccoData,
    profile,
    profileCompletionData,
    profileCompletionLoading,
    updateProfileCompletionLoading
  } = useSelector((state) => state);
  const { isECT, isKT, isSA } = profile;
  const ktStaffExceptions = exceptions.ktStaffExceptions.split(`|`);

  const isCT = !(
    (isECT || (!ktStaffExceptions.includes(profile?.hrId?.toString()) && isKT) || isSA) &&
    isEmptyOrNull(ccoData)
  );

  return (
    <Grid className="profile__completion">
      <>
        {profileCompletionLoading || updateProfileCompletionLoading || isConfirmationInProgress ? (
          <>
            <Grid.Row className="mt-1 p-1">
              <Placeholder style={{ minWidth: "200px" }}>
                <Placeholder.Line />
                <Placeholder.Line />
              </Placeholder>
            </Grid.Row>
          </>
        ) : (
          <>
            {isVisitorView ? (
              <ViewerPercentage
                percentCompleted={percentCompleted}
                profileCompletionLoading={profileCompletionLoading}
              />
            ) : (
              <>
                <OwnerPercentage
                  toggelPercentCompleted={toggelPercentCompleted}
                  percentCompleted={percentCompleted}
                  profileCompletionData={profileCompletionData}
                />
              </>
            )}
          </>
        )}
      </>
      <Grid.Row>
        <Grid.Column>
          {profileCompletionLoading || updateProfileCompletionLoading || isConfirmationInProgress ? (
            <Placeholder style={{ minWidth: "200px" }}>
              <Placeholder.Line />
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder>
          ) : (
            <ViewEditProfileCompletion
              isComplete={percentCompleted === 100}
              loggedInUserHrId={loggedInUserHrId}
              isCT={isCT}
              profile={profile}
              isVisitorView={isVisitorView}
            />
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

ProfileCompletion.propTypes = {
  percentCompleted: PropTypes.number.isRequired,
  toggelPercentCompleted: PropTypes.func.isRequired,
  loggedInUserHrId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isVisitorView: PropTypes.bool.isRequired,
  isConfirmationInProgress: PropTypes.bool.isRequired
};

export default ProfileCompletion;
