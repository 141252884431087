import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Label } from "semantic-ui-react";
import "./styles.scss";

function Tags({ tags, numberOfTagsToShow, showFull }) {
  const [displayedTags, setDisplayedTags] = useState([]);
  const [remainingTagsCount, setRemainingTagsCount] = useState(0);

  useEffect(() => {
    setDisplayedTags(tags?.slice(0, numberOfTagsToShow));
    setRemainingTagsCount(tags?.length - numberOfTagsToShow);
  }, [tags]);

  return (
    <div className="tags">
      {displayedTags?.map((tag) => (
        <Label data-testid="tags-label" key={tag} className={!showFull && "tag-limited"}>
          {tag}
        </Label>
      ))}
      {!!remainingTagsCount && <Label data-testid="additional-tags-label">+ {remainingTagsCount}</Label>}
    </div>
  );
}

Tags.defaultProps = {
  tags: [],
  numberOfTagsToShow: 2,
  showFull: false
};

Tags.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string),
  numberOfTagsToShow: PropTypes.number,
  showFull: PropTypes.bool
};

export default Tags;
