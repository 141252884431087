import { userStaffId } from "redux/constants";

const staffIdLoadingReducer = (state = false, action) => {
  switch (action.type) {
    case userStaffId.FETCH_USER_STAFF_ID:
      return true;
    case userStaffId.FETCH_USER_STAFF_ID_SUCCESS:
    case userStaffId.FETCH_USER_STAFF_ID_FAILURE:
      return false;
    default:
      return state;
  }
};

export default staffIdLoadingReducer;
