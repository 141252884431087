import { topics } from "redux/constants";

const businessSubjectsLoadingReducer = (state = false, action) => {
  switch (action.type) {
    case topics.GET_BUSINESS_SUBJECTS_DATA:
      return true;
    case topics.GET_BUSINESS_SUBJECTS_DATA_SUCCESS:
    case topics.GET_BUSINESS_SUBJECTS_DATA_FAILURE:
      return false;
    default:
      return state;
  }
};

export default businessSubjectsLoadingReducer;
