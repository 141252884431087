import { merge } from "lodash";
import { convertArrayToObject, isEmptyOrNull, mergeArrayObjects } from "helpers/utils";
import { memberList } from "redux/constants";

const memberListReducer = (state = {}, action) => {
  switch (action.type) {
    case memberList.FETCH_MEMBER_LIST_SUCCESS: {
      const employeeDataObject =
        (action?.data?.data?.data?.employees?.length &&
          convertArrayToObject(action?.data?.data?.data?.employees, "id")) ||
        {};
      const mergeObjectWithKeys = (obj1, obj2) => {
        const obj3 = {};
        for (const key in obj2) {
          obj3[key] = { ...obj1[key], ...obj2[key] };
        }
        return obj3;
      };
      const mergedObject = mergeObjectWithKeys({ ...state }, { ...employeeDataObject });
      return {
        ...state,
        ...mergedObject
      };
    }
    default:
      return state;
  }
};

export default memberListReducer;
