import { education } from "redux/constants";

const getEducation = (endpoint) => {
  return {
    type: education.FETCH_EDUCATION,
    endpoint
  };
};

const setEducation = (data) => ({
  type: education.FETCH_EDUCATION_SUCCESS,
  data
});

const setError = (error) => ({
  type: education.FETCH_EDUCATION_FAILURE,
  error
});

export { getEducation, setEducation, setError };
