import { entitlements } from "redux/constants";

const getEntitlements = (payload) => {
  return {
    type: entitlements.FETCH_ENTITLEMENTS,
    payload
  };
};

const setEntitlements = (data) => ({
  type: entitlements.FETCH_ENTITLEMENTS_SUCCESS,
  data
});

const setError = (error) => ({
  type: entitlements.FETCH_ENTITLEMENTS_FAILURE,
  error
});

export { getEntitlements, setEntitlements, setError };
