import React from "react";
import styles from "./styles.module.scss";

/**
 * @returns Pipe - vertical divider of height 30px
 *
 * @example
 * <Pipe />
 */

function Pipe() {
  return <div className={styles["vertical-divider"]} />;
}

export default Pipe;
