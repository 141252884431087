import { language } from "redux/constants";

const getLanguages = (payload) => {
  return {
    type: language.FETCH_LANGUAGE,
    payload
  };
};

const setLanguages = (data) => ({
  type: language.FETCH_LANGUAGE_SUCCESS,
  data
});

const setError = (error) => ({
  type: language.FETCH_LANGUAGE_FAILURE,
  error
});

export { getLanguages, setLanguages, setError };
