import CONFIG from "constants/config";
import * as utils from "@kdshared/okta-utils";
import { getOrgMemberImageList } from "redux/actions/organization";

const { apiUrl, xApiKey } = CONFIG;

const fetchOrgMemberImage = async (id, dispatch) => {
  dispatch(
    getOrgMemberImageList({
      endpoint: `${apiUrl.peopleImage}${id}`,
      config: {
        headers: {
          "x-api-key": `${xApiKey.people}`,
          psid: utils.getPsId()
        },
        responseType: "arraybuffer"
      },
      hrid: id,
      showToast: false
    })
  );
};

export default fetchOrgMemberImage;
