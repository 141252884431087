import { awardTypes } from "redux/constants";

const getAwardTypes = (payload) => {
  return {
    type: awardTypes.FETCH_AWARD_TYPES,
    payload
  };
};

const setAwardTypes = (data) => ({
  type: awardTypes.FETCH_AWARD_TYPES_SUCCESS,
  data
});

const setError = (error) => ({
  type: awardTypes.FETCH_AWARD_TYPES_FAILURE,
  error
});

export { getAwardTypes, setAwardTypes, setError };
