import { ssInternalMaterials } from "redux/constants";

const getSSInternalMaterials = (payload) => {
  return {
    type: ssInternalMaterials.FETCH_SSINTERNAL_MATERIALS,
    payload
  };
};

const setSSInternalMaterials = (data) => ({
  type: ssInternalMaterials.FETCH_SSINTERNAL_MATERIALS_SUCCESS,
  data
});

const setSSInternalMaterialsError = (error) => ({
  type: ssInternalMaterials.FETCH_SSINTERNAL_MATERIALS_FAILURE,
  error
});

export { getSSInternalMaterials, setSSInternalMaterials, setSSInternalMaterialsError };
