import { editProfile } from "redux/constants";

const profileUpdatingReducer = (state = false, action) => {
  switch (action.type) {
    case editProfile.UPDATE_PROFILE:
      return true;
    case editProfile.UPDATE_PROFILE_SUCCESS:
    case editProfile.UPDATE_PROFILE_FAILURE:
      return false;
    default:
      return state;
  }
};

export default profileUpdatingReducer;
