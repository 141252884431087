import PropTypes from "prop-types";
import React from "react";
import { Modal, Button, Grid } from "semantic-ui-react";
import LABEL from "constants/label";
import OrgMember from "components/common/OrgMember.js";
import "./styles.scss";

/**
 * OrgStructureViewAll - View all members of Org structure in a Modal
 *
 * @param  {boolean} open tells if the Modal is shown
 * @param  {Array} reportingLine array of the reportees
 * @param  {Object} lineManager line manager object
 * @param  {string} fullName full name of the user
 * @param  {func} onClose called when the model is closed
 * @param  {func} onOpen called when the model is opened
 *
 *
 * @return OrgStructureViewAll Component
 *
 * @example  <OrgStructureViewAll open={true} reportingLine={reportingLine} lineManager={lineManager} fullName="John Doe" onClose={onClose} onOpen={onOpen}/>
 */

const { COMMON, ORG_STRUCTURE } = LABEL;
function OrgStructureViewAll({ open, reportingLine, lineManager, fullName, onClose, onOpen }) {
  return (
    <>
      <Modal
        onClose={onClose}
        onOpen={onOpen}
        open={open}
        centered={false}
        closeOnDimmerClick={false}
        className="custom-modal custom-modal-sm"
      >
        <Modal.Header className="org-structure__heading">{ORG_STRUCTURE.Title}</Modal.Header>
        <Modal.Content className="content position-relative mt-0 pb-h">
          <Grid className="org-structure__grid">
            {lineManager && (
              <>
                <Grid.Row className="org-structure__header mt-0 pt-h pb-h pl-1">
                  <p>{`${fullName}'s ${ORG_STRUCTURE.Manager}`}</p>
                </Grid.Row>
                <Grid.Row columns={2} padded="horizontally" className="mt-h mb-h">
                  <Grid.Column computer={8} mobile={16} tablet={16}>
                    <OrgMember isBordered member={lineManager} />
                  </Grid.Column>
                </Grid.Row>
              </>
            )}
            {reportingLine && reportingLine?.length > 0 && (
              <>
                <Grid.Row className="org-structure__header mt-0 pt-h pb-h pl-1">
                  <p>{`${fullName}'s ${ORG_STRUCTURE.Organization} (${reportingLine?.length})`}</p>
                </Grid.Row>
                <Grid.Row columns={2} padded="horizontally" className="org-structure__members mb-1 pb-0">
                  {reportingLine?.map((reportee) => {
                    return (
                      <Grid.Column computer={8} mobile={16} tablet={16} className="mb-1" key={reportee.id}>
                        <OrgMember isBordered member={reportee} key={reportee.id} />
                      </Grid.Column>
                    );
                  })}
                </Grid.Row>
              </>
            )}
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button basic secondary onClick={onClose}>
            {COMMON.Close}
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
}

OrgStructureViewAll.defaultProps = {
  open: false,
  reportingLine: [],
  fullName: "",
  lineManager: {},
  onOpen: () => {},
  onClose: () => {}
};

OrgStructureViewAll.propTypes = {
  open: PropTypes.bool,
  lineManager: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string
  }),
  reportingLine: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string
    })
  ),
  fullName: PropTypes.string,
  onOpen: PropTypes.func,
  onClose: PropTypes.func
};

export default OrgStructureViewAll;
