import PropTypes from "prop-types";
import { useState } from "react";
import { Header, Progress, Checkbox } from "semantic-ui-react";
import Popover from "components/common/Popover";
import styles from "./styles.module.scss";

/**
 * ExpertiseBlock component
 *
 * @param  {object} item Expertise item
 * @param  {boolean} editMode Edit state
 * @param  {function} callback Function envokes on checkbox state change
 * @param  {bool} checkboxDisabled Prop to disabled other checkbox if count is 3
 *
 * @return returns expertise title with progress bar
 *
 * @example
 *
 * <ExpertiseBlock />
 */
const ExpertiseBlock = ({ item, editMode, callback, checkboxDisabled }) => {
  // Checkbox state
  const [check, setCheck] = useState(item.isEnabled);

  const { score = 0, topicName = "" } = item;

  if (!topicName) {
    return null;
  }

  const toggleCheckbox = (itemObj) => {
    setCheck((prevState) => !prevState);
    callback(!check, itemObj);
  };

  return (
    <div className={`${styles["c-expertise"]} `}>
      <div className={`${styles["c-expertise__heading"]} d-flex align-items-center`}>
        <Popover
          trigger={
            <Header
              as="h5"
              className={`${styles["c-expertise__title"]} ${
                editMode ? `mb-3 text-uppercase ${styles["c-expertise__title--fixedWidth"]}` : "mb-1"
              }`}
            >
              {topicName}
            </Header>
          }
          on="hover"
          inverted
          position="top center"
          size="tiny"
          popper={{ className: "md top" }}
        >
          {topicName}
        </Popover>
        <Header as="h2" className={styles["c-expertise__percentage"]}>
          {Math.round(score)}%
        </Header>
        {editMode ? (
          <Checkbox
            data-testid="checkbox"
            className={`${styles["c-expertise__checkbox"]}`}
            checked={check}
            onChange={() => toggleCheckbox(item)}
            label="Show Expertise"
            disabled={!check && checkboxDisabled}
          />
        ) : null}
      </div>

      <Progress percent={score} size="tiny" className="primary" />
    </div>
  );
};

ExpertiseBlock.defaultProps = {
  item: {},
  editMode: false,
  callback: () => {},
  checkboxDisabled: false
};

ExpertiseBlock.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    proficiency: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ranking: PropTypes.number,
    score: PropTypes.number,
    topicName: PropTypes.string,
    totalNumberOfExperts: PropTypes.number,
    isEnabled: PropTypes.bool
  }),
  editMode: PropTypes.bool,
  callback: PropTypes.func,
  checkboxDisabled: PropTypes.bool
};

export default ExpertiseBlock;
