import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { Card, Button } from "semantic-ui-react";
import PropTypes from "prop-types";
import { formatDateMonthDDYear, isEmptyOrNull, sortArrObjectByDate } from "helpers/utils";
import LABEL from "constants/label";
import { Loading, Icon } from "components/common";
import sendEventData from "analytics/analytics";
import { EVENTS, TRIGGERS } from "analytics/constants";
import { getAwardNames } from "redux/actions/awardNames";
import CONFIG from "constants/config";
import EditButton from "components/common/EditButton";
import { getAwardTypes } from "redux/actions/awardTypes";
import { addExpiredParam, getAwardIconName, sortBy } from "components/Achievements/helper/utils";
import NoContent from "../common/NoContent";
import ViewAll from "../common/ViewAll";
import ViewEditCredentials from "./ViewEditCredentials";
import "./styles.scss";

const {
  ACHIEVEMENTS: {
    ViewMoreDetails,
    NoBcgCredAdded,
    AddBcgCredential,
    AddExternalCred,
    NoExternalCredAdded,
    ExternalCred,
    BCGCred,
    Credentials,
    Awards,
    BCG,
    NoAwardAdded,
    AddAward,
    DateReceived,
    SortOrder,
    ExternalCredSource,
    BcgCredSource
  },
  COMMON
} = LABEL;

/**
 *
 *  @param {object} profile object which has the profile details of a given person
 *  @param {boolean} isEditable indicates whether the page is editable or not
 *  @param {boolean} isLoading loading state
 *
 *
 * @returns Achievement component
 *
 * @example
 * <Achievements profile={profile} isEditable={true} />
 */

const { apiUrl, xApiKey } = CONFIG;
function Achievements({ isEditable, isLoading }) {
  const dispatch = useDispatch();
  const { achievements } = useSelector((state) => state);
  const { award = [], certification = [] } = achievements;
  const awardTypesName = useSelector((state) => state.awardTypes?.awardTypesList);
  const awardNames = useSelector((state) => state.awardNames?.awardNamesList);
  const { achievementsLoading } = useSelector((state) => state);
  const [open, setOpen] = useState(false);
  const [bcgCredentials, setBcgCredentials] = useState([]);
  const [externalCredentials, setExternalCredentials] = useState([]);
  const [awards, setAwards] = useState([]);

  // logic to add expired param, sort & segregate records
  useEffect(() => {
    if (achievements?.certification) {
      const externalCreds = certification?.filter((cert) => cert?.source === ExternalCredSource);
      const internalCreds = certification?.filter((cert) => cert?.source === BcgCredSource);
      const decoratedExternalCreds = addExpiredParam(externalCreds);
      const decoratedInternalCreds = addExpiredParam(internalCreds);
      if (isEditable) {
        setExternalCredentials(sortArrObjectByDate(decoratedExternalCreds, DateReceived) || []);
        setBcgCredentials(sortArrObjectByDate(decoratedInternalCreds, DateReceived) || []);
      } else {
        setBcgCredentials(
          sortArrObjectByDate(
            decoratedInternalCreds?.filter((cert) => !cert?.expired && !cert?.hidden),
            DateReceived
          ) || []
        );
        setExternalCredentials(
          sortArrObjectByDate(
            decoratedExternalCreds?.filter((cert) => !cert?.expired),
            DateReceived
          ) || []
        );
      }
    }
    if (achievements?.award) {
      setAwards(sortArrObjectByDate(award, DateReceived) || []);
    }
  }, [achievements, isEditable]);

  useEffect(() => {
    dispatch(
      getAwardNames({
        endpoint: `${apiUrl.linkApi}profile/ref/awardname`,
        config: {
          headers: {
            "x-api-key": `${xApiKey.profile}`
          }
        }
      })
    );
    dispatch(
      getAwardTypes({
        endpoint: `${apiUrl.linkApi}profile/ref/awardtype`,
        config: {
          headers: {
            "x-api-key": `${xApiKey.profile}`
          }
        }
      })
    );
  }, []);

  useEffect(() => {
    if (!isEmptyOrNull(awards) && !isEmptyOrNull(awardNames) && !isEmptyOrNull(awardTypesName)) {
      setAwards((prevState) => {
        const newData = [...prevState];
        return newData?.map((awardData) => {
          const newAwardData = { ...awardData };
          if (!newAwardData?.name) {
            newAwardData.name = awardNames?.filter(
              (record) => record?.awardNameId === newAwardData?.awardNameRefId
            )[0].name;
          }
          newAwardData.awardTypeName = awardTypesName?.filter(
            (record) => record?.awardTypeId === newAwardData?.awardType && newAwardData?.location === BCG
          )[0]?.name;
          return newAwardData;
        });
      });
    }
  }, [awardNames, awardTypesName, achievements?.award]);

  const handleOnEditClick = () => {
    sendEventData(TRIGGERS.COMMON.EDIT, EVENTS.CRED_SECTION, {
      sectionName: Credentials
    }); // Analytics Event
    setOpen(true);
  };

  const handleOnViewAllClick = () => {
    sendEventData(TRIGGERS.COMMON.VIEW_ALL, EVENTS.CRED_SECTION, {
      sectionName: Credentials
    }); // Analytics Event
    setOpen(true);
  };

  const handleOnAddClick = () => {
    sendEventData(TRIGGERS.DIGITIAL_CREDENTIALS.ADD_AWARDS, EVENTS.PROFILE_SUMMARY, {
      sectionName: Credentials
    }); // Analytics Event
    setOpen(true);
  };

  const handleOnCertAddClick = () => {
    sendEventData(TRIGGERS.DIGITIAL_CREDENTIALS.ADD_CERTIFICATE, EVENTS.CRED_SECTION, {
      sectionName: Credentials
    }); // Analytics Event
    setOpen(true);
  };

  const handleOnCloseClick = () => {
    setOpen(false);
  };

  const getAwardType = (awardData) => {
    return awardTypesName?.filter((record) => record.awardTypeId === awardData.awardType)[0]?.name;
  };

  const featuredAwards =
    (awards?.length &&
      [...awards?.filter((record) => record?.featured), ...awards?.filter((record) => !record?.featured)]?.slice(
        0,
        1
      )) ||
    [];
  const featuredExternalCreds =
    (externalCredentials?.length &&
      [
        ...sortBy(
          externalCredentials?.filter((cred) => cred.featured && !cred.expired),
          SortOrder,
          false
        ),
        ...externalCredentials?.filter((cred) => !cred?.featured && !cred.expired)
      ]?.slice(0, 2)) ||
    [];
  const featuredBcgCreds =
    (bcgCredentials?.filter((cred) => !cred?.hidden)?.length &&
      [
        ...sortBy(
          bcgCredentials?.filter((cred) => !cred?.hidden && cred.featured && !cred.expired),
          SortOrder,
          false
        ),
        ...bcgCredentials?.filter((cred) => !cred?.featured && !cred?.hidden && !cred.expired)
      ]?.slice(0, 2)) ||
    [];

  return (
    <>
      {(!achievementsLoading && !isLoading && (
        <Card>
          <Card.Content>
            <Card.Header>
              <div className="d-flex justify-content-between align-items-center">
                {Credentials}
                {isEditable && (
                  <EditButton
                    caption={COMMON.Edit}
                    callback={handleOnEditClick}
                    isDisabled={!(award?.length || certification?.length)}
                  />
                )}
              </div>
            </Card.Header>
          </Card.Content>
          {/* BCG Credential Block */}
          {(!isEmptyOrNull(featuredBcgCreds) &&
            featuredBcgCreds?.map((bcgCredential, index) => (
              <Card.Content key={bcgCredential?.certificationId}>
                {index === 0 && (
                  <div className="d-flex justify-content-between align-items-center achievement__heading">
                    <strong>{BCGCred}</strong>
                  </div>
                )}
                <div className="achievement__content d-flex">
                  <div className="achievement__image" data-testid="bcgCredentialIcon">
                    {bcgCredential?.addedByCredly ? (
                      <img src={bcgCredential?.imageURL} width="30" height="30" alt="credlyLogo" />
                    ) : (
                      <Icon name="internalCredential" size={30} />
                    )}
                  </div>
                  <div className="achievement__text">
                    <div className="achievement__title">
                      <p>
                        <strong>{bcgCredential?.name}</strong>
                      </p>
                    </div>
                    <div className="achievement__date">
                      {` ${(bcgCredential?.authority && bcgCredential.authority + " -") || ""}  ${formatDateMonthDDYear(
                        bcgCredential?.dateReceived,
                        true
                      )}`}
                    </div>
                  </div>
                </div>
              </Card.Content>
            ))) || (
            <NoContent
              message={NoBcgCredAdded}
              buttonText={AddBcgCredential}
              isEditable={isEditable}
              onClick={handleOnCertAddClick}
            />
          )}

          {/* External Credentail Block */}
          {(!isEmptyOrNull(featuredExternalCreds) &&
            featuredExternalCreds?.map((externalCredential, index) => (
              <Card.Content key={externalCredential.certificationId}>
                {index === 0 && (
                  <div className="d-flex justify-content-between align-items-center achievement__heading">
                    <strong>{ExternalCred}</strong>
                  </div>
                )}
                <div className="achievement__content d-flex">
                  <div className="achievement__image" data-testid="externalCredentialIcon">
                    <Icon name="externalCredential" size={30} />
                  </div>
                  <div className="achievement__text">
                    <div className="achievement__title">
                      <p>
                        <strong>{externalCredential.name}</strong>
                      </p>
                    </div>
                    <div className="achievement__date">
                      {` ${
                        (externalCredential?.authority && externalCredential.authority + " -") || ""
                      }  ${formatDateMonthDDYear(externalCredential?.dateReceived, true)}`}
                    </div>
                  </div>
                </div>
              </Card.Content>
            ))) || (
            <NoContent
              message={NoExternalCredAdded}
              buttonText={AddExternalCred}
              isEditable={isEditable}
              onClick={handleOnCertAddClick}
            />
          )}

          {/* Awards Block */}
          {(!isEmptyOrNull(featuredAwards) &&
            featuredAwards?.map((record, index) => (
              <Card.Content key={record.id}>
                {index === 0 && (
                  <div className="d-flex justify-content-between align-items-center achievement__heading">
                    <strong>{Awards}</strong>
                  </div>
                )}
                <div className="achievement__content d-flex">
                  <div className="achievement__image" data-testid="awardIcon">
                    <Icon name={getAwardIconName(record)} size={30} />
                  </div>
                  <div className="achievement__text">
                    <div className="achievement__title">
                      {(record.awardSiteUrl && (
                        <a
                          href={record.awardSiteUrl}
                          target="_blank"
                          rel="noreferrer"
                          data-testid="awardSiteUrl"
                          onClick={() => {
                            sendEventData(record.name, EVENTS.ACHIEVEMENTS_SECTION); // Analytics Event
                          }}
                        >
                          <strong>{record.name}</strong>
                        </a>
                      )) || (
                        <p>
                          <strong>{record.name}</strong>
                        </p>
                      )}
                    </div>
                    <div className="achievement__date">
                      {record.location} {getAwardType(record)} - {formatDateMonthDDYear(record.dateReceived, true)}
                    </div>
                  </div>
                </div>
              </Card.Content>
            ))) || (
            <NoContent
              message={NoAwardAdded}
              buttonText={AddAward}
              isEditable={isEditable}
              onClick={handleOnAddClick}
            />
          )}

          {(!!award?.length || !!certification?.length) && (
            <ViewAll handleOnClick={handleOnViewAllClick} text={ViewMoreDetails} />
          )}
        </Card>
      )) || <Loading title={Credentials} />}
      {open && (
        <ViewEditCredentials
          open={open}
          onClose={handleOnCloseClick}
          isEditable={isEditable}
          bcgCredentials={bcgCredentials}
          externalCredentials={externalCredentials}
          awards={awards}
          setBcgCredentials={setBcgCredentials}
          setExternalCredentials={setExternalCredentials}
          setAwards={setAwards}
        />
      )}
    </>
  );
}

Achievements.defaultProps = {
  profile: {},
  isEditable: false,
  isLoading: false
};

Achievements.propTypes = {
  profile: PropTypes.shape({
    hrId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    percentComplete: PropTypes.number,
    incompleteFields: PropTypes.arrayOf(PropTypes.string),
    numberOfCases: PropTypes.number
  }),
  isEditable: PropTypes.bool,
  isLoading: PropTypes.bool
};

export default Achievements;
