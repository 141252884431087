import { achievement } from "redux/constants";

const achievementsErrorReducer = (state = {}, action) => {
  switch (action.type) {
    case achievement.FETCH_ACHIEVEMENT_FAILURE:
      return action.error;
    case achievement.FETCH_ACHIEVEMENT_SUCCESS:
    case achievement.FETCH_ACHIEVEMENT:
      return null;
    default:
      return state;
  }
};

export default achievementsErrorReducer;
