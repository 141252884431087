import { topics } from "redux/constants";

const topicSummaryDeletingReducer = (state = false, action) => {
  switch (action.type) {
    case topics.DELETE_TOPICSUMMARY:
      return true;
    case topics.DELETE_TOPICSUMMARY_SUCCESS:
    case topics.DELETE_TOPICSUMMARY_FAILURE:
      return false;
    default:
      return state;
  }
};

export default topicSummaryDeletingReducer;
