export const sortProposalsByDate = (arr) => {
  arr.sort((a, b) => {
    return new Date(b.createdDate) - new Date(a.createdDate);
  });
};

export const getExtension = (str) => {
  const index = str.lastIndexOf(".");
  let extension = "default";

  if (index !== -1) {
    extension = str.substr(index + 1);
  }

  return extension;
};
