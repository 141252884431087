import React, { useEffect } from "react";
import { Container } from "semantic-ui-react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { generateId } from "helpers/utils";
import EditWorkDayButton from "components/EditWorkDayButton/EditWorkDayButton";
import loadWalkme from "scripts/loadWalkme";
import Pipe from "../common/Pipe";
import "./styles.scss";

/**
 * @param {node} BannerImage profile image of the user
 * @param {node} LOA LOA component to be shown if the person is on leave of absence
 * @param {node} Alumni Alumni Component to be shown if the person is Alumni
 * @param {node} BannerHeader Heading of the banner rendered on top
 * @param {arrayOf(PropTypes.node)} ContactIcons contact icons such as phone, slack, mail ect
 * @param {node} SettingIcon setting icon with the content
 * @param {arrayOf(PropTypes.node)} BannerDetails array of components shown on the bottom of banner
 * @param {node} Assistant Assistant information
 * @param {node} AssistantTo AssistantTo information
 *
 * @returns Banner Component
 *
 * @example
 * <Banner BannerImage={<img src=""/>}
 *  ContactIcons={[<Icon name="phone" circular size="large" />, <Icon name="email" circular size="large" />]}
 *  BannerHeader={<H2>Header</h2>}
 *  BannerDetails={[<p>Item1<p>,<p>Item2<p>,<p>Item3<p>]}/>
 */

const Banner = ({
  BannerImage,
  LOA,
  Alumni,
  BannerHeader,
  ContactIcons,
  SettingIcon,
  BannerDetails,
  Assistant,
  AssistantTo,
  Anonymized,
  showEditWorkDayButton
}) => {
  const id = generateId();

  const { peopleData } = useSelector((state) => state);

  useEffect(() => {
    if (peopleData?.id) loadWalkme(); // load Walkme Script
  }, [peopleData]);

  return (
    <div className={`banner ${Anonymized ? "position-relative" : ""}`}>
      {/* Anonymized Profile */}
      {Anonymized}
      {/* Anonymized Profile End */}
      <Container className="banner__container">
        <div className="banner__image d-flex">
          {BannerImage}
          <div className="banner__profile-status banner--web-visible banner--mobile-hidden">
            {LOA}
            {Alumni}
          </div>
        </div>
        <div className="banner__content">
          <div className="banner__content--top d-flex justify-content-between">
            <div className="banner__heading">{BannerHeader}</div>
            <div className="banner__icons">
              {showEditWorkDayButton && <EditWorkDayButton />}
              <div className="banner__icons--contact icon-contact">
                {ContactIcons.map((item) => (
                  <React.Fragment key={id.next().value}>{item}</React.Fragment>
                ))}
              </div>
              {/* For UAT only, need to remove env condition after that */}
              {SettingIcon && (
                <>
                  <Pipe />
                  <div className="banner__icons--settings icon-setting">{SettingIcon}</div>
                </>
              )}
            </div>
          </div>
          <div className="banner__content--bottom d-flex">
            <div className={`banner__profile--details ${Anonymized ? "banner--has-anon" : ""}`}>
              {BannerDetails.map((item) => (
                <React.Fragment key={id.next().value}>{item}</React.Fragment>
              ))}
            </div>
            <div className={`banner__assistant--info ${Alumni || LOA ? "banner--has-loa" : ""}`}>
              {Assistant || AssistantTo}
            </div>
            <div className="banner__profile-status banner--mobile-visible banner--web-hidden">
              {LOA}
              {Alumni}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

Banner.defaultProps = {
  SettingIcon: null,
  Assistant: null,
  AssistantTo: null,
  Alumni: null,
  LOA: null,
  Anonymized: null
};

Banner.propTypes = {
  BannerImage: PropTypes.node.isRequired,
  BannerHeader: PropTypes.node.isRequired,
  ContactIcons: PropTypes.arrayOf(PropTypes.node).isRequired,
  BannerDetails: PropTypes.arrayOf(PropTypes.node).isRequired,
  SettingIcon: PropTypes.node,
  Assistant: PropTypes.node,
  AssistantTo: PropTypes.node,
  LOA: PropTypes.node,
  Alumni: PropTypes.node,
  Anonymized: PropTypes.node,
  showEditWorkDayButton: PropTypes.bool.isRequired
};

export default Banner;
