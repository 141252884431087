import { call, put } from "redux-saga/effects";
import { get } from "redux/api";
import { setPeopleImage, setPeopleImageError } from "redux/actions/people";

function* handleFetchPeopleImage({ payload }) {
  const { data, error } = yield call(get, payload);
  if (error) {
    yield put(setPeopleImageError(error));
  } else {
    yield put(setPeopleImage({ data, payload }));
  }
}

export default handleFetchPeopleImage;
