import { topics } from "redux/constants";

const getTopics = (endpoint) => {
  return {
    type: topics.FETCH_TOPIC,
    endpoint
  };
};

const setTopics = (data) => ({
  type: topics.FETCH_TOPIC_SUCCESS,
  data
});

const setError = (error) => ({
  type: topics.FETCH_TOPIC_FAILURE,
  error
});

const updateTopic = (payload) => ({
  type: topics.UPDATE_TOPIC,
  payload
});

const setUpdateTopicSuccess = (data) => ({
  type: topics.UPDATE_TOPIC_SUCCESS,
  data
});

const setUpdateTopicError = (error) => ({
  type: topics.UPDATE_TOPIC_FAILURE,
  error
});

const deleteTopic = (payload) => ({
  type: topics.DELETE_TOPIC,
  payload
});

const setDeleteTopicSuccess = (data) => ({
  type: topics.DELETE_TOPIC_SUCCESS,
  data
});

const setDeleteTopicError = (error) => ({
  type: topics.DELETE_TOPIC_FAILURE,
  error
});

//* To be removed//
const deleteTopicSummary = (payload) => ({
  type: topics.DELETE_TOPICSUMMARY,
  payload
});
//* To be removed//
const setDeleteTopicSummarySuccess = (data) => ({
  type: topics.DELETE_TOPICSUMMARY_SUCCESS,
  data
});
//* To be removed//
const setDeleteTopicSummaryError = (error) => ({
  type: topics.DELETE_TOPICSUMMARY_FAILURE,
  error
});

const getBusinessAndSubjectData = (payload) => ({
  type: topics.GET_BUSINESS_SUBJECTS_DATA,
  payload
});

const setBusinessAndSubjectDataSuccess = (data) => ({
  type: topics.GET_BUSINESS_SUBJECTS_DATA_SUCCESS,
  data
});

const setBusinessAndSubjectDataFailure = (error) => ({
  type: topics.GET_BUSINESS_SUBJECTS_DATA_FAILURE,
  error
});

export {
  getTopics,
  setTopics,
  setError,
  updateTopic,
  setUpdateTopicSuccess,
  setUpdateTopicError,
  deleteTopic,
  setDeleteTopicSuccess,
  setDeleteTopicError,
  deleteTopicSummary,
  setDeleteTopicSummarySuccess,
  setDeleteTopicSummaryError,
  getBusinessAndSubjectData,
  setBusinessAndSubjectDataSuccess,
  setBusinessAndSubjectDataFailure
};
