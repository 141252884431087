import { call, put as putSaga } from "redux-saga/effects";
import { put } from "redux/api";
import { setUpdateCaseTopicSuccess, setUpdateCaseTopicsError } from "redux/actions/cases";
import { apiErrorCodes } from "constants/index";

function* handleUpdateCaseTopics({ payload }) {
  const { res, error } = yield call(put, payload);
  if (apiErrorCodes.includes(res?.data?.error?.code || error)) {
    yield putSaga(setUpdateCaseTopicsError(res?.data?.error));
    return;
  }
  yield putSaga(setUpdateCaseTopicSuccess(res));
}

export default handleUpdateCaseTopics;
