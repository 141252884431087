import { takeLatest } from "redux-saga/effects";

import { pronunciation } from "redux/constants";
import handleSavePronunciation from "../worker/handleSavePronunciation";

function* watchSavePronunciation() {
  yield takeLatest(pronunciation.SAVE_PRONUNCIATION, handleSavePronunciation);
}

export default watchSavePronunciation;
