import { awardNames } from "redux/constants";

const awardNamesErrorReducer = (state = {}, action) => {
  switch (action.type) {
    case awardNames.FETCH_AWARD_NAMES_FAILURE:
      return action.error;
    case awardNames.FETCH_AWARD_NAMES_SUCCESS:
    case awardNames.FETCH_AWARD_NAMES:
      return null;
    default:
      return state;
  }
};

export default awardNamesErrorReducer;
