import { cases } from "redux/constants";

const { STATUS } = cases;

const updateCaseTopicsReducer = (state = "", action) => {
  switch (action.type) {
    case cases.UPDATE_CASE_TOPICS:
      return STATUS.SAVING;
    case cases.UPDATE_CASE_TOPICS_SUCCESS:
      return STATUS.SAVE_SUCCESS;
    case cases.UPDATE_CASE_TOPICS_ERROR:
      return STATUS.SAVE_FAILURE;
    case cases.RESET_CASE_TOPICS_SKILL_STATUS:
      return "";
    default:
      return state;
  }
};

export default updateCaseTopicsReducer;
