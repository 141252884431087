import { call, put as putSaga } from "redux-saga/effects";
import { put } from "redux/api";
import { setUpdateAboutECTSuccess, setUpdateAboutECTError } from "redux/actions/about";

function* handleUpdateAboutECT({ payload }) {
  const { data, error } = yield call(put, payload);
  if (error) {
    yield putSaga(setUpdateAboutECTError(error));
  } else {
    yield putSaga(setUpdateAboutECTSuccess(data));
  }
}

export default handleUpdateAboutECT;
