import { call, put } from "redux-saga/effects";
import { get } from "redux/api";
import { setPracticeAreaOptionsError, setPracticeAreaOptionsSuccess } from "redux/actions/about";

function* handleFetchPracticeAreaOptions({ payload }) {
  const { data, error } = yield call(get, payload);
  if (error) {
    yield put(setPracticeAreaOptionsError(error));
  } else {
    yield put(setPracticeAreaOptionsSuccess(data));
  }
}

export default handleFetchPracticeAreaOptions;
