import { call, put } from "redux-saga/effects";
import { get } from "redux/api";
import { setSSMaterials, setSSMaterialsError } from "redux/actions/ssMaterials";

function* handleFetchSSCases({ payload }) {
  const { data, error } = yield call(get, payload);
  if (error) {
    yield put(setSSMaterialsError(error));
  } else {
    yield put(setSSMaterials(data));
  }
}

export default handleFetchSSCases;
