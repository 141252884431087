import { call, put } from "redux-saga/effects";
import { get } from "redux/api";
import { setExpertiseList, setError } from "redux/actions/expertiseList";

function* handleFetchExpertiseList(endpoint) {
  const { data, error } = yield call(get, endpoint);
  if (error) {
    yield put(setError(error));
  } else {
    yield put(setExpertiseList(data));
  }
}

export default handleFetchExpertiseList;
