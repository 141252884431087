import LABEL from "constants/label";
import { Icon, Popover } from "components/common";
import { useState, useEffect } from "react";
import { Button, Checkbox, Divider } from "semantic-ui-react";
import CONFIG from "constants/config";
import { updateProfile } from "redux/actions/editProfile";
import { getProfile } from "redux/actions/profile";
import { toastSuccess, toastError, isEmptyOrNull } from "helpers/utils";
import { useSelector, useDispatch } from "react-redux";
import "./styles.scss";

const {
  BANNER: { EditWorkDayFields, GoToWorkDay }
} = LABEL;

const {
  apiUrl,
  xApiKey,
  workDay: { editWorkDayUrl }
} = CONFIG;

const EditWorkDayButton = () => {
  const dispatch = useDispatch();
  const [isUpdated, setIsUpdated] = useState(false);
  const { profile, profileUpdating, profileUpdatingError } = useSelector((state) => state);
  const [showBirthday, setShowBirthday] = useState(!profile?.birthdateOptout);

  useEffect(() => {
    setShowBirthday(!profile?.birthdateOptout);
  }, [profile?.birthdateOptout]);

  const toggleBirthdayState = () => {
    setIsUpdated(true);
    setShowBirthday(!showBirthday);
    try {
      dispatch(
        updateProfile({
          endpoint: `${apiUrl.linkApi}profile/${profile?.hrId}/basic`,
          data: { ...profile, birthdateOptout: showBirthday },
          config: {
            headers: {
              "x-api-key": `${xApiKey.profile}`
            }
          }
        })
      );
    } catch (e) {
      toastError();
      setIsUpdated(false);
    }
  };

  useEffect(() => {
    if (!profileUpdating && isUpdated) {
      if (isEmptyOrNull(profileUpdatingError)) {
        setIsUpdated(false);
        toastSuccess();
        dispatch(
          getProfile({
            endpoint: `${apiUrl.linkApi}profile/${profile?.hrId}/basic`,
            config: {
              headers: {
                "x-api-key": `${xApiKey.profile}`
              }
            }
          })
        );
      } else {
        toastError();
        setIsUpdated(false);
      }
    }
  }, [profileUpdating]);

  return (
    <Popover
      trigger={
        <div className="d-flex text-uppercase cursor-pointer editWorkDayButton" data-testid="editWorkDayButton">
          {EditWorkDayFields}
        </div>
      }
      on="hover"
      position="bottom left"
      popper={{ className: "tags-tooltip cursor-pointer editWorkDayButton__editWorkDayPopOverContent" }}
      size="large"
    >
      <div>
        <div>Profile photo, Mobile Number, Experience, Education and Language details can be updated on workday</div>
        <Button
          data-testid="goToWorkDay"
          onClick={() => window.open(editWorkDayUrl, "_blank")}
        >{`${GoToWorkDay} >`}</Button>
        <div className="d-flex">
          <Icon name="circle-exclamation" size={16} />
          <span>It will take 24 hours for edits on workday to show on profiles and 7 days for profile picture.</span>
        </div>
        <div className="d-flex">
          <Icon name="circle-exclamation" size={16} />
          <span>
            For updating Title, Name, Assistant details, Salutation, Email, Office 1/ Office 2 Numbers & Office details
            please contact your Local HR team.
          </span>
        </div>
        <Divider />
        <div className="d-flex">
          <span>Show Birthdate on Banner ? </span>
          <Checkbox toggle onChange={toggleBirthdayState} checked={showBirthday} />
        </div>
      </div>
    </Popover>
  );
};

export default EditWorkDayButton;
