import { expertiseList } from "redux/constants";

const expertiseListErrorReducer = (state = {}, action) => {
  switch (action.type) {
    case expertiseList.FETCH_EXPERTISE_SUMMARY_FAILURE:
      return action.error;
    case expertiseList.FETCH_EXPERTISE_SUMMARY_SUCCESS:
    case expertiseList.FETCH_EXPERTISE_SUMMARY:
      return null;
    default:
      return state;
  }
};

export default expertiseListErrorReducer;
