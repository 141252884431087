import { childTopicsFinder } from "redux/constants";

const childTopicsFinderErrorReducer = (state = {}, action) => {
  switch (action.type) {
    case childTopicsFinder.FETCH_CHILD_TOPICS_FINDER_FAILURE:
      return action.error;
    case childTopicsFinder.FETCH_CHILD_TOPICS_FINDER_SUCCESS:
    case childTopicsFinder.FETCH_CHILD_TOPICS_FINDER:
      return null;
    default:
      return state;
  }
};

export default childTopicsFinderErrorReducer;
