import { cases } from "redux/constants";

const caseUpdatingErrorReducer = (state = null, action) => {
  switch (action.type) {
    case cases.UPDATE_CASE_FAILURE:
      return action.error;
    case cases.UPDATE_CASE_SUCCESS:
    case cases.UPDATE_CASE:
      return null;
    default:
      return state;
  }
};

export default caseUpdatingErrorReducer;
