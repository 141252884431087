import { call, put } from "redux-saga/effects";
import { get } from "redux/api";
import { setUploadResumeError, uploadResumeToS3 } from "redux/actions/upload";
import CONFIG from "constants/config";

const { xApiKey } = CONFIG;

function* handleUploadResumeStart({ payload }) {
  const { fileInfo } = payload;
  const { data, error } = yield call(get, payload);
  if (error) {
    yield put(setUploadResumeError(error));
  } else {
    yield put(
      uploadResumeToS3({
        ...payload,
        endpoint: data?.signedRequest,
        config: {
          headers: {
            "Content-Type": fileInfo.ext,
            "x-api-key": `${xApiKey.profile}`,
            customAuthHeader: true
          }
        }
      })
    );
  }
}

export default handleUploadResumeStart;
