import PropTypes from "prop-types";
import { lazy, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProfileCases from "components/ProfileCases";
// import { Loading } from "components/common";
import { TABS } from "analytics/constants";
import CONFIG from "constants/config";
import { isMD } from "pages/helpers/utils";
import { getCCODetails } from "redux/actions/cco";
import useLogProfileViews from "customHooks/useLogProfileViews";
import IpadMasonary from "./IpadMasonary";
import DesktopMobileMasonary from "./DesktopMobileMasonary";

const ProfileMaterials = lazy(() => import("components/ProfileMaterials"));
const { exceptions, xApiKey, apiUrl } = CONFIG;

/**
 *  Profile page
 *  @page
 *
 *  @param  {object} profile user profile data
 *  @param  {bool} isProfileLoading profile loading boolean
 *  @param  {bool} isSSCasesLoading navigator cases loading boolean
 *  @param  {bool} isEditable checks if profile is editable
 *  @param  {bool} casesLoading link cases loading boolean
 *  @param  {object} cases merged cases
 *  @param  {object} linkCases Link cases
 *  @param  {bool} ssMaterialLoadingBatch loading boolean
 *  @param  {object} materialsData material data
 *  @param  {bool} showCases show cases or material in overview
 *
 *  @return component
 */

function Home({
  profile,
  isProfileLoading,
  recentCases,
  recentMaterials,
  ssRecentCasesLoading,
  ssRecentMaterialsLoading,
  allCases,
  ssCasesLoading,
  materialsData,
  showCases,
  ssMaterialLoadingBatch,
  showMetrics,
  loggedInUserHrId,
  isEditable,
  setLinkedInModalOpen
}) {
  const dispatch = useDispatch();
  const { ccoData, userEntitlements } = useSelector((state) => state);
  const { isCCO } = userEntitlements;
  const { isECT, isKT, isSA } = profile;

  // Profile View log
  useLogProfileViews(loggedInUserHrId, profile?.hrId, TABS.OVERVIEW, isProfileLoading);
  const ktStaffExceptions = exceptions.ktStaffExceptions.split(`|`);

  useEffect(() => {
    if (profile?.hrId) {
      dispatch(
        getCCODetails({
          endpoint: `${apiUrl.linkApi}profile/${profile?.hrId}/tb`,
          config: {
            headers: {
              "x-api-key": `${xApiKey.profile}`
            }
          }
        })
      );
    }
  }, [profile?.hrId]);

  // Global variable for Analytics
  window.$tabName = TABS.OVERVIEW;

  // const renderComponentOrLoader = (component, props) => {
  //   return isProfileLoading ? <Loading {...props} /> : component;
  // };

  // const { expertiseSummary = [] } = profile;

  // check if About section contains any value, then show it otherwise hide it

  const isAboutHasValue =
    isECT || (!ktStaffExceptions.includes(profile?.hrId?.toString()) && isKT) || isSA || (ccoData?.length && isCCO);

  // show cases or materials
  let showElement = (
    <ProfileCases
      profile={profile}
      isLoading={isProfileLoading || ssRecentCasesLoading}
      isEditable={false}
      className="mobile-order-4"
      cases={recentCases}
    />
  );

  if (!showCases) {
    showElement = (
      <ProfileMaterials
        isLoading={isProfileLoading || ssRecentMaterialsLoading}
        isEditable={isEditable}
        className="mobile-order-4"
        materialsData={recentMaterials}
      />
    );
  }

  if (Object.keys(recentMaterials).length === 0 && Object.keys(recentCases).length === 0) {
    showElement = null;
  }
  return (
    <>
      {!isMD() ? (
        <DesktopMobileMasonary
          isProfileLoading={isProfileLoading}
          showElement={showElement}
          isEditable={isEditable}
          allCases={allCases}
          ssCasesLoading={ssCasesLoading}
          materialsData={materialsData}
          ssMaterialLoadingBatch={ssMaterialLoadingBatch}
          showMetrics={showMetrics}
          isAboutHasValue={isAboutHasValue}
          setLinkedInModalOpen={setLinkedInModalOpen}
          loggedInUserHrId={loggedInUserHrId}
        />
      ) : (
        <IpadMasonary
          isProfileLoading={isProfileLoading}
          showElement={showElement}
          isEditable={isEditable}
          allCases={allCases}
          ssCasesLoading={ssCasesLoading}
          materialsData={materialsData}
          ssMaterialLoadingBatch={ssMaterialLoadingBatch}
          showMetrics={showMetrics}
          isAboutHasValue={isAboutHasValue}
          setLinkedInModalOpen={setLinkedInModalOpen}
          loggedInUserHrId={loggedInUserHrId}
        />
      )}
    </>
  );
}

Home.defaultProps = {
  profile: {},
  isProfileLoading: false,
  isEditable: false,
  recentCases: [],
  allCases: [],
  ssCasesLoading: false,
  ssMaterialLoadingBatch: false,
  materialsData: {},
  showCases: true,
  showMetrics: false,
  recentMaterials: {},
  ssRecentCasesLoading: false,
  ssRecentMaterialsLoading: false,
  loggedInUserHrId: "0"
};

Home.propTypes = {
  profile: PropTypes.shape({
    id: PropTypes.number,
    hrId: PropTypes.number,

    isSA: PropTypes.bool,
    isKT: PropTypes.bool,
    isECT: PropTypes.bool,
    isActive: PropTypes.bool
  }),
  isProfileLoading: PropTypes.bool,
  isEditable: PropTypes.bool,
  recentCases: PropTypes.arrayOf(PropTypes.shape()),
  allCases: PropTypes.arrayOf(PropTypes.shape()),
  ssCasesLoading: PropTypes.bool,
  ssMaterialLoadingBatch: PropTypes.bool,
  materialsData: PropTypes.shape(),
  showCases: PropTypes.bool,
  showMetrics: PropTypes.bool,
  recentMaterials: PropTypes.shape(),
  ssRecentCasesLoading: PropTypes.bool,
  ssRecentMaterialsLoading: PropTypes.bool,
  loggedInUserHrId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default Home;
