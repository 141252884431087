import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Card, Grid, List, Placeholder } from "semantic-ui-react";
import LABEL from "constants/label";
import { useDispatch, useSelector } from "react-redux";
import { getCVBioMaterialsViews, fetchProfileViews } from "redux/actions/profileViews";
import CONFIG from "constants/config";
import { isEmptyOrNull } from "helpers/utils";
import { Popover } from "components/common";

const ProfileInsights = ({ profile, loggedInUserHrId }) => {
  const { apiUrl, xApiKey } = CONFIG;
  const dispatch = useDispatch();

  const {
    profileViewsDetails,
    profileViewsDetailsLoading,
    profileResumes = [],
    profileProposalBios = [],
    cvBioViews,
    cvBioViewsLoading,
    profileProposalBiosLoading,
    profileResumeLoading,
    profileProposalBiosError,
    profileResumeError
  } = useSelector((state) => state);

  const { visits, count } = profileViewsDetails[0] ? profileViewsDetails[0] : {};
  const profileResume = profileResumes?.map((item) => item.resumeId);
  const proposalBioArr = profileProposalBios?.map((item) => item.proposalBioId);

  useEffect(() => {
    if (profile?.hrId === Number(loggedInUserHrId)) {
      dispatch(
        fetchProfileViews({
          endpoint: `${apiUrl.linkApi}profile/${profile?.hrId}/getProfileVisits`,
          config: {
            headers: {
              "x-api-key": `${xApiKey.profile}`
            }
          }
        })
      );
    }
  }, [profile?.hrId]);

  useEffect(() => {
    if (
      (profileProposalBios?.length || profileResume?.length) &&
      !profileProposalBiosLoading &&
      !profileResumeLoading &&
      !profileResumeError &&
      !profileProposalBiosError
    )
      dispatch(
        getCVBioMaterialsViews({
          endpoint: `${apiUrl.linkApi}download/count/Profiles?hrId=${profile?.hrId}&resumeIds=${profileResume}&proposalBioIds=${proposalBioArr}`,
          config: {
            headers: {
              "x-api-key": `${xApiKey.profile}`
            }
          }
        })
      );
  }, [proposalBioArr?.length, profileResume?.length]);

  return (
    <>
      {profile?.hrId === Number(loggedInUserHrId) ? (
        <Card.Content>
          {(!profileViewsDetailsLoading && !cvBioViewsLoading && profileViewsDetails[0]?.visits && (
            <div className="update-overview">
              <Grid>
                <Grid.Row className="p-0">
                  {(count?.length && visits && (
                    <Popover
                      trigger={
                        <Grid.Column mobile={16} computer={5}>
                          <p data-testid="profileViews" className="update-overview__statHeading fw-normal">
                            {visits || "0"}
                          </p>
                          <p className="update-overview__statDesc fw-bold">{LABEL.PROFILE_OVERVIEW.ProfileViews}</p>
                        </Grid.Column>
                      }
                      inverted
                      position="bottom left"
                      popper={{ className: "profile-views-tooltip" }}
                      pinned
                      hoverable
                      on="click"
                      hideOnScroll={false}
                    >
                      {(!isEmptyOrNull(count) && (
                        <div className="update-overview__tooltip">
                          <List as="ol">
                            {count.map(({ jobFamilyGroup, visitsCount }) => (
                              <List
                                as="ol"
                                key={`${jobFamilyGroup}-${visitsCount}`}
                              >{` ${jobFamilyGroup} - ${visitsCount}`}</List>
                            ))}
                          </List>
                        </div>
                      )) || (
                        <Placeholder inverted style={{ minWidth: "200px" }}>
                          <Placeholder.Header>
                            <Placeholder.Line />
                            <Placeholder.Line />
                          </Placeholder.Header>
                          <Placeholder.Paragraph>
                            <Placeholder.Line length="medium" />
                            <Placeholder.Line length="short" />
                          </Placeholder.Paragraph>
                        </Placeholder>
                      )}
                    </Popover>
                  )) || (
                    <Grid.Column mobile={16} computer="5" className="custom-class">
                      <p data-testid="profileViews" className="update-overview__statHeading fw-normal">
                        {visits || "0"}
                      </p>
                      <p className="update-overview__statDesc fw-bold">{LABEL.PROFILE_OVERVIEW.ProfileViews}</p>
                    </Grid.Column>
                  )}

                  <Grid.Column mobile={16} computer="5" className="custom-class">
                    <p data-testid="cvBioDownload" className="update-overview__statHeading fw-normal">
                      {cvBioViews?.proposalCVDownloadCount || "0"}
                    </p>
                    <p className="update-overview__statDesc fw-bold">{LABEL.PROFILE_OVERVIEW.CVBioDownlods}</p>
                  </Grid.Column>

                  <Grid.Column mobile={16} computer="5" className="custom-class">
                    <p data-testid="materialsDownload" className="update-overview__statHeading fw-normal">
                      {cvBioViews?.materialsDownloadCount || "0"}
                    </p>
                    <p className="update-overview__statDesc fw-bold">{LABEL.PROFILE_OVERVIEW.MaterialDownloads}</p>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
          )) || (
            <>
              <Placeholder style={{ minWidth: "200px" }}>
                <Placeholder.Line />
                <Placeholder.Line />
              </Placeholder>
            </>
          )}
        </Card.Content>
      ) : null}
    </>
  );
};

ProfileInsights.defaultProps = {
  profile: {}
};

ProfileInsights.propTypes = {
  profile: PropTypes.shape({
    id: PropTypes.number,
    hrId: PropTypes.number,
    hrEmployeeId: PropTypes.number,
    profileLastUpdateDate: PropTypes.string,
    percentComplete: PropTypes.number,
    incompleteFields: PropTypes.arrayOf(PropTypes.string),
    lastModifiedTime: PropTypes.string,
    profileView: PropTypes.shape({
      total: PropTypes.number,
      details: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          count: PropTypes.number
        })
      )
    }),
    cvBioDownlods: PropTypes.number,
    materialDownloads: PropTypes.number,
    loggedInUserHrId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
  })
};

export default ProfileInsights;
