import { peopleData } from "redux/constants";

const peopleDataReducer = (state = {}, action) => {
  switch (action.type) {
    case peopleData.FETCH_PEOPLE_DATA_SUCCESS: {
      const peopleObject =
        (action?.data?.data?.data?.employees?.length && action?.data?.data?.data?.employees[0]) || {};
      return {
        ...state,
        ...state?.peopleData,
        ...peopleObject
      };
    }
    default:
      return state;
  }
};

export default peopleDataReducer;
