import { upload } from "redux/constants";

const uploadBioErrorReducer = (state = false, action) => {
  switch (action.type) {
    case upload.UPLOAD_BIO_ERROR:
      return true;
    default:
      return state;
  }
};

export default uploadBioErrorReducer;
