/* eslint-disable no-underscore-dangle */
export default function loadWalkme() {
  const walkme = document.createElement("script");
  walkme.type = "text/javascript";
  walkme.async = true;
  walkme.src = process.env.REACT_APP_WALKME_URL;
  const s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(walkme, s);
  window._walkmeConfig = { smartLoad: true };
}
