import React from "react";
import PropTypes from "prop-types";
import { Button, Icon, Modal, Table } from "semantic-ui-react";
import {
  LinkedInRoleParentRow,
  LinkedInRoleChildRow
} from "components/Experience/LinkedInExperience/LinkedInExperienceRows";
import "./styles.scss";
import LABEL from "constants/label";
import CONFIG from "constants/config";

const { linkedInUrl } = CONFIG;

const { Experience, TABLE_HEADER } = LABEL.EXPERIENCE;
const { Close } = LABEL.COMMON;

function LinkedInExperienceViewAll({ open, onClose, linkedInExperience }) {
  return (
    <Modal open={open} className="experience__modal popup-modal">
      <Modal.Header className="experience__modal__title">{Experience}</Modal.Header>

      <Modal.Content className="experience__modal__content">
        <Table striped>
          <Table.Header className="modal__table--head">
            <Table.Row>
              <Table.HeaderCell width={5} className="cursor-pointer">
                {TABLE_HEADER.CompanyOrganization}
              </Table.HeaderCell>
              <Table.HeaderCell width={4} className="cursor-pointer">
                {TABLE_HEADER.Role}
              </Table.HeaderCell>
              <Table.HeaderCell width={4} className="cursor-pointer">
                {TABLE_HEADER.StartEndDate}
              </Table.HeaderCell>
              <Table.HeaderCell width={3} className="cursor-pointer">
                {TABLE_HEADER.Location}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body className="modal__table--body">
            {!!linkedInExperience?.length &&
              linkedInExperience?.map((experience) => (
                <>
                  <LinkedInRoleParentRow experience={experience} />
                  {experience?.roles?.length > 1 &&
                    experience?.roles.map(
                      (role, index) =>
                        index > 0 && <LinkedInRoleChildRow experience={experience} rowItem={role} index={index} />
                    )}
                </>
              ))}
          </Table.Body>
        </Table>
      </Modal.Content>

      <Modal.Actions className="modal_footer tab__footer d-flex justify-content-between align-items-center">
        <div className="experience__content__small">
          <Icon name="info circle" className="experience__content__icon" />
          Any changes to Pre-BCG Work Experience can only be updated in LinkedIn. Please{" "}
          <a href={linkedInUrl} target="_blank" rel="noopener noreferrer">
            click here
          </a>{" "}
          to access the LinkedIn portal.
        </div>
        <Button data-testid="close" basic className="button-sm mr-1" secondary onClick={() => onClose()}>
          {Close}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

LinkedInExperienceViewAll.defaultProps = {
  linkedInExperience: []
};

LinkedInExperienceViewAll.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  linkedInExperience: PropTypes.arrayOf(
    PropTypes.shape({
      company: PropTypes.string,
      companyDesc: PropTypes.string,
      roles: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          endYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          startYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          endMonth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          startMonth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          location: PropTypes.string
        })
      )
    })
  )
};

export default LinkedInExperienceViewAll;
