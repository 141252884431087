import { organization } from "redux/constants";

const orgErrorReducer = (state = null, action) => {
  switch (action.type) {
    case organization.FETCH_ORG_FAILURE:
      return action.error;
    case organization.FETCH_ORG_SUCCESS:
    case organization.FETCH_ORG:
      return null;
    default:
      return state;
  }
};

export default orgErrorReducer;
