import { call, put as putSaga } from "redux-saga/effects";
import { put } from "redux/api";
import { setUploadBioError, uploadProposalBioToProfile } from "redux/actions/upload";
import CONFIG from "constants/config";

const { apiUrl, xApiKey } = CONFIG;

function* handleUploadProposalBioToS3({ payload }) {
  const { fileInfo } = payload;
  try {
    const { error } = yield call(put, payload);
    if (error) {
      yield putSaga(setUploadBioError(error));
    } else {
      yield putSaga(
        uploadProposalBioToProfile({
          ...payload,
          endpoint: `${apiUrl.linkApi}profile/${payload.hrId}/proposalbio`,
          data: {
            ...fileInfo
          },
          config: {
            headers: {
              "x-api-key": `${xApiKey.profile}`
            }
          }
        })
      );
    }
  } catch (error) {
    yield putSaga(setUploadBioError(error));
  }
}

export default handleUploadProposalBioToS3;
