import React from "react";
import PropTypes from "prop-types";

/**
 * @param {string} children content to be displayed
 * @param {string} imgSrc src of the icon image
 * @param {string} altText alternate text for image
 *
 * @returns IconsWithText Component
 *
 * @example
 * <IconWithDynamicText
 *  imgSrc="/icons/loc_marker.svg"
 *  altText="Location"
 * >
 *  {children}
 * </IconWithDynamicText>
 */

function IconWithDynamicText({ children, imgSrc, altText }) {
  return (
    (children && (
      <div>
        <span>
          <img src={imgSrc} alt={altText} />
        </span>
        {children}
      </div>
    )) ||
    null
  );
}

IconWithDynamicText.propTypes = {
  children: PropTypes.node.isRequired,
  imgSrc: PropTypes.string.isRequired,
  altText: PropTypes.string.isRequired
};

export default IconWithDynamicText;
