import { takeLatest } from "redux-saga/effects";

import { linkedIn } from "redux/constants";
import handleRemoveLinkedInAccess from "../worker/handleRemoveLinkedInAccess";

function* watchRemoveLinkedInAccess() {
  yield takeLatest(linkedIn.REMOVE_LINKEDIN_ACCESS, handleRemoveLinkedInAccess);
}

export default watchRemoveLinkedInAccess;
