import { useState, useEffect } from "react";
import { Table, Icon, Button, Modal } from "semantic-ui-react";
import PropTypes from "prop-types";
import useSortableData from "pages/Cases/Vignettes/useSortableData";
import { getDuration, formatDateByMoment } from "helpers/utils";
import LABEL from "constants/label";

import "./styles.scss";

const { Experience, TABLE_HEADER } = LABEL.EXPERIENCE;
const { Close } = LABEL.COMMON;

/**
 * ExperienceViewAll - Experience View All modal
 *
 * @param  {bool} open to open modal window
 * @param  {func} onClose function to close modal window
 *
 * @return return tabular view for all experiences
 *
 * @example
 *
 * <ExperienceViewAll open={open} onClose={handleOnCloseClick} />
 */

function ExperienceViewAll({ open, onClose, experience, workdayURL }) {
  const [experienceList] = useState(experience);
  const { items, requestSort, sortConfig } = useSortableData(experienceList, {
    key: "endDate",
    direction: "down"
  });
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return false;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  useEffect(() => {
    requestSort("endDate");
  }, []);

  return (
    <Modal open={open} className="experience__modal popup-modal">
      <Modal.Header className="experience__modal__title">{Experience}</Modal.Header>

      <Modal.Content className="experience__modal__content">
        <Table striped>
          <Table.Header className="modal__table--head">
            <Table.Row>
              <Table.HeaderCell width={5} className="cursor-pointer" onClick={() => requestSort("company")}>
                {TABLE_HEADER.CompanyOrganization}
                {getClassNamesFor("company") && <Icon name={`arrow ${getClassNamesFor("company")}`} />}
              </Table.HeaderCell>
              <Table.HeaderCell width={4} className="cursor-pointer" onClick={() => requestSort("title")}>
                {TABLE_HEADER.Role}
                {getClassNamesFor("title") && <Icon name={`arrow ${getClassNamesFor("title")}`} />}
              </Table.HeaderCell>
              <Table.HeaderCell width={4} className="cursor-pointer" onClick={() => requestSort("endDate")}>
                {TABLE_HEADER.StartEndDate}
                {getClassNamesFor("endDate") && <Icon name={`arrow ${getClassNamesFor("endDate")}`} />}
              </Table.HeaderCell>

              <Table.HeaderCell width={3} className="cursor-pointer" onClick={() => requestSort("location")}>
                {TABLE_HEADER.Location}
                {getClassNamesFor("location") && <Icon name={`arrow ${getClassNamesFor("location")}`} />}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body className="modal__table--body">
            {items?.length > 0 &&
              items.map((experienceItem) => {
                return (
                  <Table.Row key={experienceItem.id}>
                    <Table.Cell className="d-flex align-items-center">
                      <span className="experience__item__image justify-content-center align-items-center">
                        <img src="/icons/ic_experience.svg" alt="experienceItem" />
                      </span>
                      <span className="td-label">{TABLE_HEADER.CompanyOrganization}</span>
                      {experienceItem.company && experienceItem.company !== "NULL" ? experienceItem.company : "-"}
                    </Table.Cell>
                    <Table.Cell>
                      <span className="td-label mt-3">{TABLE_HEADER.Role} </span>{" "}
                      {experienceItem.title && experienceItem.title !== "NULL" ? experienceItem.title : "-"}
                    </Table.Cell>
                    <Table.Cell>
                      <span className="td-label">{TABLE_HEADER.StartEndDate} </span>
                      {formatDateByMoment(experienceItem?.startDate, "MMM DD, YYYY")} –{" "}
                      {formatDateByMoment(experienceItem?.endDate, "MMM DD, YYYY")} |{" "}
                      {!!experienceItem.endDate && (
                        <span data-testid="duration">
                          {getDuration(experienceItem.endDate, experienceItem.startDate)}
                        </span>
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      <span className="td-label">{TABLE_HEADER.Location}</span>{" "}
                      {experienceItem.location && experienceItem.location !== "NULL" ? experienceItem.location : "-"}
                    </Table.Cell>
                  </Table.Row>
                );
              })}
          </Table.Body>
        </Table>
      </Modal.Content>

      <Modal.Actions className="modal_footer tab__footer d-flex justify-content-between align-items-center">
        <div className="experience__content__small">
          <Icon name="info circle" className="experience__content__icon" />
          Any changes to Pre-BCG Work Experience can only be updated in Workday. Please{" "}
          <a href={workdayURL} target="_blank" rel="noopener noreferrer">
            click here
          </a>{" "}
          to access the Workday portal.
        </div>
        <Button data-testid="close" basic className="button-sm mr-1" secondary onClick={() => onClose()}>
          {Close}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

ExperienceViewAll.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default ExperienceViewAll;
