import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { startRecording, stopRecording } from "../handlers/handlers";

// Initial state of the pronunciation recoreder.

const initialState = {
  id: null,
  recordingSeconds: 5,
  initRecording: false,
  mediaStream: null,
  mediaRecorder: null,
  audio: null,
  blob: null
};

export default function useRecorder() {
  const { profile } = useSelector((state) => state);
  const [recorderState, setRecorderState] = useState(initialState);
  const { pronunciation } = useSelector((state) => state);
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    setRecorderState((prevState) => {
      return {
        ...initialState,
        audio: pronunciation.playbackUrl || pronunciation.audioPath || null,
        recordingSeconds: prevState.recordingSeconds,
        id: pronunciation?.audioId
      };
    });
  }, [pronunciation]);

  /**
   * sets the state of the recoder as recieved from the API which was saved earlier.
   */

  useEffect(() => {
    setRecorderState((prevState) => {
      return {
        ...initialState,
        audio: profile?.audioUrl || null,
        recordingSeconds: prevState.recordingSeconds,
        id: profile?.audioId
      };
    });
  }, [profile.hrId]);

  /**
   * it updated the timer shown while recording and stops recording as soon as the time runs out.
   * default time is 5 secs.
   */

  useEffect(() => {
    let recordingInterval = null;

    if (recorderState.initRecording)
      recordingInterval = setInterval(() => {
        setRecorderState((prevState) => {
          if (prevState.recordingSeconds === 1) {
            stopRecording(recorderState.mediaRecorder, setIsDirty);
            clearInterval(recordingInterval);
            return prevState;
          }
          if (prevState.recordingSeconds > 0)
            return {
              ...prevState,
              recordingSeconds: prevState.recordingSeconds - 1
            };
          return { ...prevState };
        });
      }, 1000);
    else clearInterval(recordingInterval);

    return () => clearInterval(recordingInterval);
  });

  useEffect(() => {
    if (recorderState.mediaStream)
      setRecorderState((prevState) => {
        return {
          ...prevState,
          mediaRecorder: new MediaRecorder(prevState.mediaStream)
        };
      });
  }, [recorderState.mediaStream]);

  /**
   * creates the actual recording object to be stored in the Widen system once the recording starts.
   */

  useEffect(() => {
    const recorder = recorderState.mediaRecorder;
    let chunks = [];

    if (recorder && recorder.state === "inactive") {
      recorder.start();

      recorder.ondataavailable = (e) => {
        chunks.push(e.data);
      };

      recorder.onstop = () => {
        const blob = new Blob(chunks, { type: "audio/mp3; codecs=mp3" });
        chunks = [];

        setRecorderState((prevState) => {
          if (prevState.mediaRecorder)
            return {
              ...initialState,
              audio: window.URL.createObjectURL(blob),
              recordingSeconds: prevState.recordingSeconds,
              blob
            };
          return initialState;
        });
      };
    }

    return () => {
      if (recorder) recorder.stream.getAudioTracks().forEach((track) => track.stop());
    };
  }, [recorderState.mediaRecorder]);

  return {
    recorderState,
    startRecording: () => startRecording(setRecorderState, setIsDirty),
    deleteRecording: () => {
      setRecorderState(initialState);
      setIsDirty(false);
    },
    stopRecording: () => stopRecording(recorderState.mediaRecorder, setIsDirty),
    isDirty,
    setIsDirty
  };
}
