import { linkedIn } from "redux/constants";

const getLinkedInRedirectUrl = (payload) => {
  return {
    type: linkedIn.FETCH_LINKEDIN_REDIRECT_URL,
    payload
  };
};

const setLinkedInRedirectUrl = (data) => ({
  type: linkedIn.FETCH_LINKEDIN_REDIRECT_URL_SUCCESS,
  data
});

const setLinkedInRedirectUrlError = (error) => ({
  type: linkedIn.FETCH_LINKEDIN_REDIRECT_URL_FAILURE,
  error
});

const getLinkedInAuthStatus = (payload) => {
  return {
    type: linkedIn.FETCH_LINKEDIN_AUTH_STATUS,
    payload
  };
};

const setLinkedInAuthStatus = (data) => ({
  type: linkedIn.FETCH_LINKEDIN_AUTH_STATUS_SUCCESS,
  data
});

const setLinkedInAuthStatusError = (error) => ({
  type: linkedIn.FETCH_LINKEDIN_AUTH_STATUS_FAILURE,
  error
});

const initiateLinkedInConnect = (payload) => {
  return {
    type: linkedIn.INITIATE_LINKEDIN_CONNECT,
    payload
  };
};

const initiateLinkedInConnectSuccess = (data) => ({
  type: linkedIn.INITIATE_LINKEDIN_CONNECT_SUCCESS,
  data
});

const initiateLinkedInConnectError = (error) => ({
  type: linkedIn.INITIATE_LINKEDIN_CONNECT_FAILURE,
  error
});

const getLinkedInData = (payload) => {
  return {
    type: linkedIn.FETCH_LINKEDIN_DATA,
    payload
  };
};

const setLinkedInData = (data) => ({
  type: linkedIn.FETCH_LINKEDIN_DATA_SUCCESS,
  data
});

const setLinkedInDataError = (error) => ({
  type: linkedIn.FETCH_LINKEDIN_DATA_FAILURE,
  error
});

const updateLinkedInDeclineStatus = (payload) => ({
  type: linkedIn.UPDATE_LINKEDIN_DECLINE_STATUS,
  payload
});

const setLinkedInDeclineStatus = (data) => ({
  type: linkedIn.UPDATE_LINKEDIN_DECLINE_STATUS_SUCCESS,
  data
});

const setLinkedInDeclineError = (error) => ({
  type: linkedIn.UPDATE_LINKEDIN_DECLINE_STATUS_FAILURE,
  error
});

const removeLinkedInAccess = (payload) => ({
  type: linkedIn.REMOVE_LINKEDIN_ACCESS,
  payload
});

const setRemoveLinkedInAccessSuccess = (data) => ({
  type: linkedIn.REMOVE_LINKEDIN_ACCESS_SUCCESS,
  data
});

const setRemoveLinkedInAccessError = (error) => ({
  type: linkedIn.REMOVE_LINKEDIN_ACCESS_FAILURE,
  error
});

const updateLinkedInOptinOptions = (payload) => ({
  type: linkedIn.UPDATE_LINKEDIN_OPTIN_OPTIONS,
  payload
});

const setUpdateLinkedInOptinOptionsSuccess = (data) => ({
  type: linkedIn.UPDATE_LINKEDIN_OPTIN_OPTIONS_SUCCESS,
  data
});

const setUpdateLinkedInOptinOptionsError = (error) => ({
  type: linkedIn.UPDATE_LINKEDIN_OPTIN_OPTIONS_FAILURE,
  error
});

const getUserLinkedInStatus = (payload) => {
  return {
    type: linkedIn.FETCH_USER_LINKEDIN_STATUS,
    payload
  };
};

const setUserLinkedInStatus = (data) => ({
  type: linkedIn.FETCH_USER_LINKEDIN_STATUS_SUCCESS,
  data
});

const setUserLinkedInStatusError = (error) => ({
  type: linkedIn.FETCH_USER_LINKEDIN_STATUS_FAILURE,
  error
});

export {
  getLinkedInRedirectUrl,
  setLinkedInRedirectUrl,
  setLinkedInRedirectUrlError,
  getLinkedInAuthStatus,
  setLinkedInAuthStatus,
  setLinkedInAuthStatusError,
  initiateLinkedInConnect,
  initiateLinkedInConnectError,
  initiateLinkedInConnectSuccess,
  getLinkedInData,
  setLinkedInData,
  setLinkedInDataError,
  updateLinkedInDeclineStatus,
  setLinkedInDeclineStatus,
  setLinkedInDeclineError,
  removeLinkedInAccess,
  setRemoveLinkedInAccessSuccess,
  setRemoveLinkedInAccessError,
  updateLinkedInOptinOptions,
  setUpdateLinkedInOptinOptionsSuccess,
  setUpdateLinkedInOptinOptionsError,
  getUserLinkedInStatus,
  setUserLinkedInStatus,
  setUserLinkedInStatusError
};
