import { externalCourseId } from "redux/constants";

const externalCourseIdErrorReducer = (state = null, action) => {
  switch (action.type) {
    case externalCourseId.CHECK_COURSE_ID_FAILURE:
      return action?.error;
    case externalCourseId.CHECK_COURSE_ID_SUCCESS:
    case externalCourseId.CHECK_COURSE_ID:
      return null;
    default:
      return state;
  }
};

export default externalCourseIdErrorReducer;
