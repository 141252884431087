import { useState } from "react";
import PropTypes from "prop-types";

import Popover from "components/common/Popover";

import { getImageSizeStyle, generateId } from "helpers/utils";

import styles from "./styles.module.scss";

/**
 * IconWithImageAndPopover - Image/Icon with popover
 *
 * @param  {string} size Define image size
 * @param  {string} imgSrc Image url. Will added as background image
 * @param  {string} altText Alt text
 * @param  {node} children Content to show in popover
 * @param  {bool} withPointer Render popup with pointer to its trigger element
 *
 * @return Component wraped in Popover component
 *
 * @example
 *
 * <IconWithImageAndPopover size="medium" imgSrc="/icons/ic_call_sprites.png" altText="Phone">
    {...}
   </IconWithImageAndPopover>
 */

const IconWithImageAndPopover = ({ size, withPointer, imgSrc, altText, children, callback }) => {
  const id = generateId();

  const [open, setOpen] = useState("");

  const optionalProps = {
    basic: !withPointer
  };

  // adding class when popover is opened
  const handleOpen = () => {
    setOpen("popup--open");
  };

  // removing class when popover is opened
  const handleClose = () => {
    setOpen("");
  };
  return (
    <Popover
      trigger={
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <span
          key={id.next().value}
          data-testid="popover-trigger"
          style={{ backgroundImage: `url(${imgSrc})` }}
          alt={altText}
          className={`${styles["icon__circle"]} d-flex justify-content-center cursor-pointer ${
            styles[getImageSizeStyle(size)]
          } ${open}`}
          role="presentation"
        />
      }
      on="click"
      position="bottom center"
      onOpen={() => {
        handleOpen();
        callback();
      }}
      onClose={handleClose}
      popper={{ className: `mobile-popup-position-${altText}` }}
      {...optionalProps}
    >
      {children}
      <span className="d-none" /> {/* fix for popover children required value proptype */}
    </Popover>
  );
};

IconWithImageAndPopover.defaultProps = {
  size: "medium",
  withPointer: false,
  callback: () => {}
};

IconWithImageAndPopover.propTypes = {
  size: PropTypes.string,
  withPointer: PropTypes.bool,
  imgSrc: PropTypes.string.isRequired,
  altText: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  callback: PropTypes.func
};

export default IconWithImageAndPopover;
