import { call, put } from "redux-saga/effects";
import { get } from "redux/api";
import { setExperience, setError } from "redux/actions/experience";

function* handleFetchExperience(endpoint) {
  const { data, error } = yield call(get, endpoint);
  if (error) {
    yield put(setError(error));
  } else {
    yield put(setExperience(data));
  }
}

export default handleFetchExperience;
