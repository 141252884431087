import { cases } from "redux/constants";

const caseUpdatingReducer = (state = false, action) => {
  switch (action.type) {
    case cases.UPDATE_CASE:
      return true;
    case cases.UPDATE_CASE_SUCCESS:
    case cases.UPDATE_CASE_FAILURE:
      return false;
    default:
      return state;
  }
};

export default caseUpdatingReducer;
