import { takeEvery } from "redux-saga/effects";

import { people } from "redux/constants";
import handleFetchPeople from "../worker/handleFetchPeople";

function* watchPeople() {
  yield takeEvery(people.FETCH_PEOPLE, handleFetchPeople);
}

export default watchPeople;
