import { call, put } from "redux-saga/effects";
import { get } from "redux/api";
import { setLinkedInAuthStatus, setLinkedInAuthStatusError } from "redux/actions/linkedIn";

function* handleFetchLinkedInAuthStatus({ payload }) {
  const { data, error } = yield call(get, payload);
  if (error) {
    yield put(setLinkedInAuthStatusError(error));
  } else {
    yield put(setLinkedInAuthStatus(data));
  }
}

export default handleFetchLinkedInAuthStatus;
