import { cvBioDownloadDetails } from "redux/constants";

const cvBioDownloadDetailsErrorReducer = (state = {}, action) => {
  switch (action.type) {
    case cvBioDownloadDetails.FETCH_CV_BIO_DOWNLOAD_DETAILS_FAILURE:
      return action.error;
    case cvBioDownloadDetails.FETCH_CV_BIO_DOWNLOAD_DETAILS:
    case cvBioDownloadDetails.FETCH_CV_BIO_DOWNLOAD_DETAILS_SUCCESS:
      return null;
    default:
      return state;
  }
};

export default cvBioDownloadDetailsErrorReducer;
