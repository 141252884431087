import { skills } from "redux/constants";

const skillsToolsLoadingReducer = (state = false, action) => {
  switch (action.type) {
    case skills.GET_SKILL_TOOLS_DATA:
      return true;
    case skills.GET_SKILL_TOOLS_DATA_SUCCESS:
    case skills.GET_SKILL_TOOLS_DATA_FAILURE:
      return false;
    default:
      return state;
  }
};

export default skillsToolsLoadingReducer;
