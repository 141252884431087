import { language } from "redux/constants";

const languageReducer = (state = {}, action) => {
  switch (action.type) {
    case language.FETCH_LANGUAGE_SUCCESS: {
      return action.data;
    }
    default:
      return state;
  }
};

export default languageReducer;
