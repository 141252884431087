import { profileCompletion } from "redux/constants";

const updateProfileCompletionReducer = (state = false, action) => {
  switch (action.type) {
    case profileCompletion.UPDATE_PROFILE_COMPLETE_SUCCESS:
      return true;
    case profileCompletion.UPDATE_PROFILE_COMPLETE:
    case profileCompletion.UPDATE_PROFILE_COMPLETE_FAILURE:
      return false;
    default:
      return state;
  }
};

export default updateProfileCompletionReducer;
