import { takeLatest } from "redux-saga/effects";

import { externalCourseId } from "redux/constants";
import handleCheckExternalCourseId from "../worker/handleCheckExternalCourseId";

function* watchCheckExternalCourseId() {
  yield takeLatest(externalCourseId.CHECK_COURSE_ID, handleCheckExternalCourseId);
}

export default watchCheckExternalCourseId;
