import { language } from "redux/constants";

const languageLoadingReducer = (state = false, action) => {
  switch (action.type) {
    case language.FETCH_LANGUAGE:
      return true;
    case language.FETCH_LANGUAGE_SUCCESS:
    case language.FETCH_LANGUAGE_FAILURE:
      return false;
    default:
      return state;
  }
};

export default languageLoadingReducer;
