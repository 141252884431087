import { proposalBios } from "redux/constants";

const proposalBiosErrorReducer = (state = {}, action) => {
  switch (action.type) {
    case proposalBios.FETCH_PROPOSAL_BIOS_FAILURE:
      return action.error;
    case proposalBios.FETCH_PROPOSAL_BIOS_SUCCESS:
    case proposalBios.FETCH_PROPOSAL_BIOS:
      return null;
    default:
      return state;
  }
};

export default proposalBiosErrorReducer;
