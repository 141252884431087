import { takeEvery } from "redux-saga/effects";

import { upload } from "redux/constants";
import handleUploadProposalBioToS3 from "../worker/handleUploadProposalBioToS3";

function* watchUploadProposalBioToS3() {
  yield takeEvery(upload.UPLOAD_PROPOSAL_BIO_T0_S3, handleUploadProposalBioToS3);
}

export default watchUploadProposalBioToS3;
