const decodeHtml = (html) => {
  const txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
};

const sanitizeData = (str) => {
  let temp = str || " ";
  temp = decodeHtml(temp);
  return temp;
};

const isCharacterALetterOrNumber = (char) => {
  return /[a-zA-Z0-9]/.test(char);
};

const getLastSpacePosition = (text, lastItemIndex, limitValue, countDots) => {
  let lastIndex = lastItemIndex;

  const getLastIndex = (index) => {
    let newIndex = index;

    for (let i = newIndex; i >= 0; i -= 1) {
      if (text[i] === " ") {
        newIndex = i;
        break;
      }
    }

    return newIndex;
  };

  lastIndex = getLastIndex(lastIndex);

  // after postfixing three dots "..."
  if (countDots) {
    if (lastIndex + 3 < limitValue) {
      return lastIndex;
    }

    lastIndex = getLastIndex(lastIndex);
  }

  return lastIndex;
};

// This function helps in finding the last word after which dots need to be added
// Can use npm package html-substring if things go wrong
const getLastWord = (lastIndex, str) => {
  let start = 0;
  let end = lastIndex;
  let special = null;

  if (str[lastIndex] === " ") {
    end = lastIndex - 1;
  }

  for (let i = end; i >= 0; i -= 1) {
    if (str[i] === " ") {
      start = i + 1;
      break;
    }
  }

  let word = str.slice(start, lastIndex);
  const wordLen = word.length;

  // removing special characters from the end
  if (!isCharacterALetterOrNumber(word[wordLen - 1])) {
    word = word.slice(0, end);
    end -= 1;
    special = word[wordLen - 1];
  }

  return {
    word,
    startIndex: start,
    endIndex: end,
    special,
    wordLen
  };
};

export { sanitizeData, getLastSpacePosition, getLastWord };
