import { call, put } from "redux-saga/effects";
import { get } from "redux/api";
import { setSkillsToolsDataFailure, setSkillsToolsDataSuccess } from "redux/actions/skills";

function* handleFetchSkillsTools({ payload }) {
  const { data, error } = yield call(get, payload);
  if (error) {
    yield put(setSkillsToolsDataFailure(error));
  } else {
    yield put(setSkillsToolsDataSuccess(data));
  }
}

export default handleFetchSkillsTools;
