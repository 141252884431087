import { awardNames } from "redux/constants";

const awardNamesLoadingReducer = (state = false, action) => {
  switch (action.type) {
    case awardNames.FETCH_AWARD_NAMES:
      return true;
    case awardNames.FETCH_AWARD_NAMES_SUCCESS:
    case awardNames.FETCH_AWARD_NAMES_FAILURE:
      return false;
    default:
      return state;
  }
};

export default awardNamesLoadingReducer;
