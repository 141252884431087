import { ssRecentInternalMaterials } from "redux/constants";

const getRecentSSInternalMaterials = (payload) => {
  return {
    type: ssRecentInternalMaterials.FETCH_RECENT_SS_INTERNAL_MATERIALS,
    payload
  };
};

const setRecentSSInternalMaterials = (data) => ({
  type: ssRecentInternalMaterials.FETCH_RECENT_SS_INTERNAL_MATERIALS_SUCCESS,
  data
});

const setError = (error) => ({
  type: ssRecentInternalMaterials.FETCH_RECENT_SS_INTERNAL_MATERIALS_FAILURE,
  error
});

export { getRecentSSInternalMaterials, setRecentSSInternalMaterials, setError };
