import { awardTypes } from "redux/constants";

const awardTypesReducer = (state = {}, action) => {
  switch (action.type) {
    case awardTypes.FETCH_AWARD_TYPES_SUCCESS: {
      return action.data;
    }
    default:
      return state;
  }
};

export default awardTypesReducer;
