import { takeLatest } from "redux-saga/effects";

import { editProfile } from "redux/constants";
import handleResumeFile from "../worker/handleResumeFile";

function* watchResumeFile() {
  yield takeLatest(editProfile.RESUME_FILE, handleResumeFile);
}

export default watchResumeFile;
