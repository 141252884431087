import { skills } from "redux/constants";

const getSkills = (endpoint) => {
  return {
    type: skills.FETCH_SKILL,
    endpoint
  };
};

const setSkills = (data) => ({
  type: skills.FETCH_SKILL_SUCCESS,
  data
});

const setError = (error) => ({
  type: skills.FETCH_SKILL_FAILURE,
  error
});

const updateSkill = (payload) => ({
  type: skills.UPDATE_SKILL,
  payload
});

const setUpdateSkillSuccess = (data) => ({
  type: skills.UPDATE_SKILL_SUCCESS,
  data
});

const setUpdateSkillError = (error) => ({
  type: skills.UPDATE_SKILL_FAILURE,
  error
});

const deleteSkill = (payload) => ({
  type: skills.DELETE_SKILL,
  payload
});

const setDeleteSkillSuccess = (data) => ({
  type: skills.DELETE_SKILL_SUCCESS,
  data
});

const setDeleteSkillError = (error) => ({
  type: skills.DELETE_SKILL_FAILURE,
  error
});
//* To be removed//
const deleteSkillSummary = (payload) => ({
  type: skills.DELETE_SKILLSUMMARY,
  payload
});
//* To be removed//
const setDeleteSkillSummarySuccess = (data) => ({
  type: skills.DELETE_SKILLSUMMARY_SUCCESS,
  data
});
//* To be removed//
const setDeleteSkillSummaryError = (error) => ({
  type: skills.DELETE_SKILLSUMMARY_FAILURE,
  error
});

const getSkillsToolsData = (payload) => ({
  type: skills.GET_SKILL_TOOLS_DATA,
  payload
});

const setSkillsToolsDataSuccess = (data) => ({
  type: skills.GET_SKILL_TOOLS_DATA_SUCCESS,
  data
});

const setSkillsToolsDataFailure = (error) => ({
  type: skills.GET_SKILL_TOOLS_DATA_FAILURE,
  error
});

const saveSkills = (payload) => ({
  type: skills.UPDATE_SKILL,
  payload
});
const setSaveSkillSuccess = (data) => ({
  type: skills.UPDATE_SKILL_SUCCESS,
  data
});

const setSaveSkillError = (error) => ({
  type: skills.UPDATE_SKILL_FAILURE,
  error
});

export {
  getSkills,
  setSkills,
  setError,
  updateSkill,
  setUpdateSkillSuccess,
  setUpdateSkillError,
  deleteSkill,
  setDeleteSkillSuccess,
  setDeleteSkillError,
  deleteSkillSummary,
  setDeleteSkillSummarySuccess,
  setDeleteSkillSummaryError,
  getSkillsToolsData,
  setSkillsToolsDataSuccess,
  setSkillsToolsDataFailure,
  saveSkills,
  setSaveSkillSuccess,
  setSaveSkillError
};
