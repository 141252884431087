import { call, put } from "redux-saga/effects";
import { get } from "redux/api";
import { setEntitlements, setError } from "redux/actions/entitlements";

function* handleFetchEntitlements({ payload }) {
  const { data, error } = yield call(get, payload);
  if (error) {
    yield put(setError(error));
  } else {
    yield put(setEntitlements(data));
  }
}

export default handleFetchEntitlements;
