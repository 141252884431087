import { takeLatest } from "redux-saga/effects";

import { topics } from "redux/constants";
import handleDeleteTopic from "../worker/handleDeleteTopic";

function* watchDeleteTopic() {
  yield takeLatest(topics.DELETE_TOPIC, handleDeleteTopic);
}

export default watchDeleteTopic;
