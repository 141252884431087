import sendEventData from "analytics/analytics";
import { EVENTS, TRIGGERS } from "analytics/constants";
import PropTypes from "prop-types";
import { Tab } from "semantic-ui-react";
import LABEL from "constants/label";
import { noop } from "lodash";

/**
 * Expanded component - It is build on top oppaf semantic ui tab component and renders pane titles with specified components.
 *
 * @param {array} tabConfig
 *
 * @return returns semantic ui tabs
 *
 * @example
 *
 * <Expanded tabConfig={[...]} />
 */
function Expanded({ tabConfig, sortBy, getFilterterm, kpCmsId, docRank }) {
  const { CASES, MATERIALS } = LABEL;

  if (!tabConfig || tabConfig.length === 0) {
    return null;
  }

  const handleTabChange = ({ target }) => {
    switch (target.innerHTML) {
      case CASES.CASEDESCRIPTION:
        sendEventData(TRIGGERS.CASE.CASE_DESC, EVENTS.CASES_SECTION, {
          preSortTerm: sortBy,
          preFilterTerm: getFilterterm(),
          docRank
        });
        break;
      case CASES.CASETEAM:
        sendEventData(TRIGGERS.CASE.CASE_TEAM, EVENTS.CASES_SECTION, {
          preSortTerm: sortBy,
          preFilterTerm: getFilterterm(),
          docRank
        });
        break;
      case CASES.CASEVIGNETTES:
        sendEventData(TRIGGERS.CASE.CASE_VIG, EVENTS.CASES_SECTION, {
          preSortTerm: sortBy,
          preFilterTerm: getFilterterm(),
          docRank
        });
        break;
      case MATERIALS.Description:
        sendEventData(TRIGGERS.MATERIAL.DESCRIPTION, EVENTS.MATERIAL_SECTION, {
          preSortTerm: sortBy,
          preFilterTerm: getFilterterm(),
          kpid: kpCmsId,
          docRank
        });
        break;
      case MATERIALS.AssociatedBundles:
        sendEventData(TRIGGERS.MATERIAL.ASSOCIATED_BUNDLES, EVENTS.MATERIAL_SECTION, {
          preSortTerm: sortBy,
          preFilterTerm: getFilterterm(),
          kpid: kpCmsId,
          docRank
        });
        break;
      case MATERIALS.Authors:
        sendEventData(TRIGGERS.MATERIAL.AUTHORS, EVENTS.MATERIAL_SECTION, {
          preSortTerm: sortBy,
          preFilterTerm: getFilterterm(),
          kpid: kpCmsId,
          docRank
        });
        break;
      default:
      // do nothing
    }
  };

  const panes = tabConfig.map((item) => ({
    menuItem: item.title,
    render: () => <Tab.Pane as="div">{item.component}</Tab.Pane>
  }));

  return (
    <Tab className="ss-tabs" menu={{ secondary: true, pointing: true }} panes={panes} onTabChange={handleTabChange} />
  );
}

Expanded.defaultProps = {
  tabConfig: [],
  sortBy: "",
  getFilterterm: noop,
  kpCmsId: "",
  docRank: 0
};

Expanded.propTypes = {
  tabConfig: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      component: PropTypes.node
    })
  ),
  sortBy: PropTypes.string,
  getFilterterm: PropTypes.func,
  kpCmsId: PropTypes.string,
  docRank: PropTypes.number
};

export default Expanded;
