import { cases } from "redux/constants";

const casesLoadingReducer = (state = false, action) => {
  switch (action.type) {
    case cases.FETCH_CASES:
      return true;
    case cases.FETCH_CASES_SUCCESS:
    case cases.FETCH_CASES_FAILURE:
      return false;
    default:
      return state;
  }
};

export default casesLoadingReducer;
