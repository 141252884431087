import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import sendEventData from "analytics/analytics";
import { TRIGGERS, EVENTS } from "analytics/constants";
import LABEL from "constants/label";
import { Expand } from "../../Button";
import styles from "./styles.module.scss";

const { ProfileSummary } = LABEL.PROFILE_SUMMARY;
/**
 * List Limiter component
 *
 * @param  {number} limit - initial number of items to show
 * @param  {array} items - list of items
 * @param  {string} featured - this will become first item
 *
 * @return list of items with show less/show more link
 *
 * @example
 *
 * <List data={['one', 'two', 'three']} featured='zero'  />
 */
function List({ limit, items, featured }) {
  const [show, setShow] = useState(false);
  const [list, setList] = useState({ shortList: [], fullList: [] });
  const dataCount = items.length;
  const totalCount = featured ? dataCount + 1 : dataCount;
  const { shortList, fullList } = list;

  // show more link click handler
  const onClickHandler = () => {
    if (show) {
      window.$tabName = ProfileSummary; // Global variable for Analytics
      sendEventData(TRIGGERS.PROFILE_SUMMARY.SHOW_LESS_PROPOSAL_BIO, EVENTS.PROFILE_SUMMARY); // Analytics Event
    } else {
      window.$tabName = ProfileSummary; // Global variable for Analytics
      sendEventData(TRIGGERS.PROFILE_SUMMARY.SHOW_MORE_PROPOSAL_BIO, EVENTS.PROFILE_SUMMARY); // Analytics Event
    }
    setShow((prevShow) => !prevShow);
  };

  useEffect(() => {
    const processData = () => {
      let fullListArr = [];
      let shortListArr = [];
      if (featured) {
        fullListArr.push(<li key="featured">{featured}</li>);
      }

      items.forEach((item) => {
        fullListArr.push(<li key={item.key}>{item}</li>);
      });

      // get the first two items
      fullListArr.some((item, index) => {
        if (index === limit) return true;
        shortListArr.push(item);
        return false;
      });

      if (fullListArr.length) {
        fullListArr = <ul className={styles["list__limiter"]}>{fullListArr}</ul>;
      }

      if (shortListArr.length) {
        shortListArr = <ul className={styles["list__limiter"]}>{shortListArr}</ul>;
      }

      setList({ shortList: shortListArr, fullList: fullListArr });
    };

    processData();
  }, [featured, items, limit]);

  return totalCount > 0 ? (
    <>
      {show ? fullList : shortList}
      {totalCount > limit && <Expand expanded={show} onClick={onClickHandler} />}
    </>
  ) : null;
}

List.defaultProps = {
  limit: 2,
  featured: null
};

List.propTypes = {
  limit: PropTypes.number,
  featured: PropTypes.node,
  items: PropTypes.arrayOf(PropTypes.node).isRequired
};

export default List;
