import { about } from "redux/constants";

const aboutSponsersDetailsReducer = (state = false, action) => {
  switch (action.type) {
    case about.FETCH_ABOUT_SPONSER_DETAILS: {
      return true;
    }
    case about.FETCH_ABOUT_SPONSER_DETAILS_SUCCESS: {
      return false;
    }
    default:
      return state;
  }
};

export default aboutSponsersDetailsReducer;
