import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useQuery } from "helpers/utils";

const useMakeLinkedInQuery = () => {
  const location = useLocation();
  const query = useQuery(location.search);

  const linkedInError = query.get("error");
  const linkedInCode = query.get("code");

  const [linkedInQuery, setLinkedInQuery] = useState("");

  useEffect(() => {
    let linkedInParams = "";
    if (linkedInError)
      linkedInParams = `&error=${linkedInError}&error_description=${query.get("error_description")}&state=${query.get(
        "state"
      )}`;
    if (linkedInCode) linkedInParams = `&code=${linkedInCode}&state=${query.get("state")}`;
    setLinkedInQuery(linkedInParams);
  }, [location.search]);

  return { linkedInQuery, linkedInError, linkedInCode };
};

export default useMakeLinkedInQuery;
