import { topics } from "redux/constants";

const topicsLoadingReducer = (state = true, action) => {
  switch (action.type) {
    case topics.FETCH_TOPIC:
      return true;
    case topics.FETCH_TOPIC_SUCCESS:
    case topics.FETCH_TOPIC_FAILURE:
      return false;
    default:
      return state;
  }
};

export default topicsLoadingReducer;
