import { editProfile } from "redux/constants";

const proposalFileUploadingErrorReducer = (state = null, action) => {
  switch (action.type) {
    case editProfile.PROPOSAL_FILE_FAILURE:
      return action.error;
    case editProfile.PROPOSAL_FILE_SUCCESS:
    case editProfile.PROPOSAL_FILE:
      return null;
    default:
      return state;
  }
};

export default proposalFileUploadingErrorReducer;
