import LABEL from "constants/label";
import moment from "moment";

const {
  ACHIEVEMENTS: {
    KeyToPurpose,
    Bcg,
    Select,
    Award,
    ICONS: { KeyToPurposeIcon, ExternalAwardIcon, InternalAwardIcon }
  }
} = LABEL;

const toggleAwardIcon = (award) => {
  return award?.location?.toLowerCase() !== Bcg && award?.location !== Select ? ExternalAwardIcon : InternalAwardIcon;
};
const isKeyToPurposeAward = (award) => {
  return award?.name === KeyToPurpose || award?.awardNameRefId === 23;
};

const getAwardIconName = (award) => {
  return isKeyToPurposeAward(award) ? KeyToPurposeIcon : toggleAwardIcon(award);
};

const sortBy = (credentials, paramName, sortByDescendingOrder = true) => {
  const sortedData = credentials?.sort((credentialA, credentialB) => {
    return sortByDescendingOrder
      ? credentialB?.[paramName] - credentialA?.[paramName]
      : credentialA?.[paramName] - credentialB?.[paramName];
  });
  return sortedData;
};

const findRemainingArrayElements = (fullArray, knownArrayElements = [], primaryKey) => {
  return fullArray?.filter((record) => {
    return !knownArrayElements?.some((knownObj) => {
      return knownObj[primaryKey] === record[primaryKey];
    });
  });
};

const getAwardCredentialId = (record, recordType) => {
  return recordType === Award ? record?.awardId : record?.certificationId;
};

// for showing hidden record at end of non featured section
const arrangeNonFeaturedRecords = (records) => {
  return [...records?.filter((cred) => !cred?.hidden), ...records?.filter((cred) => cred?.hidden)];
};

// for adding expired param in credential object
const addExpiredParam = (records) => {
  return records?.map((record) => {
    const newRecord = { ...record };
    newRecord.expired = moment().format("YYYY-MM-DD") > moment(record?.certificationExpiry).format("YYYY-MM-DD");
    return newRecord;
  });
};

export {
  toggleAwardIcon,
  isKeyToPurposeAward,
  getAwardIconName,
  sortBy,
  findRemainingArrayElements,
  getAwardCredentialId,
  arrangeNonFeaturedRecords,
  addExpiredParam
};
