import { takeLatest } from "redux-saga/effects";

import { experience } from "redux/constants";
import handleFetchExperience from "../worker/handleFetchExperience";

function* watchExperience() {
  yield takeLatest(experience.FETCH_EXPERIENCE, handleFetchExperience);
}

export default watchExperience;
