import { entitlements } from "redux/constants";

const entitlementsLoadingReducer = (state = false, action) => {
  switch (action.type) {
    case entitlements.FETCH_ENTITLEMENTS:
      return true;
    case entitlements.FETCH_ENTITLEMENTS_SUCCESS:
    case entitlements.FETCH_ENTITLEMENTS_FAILURE:
      return false;
    default:
      return state;
  }
};

export default entitlementsLoadingReducer;
