import { ssCases } from "redux/constants";

const ssCasesReducer = (state = {}, action) => {
  switch (action.type) {
    case ssCases.FETCH_SSCASE_SUCCESS: {
      return { ...action.data };
    }
    default:
      return state;
  }
};

export default ssCasesReducer;
