import { ssRecentMaterials } from "redux/constants";

const ssRecentMaterialsReducer = (state = {}, action) => {
  switch (action.type) {
    case ssRecentMaterials.FETCH_RECENT_SSMATERIALS_SUCCESS: {
      return { ...action.data };
    }
    default:
      return state;
  }
};

export default ssRecentMaterialsReducer;
