import { skills } from "redux/constants";

const skillUpdatingErrorReducer = (state = null, action) => {
  switch (action.type) {
    case skills.UPDATE_SKILL_FAILURE:
      return action.error;
    case skills.UPDATE_SKILL_SUCCESS:
    case skills.UPDATE_SKILL:
      return null;
    default:
      return state;
  }
};

export default skillUpdatingErrorReducer;
