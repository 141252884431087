import { cco } from "redux/constants";

const ccoErrorReducer = (state = {}, action) => {
  switch (action.type) {
    case cco.FETCH_CCO_FAILURE:
      return action.error;
    case cco.FETCH_CCO_SUCCESS:
    case cco.FETCH_CCO:
      return null;
    default:
      return state;
  }
};

export default ccoErrorReducer;
