import { call, put as putSaga } from "redux-saga/effects";
import { put } from "redux/api";
import { setUpdateAboutKTSuccess, setUpdateAboutKTError } from "redux/actions/about";

function* handleUpdateAboutKT({ payload }) {
  const { data, error } = yield call(put, payload);
  if (error) {
    yield putSaga(setUpdateAboutKTError(error));
  } else {
    yield putSaga(setUpdateAboutKTSuccess(data));
  }
}

export default handleUpdateAboutKT;
