import { profileViews } from "redux/constants";

const saveProfileViews = (payload) => {
  return {
    type: profileViews.SAVE_PROFILE_VIEWS,
    payload
  };
};

const saveProfileViewsSuccess = (data) => ({
  type: profileViews.SAVE_PROFILE_VIEWS_SUCCESS,
  data
});

const saveProfileViewsFailure = (error) => ({
  type: profileViews.SAVE_PROFILE_VIEWS_FAILURE,
  error
});
export { saveProfileViews, saveProfileViewsSuccess, saveProfileViewsFailure };
