import { call, put } from "redux-saga/effects";
import { get } from "redux/api";
import { setProfileCompletion, setProfileCompletionError } from "redux/actions/profileCompletion";

function* handleFetchProfileCompletion({ payload }) {
  const { data, error } = yield call(get, payload);
  if (error) {
    yield put(setProfileCompletionError(error));
  } else {
    yield put(setProfileCompletion(data));
  }
}

export default handleFetchProfileCompletion;
