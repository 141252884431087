import { takeLatest } from "redux-saga/effects";

import { proposalBios } from "redux/constants";
import handleFetchProfileProposalBios from "../worker/handleFetchProfileProposalBios";

function* watchFetchProposalBios() {
  yield takeLatest(proposalBios.FETCH_PROPOSAL_BIOS, handleFetchProfileProposalBios);
}

export default watchFetchProposalBios;
