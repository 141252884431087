import { useEffect } from "react";
import { Button } from "semantic-ui-react";
import { useHistory, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import LABEL from "constants/label";
import "./styles.scss";

const { ERRORS } = LABEL;

function ErrorPage({ code, message, title, showBtn = true, isError, showSupportBtn, header }) {
  const location = useLocation();
  const errorCode = code || location?.state?.code || "";
  const history = useHistory();
  let newMessage = message;
  let newTitle = title;
  let newHeader = header;

  switch (errorCode) {
    case "400":
    case 400: {
      newTitle = ERRORS.NewTitle400;
      newMessage = ERRORS.NewMessage400;
      break;
    }

    case "401":
    case 401: {
      newTitle = ERRORS.NewTitle401;
      newMessage = ERRORS.NewMessage401;
      break;
    }

    case "403":
    case 403: {
      newTitle = ERRORS.NewTitle403;
      newMessage = ERRORS.NewMessage403;
      break;
    }

    case "404":
    case 404: {
      newTitle = ERRORS.NewTitle404;
      newMessage = ERRORS.NewMessage404;
      break;
    }

    case "500":
    case 500: {
      newTitle = ERRORS.NewTitle500;
      newMessage = ERRORS.NewMessage500;
      break;
    }

    case "IE11": {
      newTitle = ERRORS.NewTitleIE;
      newMessage = ERRORS.NewMessageIE;
      break;
    }

    case "SAFARI": {
      newTitle = ERRORS.NewTitleSafari;
      newMessage = ERRORS.NewMessageIE;
      break;
    }

    case "LOA": {
      newHeader = ERRORS.NewHeaderOOPS;
      newTitle = ERRORS.NewTitleLOA;
      newMessage = ERRORS.NewMessageLOA;
      break;
    }
    case "CT": {
      newHeader = ERRORS.NewHeaderOOPS;
      newMessage = ERRORS.NewTitleCT;
      break;
    }
    default: {
      newTitle = ERRORS.NewTitleGen;
      newMessage = ERRORS.NewMessageGen;
      break;
    }
  }

  useEffect(() => {
    if (errorCode) {
      isError(true);
    }
  }, []);

  const redirectToHome = () => {
    isError(false);
    history.push("/overview");
  };

  return (
    <section className="error__container d-flex justify-content-center align-items-center">
      <h1 className="error__code m-0">{newHeader || errorCode}</h1>
      {newTitle ? <p className="error__title fw-bold">{newTitle}</p> : null}
      {newMessage ? <p className="error__message">{newMessage}</p> : null}
      {showSupportBtn ? (
        <a href="mailto:bostonconsultinggroup@service-now.com" className="error__button ui primary button">
          {ERRORS.ContactServiceCenter}
        </a>
      ) : null}
      {showBtn && errorCode !== 401 && errorCode !== "401" && (
        <Button primary onClick={redirectToHome} className="error__button text-align-center">
          {ERRORS.GoHome}
        </Button>
      )}

      {/* {showBtn && (errorCode === 401 || errorCode === "401") && (
       
      )} */}
    </section>
  );
}

ErrorPage.defaultProps = {
  code: "",
  title: "",
  message: "",
  showBtn: true,
  isError: () => {},
  header: "",
  showSupportBtn: false
};

ErrorPage.propTypes = {
  code: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  message: PropTypes.string,
  showBtn: PropTypes.bool,
  isError: PropTypes.func,
  showSupportBtn: PropTypes.bool,
  header: PropTypes.string
};
export default ErrorPage;
