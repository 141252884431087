import React from "react";
import PropTypes from "prop-types";
import { Icon, Popover } from "components/common";

import "./styles.scss";

const InfoIcon = ({ children }) => {
  return (
    <Popover
      trigger={
        <div className="cursor-pointer">
          <Icon name="info" size={16} />
        </div>
      }
      on="hover"
      inverted
      position="bottom center"
      popper={{ className: "clipboard-tooltip info__area" }}
      size="large"
    >
      {children}
    </Popover>
  );
};

InfoIcon.propTypes = {
  children: PropTypes.element.isRequired
};

export default InfoIcon;
