import React from "react";
import PropTypes from "prop-types";
import { List } from "semantic-ui-react";

import "../styles.scss";

function ProfileImageOptions({ options }) {
  return (
    <List divided relaxed className="profile__image--options" data-testid="profile-image-options">
      {options?.map((option) => (
        <List.Item key={option?.id} onClick={option?.callback}>
          {option?.iconName && (
            <div className="newIcon">
              <img src={option.iconName} alt="new" title="new icon" width={23} height={20} />
            </div>
          )}
          <div className="title">{option?.title}</div>
          <div className="subtitle">{option?.subTitle}</div>
        </List.Item>
      ))}
    </List>
  );
}

ProfileImageOptions.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      callback: PropTypes.func
    })
  ).isRequired
};

export default ProfileImageOptions;
