import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { Label } from "semantic-ui-react";
import ControlledPopover from "components/common/ControlledPopover";
import { Icon as ProfilesCustomIcon } from "components/common";
import "./styles.scss";

function AssistantTo({ assistantTo = [] }) {
  const [withExecutivesData, setWithExecutivesData] = useState(false);
  const [sortedExecutivesData, setSortedExecutivesData] = useState([]);
  const [popupExecutivesData, setPopupExecutivesData] = useState([]);
  const [popupExecutiveCounter, setPopupExecutiveCounter] = useState(0);
  const [isShowPopup, setIsShowPopup] = useState(false);

  const MIN_EXEC_DISPLAY_COUNT = 3;

  const getExecutiveProfile = (url, id) => {
    const arr = url.split("/");
    return `${arr[0]}//${arr[2]}/overview?hrid=${id}`;
  };

  const sortExecutivesData = () => {
    return assistantTo?.sort((a, b) => {
      // eslint-disable-next-line no-nested-ternary
      return a.firstName < b.firstName ? -1 : a.firstName > b.firstName ? 1 : 0;
    });
  };

  const popupOffset = ({ popper }) => {
    return [popper.height / 2 - 12, 5];
  };

  const openPopup = () => setIsShowPopup(true);
  const closePopup = () => setIsShowPopup(false);

  useEffect(() => {
    const withExecs = !!assistantTo && assistantTo?.length;
    const sortedExecs = sortExecutivesData();
    const popupExecs = sortedExecs?.splice(MIN_EXEC_DISPLAY_COUNT);

    setWithExecutivesData(withExecs);
    setPopupExecutivesData(withExecs ? popupExecs : []);
    setSortedExecutivesData(sortedExecs);
    setPopupExecutiveCounter(popupExecs?.length);
  }, [assistantTo]);

  return (
    <div className="assistant-to-info">
      {!!withExecutivesData && (
        <>
          <span className="d-block" data-testid="assistant-to-label">
            Assistant To:
          </span>
          {!!withExecutivesData &&
            sortedExecutivesData?.map((execData, index) => (
              <p key={`main-ea-to-${execData.id}`}>
                <a
                  key={`ea-to-${execData.id}`}
                  target="blank"
                  rel="noreferrer noopener"
                  href={getExecutiveProfile(window.location.href, execData.id)}
                  data-testid="assistant-to-main-list-item"
                >
                  {`${execData.firstName} ${execData.lastName}`}
                </a>
                {MIN_EXEC_DISPLAY_COUNT > 0 && index === MIN_EXEC_DISPLAY_COUNT - 1 && !!popupExecutiveCounter && (
                  <ControlledPopover
                    trigger={
                      <Label
                        circular
                        className="assistant-to-info__extra-exec--launcher"
                        as="span"
                        data-testid="assistant-to-count-plus"
                      >
                        {`+${popupExecutiveCounter}`}
                      </Label>
                    }
                    basic
                    on="click"
                    openState={isShowPopup}
                    onOpen={() => openPopup()}
                    onClose={() => closePopup()}
                    position="right center"
                    offset={popupOffset}
                    className="assistant-to-info__extra-exec-popup"
                    hideOnScroll={null}
                    pinned
                    data-testid="assistant-to-popup"
                  >
                    <Label
                      circular
                      className="assistant-to-info__extra-exec__popup-close-panel"
                      as="span"
                      onClick={() => closePopup()}
                      data-testid="assistant-to-popup-close"
                    >
                      <ProfilesCustomIcon
                        className="assistant-to-info__extra-exec--popup__close"
                        name="close"
                        size={18}
                      />
                    </Label>
                    {popupExecutivesData?.map((popupExec) => (
                      <p key={`sub-ea-to-${popupExec.id}`}>
                        <a
                          key={`ea-to-${popupExec.id}`}
                          target="blank"
                          rel="noreferrer noopener"
                          href={getExecutiveProfile(window.location.href, popupExec.id)}
                          data-testid="assistant-to-sub-list-item"
                        >
                          {`${popupExec.firstName} ${popupExec.lastName}`}
                        </a>
                      </p>
                    ))}
                  </ControlledPopover>
                )}
              </p>
            ))}
        </>
      )}
    </div>
  );
}

AssistantTo.defaultProps = {
  assistantTo: null
};

AssistantTo.propTypes = {
  assistantTo: PropTypes.arrayOf(PropTypes.shape())
};

export default AssistantTo;
