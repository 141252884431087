import { editProfile } from "redux/constants";

const resumeFileUploadingReducer = (state = false, action) => {
  switch (action.type) {
    case editProfile.RESUME_FILE:
      return true;
    case editProfile.RESUME_FILE_SUCCESS:
    case editProfile.RESUME_FILE_FAILURE:
      return false;
    default:
      return state;
  }
};

export default resumeFileUploadingReducer;
