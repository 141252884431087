import { takeLatest } from "redux-saga/effects";

import { profileCompletion } from "redux/constants";
import handleFetchProfileCompletion from "../worker/handleFetchProfileCompletion";

function* watchProfileCompletion() {
  yield takeLatest(profileCompletion.FETCH_PROFILE_COMPLETION, handleFetchProfileCompletion);
}

export default watchProfileCompletion;
