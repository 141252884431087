import { proposalBios } from "redux/constants";

const getProposalBios = (payload) => {
  return {
    type: proposalBios.FETCH_PROPOSAL_BIOS,
    payload
  };
};

const setProposalBios = (data) => ({
  type: proposalBios.FETCH_PROPOSAL_BIOS_SUCCESS,
  data
});

const setProposalBiosError = (error) => ({
  type: proposalBios.FETCH_PROPOSAL_BIOS_FAILURE,
  error
});

export { getProposalBios, setProposalBios, setProposalBiosError };
