import CONFIG from "constants/config";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRecentSSCases } from "redux/actions/ssRecentCases";

const { apiUrl, xApiKey } = CONFIG;

const useFetchRecentCases = (profile, isErrorApi) => {
  const dispatch = useDispatch();

  const [recentCases, setRecentCases] = useState([]);

  const { ssRecentCases } = useSelector((state) => state);

  const { ssRecentCasesLoading } = useSelector((state) => state);

  const [totalCases, setTotalCases] = useState(null);

  useEffect(() => {
    if (profile?.hrId && !isErrorApi) {
      dispatch(
        getRecentSSCases({
          endpoint: `${apiUrl.ssRecentCases}${profile?.hrId}&resultsPerPage=${4}&resultsFromPage=1`,
          config: {
            headers: {
              "x-api-key": `${xApiKey.smartsearch}`
            }
          }
        })
      );
    }
  }, [profile?.hrId, dispatch]);

  useEffect(() => {
    if (ssRecentCases.TotalCount) setTotalCases(ssRecentCases.TotalCount.toLocaleString());
    else if (!ssRecentCasesLoading && ssRecentCases.TotalCount === undefined) setTotalCases("0");
    else setTotalCases("--");
  }, [recentCases, ssRecentCases]);

  useEffect(() => {
    setRecentCases(ssRecentCases.doc);
  }, [ssRecentCases]);

  return {
    ssRecentCasesLoading,
    recentCases,
    totalCases
  };
};

export default useFetchRecentCases;
