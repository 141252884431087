import { takeLatest } from "redux-saga/effects";

import { cvBioDownloadDetails } from "redux/constants";
import handleCvBiodownloadDetails from "../worker/handleCvBioDownloadDetails";

function* watchCvBioDownloadDetails() {
  yield takeLatest(cvBioDownloadDetails.FETCH_CV_BIO_DOWNLOAD_DETAILS, handleCvBiodownloadDetails);
}

export default watchCvBioDownloadDetails;
