import { call, put } from "redux-saga/effects";
import { get } from "redux/api";
import { setProfileImageError, setProfileImage } from "redux/actions/profileImage";
import { Buffer } from "buffer";

function* handleFetchProfileImage({ payload }) {
  const { data, error } = yield call(get, payload);
  if (data) {
    const imgData = { img: `data:image/png;base64,${Buffer.from(data, "binary").toString("base64")}` };
    yield put(setProfileImage(imgData));
  }
  if (error) yield put(setProfileImageError(error));
}

export default handleFetchProfileImage;
