import axios from "axios";
import authService from "auth/auth";
import { toastError } from "helpers/utils";
import { getNumberOfRelaodAttempts, reloadApplicationOnAuthError, setNumberOfRelaodAttempts } from "auth/utils";
import LABEL from "constants/label";
import Cookies from "js-cookies";

axios.interceptors.request.use(async (config) => {
  const correlationid = crypto.randomUUID();
  const accessToken = await authService?.getAccessToken();
  let authHeader = {};
  if (typeof config.headers["noAuthHeader"] === "undefined") {
    // send no bearer token at all if noAuthHeader is set true
    const conditionHeader = config.headers["customAuthHeader"] !== "undefined" && !!config.headers["customAuthHeader"];
    authHeader = {
      [conditionHeader ? "specifiedAuthorization" : "authorization"]: `Bearer ${accessToken}`
    };
  }
  return {
    ...config,
    headers: {
      ...config.headers,
      ...authHeader,
      "x-correlation-id": correlationid,
      "x-datdog-trace-id": correlationid
    }
  };
});

/**
 * checkImpersonateStatus method
 * returns header object
 */

const checkImpersonateStatus = (config) => {
  const isImpersonate = Cookies.getItem("impersonateUser");
  const impersonatingHrId = Cookies.getItem("impersonatedUserHrId");

  let header;
  if (!isImpersonate) {
    if (config) {
      header = { ...config };
    } else {
      header = { withCredentials: true };
    }
    return header;
  }
  // if impersonate
  if (isImpersonate) {
    header = {
      ...config,
      headers: { ...config.headers, canImpersonate: isImpersonate, impersonatingHrId }
    };
  } else {
    header = { withCredentials: true, headers: { canImpersonate: isImpersonate } };
  }
  return header;
};

/**
 * the POST method
 * @param {*} endpointUrl endpoint url, without base-url
 * @param {*} data data object to be posted
 * @param {*} config additional config
 */
async function post({ endpointUrl, data, config }) {
  const tempConfig = checkImpersonateStatus(config);
  let newConfig;
  // for differnciate between LINK and People API
  if (tempConfig.headers) {
    newConfig = config;
  } else {
    newConfig = tempConfig;
  }
  try {
    const res = await axios.post(endpointUrl, data, newConfig);
    return { res, error: null };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    return { res: null, error };
  }
}

/**
 * the PUT method
 * @param {*} endpoint endpoint url, without base-url
 * @param {*} data data object to be posted
 * @param {*} config additional config
 */
async function put({ endpoint, data, config }) {
  const newConfig = checkImpersonateStatus(config);
  try {
    const res = await axios.put(endpoint, data, newConfig);
    return { res, error: null };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    return { res: null, error };
  }
}

/**
 * the Get method
 * @param {*} endpoint endpoint url, without base-url
 * @param {*} config additional config
 */
async function get(payload) {
  const { endpoint, config = null, showToast = false } = payload;
  // const header = checkImpersonateStatus();

  try {
    const { data } = await axios.get(endpoint, config);
    return { data, error: null };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    if (showToast !== false) {
      // toast messages
      let msg = "";
      const url = error?.response?.config?.url;
      const reloadAttemps = getNumberOfRelaodAttempts();
      if (url?.includes("people")) {
        msg = LABEL.ERRORS.PeopleErrMsg;
        if ((!reloadAttemps || reloadAttemps < 2) && error?.response?.status === 401) {
          setNumberOfRelaodAttempts();
          reloadApplicationOnAuthError();
          return null;
        }
      } else if (url?.includes("case-search")) {
        msg = LABEL.ERRORS.CaseErrMsg;
        if ((!reloadAttemps || reloadAttemps < 2) && error?.response?.status === 401) {
          setNumberOfRelaodAttempts();
          reloadApplicationOnAuthError();
          return null;
        }
      } else if (url?.includes("knowledge-search")) {
        msg = LABEL.ERRORS.MaterialErrMsg;
        if ((!reloadAttemps || reloadAttemps < 2) && error?.response?.status === 401) {
          setNumberOfRelaodAttempts();
          reloadApplicationOnAuthError();
          return null;
        }
      } else if (url?.includes("api-knowledge")) {
        msg = LABEL.ERRORS.LinkErrMsg;
        if ((!reloadAttemps || reloadAttemps < 2) && error?.response?.status === 401) {
          setNumberOfRelaodAttempts();
          reloadApplicationOnAuthError();
          return null;
        }
      }
      switch (error?.response?.status) {
        case 404:
          toastError(10000, LABEL.ERRORS.NotFound, msg);
          break;
        case 500:
        case 400:
          toastError(10000, LABEL.ERRORS.ServerError, msg);
          break;
        case 401:
          toastError(10000, LABEL.ERRORS.AuthError, msg);
          break;
        case 403:
          toastError(10000, LABEL.ERRORS.PageForbidden, msg);
          break;
        case 502:
          toastError(10000, LABEL.ERRORS.BadGateway, msg);
          break;
        default:
          break; // do nothing in case of sucessfully fetching
      }
      // in case of silent newtowk error
      if (error?.message === LABEL.ERRORS.NetworkError) {
        toastError(10000, LABEL.ERRORS.NetworkError, msg);
      }
    }
    return { data: null, error };
  }
}

/**
 * the Delete method
 * @param {*} endpointUrl endpoint url, without base-url
 * @param {*} config additional config
 */
async function delet({ endpointUrl, config }) {
  const newConfig = checkImpersonateStatus(config);
  try {
    const res = await axios.delete(endpointUrl, newConfig);
    return { res, error: null };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    return { res: null, error };
  }
}

export { get, post, put, delet as delete };
