import { takeEvery } from "redux-saga/effects";

import { upload } from "redux/constants";
import handleUploadProposalBioToProfile from "../worker/handleUploadProposalBioToProfile";

function* watchUploadProposalBioToProfile() {
  yield takeEvery(upload.UPLOAD_PROPOSAL_BIO_T0_PROFILE, handleUploadProposalBioToProfile);
}

export default watchUploadProposalBioToProfile;
