/* eslint-disable no-nested-ternary */
import PropTypes from "prop-types";
import { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, List, Icon } from "semantic-ui-react";
import * as utils from "@kdshared/okta-utils";
import authService from "auth/auth";
import CONFIG from "constants/config";
import { toastError } from "helpers/utils";
import LABEL from "constants/label";
import { getPeople, getPeopleImage } from "redux/actions/people";
import styles from "./style.module.scss";

const { xApiKey, apiUrl } = CONFIG;

/**
 * PeoplePicker
 *
 * @param  {string} currentValue Sponsor name from Link DB
 * @param  {string} placeholderText custom placeholder text
 * @param  {bool} readOnly indicates that this picker has no other function than display and read-only
 * @param  {func} callback function fires when picker value changes
 * @param  {number} hrid sponsor HRID
 *
 * @returns People picker component
 */

const PeoplePicker = ({ currentValue, placeholderText, readOnly, callback, hrid }) => {
  const dispatch = useDispatch();
  const { people, peopleImage } = useSelector((state) => state);

  // local states
  const [loading, setLoading] = useState(false);
  const [val, setVal] = useState("");

  const [option, setOption] = useState();
  const [peopleId, setPeopleId] = useState(hrid);
  const [selectedItem, setSelectedItem] = useState(currentValue);
  const [fetched, setFetched] = useState(false);

  const placeholder = placeholderText || "Search for a user";

  // Fetach people details from people API
  const fetchPeopleDetails = async (personName) => {
    setLoading(true);
    try {
      dispatch(
        getPeople({
          endpointUrl: `${apiUrl.peopleV2Data}`,
          data: {
            query: `
            query {
              searchFilter(
                compositeFilter: {
                  logicOperator: AND
                  subFilters: [
                    {
                      logicOperator: OR
                      subFilters: [
                        { valueFilter: { field: "firstName=${personName}*" } }
                        { valueFilter: { field: "lastName=${personName}*" } }
                        { valueFilter: { field: "name=${personName}*" } }
                        { valueFilter: { field: "phone=${personName}*" } }
                        { valueFilter: { field: "mobile=${personName}*" } }
                      ]
                    }
                    {
                      logicOperator: NOT
                      subFilters: [
                        { valueFilter: { field:"name=Anonymized" } }
                      ]
                    }
                    {
                      logicOperator: NOT
                      subFilters: [
                        { valueFilter: { field:"status=Contract-Terminated" } }
                      ]
                    }
                    {
                      logicOperator: NOT
                      subFilters: [
                        { valueFilter: { field:"status=Temporary-Terminated" } }
                      ]
                    }
                  ]
                }
                limit:25
                offset:0
                dataSet:BCG_ALL
              ){
                totalCount
                employees {
                  id 
                  firstName
                  lastName
                  preferredName
                  preferredFirstName
                  preferredLastName
                  workEmail 
                  businessTitle
                  jobCategory
                  profilePicture
                  status
                  hostOfficeLocation {
                    id
                    name
                  }   
                  id
                  title
                }
              }
            }`
          },
          config: {
            headers: {
              "x-api-key": `${xApiKey.people}`,
              psid: utils.getPsId()
            }
          }
        })
      );
    } catch (error) {
      setLoading(false);
      toastError(0, LABEL.ERRORS.AuthError, LABEL.ERRORS.PeopleErrMsg);
    }
  };
  useEffect(() => {
    if (loading) {
      setLoading(false);
      const temparr = [];
      people.forEach((element) => {
        temparr.push({
          key: element.id,
          id: element.id,
          text: element.preferredName,
          value: { name: element.preferredName, hrid: element.id },
          rendercontent: element.preferredName,
          content: (
            <List>
              <List.Item>
                <List.Content floated="right">
                  {element.businessTitle} , {element.hostOfficeLocation?.name}
                </List.Content>
                <List.Content>{element.preferredName}</List.Content>
              </List.Item>
            </List>
          )
        });
      });
      setOption(temparr);
    }
  }, [people]);

  // Fetch image based on selected person
  const fetchImageHandler = async (hrEmployeeId) => {
    try {
      dispatch(
        getPeopleImage({
          endpoint: `${apiUrl.peopleImage}${hrEmployeeId}`,
          config: {
            headers: {
              "x-api-key": `${xApiKey.people}`,
              psid: utils.getPsId()
            },
            responseType: "arraybuffer"
          },
          hrid: hrEmployeeId
        })
      );
    } catch (error) {
      toastError(0, LABEL.ERRORS.AuthError, LABEL.ERRORS.PeopleErrMsg);
    }
  };

  // if current value fetch user image from peopleApi

  useEffect(() => {
    if (currentValue && !fetched) {
      fetchImageHandler(hrid);
    }
  }, [currentValue]);

  // Handle input value change
  const handleChange = (e, data) => {
    setVal(e.target.value);
    fetchPeopleDetails(data.value);
  };

  // Delete
  const handleDelete = () => {
    setSelectedItem();
    setPeopleId();
    callback("");
  };

  // Function on item change
  const handleClick = (value) => {
    // If value
    if (value) {
      setSelectedItem(value.name);
      setPeopleId(value.hrid);
      fetchImageHandler(value.hrid);
      setFetched(true);
    }
    setOption();
    setVal("");
    callback(value);
  };

  const renderList = () => {
    return option.map((item) => {
      return (
        <li
          key={item.id}
          className="item"
          onKeyDown={() => {}}
          onClick={() => handleClick(item.value)}
          aria-hidden="true"
        >
          {item.content}
        </li>
      );
    });
  };

  // Detect Click outside
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOption();
          setVal("");
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <div className="people-picker position-relative">
      <Input
        data-testid="peoplepicker"
        fluid
        placeholder={placeholder}
        onChange={(e, data) => handleChange(e, data)}
        loading={loading}
        disabled={readOnly || selectedItem?.length > 0}
        value={val}
      />
      {selectedItem && (
        <span className="mr-1 float-left chip" data-testid="chip">
          <img src={peopleImage[peopleId]} alt="" className={`float-left mr-h ${styles["people-picker__avatar"]}`} />
          <span className={`${styles["people-picker__avatarName"]}`}>
            <strong>{selectedItem || currentValue}</strong>
          </span>
          {!readOnly && <Icon name="delete" data-testid="delete" onClick={handleDelete} />}
        </span>
      )}
      {option && (
        <ul className="menu m-0 p-0" ref={wrapperRef} data-testid="option">
          {renderList()}
        </ul>
      )}
    </div>
  );
};

PeoplePicker.defaultProps = {
  currentValue: "",
  placeholderText: "",
  readOnly: false,
  hrid: null,
  callback: () => {}
};

PeoplePicker.propTypes = {
  currentValue: PropTypes.string,
  placeholderText: PropTypes.string,
  readOnly: PropTypes.bool,
  callback: PropTypes.func,
  hrid: PropTypes.number
};

export default PeoplePicker;
