import React from "react";
import { Portal } from "react-overlays";
import PropTypes from "prop-types";

function CalendarContainer({ children }) {
  const e = document.getElementById("calendar-portal");

  return <Portal container={e}>{children}</Portal>;
}

CalendarContainer.defaultProps = {
  children: ""
};

CalendarContainer.propTypes = {
  children: PropTypes.string
};

export default CalendarContainer;
