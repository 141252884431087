import { takeLatest } from "redux-saga/effects";

import { language } from "redux/constants";
import handleFetchLanguages from "../worker/handleFetchLanguages";

function* watchLanguages() {
  yield takeLatest(language.FETCH_LANGUAGE, handleFetchLanguages);
}

export default watchLanguages;
