/* eslint-disable no-extra-boolean-cast */
import React from "react";
import PropTypes from "prop-types";
import { Table } from "semantic-ui-react";
import { formatDateByMoment, getDuration } from "helpers/utils";
import LABEL from "constants/label";

const { TABLE_HEADER } = LABEL.EXPERIENCE;
const { Present, NA } = LABEL.COMMON;

function LinkedInRoleParentRow({ experience }) {
  return (
    <Table.Row key={experience?.companyName} className={experience?.roles?.length > 1 ? "role__tree-row" : ""}>
      <Table.Cell
        className={
          experience?.roles?.length > 1
            ? "d-flex align-items-center role__tree-first-cell"
            : "d-flex align-items-center"
        }
      >
        <span className="experience__item__image justify-content-center align-items-center">
          <img src="/icons/ic_experience.svg" alt="experience" />
        </span>
        <span className="td-label">{TABLE_HEADER.CompanyOrganization}</span>
        {experience?.companyName ? experience?.companyName : "-"}
      </Table.Cell>
      <Table.Cell className={experience?.roles?.length > 1 ? "role__tree-second-cell" : ""}>
        <span className="td-label mt-3">{TABLE_HEADER.Role} </span>{" "}
        {experience?.roles[0]?.title ? experience?.roles[0]?.title : "-"}
      </Table.Cell>
      <Table.Cell>
        <span className="td-label">{TABLE_HEADER.StartEndDate} </span>
        {(!!experience?.roles[0]?.startYear &&
          formatDateByMoment(
            new Date(
              experience?.roles[0]?.startYear,
              !!experience?.roles[0]?.startMonth
                ? experience?.roles[0]?.startMonth - 1
                : experience?.roles[0]?.startMonth
            ),
            "MMM DD, YYYY"
          )) ||
          NA}{" "}
        –{" "}
        {(experience?.roles[0]?.endYear &&
          formatDateByMoment(
            new Date(
              experience?.roles[0]?.endYear,
              !!experience?.roles[0]?.endMonth ? experience?.roles[0]?.endMonth - 1 : experience?.roles[0]?.endMonth
            ),
            "MMM DD, YYYY"
          )) || <span data-testid="ongoing">{Present}</span>}{" "}
        {!!experience?.roles[0]?.startYear && (
          <span data-testid="duration">
            |{" "}
            {getDuration(
              (!!experience?.roles[0]?.endYear &&
                new Date(
                  experience?.roles[0]?.endYear,
                  !!experience?.roles[0]?.endMonth ? experience?.roles[0]?.endMonth : experience?.roles[0]?.endMonth - 1
                )) ||
                new Date(),
              new Date(
                experience?.roles[0]?.startYear,
                experience?.roles[0]?.startMonth
                  ? experience?.roles[0]?.startMonth - 1
                  : experience?.roles[0]?.startMonth
              )
            )}
          </span>
        )}
      </Table.Cell>
      <Table.Cell>
        <span className="td-label">{TABLE_HEADER.Location}</span>{" "}
        {experience?.roles[0]?.location ? experience?.roles[0]?.location : "-"}
      </Table.Cell>
    </Table.Row>
  );
}

LinkedInRoleParentRow.defaultProps = {
  experience: null
};

LinkedInRoleParentRow.propTypes = {
  experience: PropTypes.shape({
    companyName: PropTypes.string,
    companyDesc: PropTypes.string,
    roles: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        endYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        startYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        endMonth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        startMonth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        location: PropTypes.string
      })
    )
  })
};

export default LinkedInRoleParentRow;
