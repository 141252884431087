import { combineReducers } from "redux";

import errorReducer from "./errorReducer";

import userReducer from "./user/userReducer";
import userLoadingReducer from "./user/userLoadingReducer";
import userErrorReducer from "./user/userErrorReducer";

import profileLoadingReducer from "./profile/profileLoadingReducer";
import profileReducer from "./profile/profileReducer";
import profileErrorReducer from "./profile/profileErrorReducer";

import profileImageLoadingReducer from "./profileImage/profileImageLoadingReducer";
import profileImageReducer from "./profileImage/profileImageReducer";
import profileImageErrorReducer from "./profileImage/profileImageErrorReducer";

import profileImageHighResLoadingReducer from "./profileImageHighRes/profileImageHighResLoadingReducer";
import profileImageHighResReducer from "./profileImageHighRes/profileImageHighResReducer";
import profileImageHighResErrorReducer from "./profileImageHighRes/profileImageHighResErrorReducer";

import experienceLoadingReducer from "./experience/experienceLoadingReducer";
import experienceReducer from "./experience/experienceReducer";
import experienceErrorReducer from "./experience/experienceErrorReducer";

import educationLoadingReducer from "./education/educationLoadingReducer";
import educationReducer from "./education/educationReducer";
import educationErrorReducer from "./education/educationErrorReducer";

import achievementsLoadingReducer from "./achievements/achievementsLoadingReducer";
import achievementsReducer from "./achievements/achievementsReducer";
import achievementsErrorReducer from "./achievements/achievementsErrorReducer";

import casesLoadingReducer from "./cases/casesLoadingReducer";
import casesReducer from "./cases/casesReducer";
import caseResponsibilityLoadingReducer from "./cases/caseResponsibilityLoadingReducer";
import caseResponsibilityReducer from "./cases/caseResponsibilityReducer";
import setAllCasesReducer from "./cases/setAllCasesReducer";
import casesErrorReducer from "./cases/casesErrorReducer";
import caseUpdatingReducer from "./cases/caseUpdatingReducer";
import caseUpdatingErrorReducer from "./cases/caseUpdatingErrorReducer";

import materialsLoadingReducer from "./materials/materialsLoadingReducer";
import materialsReducer from "./materials/materialsReducer";
import materialsErrorReducer from "./materials/materialsErrorReducer";

import topicsLoadingReducer from "./topics/topicsLoadingReducer";
import topicsReducer from "./topics/topicsReducer";
import topicsErrorReducer from "./topics/topicsErrorReducer";
import topicUpdatingReducer from "./topics/topicUpdatingReducer";
import topicUpdatingErrorReducer from "./topics/topicUpdatingErrorReducer";
import topicDeletingReducer from "./topics/topicDeletingReducer";
import topicDeletingErrorReducer from "./topics/topicDeletingErrorReducer";
import topicSummaryDeletingReducer from "./topics/topicSummaryDeletingReducer";
import topicSummaryDeletingErrorReducer from "./topics/topicSummaryDeletingErrorReducer";
import businessSubjectsReducer from "./topics/businessSubjectsReducer";
import businessSubjectsErrorReducer from "./topics/businessSubjectsErrorReducer";
import businessSubjectsLoadingReducer from "./topics/businessSubjectsLoadingReducer";

import skillsLoadingReducer from "./skills/skillsLoadingReducer";
import skillsReducer from "./skills/skillsReducer";
import skillsToolsReducer from "./skills/skillsToolsReducer";
import skillsToolsErrorReducer from "./skills/skillsToolsErrorReducer";
import skillsToolsLoadingReducer from "./skills/skillsToolsLoadingReducer";
import skillsErrorReducer from "./skills/skillsErrorReducer";
import skillUpdatingReducer from "./skills/skillUpdatingReducer";
import skillUpdatingErrorReducer from "./skills/skillUpdatingErrorReducer";
import skillDeletingReducer from "./skills/skillDeletingReducer";
import skillDeletingErrorReducer from "./skills/skillDeletingErrorReducer";
import skillSummaryDeletingReducer from "./skills/skillSummaryDeletingReducer";
import skillSummaryDeletingErrorReducer from "./skills/skillSummaryDeletingErrorReducer";

import ssCasesLoadingReducer from "./ssCases/ssCasesLoadingReducer";
import ssCasesReducer from "./ssCases/ssCasesReducer";
import ssCasesErrorReducer from "./ssCases/ssCasesErrorReducer";

import ssMaterialsReducer from "./ssMaterials/ssMaterialsReducer";
import ssMaterialsLoadingReducer from "./ssMaterials/ssMaterialsLoadingReducer";
import ssMaterialsErrorReducer from "./ssMaterials/ssMaterialsErrorReducer";

import awardNamesLoadingReducer from "./awardNames/awardNamesLoadingReducer";
import awardNamesReducer from "./awardNames/awardNamesReducer";
import awardNamesErrorReducer from "./awardNames/awardNamesErrorReducer";

import awardTypesLoadingReducer from "./awardTypes/awardTypesLoadingReducer";
import awardTypesReducer from "./awardTypes/awardTypesReducer";
import awardTypesErrorReducer from "./awardTypes/awardTypesErrorReducer";

import topicsFinderLoadingReducer from "./finder/topicsFinderLoadingReducer";
import topicsFinderReducer from "./finder/topicsFinderReducer";
import topicsFinderErrorReducer from "./finder/topicsFinderErrorReducer";

import topicsSkillsSearchLoadingReducer from "./finder/topicsSkillsSearchLoadingReducer";
import topicsSkillsSearchReducer from "./finder/topicsSkillsSearchReducer";
import topicsSkillsSearchErrorReducer from "./finder/topicsSkillsSearchErrorReducer";

import topicsSkillFinderLoadingReducer from "./finder/topicsSkillFinderLoadingReducer";
import topicsSkillFinderReducer from "./finder/topicsSkillFinderReducer";
import topicsSkillFinderErrorReducer from "./finder/topicsSkillFinderErrorReducer";

import profileUpdateHistoryReducer from "./profileUpdateHistory/profileUpdateHistoryReducer";
import profileUpdateHistoryLoadingReducer from "./profileUpdateHistory/profileUpdateHistoryLoadingReducer";
import profileUpdateHistoryErrorReducer from "./profileUpdateHistory/profileUpdateHistoryErrorReducer";

import staffIdLoadingReducer from "./staffId/staffIdLoadingReducer";
import staffIdReducer from "./staffId/staffIdReducer";
import staffIdErrorReducer from "./staffId/staffIdErrorReducer";

import expertiseListLoadingReducer from "./expertiseList/expertiseListLoadingReducer";
import expertiseListReducer from "./expertiseList/expertiseListReducer";
import expertiseListErrorReducer from "./expertiseList/expertiseListErrorReducer";

import childTopicsFinderLoadingReducer from "./finder/childTopicsFinderLoadingReducer";
import childTopicsFinderReducer from "./finder/childTopicsFinderReducer";
import childTopicsFinderErrorReducer from "./finder/childTopicsFinderErrorReducer";
import accessRolesReducer from "./accessRoles/accessRolesReducer";

import awardUpdatingReducer from "./award/awardUpdatingReducer";
import awardUpdatingErrorReducer from "./award/awardUpdatingErrorReducer";
import awardDeletingReducer from "./award/awardDeletingReducer";
import awardDeletingErrorReducer from "./award/awardDeletingErrorReducer";

import certificateUpdatingReducer from "./certificate/certificateUpdatingReducer";
import certificateUpdatingErrorReducer from "./certificate/certificateUpdatingErrorReducer";
import certificateDeletingReducer from "./certificate/certificateDeletingReducer";
import certificateDeletingErrorReducer from "./certificate/certificateDeletingErrorReducer";

import profileFileDeletingReducer from "./editProfile/profileFileDeletingReducer";
import profileFileDeletingErrorReducer from "./editProfile/profileFileDeletingErrorReducer";
import proposalUpdatingReducer from "./editProfile/proposalUpdatingReducer";
import proposalUpdatingErrorReducer from "./editProfile/proposalUpdatingErrorReducer";
import proposalFileUploadingReducer from "./editProfile/proposalFileUploadingReducer";
import proposalFileUploadingErrorReducer from "./editProfile/proposalFileUploadingErrorReducer";
import resumeUpdatingReducer from "./editProfile/resumeUpdatingReducer";
import resumeUpdatingErrorReducer from "./editProfile/resumeUpdatingErrorReducer";
import resumeFileUploadingReducer from "./editProfile/resumeFileUploadingReducer";
import resumeFileUploadingErrorReducer from "./editProfile/resumeFileUploadingErrorReducer";
import profileUpdatingReducer from "./editProfile/profileUpdatingReducer";
import profileUpdatingErrorReducer from "./editProfile/profileUpdatingErrorReducer";

import memberListReducer from "./memberList/memberListReducer";
import memberListErrorReducer from "./memberList/memberListErrorReducer";
import memberListImageReducer from "./memberList/memberListImageReducer";
import memberListLoadingReducer from "./memberList/memberListLoadingReducer";

import expertiseUpdatingReducer from "./manageExpertise/expertiseUpdatingReducer";
import expertiseUpdatingErrorReducer from "./manageExpertise/expertiseUpdatingErrorReducer";
import aboutUpdatingReducer from "./about/aboutUpdatingReducer";
import aboutUpdatingErrorReducer from "./about/aboutUpdatingErrorReducer";

import peopleReducer from "./people/peopleReducer";
import peopleImageReducer from "./people/peopleImageReducer";

import impersonateUserImageReducer from "./impersonateUserImage/impersonateUserImageReducer";

import pronunciationSavingReducer from "./pronunciation/pronunciationSavingReducer";
import fetchPronunciationReducer from "./pronunciation/fetchPronunciationReducer";
import pronunciationDeletingReducer from "./pronunciation/pronunciationDeletingReducer";
import pronunciationErrorReducer from "./pronunciation/pronunciationErrorReducer";

import orgErrorReducer from "./organization/orgErrorReducer";
import orgLoadingReducer from "./organization/orgLoadingReducer";
import orgReducer from "./organization/orgReducer";

import orgMemberImageReducer from "./organization/orgMemberImageReducer";
import orgMemberImageErrorReducer from "./organization/orgMemberImageErrorReducer";
import orgMemberImageLoadingReducer from "./organization/orgMemberImageLoadingReducer";

import ssInternalMaterialsReducer from "./ssInternalMaterials/ssInternalMaterialsReducer";
import ssInternalMaterialsLoadingReducer from "./ssInternalMaterials/ssInternalMaterialsLoadingReducer";
import ssInternalMaterialsErrorReducer from "./ssInternalMaterials/ssInternalMaterialsErrorReducer";

import ssRecentCasesReducer from "./ssRecentCases/ssRecentCasesReducer";
import ssRecentCasesLoadingReducer from "./ssRecentCases/ssRecentCasesLoadingReducer";
import ssRecentCasesErrorReducer from "./ssRecentCases/ssRecentCasesErrorReducer";

import ssRecentMaterialsReducer from "./ssRecentMaterials/ssRecentMaterialsReducer";
import ssRecentMaterialsLoadingReducer from "./ssRecentMaterials/ssRecentMaterialsLoadingReducer";
import ssRecentMaterialsErrorReducer from "./ssRecentMaterials/ssRecentMaterialsErrorReducer";

import ssRecentInternalMaterialsReducer from "./ssRecentInternalMaterials/ssRecentInternalMaterialsReducer";
import ssRecentInternalMaterialsLoadingReducer from "./ssRecentInternalMaterials/ssRecentInternalMaterialsLoadingReducer";
import ssRecentInternalMaterialsErrorReducer from "./ssRecentInternalMaterials/ssRecentInternalMaterialsErrorReducer";

import peopleDataReducer from "./peopleData/peopleDataReducer";
import peopleDataLoadingReducer from "./peopleData/peopleDataLoadingReducer";
import peopleDataErrorReducer from "./peopleData/peopleDataErrorReducer";

import uploadResumeReducer from "./upload/uploadResumeReducer";
import uploadResumeErrorReducer from "./upload/uploadResumeErrorReducer";
import uploadBioReducer from "./upload/uploadBioReducer";
import uploadBioErrorReducer from "./upload/uploadBioErrorReducer";
import resumeReducer from "./resume/resumeReducer";
import resumeErrorReducer from "./resume/resumeErrorReducer";
import resumeLoadingReducer from "./resume/resumeLoadingReducer";
import proposalBiosReducer from "./proposalbios/proposalBiosReducer";
import proposalBiosLoadingReducer from "./proposalbios/proposalBiosLoadingReducer";
import proposalBiosErrorReducer from "./proposalbios/proposalBiosErrorReducer";
import downloadPendingReducer from "./download/downloadPendingReducer";
import proficiencyReducer from "./proficiency/proficiencyReducer";
import designatedByReducer from "./designatedBy/designatedByReducer";
import profileViewsReducer from "./profileViews/profileViewsReducer";
import profileViewsErrorReducer from "./profileViews/profileViewsErrorReducer";
import profileViewsLoadingReducer from "./profileViews/profileViewsLoadingReducer";
import profileViewsDetailsReducer from "./profileViews/profileViewsDetailsReducer";
import profileViewsDetailsLoadingReducer from "./profileViews/profileViewsDetailsLoadingReducer";
import aboutReducer from "./about/aboutReducer";
import aboutLoadingReducer from "./about/aboutLoadingReducer";
import aboutErrorReducer from "./about/aboutErrorReducer";
import entitlementsReducer from "./entitlements/entitlementsReducer";
import entitlementsLoadingReducer from "./entitlements/entitlementsLoadingReducer";
import entitlementsErrorReducer from "./entitlements/entitlementsErrorReducer";
import aboutAllTermsReducer from "./about/aboutAllTermsReducer";
import aboutAllTermsLoadingReducer from "./about/aboutAllTermsLoadingReducer";
import aboutSponsersDetailsReducer from "./about/aboutSponsersDetailsReducer";
import ccoReducer from "./cco/ccoReducer";
import ccoLoadingReducer from "./cco/ccoLoadingReducer";
import ccoErrorReducer from "./cco/ccoErrorReducer";
import aboutTopicDetailsReducer from "./about/aboutTopicDetailsReducer";
import updateBioReducer from "./upload/updateBioReducer";
import updateBioErrorReducer from "./upload/updateBioErrorReducer";
import updateResumeReducer from "./upload/updateResumeReducer";
import updateResumeErrorReducer from "./upload/updateResumeErrorReducer";
import updateCaseTopicsReducer from "./cases/updateCaseTopicsReducer";
import updateCaseTopicsErrorReducer from "./cases/updateCaseTopicsErrorReducer";
import updateCaseSkillsReducer from "./cases/updateCaseSkillsReducer";
import updateCaseSkillsErrorReducer from "./cases/updateCaseSkillsErrorReducer";
import deleteCaseTopicsSkillsReducer from "./cases/deleteCaseTopicsSkillsReducer";
import deleteCaseTopicsSkillsErrorReducer from "./cases/deleteCaseTopicsSkillsErrorReducer";
import updateCaseResponsibiltyReducer from "./cases/updateCaseResponsibiltyReducer";
import practiceAreaOptionsReducer from "./practiceAreaOptions/practiceAreaOptionsReducer";
import practiceAreaOptionsErrorReducer from "./practiceAreaOptions/practiceAreaOptionsErrorReducer";
import userDataErrorReducer from "./userData/userDataErrorReducer";
import userDataLoadingReducer from "./userData/userDataLoadingReducer";
import userDataReducer from "./userData/userDataReducer";
import linkedInRedirectUrlReducer from "./linkedIn/linkedInRedirectUrlReducer";
import linkedInRedirectUrlLoadingReducer from "./linkedIn/linkedInRedirectUrlLoadingReducer";
import linkedInRedirectUrlErrorReducer from "./linkedIn/linkedInRedirectUrlErrorReducer";
import linkedInAuthStatusReducer from "./linkedIn/linkedInAuthStatusReducer";
import linkedInAuthStatusErrorReducer from "./linkedIn/linkedInAuthStatusErrorReducer";
import initiateLinkedInConnectReducer from "./linkedIn/initiateLinkedInConnectReducer";
import linkedInConnectStatusReducer from "./linkedIn/linkedInConnectStatusReducer";
import initiateLinkedInConnectErrorReducer from "./linkedIn/initiateLinkedInConnectErrorReducer";
import linkedInDataReducer from "./linkedIn/linkedInDataReducer";
import linkedInDataLoadingReducer from "./linkedIn/linkedInDataLoadingReducer";
import linkedInDataErrorReducer from "./linkedIn/linkedInDataErrorReducer";
import linkedInDeclineStatusReducer from "./linkedIn/linkedInDeclineStatusReducer";
import linkedInDeclineStatusLoadingReducer from "./linkedIn/linkedInDeclineStatusLoadingReducer";
import linkedInDeclineStatusErrorReducer from "./linkedIn/linkedDeclineStatusErrorReducer";
import removeLinkedInAccessReducer from "./linkedIn/removeLinkedInAccessReducer";
import removeLinkedInAccessLoadingReducer from "./linkedIn/removeLinkedInAccessLoadingReducer";
import removeLinkedInAccessErrorReducer from "./linkedIn/removeLinkedInAccessErrorReducer";
import profileCompletionReducer from "./profileCompletion/profileCompletionReducer";
import profileCompletionLoadingReducer from "./profileCompletion/profileCompletionLoadingReducer";
import profileCompletionErrorReducer from "./profileCompletion/profileCompletionErrorReducer";
import aboutAdditionalDataReducer from "./about/aboutAdditionalDataReducer";
import profileCompletionVerbiageReducer from "./profileCompletion/profileCompletionVerbiageReducer";
import profileCompletionVerbiageLoadingReducer from "./profileCompletion/profileCompletionVerbiageLoadingReducer";
import profileCompletionVerbiageErrorReducer from "./profileCompletion/profileCompletionVerbiageErrorReducer";
import skipForNowErrorReducer from "./profileCompletion/skipForNowErrorReducer";
import skipForNowReducer from "./profileCompletion/skipForNowReducer";
import skipForNowLoadingReducer from "./profileCompletion/skipForNowLoadingReducer";
import updateProfileCompletionErrorReducer from "./profileCompletion/updateProfileCompletionErrorReducer";
import updateProfileCompletionReducer from "./profileCompletion/updateProfileCompletionReducer";
import updateProfileCompletionLoadingReducer from "./profileCompletion/updateProfileCompletionLoadingReducer";
import updateLinkedInOptinOptionsReducer from "./linkedIn/updateLinkedInOptinOptionsReducer";
import updateLinkedInOptinOptionsLoadingReducer from "./linkedIn/updateLinkedInOptinOptionsLoadingReducer";
import updateLinkedInOptinOptionsErrorReducer from "./linkedIn/updateLinkedInOptinOptionsErrorReducer";
import linkedInUserStatusReducer from "./linkedIn/linkedInUserStatusReducer";
import linkedInUserStatusLoadingReducer from "./linkedIn/linkedInUserStatusLoadingReducer";
import linkedInUserStatusErrorReducer from "./linkedIn/linkedInUserStatusErrorReducer";
import cvBioMaterialsViewsReducer from "./profileViews/cvBioMaterialsViewsReducer";
import cvBioMaterialsViewsLoadingReducer from "./profileViews/cvBioMaterialsViewsLoadingReducer";
import cvBioMaterialsViewsErrorReducer from "./profileViews/cvBioMaterialsViewsErrorReducer";
import languageReducer from "./language/languageReducer";
import languageLoadingReducer from "./language/languageLoadingReducer";
import languageErrorReducer from "./language/languageErrorReducer";
import externalCourseIdReducer from "./externalCourseId/externalCourseIdReducer";
import externalCourseIdLoadingReducer from "./externalCourseId/externalCourseIdLoadingReducer";
import externalCourseIdErrorReducer from "./externalCourseId/externalCourseIdErrorReducer";
import cvBioDownloadDetailsReducer from "./cvBioDownloadDetails/cvBioDownloadDetailsReducer";
import cvBioDownloadDetailsLoadingReducer from "./cvBioDownloadDetails/cvBioDownloadDetailsLoadingReducer";
import cvBioDownloadDetailsErrorReducer from "./cvBioDownloadDetails/cvBioDownloadDetailsError";

const rootReducer = combineReducers({
  error: errorReducer,
  isLoading: userLoadingReducer,
  user: userReducer,
  userError: userErrorReducer,
  profileLoading: profileLoadingReducer,
  profile: profileReducer,
  profileError: profileErrorReducer,
  profileImageLoading: profileImageLoadingReducer,
  profileImage: profileImageReducer,
  profileImageError: profileImageErrorReducer,
  profileImageHighResLoading: profileImageHighResLoadingReducer,
  profileImageHighRes: profileImageHighResReducer,
  profileImageHighResError: profileImageHighResErrorReducer,
  experienceLoading: experienceLoadingReducer,
  experience: experienceReducer,
  experienceError: experienceErrorReducer,
  educationLoading: educationLoadingReducer,
  education: educationReducer,
  educationError: educationErrorReducer,
  achievementsLoading: achievementsLoadingReducer,
  achievements: achievementsReducer,
  achievementsError: achievementsErrorReducer,
  casesLoading: casesLoadingReducer,
  cases: casesReducer,
  allCases: setAllCasesReducer,
  casesError: casesErrorReducer,
  caseUpdating: caseUpdatingReducer,
  caseUpdatingError: caseUpdatingErrorReducer,
  materialsLoading: materialsLoadingReducer,
  materials: materialsReducer,
  materialsError: materialsErrorReducer,
  topicsLoading: topicsLoadingReducer,
  topics: topicsReducer,
  topicsError: topicsErrorReducer,
  topicUpdating: topicUpdatingReducer,
  topicUpdatingError: topicUpdatingErrorReducer,
  topicDeleting: topicDeletingReducer,
  topicDeletingError: topicDeletingErrorReducer,
  topicSummaryDeleting: topicSummaryDeletingReducer,
  topicSummaryDeletingError: topicSummaryDeletingErrorReducer,
  skillsLoading: skillsLoadingReducer,
  skills: skillsReducer,
  skillsError: skillsErrorReducer,
  skillUpdating: skillUpdatingReducer,
  skillUpdatingError: skillUpdatingErrorReducer,
  skillDeleting: skillDeletingReducer,

  skillDeletingError: skillDeletingErrorReducer,
  skillSummaryDeleting: skillSummaryDeletingReducer,
  skillSummaryDeletingError: skillSummaryDeletingErrorReducer,
  ssCasesLoading: ssCasesLoadingReducer,
  ssCases: ssCasesReducer,
  ssCasesError: ssCasesErrorReducer,
  ssRecentCases: ssRecentCasesReducer,
  ssRecentCasesLoading: ssRecentCasesLoadingReducer,
  ssRecentCasesError: ssRecentCasesErrorReducer,
  ssRecentMaterials: ssRecentMaterialsReducer,
  ssRecentMaterialsLoading: ssRecentMaterialsLoadingReducer,
  ssRecentMaterialsError: ssRecentMaterialsErrorReducer,
  ssMaterials: ssMaterialsReducer,
  ssMaterialsLoading: ssMaterialsLoadingReducer,
  ssMaterialsError: ssMaterialsErrorReducer,
  ssInternalMaterials: ssInternalMaterialsReducer,
  ssInternalMaterialsLoading: ssInternalMaterialsLoadingReducer,
  ssInternalMaterialsError: ssInternalMaterialsErrorReducer,
  ssRecentInternalMaterials: ssRecentInternalMaterialsReducer,
  ssRecentInternalMaterialsLoading: ssRecentInternalMaterialsLoadingReducer,
  ssRecentInternalMaterialsError: ssRecentInternalMaterialsErrorReducer,
  awardNamesLoading: awardNamesLoadingReducer,
  awardNames: awardNamesReducer,
  awardNamesError: awardNamesErrorReducer,
  awardTypesLoading: awardTypesLoadingReducer,
  awardTypes: awardTypesReducer,
  awardTypesError: awardTypesErrorReducer,
  childTopicsFinderLoading: childTopicsFinderLoadingReducer,
  childTopicsFinder: childTopicsFinderReducer,
  childTopicsFinderError: childTopicsFinderErrorReducer,
  topicsFinder: topicsFinderReducer,
  topicsFinderLoading: topicsFinderLoadingReducer,
  topicsFinderError: topicsFinderErrorReducer,
  topicsSkillsSearchLoading: topicsSkillsSearchLoadingReducer,
  topicsSkillsSearch: topicsSkillsSearchReducer,
  topicsSkillsSearchError: topicsSkillsSearchErrorReducer,
  topicsSkillFinder: topicsSkillFinderReducer,
  topicsSkillFinderLoading: topicsSkillFinderLoadingReducer,
  topicsSkillFinderError: topicsSkillFinderErrorReducer,
  profileUpdateHistoryLoading: profileUpdateHistoryLoadingReducer,
  profileUpdateHistory: profileUpdateHistoryReducer,
  profileUpdateHistoryError: profileUpdateHistoryErrorReducer,
  staffIdLoading: staffIdLoadingReducer,
  staffId: staffIdReducer,
  staffIdError: staffIdErrorReducer,
  expertiseListLoading: expertiseListLoadingReducer,
  expertiseList: expertiseListReducer,
  expertiseListError: expertiseListErrorReducer,
  awardUpdating: awardUpdatingReducer,
  awardUpdatingError: awardUpdatingErrorReducer,
  awardDeleting: awardDeletingReducer,
  awardDeletingError: awardDeletingErrorReducer,
  certificateUpdating: certificateUpdatingReducer,
  certificateUpdatingError: certificateUpdatingErrorReducer,
  certificateDeleting: certificateDeletingReducer,
  certificateDeletingError: certificateDeletingErrorReducer,
  profileFileDeleting: profileFileDeletingReducer,
  profileFileDeletingError: profileFileDeletingErrorReducer,
  proposalUpdating: proposalUpdatingReducer,
  proposalUpdatingError: proposalUpdatingErrorReducer,
  proposalFileUploading: proposalFileUploadingReducer,
  proposalFileUploadingError: proposalFileUploadingErrorReducer,
  resumeUpdating: resumeUpdatingReducer,
  resumeUpdatingError: resumeUpdatingErrorReducer,
  resumeFileUploading: resumeFileUploadingReducer,
  resumeFileUploadingError: resumeFileUploadingErrorReducer,
  profileUpdating: profileUpdatingReducer,
  profileUpdatingError: profileUpdatingErrorReducer,
  accessRoles: accessRolesReducer,
  memberList: memberListReducer,
  memberListImage: memberListImageReducer,
  memberListLoading: memberListLoadingReducer,
  memberListError: memberListErrorReducer,
  expertiseUpdating: expertiseUpdatingReducer,
  expertiseUpdatingError: expertiseUpdatingErrorReducer,
  aboutUpdating: aboutUpdatingReducer,
  aboutUpdatingError: aboutUpdatingErrorReducer,
  people: peopleReducer,
  peopleImage: peopleImageReducer,
  impersonateUserImage: impersonateUserImageReducer,
  pronunciationSaving: pronunciationSavingReducer,
  pronunciation: fetchPronunciationReducer,
  pronunciationDeleting: pronunciationDeletingReducer,
  pronunciationSaveError: pronunciationErrorReducer,
  organization: orgReducer,
  orgLoading: orgLoadingReducer,
  orgError: orgErrorReducer,
  orgMemberImageList: orgMemberImageReducer,
  orgMemberImageError: orgMemberImageErrorReducer,
  orgMemberImageLoading: orgMemberImageLoadingReducer,
  peopleData: peopleDataReducer,
  peopleDataLoading: peopleDataLoadingReducer,
  peopleDataError: peopleDataErrorReducer,
  userData: userDataReducer,
  userDataLoading: userDataLoadingReducer,
  userDataError: userDataErrorReducer,
  uploadResumeStatus: uploadResumeReducer,
  uploadResumeError: uploadResumeErrorReducer,
  uploadBioStatus: uploadBioReducer,
  uploadBioError: uploadBioErrorReducer,
  profileResumes: resumeReducer,
  profileResumeError: resumeErrorReducer,
  profileResumeLoading: resumeLoadingReducer,
  profileProposalBios: proposalBiosReducer,
  profileProposalBiosLoading: proposalBiosLoadingReducer,
  profileProposalBiosError: proposalBiosErrorReducer,
  downloading: downloadPendingReducer,
  profileViews: profileViewsReducer,
  profileViewsLoading: profileViewsLoadingReducer,
  profileViewsError: profileViewsErrorReducer,
  profileViewsDetails: profileViewsDetailsReducer,
  profileViewsDetailsLoading: profileViewsDetailsLoadingReducer,
  SkillsToolsTaxonomyData: skillsToolsReducer,
  skillsToolsTaxonomyLoading: skillsToolsLoadingReducer,
  SkillsToolsTaxonomyError: skillsToolsErrorReducer,
  proficiency: proficiencyReducer,
  designatedBy: designatedByReducer,
  caseResponsibilityData: caseResponsibilityReducer,
  caseResponsibilityLoading: caseResponsibilityLoadingReducer,
  aboutMe: aboutReducer,
  aboutMeLoading: aboutLoadingReducer,
  aboutMeError: aboutErrorReducer,
  businessSubjectsTaxonomyData: businessSubjectsReducer,
  businessSubjectsTaxonomyError: businessSubjectsErrorReducer,
  businessSubjectTaxonomyLoading: businessSubjectsLoadingReducer,
  userEntitlements: entitlementsReducer,
  userEntitlementsLoading: entitlementsLoadingReducer,
  userEntitlementsError: entitlementsErrorReducer,
  aboutAllTerms: aboutAllTermsReducer,
  aboutAllTermsLoading: aboutAllTermsLoadingReducer,
  aboutSponsersData: aboutSponsersDetailsReducer,
  ccoData: ccoReducer,
  ccoLoading: ccoLoadingReducer,
  ccoError: ccoErrorReducer,
  aboutTopicDetails: aboutTopicDetailsReducer,
  updateBioStatus: updateBioReducer,
  updateBioError: updateBioErrorReducer,
  updateResumeStatus: updateResumeReducer,
  updateResumeError: updateResumeErrorReducer,
  updateCaseTopicsStatus: updateCaseTopicsReducer,
  updateCaseTopicsError: updateCaseTopicsErrorReducer,
  updateCaseSkillsStatus: updateCaseSkillsReducer,
  updateCaseSkillsError: updateCaseSkillsErrorReducer,
  deleteCaseTopicsSkillsStatus: deleteCaseTopicsSkillsReducer,
  deleteCaseTopicsSkillsError: deleteCaseTopicsSkillsErrorReducer,
  updateCaseResponsibilityStatus: updateCaseResponsibiltyReducer,
  practiceAreaOptions: practiceAreaOptionsReducer,
  practiceAreaOptionsError: practiceAreaOptionsErrorReducer,
  linkedInRedirectUrl: linkedInRedirectUrlReducer,
  linkedInRedirectUrlLoading: linkedInRedirectUrlLoadingReducer,
  linkedInRedirectUrlError: linkedInRedirectUrlErrorReducer,
  linkedInAuthStatus: linkedInAuthStatusReducer,
  linkedInAuthStatusError: linkedInAuthStatusErrorReducer,
  linkedInConnectFinalStatus: linkedInConnectStatusReducer,
  connectingToLinkedIn: initiateLinkedInConnectReducer,
  linkedInConnectError: initiateLinkedInConnectErrorReducer,
  linkedInData: linkedInDataReducer,
  linkedInDataLoading: linkedInDataLoadingReducer,
  linkedInDataError: linkedInDataErrorReducer,
  linkedInDeclineStatus: linkedInDeclineStatusReducer,
  linkedInDeclineStatusLoading: linkedInDeclineStatusLoadingReducer,
  linkedInDeclineStatusError: linkedInDeclineStatusErrorReducer,
  removeLinkedInAccessSuccess: removeLinkedInAccessReducer,
  removeLinkedInAccessLoading: removeLinkedInAccessLoadingReducer,
  removeLinkedInAccessError: removeLinkedInAccessErrorReducer,
  profileCompletionData: profileCompletionReducer,
  profileCompletionLoading: profileCompletionLoadingReducer,
  profileCompletionError: profileCompletionErrorReducer,
  updateProfileCompletion: updateProfileCompletionReducer,
  updateProfileCompletionError: updateProfileCompletionErrorReducer,
  updateProfileCompletionLoading: updateProfileCompletionLoadingReducer,
  aboutAdditionalData: aboutAdditionalDataReducer,
  profileCompletionVerbiage: profileCompletionVerbiageReducer,
  profileCompletionVerbiageLoading: profileCompletionVerbiageLoadingReducer,
  profileCompletionVerbiageError: profileCompletionVerbiageErrorReducer,
  skipForNowError: skipForNowErrorReducer,
  skipForNow: skipForNowReducer,
  skipForNowLoading: skipForNowLoadingReducer,
  updateLinkedInOptinOptionsSuccess: updateLinkedInOptinOptionsReducer,
  updateLinkedInOptinOptionsLoading: updateLinkedInOptinOptionsLoadingReducer,
  updateLinkedInOptinOptionsError: updateLinkedInOptinOptionsErrorReducer,
  linkedInUserStatus: linkedInUserStatusReducer,
  linkedInUserStatusLoading: linkedInUserStatusLoadingReducer,
  linkedInUserStatusError: linkedInUserStatusErrorReducer,
  cvBioViews: cvBioMaterialsViewsReducer,
  cvBioViewsLoading: cvBioMaterialsViewsLoadingReducer,
  cvBioViewsError: cvBioMaterialsViewsErrorReducer,
  languages: languageReducer,
  languageLoading: languageLoadingReducer,
  languageError: languageErrorReducer,
  doesCourseExist: externalCourseIdReducer,
  externalCourseIdLoading: externalCourseIdLoadingReducer,
  externalCourseIdError: externalCourseIdErrorReducer,
  cvBioDownloadDetails: cvBioDownloadDetailsReducer,
  cvBioDownloadDetailsLoading: cvBioDownloadDetailsLoadingReducer,
  cvBioDownloadDetailsError: cvBioDownloadDetailsErrorReducer
});

export default rootReducer;
