import { takeLatest } from "redux-saga/effects";

import { editProfile } from "redux/constants";
import handleUpdateResume from "../worker/handleUpdateResume";

function* watchUpdateResume() {
  yield takeLatest(editProfile.UPDATE_RESUME, handleUpdateResume);
}

export default watchUpdateResume;
