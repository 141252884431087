import { user } from "redux/constants";

const userLoadingReducer = (state = false, action) => {
  switch (action.type) {
    case user.FETCH_USER:
      return true;
    case user.FETCH_SUCCESS:
    case user.FETCH_FAILURE:
      return false;
    default:
      return state;
  }
};

export default userLoadingReducer;
