import { achievement } from "redux/constants";

const achievementsReducer = (state = {}, action) => {
  switch (action.type) {
    case achievement.FETCH_ACHIEVEMENT_SUCCESS: {
      return { ...action.data };
    }
    default:
      return state;
  }
};

export default achievementsReducer;
