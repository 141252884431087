import { takeLatest } from "redux-saga/effects";

import { upload } from "redux/constants";
import handleUploadResumeStart from "../worker/handleUploadResumeStart";

function* watchUploadResumeStart() {
  yield takeLatest(upload.UPLOAD_RESUME_START, handleUploadResumeStart);
}

export default watchUploadResumeStart;
