import React from "react";
import { Card } from "semantic-ui-react";
import PropTypes from "prop-types";
import LABEL from "constants/label";

/**
 * This component can be used to create View All footer for a card and must be used inside a Card.
 *
 * @param {func} handleOnClick function to be executed on click of View All
 * @returns ViewAll component with button
 *
 * @example
 *
 * <Card>
 *  <ViewAll handleOnClick={()=>{alert("hi")}}/>
 * </Card>
 */

function ViewAll({ handleOnClick, text = LABEL.COMMON.ViewAll }) {
  return (
    <Card.Content textAlign="center" extra>
      <span className="cursor-pointer" onClick={handleOnClick} onKeyPress={handleOnClick} role="button" tabIndex="-1">
        {text}
      </span>
    </Card.Content>
  );
}

ViewAll.propTypes = {
  handleOnClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired
};

export default ViewAll;
