import { takeLatest } from "redux-saga/effects";

import { linkedIn } from "redux/constants";
import handleUpdateLinkedInOptinOptions from "../worker/handleUpdateLinkedInOptinOptions";

function* watchUpdateLinkedInOptinOptions() {
  yield takeLatest(linkedIn.UPDATE_LINKEDIN_OPTIN_OPTIONS, handleUpdateLinkedInOptinOptions);
}

export default watchUpdateLinkedInOptinOptions;
