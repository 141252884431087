import { call, put } from "redux-saga/effects";
import { get } from "redux/api";
import { setSkills, setError } from "redux/actions/skills";

function* handleFetchSkills({ endpoint }) {
  const { data, error } = yield call(get, endpoint);
  if (error) {
    yield put(setError(error));
  } else {
    yield put(setSkills(data?.skill ? data?.skill : []));
  }
}

export default handleFetchSkills;
