import React from "react";
import PropTypes from "prop-types";
import LABEL from "constants/label";
import styles from "./styles.module.scss";

const { ERRORS } = LABEL;

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // eslint-disable-next-line no-console
    console.error(errorInfo); // log the error to an error reporting servicePa
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <section className={`${styles["error__container"]} error-container`} textAlign="center">
          <h2 className={styles["error__title"]}>{ERRORS.NewTitleGen}</h2>
          <p className={styles["error__message"]}>{ERRORS.NewMessageGen}</p>
        </section>
      );
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired
};

export default ErrorBoundary;
