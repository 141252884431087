import { takeLatest } from "redux-saga/effects";

import { cases } from "redux/constants";
import handleUpdateCase from "../worker/handleUpdateCase";

function* watchUpdateCase() {
  yield takeLatest(cases.UPDATE_CASE, handleUpdateCase);
}

export default watchUpdateCase;
