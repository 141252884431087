import { call, put } from "redux-saga/effects";
import { get } from "redux/api";
import { setProfile, setError } from "redux/actions/profile";

function* handleFetchProfile({ payload }) {
  const { data, error } = yield call(get, payload);
  if (error) {
    yield put(setError(error));
    return;
  }
  if (!data) {
    yield put(setError({ errorCode: 404 }));
    return;
  }
  if (data) {
    yield put(setProfile(data));
  }
}

export default handleFetchProfile;
