import { call, put } from "redux-saga/effects";
import { delete as del } from "redux/api";
import { setDeleteFileSuccess, setDeleteFileError } from "redux/actions/editProfile";

function* handleDeleteProfileFile({ payload }) {
  const { data, error } = yield call(del, payload);
  if (error) {
    yield put(setDeleteFileError(error));
  } else {
    yield put(setDeleteFileSuccess(data));
  }
}

export default handleDeleteProfileFile;
