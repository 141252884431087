import { searchTopicsSkills } from "redux/constants";

const getTopicsSkillsSearch = (endpoint) => {
  return {
    type: searchTopicsSkills.FETCH_TOPICS_SKILLS_FINDER,
    endpoint
  };
};

const setTopicsSkillsSearch = (data) => ({
  type: searchTopicsSkills.FETCH_TOPICS_SKILLS_FINDER_SUCCESS,
  data
});

const setError = (error) => ({
  type: searchTopicsSkills.FETCH_TOPICS_SKILLS_FINDER_FAILURE,
  error
});

export { getTopicsSkillsSearch, setTopicsSkillsSearch, setError };
