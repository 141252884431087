import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";

/**
 * InlineList component
 *
 * @param  {string} title
 * @param  {array} items - can be array of objects/string/number
 * @param  {string} size - regular/sm
 * @param  {object} accessor - if object is provided array will be treated as array of objects
 * @param  {string} text
 *
 * @return list of items separated by pipe symbol - |
 *
 * @example
 *
 * <InlineList items={['one', 'two', 'three']} />
 */
function InlineList(props) {
  const { title, items, accessor, size, text } = props;
  const notFoundText = title ? `No ${title} Added` : "No Data Added";
  let isItemsAvailable = items && items.length;
  const newText = text && typeof text === "string" ? text.trim() : "";

  // items can be array of objects or array of primitive types
  // in case of array of objects, accessor is required
  if (isItemsAvailable && accessor && typeof items[0] === "object") {
    isItemsAvailable = true;
  } else if (isItemsAvailable && !accessor && typeof items[0] !== "object") {
    isItemsAvailable = true;
  } else if (newText) {
    isItemsAvailable = true;
  } else {
    isItemsAvailable = false;
  }

  const renderList = () => {
    let itemClass = `${styles["list__item"]} d-inline-block`;
    switch (size) {
      case "sm":
        itemClass += ` ${styles["list__item--sm"]}`;
        break;
      default:
    }

    if (newText) {
      // eslint-disable-next-line react/no-danger
      return <div className={styles["list__text"]} dangerouslySetInnerHTML={{ __html: newText }} />;
    }

    if (accessor) {
      return (
        <ul className={`${styles["list__group"]} m-0 p-0`}>
          {items.map((item) => (
            <li className={itemClass} key={item[accessor.id]}>
              {item[accessor.item]}
            </li>
          ))}
        </ul>
      );
    }

    return (
      <ul className={`${styles["list__group"]} m-0 p-0`}>
        {items.map((item) => (
          <li className={itemClass} key={item}>
            {item}
          </li>
        ))}
      </ul>
    );
  };

  return title || isItemsAvailable ? (
    <div className={styles["list__container"]}>
      {title.length > 0 && <h5 className={styles["list__title"]}>{title}</h5>}

      {isItemsAvailable ? renderList() : <div className={styles["list__disabled"]}>{notFoundText}</div>}
    </div>
  ) : null;
}

InlineList.defaultProps = {
  title: "",
  accessor: null,
  size: "regular",
  items: [],
  text: ""
};

InlineList.propTypes = {
  accessor: PropTypes.shape({ id: PropTypes.string, item: PropTypes.string }),
  title: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.any),
  size: PropTypes.string,
  text: PropTypes.string
};

export default InlineList;
