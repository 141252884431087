import { takeLatest } from "redux-saga/effects";

import { award } from "redux/constants";
import handleDeleteAward from "../worker/handleDeleteAward";

function* watchDeleteAward() {
  yield takeLatest(award.DELETE_AWARD, handleDeleteAward);
}

export default watchDeleteAward;
