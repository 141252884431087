import { about } from "redux/constants";

const aboutErrorReducer = (state = {}, action) => {
  switch (action.type) {
    case about.FETCH_ABOUT_FAILURE:
      return action.error;
    case about.FETCH_ABOUT_SUCCESS:
    case about.FETCH_ABOUT:
      return null;
    default:
      return state;
  }
};

export default aboutErrorReducer;
