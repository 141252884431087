import React from "react";
import LABEL from "constants/label";
import { Button } from "semantic-ui-react";
import PropTypes from "prop-types";
import "./styles.scss";

const { MANAGE_PRONUNCIATION } = LABEL;

function RecordingToggleButton({ recorderState, callbacks }) {
  const { initRecording, audio } = recorderState;
  const [startRecording, stopRecording] = callbacks;
  let button;

  if (!initRecording)
    button = (
      <Button primary type="button" onClick={startRecording} className="recorder-btn">
        {MANAGE_PRONUNCIATION.StartRecording}
      </Button>
    );
  if (initRecording)
    button = (
      <Button primary type="button" onClick={stopRecording} className="recorder-btn">
        {MANAGE_PRONUNCIATION.StopRecording}
      </Button>
    );
  if (audio)
    button = (
      <div className="recorder-btn__done">
        <p>{MANAGE_PRONUNCIATION.Recorded}</p>
      </div>
    );

  return <>{button}</>;
}

RecordingToggleButton.propTypes = {
  recorderState: PropTypes.shape({
    recordingSeconds: PropTypes.number,
    initRecording: PropTypes.bool,
    audio: PropTypes.string
  }).isRequired,
  callbacks: PropTypes.arrayOf(PropTypes.func).isRequired
};

export default RecordingToggleButton;
