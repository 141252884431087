import { linkedIn } from "redux/constants";

const linkedInDeclineStatusReducer = (state = null, action) => {
  switch (action.type) {
    case linkedIn.UPDATE_LINKEDIN_DECLINE_STATUS_SUCCESS: {
      return action?.data;
    }
    default:
      return state;
  }
};

export default linkedInDeclineStatusReducer;
