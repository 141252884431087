import PropTypes from "prop-types";
import Cookies from "js-cookies";
import { Button, Grid, Placeholder } from "semantic-ui-react";
import LABEL from "constants/label";
import styles from "./style.module.scss";

const { IMPERSONATE } = LABEL;
/**
 * Impersonate User
 *
 * @param  {obj} user impersonated person user object
 * @param  {bool} loading user object loading
 *
 * returns ImpersonateUser component
 */

const ImpersonateUser = ({ user, loading, imgSrc }) => {
  // Remove impersonatation on clicking stop button
  const removeImpersonateUser = () => {
    Cookies.removeItem("impersonateUser");
    Cookies.removeItem("impersonatedUserHrId");
    window.location.reload();
  };

  return (
    <Grid className={`${styles["imp-user"]} p-0 md-none m-0`} textAlign="center" verticalAlign="middle">
      <Grid.Row>
        <Grid.Column>
          <>
            {IMPERSONATE.Impersonating}
            {!imgSrc ? (
              <Placeholder
                className={`${styles["imp-user__pic"]} d-inline-block verticle-align-middle`}
                data-testid="placeholder-img"
              >
                <Placeholder.Image />
              </Placeholder>
            ) : (
              <img
                className={`${styles["imp-user__pic"]} d-inline-block verticle-align-middle`}
                src={imgSrc}
                alt=""
                data-testid="img"
              />
            )}
            {loading ? (
              <Placeholder className={`${styles["imp-user__loader"]} d-inline-block`} data-testid="placeholder-content">
                <Placeholder.Header>
                  <Placeholder.Line />
                </Placeholder.Header>
              </Placeholder>
            ) : (
              <>
                <strong data-testid="impersonated-user-name">
                  {`${user?.preferredFirstName} ${user?.preferredLastName}`}{" "}
                </strong>{" "}
                {`(${user?.businessTitle}, ${user?.hostOfficeLocation?.name})`}
              </>
            )}
            <Button onClick={removeImpersonateUser} basic inverted secondary className="ml-2" data-testid="stop">
              {IMPERSONATE.Stop}
            </Button>
          </>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

ImpersonateUser.defaultProps = {
  user: {
    preferredFirstName: "",
    preferredLastName: "",
    businessTitle: "",
    officeName: "",
    hrEmployeeId: 1,
    title: "",
    hostOfficeLocation: ""
  },
  loading: true
};

ImpersonateUser.propTypes = {
  user: PropTypes.shape({
    preferredFirstName: PropTypes.string,
    preferredLastName: PropTypes.string,
    businessTitle: PropTypes.string,
    officeName: PropTypes.string,
    hrEmployeeId: PropTypes.number,
    title: PropTypes.string,
    hostOfficeLocation: PropTypes.string
  }),
  loading: PropTypes.bool,
  imgSrc: PropTypes.string.isRequired
};

export default ImpersonateUser;
