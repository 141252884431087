import { userProfileImage } from "redux/constants";

const profileImageReducer = (state = {}, action) => {
  switch (action.type) {
    case userProfileImage.FETCH_USER_IMAGE_SUCCESS: {
      return { ...action.data };
    }
    default:
      return state;
  }
};

export default profileImageReducer;
