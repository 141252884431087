import { skills } from "redux/constants";

const skillSummaryDeletingErrorReducer = (state = null, action) => {
  switch (action.type) {
    case skills.DELETE_SKILLSUMMARY_FAILURE:
      return action.error;
    case skills.DELETE_SKILLSUMMARY_SUCCESS:
    case skills.DELETE_SKILLSUMMARY:
      return null;
    default:
      return state;
  }
};

export default skillSummaryDeletingErrorReducer;
