import { takeLatest } from "redux-saga/effects";

import { download } from "redux/constants";
import handleDownload from "../worker/handleDownload";

function* watchDownload() {
  yield takeLatest(download.DOWNLOAD_START, handleDownload);
}

export default watchDownload;
