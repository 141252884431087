import { ssRecentCases } from "redux/constants";

const ssRecentCasesLoadingReducer = (state = true, action) => {
  switch (action.type) {
    case ssRecentCases.FETCH_RECENT_SSCASE:
      return true;
    case ssRecentCases.FETCH_RECENT_SSCASE_SUCCESS:
    case ssRecentCases.FETCH_RECENT_SSCASE_FAILURE:
      return false;
    default:
      return state;
  }
};

export default ssRecentCasesLoadingReducer;
