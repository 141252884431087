import { profileViews } from "redux/constants";

const profileViewsErrorReducer = (state = {}, action) => {
  switch (action.type) {
    case profileViews.SAVE_PROFILE_VIEWS_FAILURE:
      return action.error;
    case profileViews.SAVE_PROFILE_VIEWS:
    case profileViews.SAVE_PROFILE_VIEWS_SUCCESS:
      return null;
    default:
      return state;
  }
};

export default profileViewsErrorReducer;
