import { takeLatest } from "redux-saga/effects";

import { award } from "redux/constants";
import handleUpdateAward from "../worker/handleUpdateAward";

function* watchUpdateAward() {
  yield takeLatest(award.UPDATE_AWARD, handleUpdateAward);
}

export default watchUpdateAward;
