import { ssMaterials } from "redux/constants";

const ssMaterialsLoadingReducer = (state = true, action) => {
  switch (action.type) {
    case ssMaterials.FETCH_SSMATERIALS:
      return true;
    case ssMaterials.FETCH_SSMATERIALS_SUCCESS:
    case ssMaterials.FETCH_SSMATERIALS_FAILURE:
      return false;
    default:
      return state;
  }
};

export default ssMaterialsLoadingReducer;
