import { materials } from "redux/constants";

const materialsErrorReducer = (state = {}, action) => {
  switch (action.type) {
    case materials.FETCH_MATERIALS_FAILURE:
      return action.error;
    case materials.FETCH_MATERIALS_SUCCESS:
    case materials.FETCH_MATERIALS:
      return null;
    default:
      return state;
  }
};

export default materialsErrorReducer;
