import { call, put as putSaga } from "redux-saga/effects";
import { put } from "redux/api";
import { saveProfileViewsFailure, saveProfileViewsSuccess } from "redux/actions/profileViews";

function* handleSaveProfileViews({ payload }) {
  const { data, error } = yield call(put, payload);
  if (error) {
    yield putSaga(saveProfileViewsFailure(error));
  } else {
    yield putSaga(saveProfileViewsSuccess(data));
  }
}

export default handleSaveProfileViews;
