import { ssRecentInternalMaterials } from "redux/constants";

const ssRecentInternalMaterialsLoadingReducer = (state = true, action) => {
  switch (action.type) {
    case ssRecentInternalMaterials.FETCH_RECENT_SS_INTERNAL_MATERIALS:
      return true;
    case ssRecentInternalMaterials.FETCH_RECENT_SS_INTERNAL_MATERIALS_SUCCESS:
    case ssRecentInternalMaterials.FETCH_RECENT_SS_INTERNAL_MATERIALS_FAILURE:
      return false;
    default:
      return state;
  }
};

export default ssRecentInternalMaterialsLoadingReducer;
