import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Placeholder } from "semantic-ui-react";
import PropTypes from "prop-types";
import PopupDropDown from "../PopupDropDown";

/**
 *
 * @param {bool} isLoading indicates whether the component is loading or not
 * @param {Function} handleOnChange callback method to send the value
 *
 * @returns AwardsDropDown component
 *
 * @example
 * <AwardsDropDown handleOnchange={()=>{}}/>
 *
 */

function AwardsDropDown({ isLoading, handleOnChange, disabled, invalid, awardName }) {
  const awardNames = useSelector((state) => state.awardNames?.awardNamesList);
  const [awardOptions, setAwardOptions] = useState([]);
  const [selAward, setSelAward] = useState("");

  const onItemClick = (e, data) => {
    handleOnChange({ awardName: data.text, awardId: data.value });
  };

  useEffect(() => {
    if (awardNames?.length > 0) {
      const options = awardNames
        ?.filter((award) => award?.awardNameId !== 23)
        .map((award) => ({
          key: award.awardNameId,
          text: award.name,
          value: award.awardNameId
        }));
      // Add Select Option to dropdown
      options.unshift({
        key: 0,
        text: "Select",
        value: "Select"
      });
      setAwardOptions(options);
      setSelAward(options.findIndex((item) => item.text === awardName));
    }
  }, [awardNames, awardName]);

  let returnVal = (
    <PopupDropDown
      disabled={disabled}
      onClick={onItemClick}
      options={awardOptions}
      id="dd_award_name"
      selectedIndex={selAward}
      invalid={invalid}
    />
  );

  if (isLoading) {
    returnVal = (
      <Placeholder fluid>
        <Placeholder.Header>
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Header>
      </Placeholder>
    );
  }

  return returnVal;
}

AwardsDropDown.defaultProps = {
  isLoading: false
};
AwardsDropDown.propTypes = {
  isLoading: PropTypes.bool,
  handleOnChange: PropTypes.func.isRequired
};

export default AwardsDropDown;
