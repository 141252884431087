import { profileCompletion } from "redux/constants";

const skipForNowErrorReducer = (state = {}, action) => {
  switch (action.type) {
    case profileCompletion.SET_SKIP_FOR_NOW_FAILURE:
      return action.error;
    case profileCompletion.SET_SKIP_FOR_NOW:
    case profileCompletion.SET_SKIP_FOR_NOW_SUCCESS:
      return null;
    default:
      return state;
  }
};

export default skipForNowErrorReducer;
