import { pronunciation } from "redux/constants";

const fetchPronunciationReducer = (state = {}, action) => {
  switch (action.type) {
    case pronunciation.FETCH_PRONUNCIATION_SUCCESS: {
      return { ...action.data };
    }
    case pronunciation.SET_PRONUNCIATION_ID: {
      return { ...state, audioId: action.data };
    }
    default:
      return state;
  }
};

export default fetchPronunciationReducer;
