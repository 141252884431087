import { takeLatest } from "redux-saga/effects";

import { topics } from "redux/constants";
import handleUpdateTopic from "../worker/handleUpdateTopic";

function* watchUpdateTopic() {
  yield takeLatest(topics.UPDATE_TOPIC, handleUpdateTopic);
}

export default watchUpdateTopic;
