import { profileCompletion } from "redux/constants";

const skipForNowReducer = (state = {}, action) => {
  switch (action.type) {
    case profileCompletion.SET_SKIP_FOR_NOW_SUCCESS: {
      return { ...action.data };
    }
    default:
      return state;
  }
};

export default skipForNowReducer;
