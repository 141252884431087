import { language } from "redux/constants";

const languageErrorReducer = (state = {}, action) => {
  switch (action.type) {
    case language.FETCH_LANGUAGE_FAILURE:
      return action.error;
    case language.FETCH_LANGUAGE_SUCCESS:
    case language.FETCH_LANGUAGE:
      return null;
    default:
      return state;
  }
};

export default languageErrorReducer;
