import moment from "moment";
import LABEL from "constants/label";
import { isNil } from "lodash";
import { toast } from "react-semantic-toasts";
import axios from "axios";
import Cookies from "js-cookies";
import qs from "qs";
import { copyObject } from "pages/helpers/utils";
import { userStatus, userRole, userWorkerType } from "constants/index";
import CONFIG from "constants/config";
import { MONTHSTRING, MONTHDIGIT } from "./constants";

const { COMMON, EMPLOYEE_STATUS } = LABEL;
const { apiUrl, xApiKey, cookies, disabledUserIds } = CONFIG;

/**
 * This method can be used to format date string to get Mon YYYY or Month YYYY
 *
 * @param {string} date
 * @param {bool} isFullMonthName
 *
 * @returns {string} formated date (Mon YYYY)
 *
 * @example
 * formatDateMonthYear("2017-04-30T00:00:00Z") returns either Apr 2017 or April 2017
 */
export const formatDateByMoment = (date, format) => {
  if (date) {
    return moment(date)?.format(format);
  }
  return "";
};

/**
 * This method can be used to format year in YYYY format
 *
 * @param {number} year
 * @param {format} YYYY
 *
 * @returns {number} formated year (YYYY)
 *
 * @example
 * formatYearByMoment(1992, "YYYY") returns 2017
 */
export const formatYearByMoment = (date, format) => {
  if (date) {
    return moment(date, format)?.format(format);
  }
  return "";
};

export const formatDateMonthYear = (date, isFullMonthName = false) => {
  if (date) {
    const dateObj = new Date(date);
    return isFullMonthName
      ? `${MONTHSTRING[dateObj.getUTCMonth()]?.full} ${dateObj.getUTCFullYear()}`
      : `${MONTHSTRING[dateObj.getUTCMonth()]?.short} ${dateObj.getUTCFullYear()}`;
  }
  return "";
};

/**
 * This method can be used to format date string to get either Mon DD, YYYY or Month DD, YYYY
 *
 * @param {string} date
 * @param {bool} isFullMonthName
 *
 * @returns {string} formated date (Mon YYYY)
 *
 * @example
 * formatDateMonthDDYear("2017-04-30T00:00:00Z") returns either Apr 30, 2017 or April 30, 2017
 */

export const formatDateMonthDDYear = (date, isFullMonthName = false) => {
  if (date) {
    const dateObj = new Date(date.includes("-") ? date : +date);
    return isFullMonthName
      ? `${MONTHSTRING[dateObj.getUTCMonth()]?.full} ${dateObj.getUTCDate()}, ${dateObj.getUTCFullYear()}`
      : `${MONTHSTRING[dateObj.getUTCMonth()]?.short} ${dateObj.getUTCDate()}, ${dateObj.getUTCFullYear()}`;
  }
  return "";
};

export const formatDateMonth = (date) => {
  if (date) {
    const dateObj = new Date(date.includes("-") ? date : +date);
    return `${MONTHSTRING[dateObj.getUTCMonth()]?.full} ${dateObj.getUTCDate()}`;
  }
  return "";
};

export const formatDateMonthDDYearForAchievements = (date, isFullMonthName = false) => {
  if (date) {
    const dateObj = new Date(date);
    return isFullMonthName
      ? `${dateObj.getUTCDate()} ${MONTHSTRING[dateObj.getUTCMonth()].full} ${dateObj.getUTCFullYear()}`
      : `${dateObj.getUTCDate()} ${MONTHSTRING[dateObj.getUTCMonth()].short} ${dateObj.getUTCFullYear()}`;
  }
  return "";
};

/**
 * This method can be used to get the short name of the month when a month number is passed.
 *
 * @param {string} month
 *
 * @returns {string} short name for the month.
 *
 * @example
 * getMonthShort("02") returns Feb
 *
 */

export const getMonthShort = (month) => {
  if (month) {
    return `${MONTHSTRING[month.trim() - 1].short}`;
  }
  return "";
};

/**
 * This method can be used to get the full name of the month when a month number is passed.
 *
 * @param {string} month
 *
 * @returns {string} full name of the month.
 *
 * @example
 * getMonthFullName("02") returns February
 *
 */

export const getMonthFullName = (month) => {
  return month ? `${MONTHSTRING[month.trim() - 1].full}` : "";
};

/**
 * This method is used to get the class name of the icon image
 *
 * @param {string} size size of the image icon (medium, small)
 * @returns {string} class name of the icon
 *
 * @example
 *
 * getImageSizeStyle("medium") returns icon__circle--medium
 */

export const getImageSizeStyle = (size) => {
  switch (size) {
    case "medium":
      return "icon__circle--medium";
    case "small":
      return "icon__circle--small";
    default:
      return "icon__circle--medium";
  }
};

/**
 * This method is used to get the class name of the profile image
 *
 * @param {string} size size of the Profile Image (medium, small)
 * @returns {string} class name of the Profile Image
 *
 * @example
 *
 * getProfileImageStyle("medium") returns profile__image--medium
 */

export const getProfileImageStyle = (size) => {
  switch (size) {
    case "medium":
      return "profile__image--medium";
    case "small":
      return "profile__image--small";
    default:
      return "profile__image--medium";
  }
};

/**
 * This method can be used to get duration beteen two dates.
 *
 * @example
 * getDuration = (2017-04-30T00:00:00Z, 2017-04-01T00:00:00Z)
 *
 * @return {string} time duration.
 */

export const getDuration = (date1, date2) => {
  const monthDifference = Math.round(moment(new Date(date1)).add(1, "days").diff(new Date(date2), "months", true));

  const yearsTemp = Math.floor(monthDifference / 12);
  const monthsTemp = monthDifference % 12;

  let years = yearsTemp > 0 ? `${yearsTemp} Yr` : "";
  years = yearsTemp > 1 ? `${yearsTemp} Yrs` : years;
  let months = monthsTemp > 0 ? `${monthsTemp} Mo` : "";
  months = monthsTemp > 1 ? `${monthsTemp} Mos` : months;

  return `${years} ${months}`;
};

/**
 * This method can be used as text limiter after given limit.
 *
 * @example
 * letterLimit(data, 145)
 *
 * @return {string} updated text.
 */

export const letterLimit = (text, count) => {
  return text.slice(0, count) + (text.length > count ? "..." : "");
};

/**
 * This method can be used to generate unique ids.
 *
 * @example
 * const id = generateId()
 * console.log(id.next().value)
 *
 * @return {number} id.
 */

export function* generateId() {
  let id = 1;

  while (true) {
    yield id;
    id += 1;
  }
}

/**
 *
 * @param {string} date.
 * @param {string} format
 * @return {string} formatted date.
 */

export const getDate = (dateStr) => {
  const dateObj = new Date(dateStr);
  let date = dateObj.getDate();
  let month = dateObj.getMonth() + 1;
  const year = dateObj.getFullYear();

  date = date.toString().length < 2 ? `0${date}` : date;
  month = month.toString().length < 2 ? `0${month}` : month;

  return `${date}/${month}/${year}`;
};

/**
 * This method concatenates the month and day passed in a string (Mon day)
 * @param {string} month
 * @param {string} day
 *
 * @returns {string} concatenated string in 'month day' format.
 *
 * @example
 *
 * getDOB("Jan", "1") returns Jan 1
 */

export const getDOB = (month, day) => {
  return month && day ? `${month} ${day}` : "";
};

/**
 * This method returns extension of a file.
 * If not extension is present then string value of `default` is returned.
 * @param {string} filename
 *
 * @returns {string} extension of the file
 *
 * @example
 *
 * getDOB("Jan", "1") returns Jan 1
 */

export const getExtension = (str = "") => {
  const index = str.lastIndexOf(".");
  let extension = "default";

  if (index !== -1) {
    extension = str.substr(index + 1).toLowerCase();
  }

  return extension;
};

/**
 * This method copy the content to clipboard.
 * @param {string} textToCopy
 *
 */
export const copyToClipboard = (textToCopy) => {
  if (navigator.clipboard && window.isSecureContext) {
    return navigator.clipboard.writeText(textToCopy);
  }

  const textArea = document.createElement("textarea");
  textArea.value = textToCopy;
  textArea.style.position = "fixed";
  textArea.style.left = "-999999px";
  textArea.style.top = "-999999px";
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  return new Promise((res, rej) => {
    if (document.execCommand("copy")) {
      res();
    } else {
      rej();
    }
    textArea.remove();
  });
};

export const isIE = () => {
  return navigator.userAgent.indexOf("MSIE") !== -1 || navigator.appVersion.indexOf("Trident/") > -1;
};

export const isSafari = () => {
  // Check the userAgent property of the window.navigator object
  const ua = window.navigator.userAgent;
  const isSafariBrowser = /^((?!chrome|android).)*safari/i.test(ua);
  return isSafariBrowser;
};

export const isMobileSafari = () => {
  // Check the userAgent property of the window.navigator object
  const ua = window.navigator.userAgent;
  return ua.match(/(iPod|iPhone|iPad)/) && ua.match(/AppleWebKit/);
};

export const useQuery = (queryStr) => {
  return new URLSearchParams(queryStr);
};

export const getEntries = (data) => Object.keys(data).map((key) => [key, data[key]]);

// function used to create query string based on object as an input
export const buildQueryString = (obj) => {
  const o = getEntries(obj)
    ?.filter(([key, val]) => key !== undefined && val !== undefined)
    ?.map(([key, val]) => {
      let ret = { [key]: val };

      if (val[0] && typeof val[0] === "object" && val[0].QUERY_PARAM) {
        ret = { [val[0].QUERY_PARAM]: val.map((v) => v.name) };
      }

      return ret;
    })
    ?.reduce((acc, curr) => ({ ...acc, ...curr }), {});

  return qs.stringify(o, { indices: false });
};

// function used to create cases api query string based on cases page url
export const createCasesQuery = (urlQueryParams, CASE_QUERY_PARAMS) => {
  const paramObj = {
    query: urlQueryParams[CASE_QUERY_PARAMS.SEARCHED_TERM],
    enableAutoCorrect: urlQueryParams[CASE_QUERY_PARAMS.ENABLE_AUTO_CORRECT],
    resultsFromPage: urlQueryParams[CASE_QUERY_PARAMS.RESULTS_FROM_PAGE],
    resultsPerPage: urlQueryParams[CASE_QUERY_PARAMS.RESULTS_PER_PAGE],
    sortingOrder: urlQueryParams[CASE_QUERY_PARAMS.SORTING_ORDER],
    viewState: urlQueryParams[CASE_QUERY_PARAMS.VIEW_STATE],
    caseType: urlQueryParams[CASE_QUERY_PARAMS.CASE_TYPE],
    industryPA: urlQueryParams[CASE_QUERY_PARAMS.INDUSTRY_PA],
    functionalPA: urlQueryParams[CASE_QUERY_PARAMS.FUNCTIONAL_PA],
    bcgRegionOffice: urlQueryParams[CASE_QUERY_PARAMS.REGIONS],
    dateRange: urlQueryParams[CASE_QUERY_PARAMS.DATE_RANGE],
    vignettes: urlQueryParams[CASE_QUERY_PARAMS.VIGNETTES]
  };
  return buildQueryString(paramObj);
};

// function used to create materials api query string based on cases page url
export const createMaterialsQuery = (urlQueryParams, MATERIAL_QUERY_PARAMS) => {
  const paramObj = {
    query: urlQueryParams[MATERIAL_QUERY_PARAMS.SEARCHED_TERM],
    sortingOrder: urlQueryParams[MATERIAL_QUERY_PARAMS.SORTING_ORDER],
    viewState: urlQueryParams[MATERIAL_QUERY_PARAMS.VIEW_STATE],
    enableAutoCorrect: urlQueryParams[MATERIAL_QUERY_PARAMS.ENABLE_AUTO_CORRECT],
    resultsPerPage: urlQueryParams[MATERIAL_QUERY_PARAMS.RESULTS_PER_PAGE],
    resultsFromPage: urlQueryParams[MATERIAL_QUERY_PARAMS.RESULTS_FROM_PAGE],
    materialType: urlQueryParams[MATERIAL_QUERY_PARAMS.MATERIAL_TYPE],
    industryPA: urlQueryParams[MATERIAL_QUERY_PARAMS.INDUSTRY_PA],
    functionalPA: urlQueryParams[MATERIAL_QUERY_PARAMS.FUNCTIONAL_PA],
    subjectPA: urlQueryParams[MATERIAL_QUERY_PARAMS.SUBJECT_PA],
    dateRevised: urlQueryParams[MATERIAL_QUERY_PARAMS.DATE_REVISED],
    recommendedBy: urlQueryParams[MATERIAL_QUERY_PARAMS.RECOMMENDED_BY]
  };
  return buildQueryString(paramObj);
};

// function to trigger materials api calls
export const fetchMaterialsExportData = (query, offset, NUM_PER_PAGE, profile, QUERY_PARAMS) => {
  const exportQuery = copyObject(query);
  exportQuery.resultsFromPage = offset + 1;
  exportQuery.resultsPerPage = NUM_PER_PAGE;
  const updatedExportQuery = createMaterialsQuery(exportQuery, QUERY_PARAMS);
  const endpoint = `${
    query["bcgInternalsOnly"] === "true" ? apiUrl.ssInternalMaterials : apiUrl.ssMaterial
  }?${updatedExportQuery}&hrId=${profile.hrId}`;
  const config = {
    headers: {
      "x-api-key": `${xApiKey.smartsearch}`
    }
  };
  return axios.get(endpoint, config);
};

// function to trigger cases api calls
export const fetchCasesExportData = (query, offset, NUM_PER_PAGE, profile, QUERY_PARAMS) => {
  const exportQuery = copyObject(query);
  exportQuery.resultsFromPage = offset + 1;
  exportQuery.resultsPerPage = NUM_PER_PAGE;
  const updatedExportQuery = createCasesQuery(exportQuery, QUERY_PARAMS);
  const endpoint = `${apiUrl.ssCases}?${updatedExportQuery}&caseTeamMemberHrId=${profile?.firstName}%20${profile?.lastName}%20${profile?.hrId}`;
  const config = {
    headers: {
      "x-api-key": `${xApiKey.smartsearch}`
    }
  };
  return axios.get(endpoint, config);
};

// Toast messages

export const toastSuccess = () => {
  return toast({
    type: "success",
    icon: COMMON.SuccessMsgIcon,
    title: COMMON.SuccessMsgHeader,
    description: COMMON.SuccessMsgDesc,
    animation: "fly left",
    time: 5000
  });
};

export const toastDeleteSuccess = () => {
  return toast({
    type: "success",
    icon: COMMON.DeleteMsgIcon,
    title: COMMON.DeleteMsgHeader,
    description: COMMON.DeleteMsgDesc,
    animation: "fly left",
    time: 5000
  });
};

export const toastError = (timePeriod = 5000, error, msg) => {
  return toast({
    type: "error",
    icon: COMMON.ErrorMsgIcon,
    title: error || COMMON.ErrorMsgHeader,
    description: msg || COMMON.ErrorMsgDesc,
    animation: "fly left",
    time: timePeriod
  });
};

/**
 * this function checks whether a value or array is empty or null
 * @param {string or Array} value
 *
 * @returns
 * true if the value passed is empty or null otherwise returns false
 *
 */

export const isEmptyOrNull = (value) => {
  if (value === "" || isNil(value)) return true;
  if (Array.isArray(value)) return value.length === 0;
  return false;
};

/**
 *
 * @param {string} name
 * @param {Object} sortConfig
 * @returns
 */

export const getClassNamesFor = (name, sortConfig) => {
  if (!sortConfig) {
    return false;
  }
  return sortConfig.key === name ? sortConfig.direction : undefined;
};

// Merge of Link anf Navigator cases

export const mergeArrayObjects = (arr1, arr2, ssCasesLoadingBatch, casesLoading) => {
  if (isNil(ssCasesLoadingBatch) || ssCasesLoadingBatch || isNil(casesLoading) || casesLoading) {
    return;
  }
  const arrTemp = [];
  arr1?.forEach((item, i) => {
    arr2?.forEach((arrItem, j) => {
      if (item.projectId === arrItem.siteCoreCaseGuid) {
        if (!arrTemp.find((tempItem) => tempItem.caseId === arrItem.caseId)) arrTemp.push({ ...arr2[j], ...arr1[i] });
      }
    });
  });

  // newnavigator cases not yrt in Link
  const leftOutArray = arr1?.filter((item) => arrTemp?.every(({ projectId }) => item.projectId !== projectId)) || [];

  const finalVal = [...leftOutArray, ...arrTemp];

  // eslint-disable-next-line consistent-return
  return finalVal;
};

export function createSynthId(doc) {
  if (doc.attachmentId) {
    return `${doc.attachmentId}-${doc.materialId}-${doc.fileName}-${doc.title}-${doc.materialURL}`;
  }
  if (doc.projectId) {
    return `${doc.projectId}-${doc.docId}-${doc.projectName}`;
  }
  if (doc.labCourseId) {
    return `${doc.labCourseId}-${doc.docId}-${doc.labCourseUrl}`;
  }
  if (doc.kpCmsId || doc.kbCmsId) {
    return `${doc.kpCmsId}-${doc.kbCmsId}`;
  }
  return undefined;
}

export const formatDate = (dateStr) => {
  if (dateStr) {
    const dateObj = new Date(dateStr);
    return `${MONTHDIGIT[dateObj.getUTCMonth()]}/${dateObj.getUTCDate()}/${dateObj
      .getUTCFullYear()
      .toString()
      .substring(2)}`;
  }
  return "-";
};

export const getAllMaterials = (materials, interMaterials) => {
  let allMaterials = {};
  if (materials?.doc?.length > 0 && interMaterials?.doc?.length > 0) {
    allMaterials = {
      ...materials,
      doc: materials?.doc.concat(interMaterials?.doc),
      TotalCount: materials?.TotalCount + interMaterials.TotalCount
    };
    return allMaterials;
  }
  if (materials?.doc) return materials;
  if (interMaterials?.doc) return interMaterials;
  return allMaterials;
};

export const getAllSortedRecentMaterials = (materials, interMaterials) => {
  let allMaterials = {};
  if (materials?.doc?.length > 0 && interMaterials?.doc?.length > 0) {
    allMaterials = {
      ...materials,
      doc: materials?.doc
        .concat(interMaterials?.doc)
        .sort((a, b) => {
          return moment(b.modified).toDate() - moment(a.modified).toDate();
        })
        .slice(0, 4),
      TotalCount: materials?.TotalCount + interMaterials.TotalCount
    };
    return allMaterials;
  }
  if (materials?.doc?.length > 0) return materials;
  if (interMaterials?.doc?.length > 0) return interMaterials;
  return allMaterials;
};

// Logic to generate Smart Logic Authentication token
export const generateSLToken = async () => {
  const data = { grant_type: xApiKey.grantType, key: xApiKey.smartLogic };
  let formBody = [];

  Object.keys(data)?.forEach((property) => {
    const encodedKey = encodeURIComponent(property);
    const encodedValue = encodeURIComponent(data[property]);
    formBody.push(`${encodedKey}=${encodedValue}`);
  });

  formBody = formBody.join("&");
  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      noAuthHeader: true
    }
  };
  try {
    const response = await axios.post(apiUrl.smartLogicToken, formBody, config);
    Cookies.setItem(cookies.SMART_LOGIC_TOKEN, JSON.stringify(response.data), response.data.expires_in);
    return response;
  } catch (error) {
    return error;
  }
};

// Logic for Smart Logic allterms api integration
export const invokeSmartLogicAllTermsApi = (dispatch, action, model, token) => {
  dispatch(
    action({
      endpoint: `${apiUrl.smartLogicApi}?TBDB=${model}&service=allterms&template=service.json`,
      config: {
        headers: {
          authorization: `Bearer ${token}`,
          noAuthHeader: true
        }
      }
    })
  );
};
// Logic for Smart Logic term api integration
export const invokeSmartLogicTermApi = (dispatch, action, model, token, data) => {
  dispatch(
    action({
      endpoint: `${apiUrl.smartLogicApi}?TBDB=${model}&service=term&template=service.json&id=${data}`,
      config: {
        headers: {
          authorization: `Bearer ${token}`,
          noAuthHeader: true
        }
      }
    })
  );
};

// debounce - to be used for cases like search

export function debounce(func, timeout = 500) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

export const findElementById = (array, id) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const element of array) {
    if (element.Id === id) {
      return element;
    }
    if (element?.children?.length > 0) {
      const result = findElementById(element?.children, id);
      if (result) {
        return result;
      }
    }
  }
  return null;
};

export const checkScroll = (targetRef, targetFunc) => {
  if (targetRef?.current?.offsetHeight < targetRef?.current?.scrollHeight) {
    targetFunc(true);
  } else {
    targetFunc(false);
  }
};

export const getAllChildrenElements = (object) => {
  let children = [];
  if (object?.children?.length > 0) {
    object?.children.forEach((item) => {
      children.push({ ...item, id: item?.Id, name: item?.Name });
      if (item?.children?.length > 0) {
        children = [...children, ...getAllChildrenElements(item)];
      }
    });
  } else {
    children.push({ ...object, id: object?.Id, name: object?.Name });
  }
  return children;
};

export const removeSpecialCharsAndSpacesFromString = (string) => {
  return string
    ?.replace(/[^\w\s]/gi, "")
    .split(" ")
    .join("");
};

// eslint-disable-next-line class-methods-use-this
export const areArraysEqual = (arr1 = [], arr2 = [], prop) => {
  const set1 = new Set();
  const set2 = new Set();

  // Iterate over the first array of objects
  arr1.forEach((item) => {
    set1.add(item[prop]);
  });

  arr2.forEach((item) => {
    set2.add(item[prop]);
  });

  // Compare the size of the two sets.
  if (set1.size !== set2.size) {
    return false;
  }

  // Compare all elements in set1
  // eslint-disable-next-line no-restricted-syntax
  for (const val of set1) {
    if (!set2.has(val)) {
      return false;
    }
  }
  return true;
};

export const isLOA = ({ workerType, status }) => {
  return userWorkerType.FTE === workerType && userStatus.LOA === status ? EMPLOYEE_STATUS.LOA : "";
};

export const isCWTerminated = ({ status, role, workerType }) => {
  return userStatus.Terminated === status && userWorkerType.CW === workerType && !userRole.SA.includes(role)
    ? EMPLOYEE_STATUS.CT
    : "";
};

export const isDisabledUser = (hrId) => {
  return disabledUserIds.includes(hrId?.toString()) ? 404 : "";
};
export const getErrorDetails = (searchedPeopleData) => {
  const status = isCWTerminated(searchedPeopleData) || isDisabledUser(searchedPeopleData.hrId);
  // isLOA(loggedInUserData.status)

  return status ? { code: status } : "";
};

export const sortArrObjectByDate = (data, fieldName, sortOrder = "desc") => {
  return data?.sort((a, b) => {
    return sortOrder === "asc"
      ? new Date(a[fieldName]) - new Date(b[fieldName])
      : new Date(b[fieldName]) - new Date(a[fieldName]);
  });
};

export const checkIfRegex = (str) => {
  const format = /^[!@#$%&*()_\-+={[}\]|:;"',.?\/\\ \w\s\d]*$/;
  return format.test(str);
};

export const convertArrayToObject = (array, key) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item
    };
  }, initialValue);
};
export const noOfDaystoExpire = (date) => {
  const startDate = new Date(date); // this is the Opt - in date
  const expirationDate = new Date(startDate);
  expirationDate.setFullYear(startDate.getFullYear() + 1); // this will be the expiry date after adding one year.
  const currentDate = new Date();
  const timeDifference = expirationDate - currentDate; // time difference in milliseconds

  // Convert milliseconds to days
  const daysRemaining = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  return daysRemaining;
};

export const filterListBasedOnEndYear = (experiences, cb) => {
  return experiences?.filter((item) => {
    const { roles = [] } = item;
    if (roles && roles.length > 0) {
      return roles.some((role) => cb(role));
    }
    return false;
  });
};

export const sortRolesListBasedOnEndYear = (list) => {
  list?.forEach((item) =>
    item?.roles?.sort((a, b) => {
      const endYearA = new Date(
        !a?.endYear ? new Date().getFullYear() : a?.endYear,
        a?.endMonth ? a?.endMonth - 1 : new Date().getMonth()
      );
      const endYearB = new Date(
        !b?.endYear ? new Date().getFullYear() : b?.endYear,
        b?.endMonth ? b?.endMonth - 1 : new Date().getMonth()
      );
      return endYearB - endYearA;
    })
  );

  return list;
};

export const sortListBasedOnEndYear = (list) => {
  list?.sort((a, b) => {
    const endYearA = new Date(
      !a?.roles[0]?.endYear ? new Date().getFullYear() : a?.roles[0]?.endYear,
      a?.roles[0]?.endMonth ? a?.roles[0]?.endMonth - 1 : new Date().getMonth()
    );
    const endYearB = new Date(
      !b?.roles[0]?.endYear ? new Date().getFullYear() : b?.roles[0]?.endYear,
      b?.roles[0]?.endMonth ? b?.roles[0]?.endMonth - 1 : new Date().getMonth()
    );
    return endYearB - endYearA;
  });

  return list;
};

/**
 *
 * @param {string} str number to be formatted
 * @param {string} prefix prefix to be added
 * @returns
 */

export function formatPhoneNumber(str, prefix) {
  // Remove all non-digit characters from the phone number string
  const cleaned = str?.replace(/\D/g, "");
  if (!isEmptyOrNull(cleaned)) {
    return `+${prefix} ${cleaned}`;
  }
  return "";
}

export const createParamsObject = (search) => {
  const searchParams = new URLSearchParams(search); // Step 1
  const paramsObj = Array.from(searchParams.entries()).reduce((acc, [key, value]) => {
    acc[key] = value; // Step 2
    return acc;
  }, {});
  return paramsObj;
};
