import { profile } from "redux/constants";

const Error = (state = {}, action) => {
  switch (action.type) {
    case profile.FETCH_PROFILE_FAILURE:
      return action.error;
    case profile.FETCH_PROFILE_SUCCESS:
    case profile.FETCH_PROFILE:
      return null;
    default:
      return state;
  }
};

export default Error;
