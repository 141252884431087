import { cases } from "redux/constants";

const getCases = (endpoint) => {
  return {
    type: cases.FETCH_CASES,
    endpoint
  };
};

const setAllCases = (data) => {
  return {
    type: cases.SET_ALL_CASES,
    data
  };
};

const setCases = (data) => ({
  type: cases.FETCH_CASES_SUCCESS,
  data
});

const setError = (error) => ({
  type: cases.FETCH_CASES_FAILURE,
  error
});

const updateCase = (payload) => ({
  type: cases.UPDATE_CASE,
  payload
});

const updateResponsibility = (payload) => ({
  type: cases.UPDATE_RESPONSIBILITY,
  payload
});

const setUpdateResponsibilitySuccess = (payload) => ({
  type: cases.UPDATE_RESPONSIBILITY_SUCCESS,
  payload
});

const setUpdateResponsibilityFailure = (payload) => ({
  type: cases.UPDATE_RESPONSIBILITY_FAILURE,
  payload
});

const setResponsibilitySuccess = (data) => ({
  type: cases.SET_RESPONSIBILITY_SUCCESS,
  data
});

const setResponsibilityError = (error) => ({
  type: cases.SET_RESPONSIBILITY_FAILURE,
  error
});

const getResponsibility = (payload) => ({
  type: cases.GET_RESPONSIBILITIES,
  payload
});

const setUpdateCaseSuccess = (data) => ({
  type: cases.UPDATE_CASE_SUCCESS,
  data
});

const setUpdateCaseError = (error) => ({
  type: cases.UPDATE_CASE_FAILURE,
  error
});

const updateCaseTopics = (payload) => ({
  type: cases.UPDATE_CASE_TOPICS,
  payload
});

const setUpdateCaseTopicSuccess = (data) => ({
  type: cases.UPDATE_CASE_TOPICS_SUCCESS,
  data
});

const setUpdateCaseTopicsError = (error) => ({
  type: cases.UPDATE_CASE_TOPICS_ERROR,
  error
});

const updateCaseSkills = (payload) => ({
  type: cases.UPDATE_CASE_SKILLS,
  payload
});

const setUpdateCaseSkillsSuccess = (data) => ({
  type: cases.UPDATE_CASE_SKILLS_SUCCESS,
  data
});

const setUpdateCaseSkillsError = (error) => ({
  type: cases.UPDATE_CASE_SKILLS_ERROR,
  error
});

const deleteCaseTopicsAndSkills = (payload) => ({
  type: cases.DELETE_CASE_TOPICS_SKILLS,
  payload
});

const setDeleteCaseTopicsAndSkillsSuccess = (data) => ({
  type: cases.DELETE_CASE_TOPICS_SKILLS_SUCCESS,
  data
});

const setDeleteCaseTopicsAndSkillsError = (error) => ({
  type: cases.DELETE_CASE_TOPICS_SKILLS_ERROR,
  error
});

const resetCaseTopicsAndSkillsStatus = () => ({
  type: cases.RESET_CASE_TOPICS_SKILL_STATUS
});

export {
  getCases,
  setCases,
  setError,
  updateCase,
  setUpdateCaseSuccess,
  setUpdateCaseError,
  setAllCases,
  updateResponsibility,
  setResponsibilitySuccess,
  setResponsibilityError,
  getResponsibility,
  updateCaseTopics,
  setUpdateCaseTopicSuccess,
  setUpdateCaseTopicsError,
  updateCaseSkills,
  setUpdateCaseSkillsSuccess,
  setUpdateCaseSkillsError,
  deleteCaseTopicsAndSkills,
  setDeleteCaseTopicsAndSkillsSuccess,
  setDeleteCaseTopicsAndSkillsError,
  resetCaseTopicsAndSkillsStatus,
  setUpdateResponsibilitySuccess,
  setUpdateResponsibilityFailure
};
