import { takeLatest } from "redux-saga/effects";

import { childTopicsFinder } from "redux/constants";
import handleFetchChildTopicsFinder from "../worker/handleFetchChildTopicsFinder";

function* watchChildTopicsFinder() {
  yield takeLatest(childTopicsFinder.FETCH_CHILD_TOPICS_FINDER, handleFetchChildTopicsFinder);
}

export default watchChildTopicsFinder;
