import { call, put as putSaga } from "redux-saga/effects";
import { put } from "redux/api";
import { setUpdateCaseSuccess, setUpdateCaseError } from "redux/actions/cases";

function* handleUpdateCase({ payload }) {
  const { data, error } = yield call(put, payload);
  if (error) {
    yield putSaga(setUpdateCaseError(error));
  } else {
    yield putSaga(setUpdateCaseSuccess(data));
  }
}

export default handleUpdateCase;
