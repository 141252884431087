import { call, put } from "redux-saga/effects";
import { get } from "redux/api";
import { setError, setTopicsSkillsSearch } from "redux/actions/finder/searchTopicsSkills";

function* handleFetchTopicsSkillsSearch(endpoint) {
  const { data, error } = yield call(get, endpoint);
  if (error) {
    yield put(setError(error));
  } else {
    yield put(setTopicsSkillsSearch(data));
  }
}

export default handleFetchTopicsSkillsSearch;
