import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Menu } from "semantic-ui-react";
import { EVENTS, TABS } from "analytics/constants";
import sendEventData from "analytics/analytics";
import useIsMounted from "components/TaxonomySelector/useIsMounted";

import "./styles.scss";
/**
 * Navigation - Left navigation component
 *
 * @param  {string} className class name
 * @param  {string || number} userId userId
 * @param  {string || number} caseCount case count
 * @param  {string || number} materialCount material count
 *
 * @return component with navigation items
 *
 * @example
 * <Navigation className="left-nav" />
 */

const Navigation = ({ className, userId, caseCount, materialCount, hide, sourceApplicationName }) => {
  // Navigation items, links and icon data
  const NAV = [
    {
      name: "Overview",
      iconName: "ic_home.png",
      link: `/overview?hrid=${userId}${sourceApplicationName}`,
      click: () => {
        window.$tabName = TABS.OVERVIEW; //   // Global variable for Analytics
        sendEventData(TABS.OVERVIEW, EVENTS.TAB_CLICKS); // Analytics Event
      }
    },
    {
      name: `Cases (${caseCount})`,
      iconName: "ic_cases.png",
      link: `/cases?hrid=${userId}${sourceApplicationName}`,
      click: () => {
        window.$tabName = TABS.CASES; // Global variable for Analytics
        sendEventData(TABS.CASES, EVENTS.TAB_CLICKS);
      } // Analytics Event
    },
    {
      name: `Materials (${materialCount})`,
      iconName: "ic_materials.png",
      link: `/materials?hrid=${userId}${sourceApplicationName}`,
      click: () => {
        window.$tabName = TABS.MATERIALS; //   // Global variable for Analytics
        sendEventData(TABS.MATERIALS, EVENTS.TAB_CLICKS); // Analytics Event
      }
    },
    {
      name: "Expertise & Skills",
      iconName: "ic_exp-grey.svg",
      link: `/expertise?hrid=${userId}${sourceApplicationName}`,
      click: () => {
        window.$tabName = TABS.EXPERTISE; //   // Global variable for Analytics
        sendEventData(TABS.EXPERTISE, EVENTS.TAB_CLICKS); // Analytics Event
      }
    }
  ];

  const isMounted = useIsMounted();
  const [scrolled, setScrolled] = useState(false);
  const [hideNav, setHideNav] = useState(false);

  useEffect(() => {
    if (isMounted.current) setHideNav(hide);
  }, [hide]);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 272 && isMounted.current) {
      setScrolled(true);
      return;
    }
    if (isMounted.current) setScrolled(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  });

  return (
    <div className={`${className}`} id="navbar">
      <Menu compact vertical className={`navigation__menu ${scrolled && "scrolled"} ${hideNav && "d-none"}`}>
        {NAV.map((item) => (
          <Menu.Item as="span" className="navigation__menu__item" name={item.name} key={item.name} onClick={item.click}>
            <NavLink
              title={item.name}
              to={item.link}
              exact
              activeClassName={`navigation__menu__item--active active-${item.name}`}
            >
              <span style={{ backgroundImage: `url(/icons/${item.iconName})` }} />
              {item.name}
            </NavLink>
          </Menu.Item>
        ))}
      </Menu>
    </div>
  );
};

Navigation.defaultProps = {
  className: "",
  userId: "",
  hide: false,
  caseCount: "--",
  materialCount: "--"
};

Navigation.propTypes = {
  className: PropTypes.string,
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hide: PropTypes.bool,
  caseCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  materialCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default Navigation;
