import { topicsSkillFinder } from "redux/constants";

const getSkillTopicsFinder = (endpoint) => {
  return {
    type: topicsSkillFinder.FETCH_SKILL_TOPICS_FINDER,
    endpoint
  };
};

const setSkillTopicsFinder = (data) => ({
  type: topicsSkillFinder.FETCH_SKILL_TOPICS_FINDER_SUCCESS,
  data
});

const setError = (error) => ({
  type: topicsSkillFinder.FETCH_SKILL_TOPICS_FINDER_FAILURE,
  error
});

export { getSkillTopicsFinder, setSkillTopicsFinder, setError };
