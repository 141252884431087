import { cases } from "redux/constants";

const { STATUS } = cases;

const updateCaseResponsibiltyReducer = (state = "", action) => {
  switch (action.type) {
    case cases.UPDATE_RESPONSIBILITY:
      return STATUS.SAVING;
    case cases.UPDATE_RESPONSIBILITY_SUCCESS:
      return STATUS.SAVE_SUCCESS;
    case cases.UPDATE_RESPONSIBILITY_FAILURE:
      return STATUS.SAVE_FAILURE;
    case cases.RESET_CASE_TOPICS_SKILL_STATUS:
      return "";
    default:
      return state;
  }
};

export default updateCaseResponsibiltyReducer;
