import { searchTopicsSkills } from "redux/constants";

const topicsSkillsSearchReducer = (state = {}, action) => {
  switch (action.type) {
    case searchTopicsSkills.FETCH_TOPICS_SKILLS_FINDER_SUCCESS: {
      return [...action.data];
    }
    default:
      return state;
  }
};

export default topicsSkillsSearchReducer;
