import { Grid } from "semantic-ui-react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import "./styles.scss";
import AboutTileCompletion from "../AboutTileCompletion/AboutTileCompletion";
import ProfileSummaryCvBioCompletion from "../ProfileSummaryCvBioCompletion/ProfileSummaryCvBioCompletion";

const ViewEditProfileCompletion = ({ isComplete, loggedInUserHrId, isCT, profile, isVisitorView }) => {
  const { profileCompletionData } = useSelector((state) => state);
  const profileCompletionParams = profileCompletionData?.completionResponseList;
  const isProfileCompleted = profileCompletionParams?.every((param) => param.completed && !param.attributeStale);

  return (
    <Grid className="viewEditProfileCompletion">
      {!isCT && (
        <AboutTileCompletion
          isComplete={isComplete}
          skipForNowClicked={profileCompletionData?.aboutSkip}
          profileCompletionParams={profileCompletionParams}
          profile={profile}
          loggedInUserHrId={loggedInUserHrId}
          isVisitorView={isVisitorView}
        />
      )}
      {!isCT && !isProfileCompleted && !isComplete && !isVisitorView && (
        <div data-testid="border" className="viewEditProfileCompletion__borderTop" />
      )}
      <ProfileSummaryCvBioCompletion
        isComplete={isComplete}
        skipForNowClicked={profileCompletionData?.summarySkip}
        profileCompletionParams={profileCompletionParams}
        isVisitorView={isVisitorView}
      />
    </Grid>
  );
};

ViewEditProfileCompletion.propTypes = {
  isCT: PropTypes.bool.isRequired,
  loggedInUserHrId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  profile: PropTypes.shape().isRequired,
  isComplete: PropTypes.bool.isRequired,
  isVisitorView: PropTypes.bool.isRequired
};

export default ViewEditProfileCompletion;
