import { isEmptyOrNull, sortArrObjectByDate } from "helpers/utils";

import { useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import EditAbout from "components/EditAbout";
import LABEL from "constants/label";
import PropTypes from "prop-types";
import sendEventData from "analytics/analytics";

import { EVENTS, TRIGGERS } from "analytics/constants";

import ProfileCompletionRow from "../ProfileCompletionRow/ProfileCompletionRow";
import { getProfileCompletionData } from "../helpers/utils";

const {
  PROFILE_OVERVIEW: {
    AboutTileData,
    AboutTileKTFields,
    AboutTileECTFields,
    AboutTileSAFields,
    DateFormat,
    LastUpdateDate
  }
} = LABEL;

/**
 * About
 *
 * @param  {object} profile about details object
 * @param  {string} className class name
 * @param  {bool} isLoading loading state
 * @param  {bool} isEditable true if user seeing his own profile
 *
 * @return About component
 */

const AboutTileCompletion = ({ isComplete, profileCompletionParams, profile, isVisitorView, skipForNowClicked }) => {
  const { aboutMe, aboutAdditionalData } = useSelector((state) => state);
  // Edit About popup state
  const [open, setOpen] = useState(false);

  // Edit About Popup
  const openEditAboutPopup = () => {
    sendEventData(TRIGGERS.COMMON.EDIT, EVENTS.ABOUT); // Analytics Event
    setOpen(true);
  };

  const closeEditAboutPopup = () => {
    setOpen(false);
  };

  const getAboutTileCompletionData = () => {
    switch (true) {
      case profile?.isKT:
        return getProfileCompletionData(AboutTileKTFields, profileCompletionParams);
      case profile?.isECT:
        return getProfileCompletionData(AboutTileECTFields, profileCompletionParams);
      case profile?.isSA:
        return getProfileCompletionData(AboutTileSAFields, profileCompletionParams);
      default: {
        return null;
      }
    }
  };

  // logic to calculate about tile completion and last updated date
  const aboutTileCompletionData = getAboutTileCompletionData();
  const isAboutTileCompleted = aboutTileCompletionData?.isCompleted || isComplete;
  const sortedAboutTileCompletionFieldData = sortArrObjectByDate(aboutTileCompletionData?.fieldData, LastUpdateDate)[0]
    ?.lastUpdateDate;
  const aboutTileLastUpdatedDate =
    !isEmptyOrNull(sortedAboutTileCompletionFieldData) && moment(sortedAboutTileCompletionFieldData).format(DateFormat);
  return (
    <>
      <ProfileCompletionRow
        isCompleted={isAboutTileCompleted}
        lastUpdateDate={aboutTileLastUpdatedDate}
        listData={AboutTileData}
        rowName="About"
        isSectionNotCompleted={!isAboutTileCompleted}
        testId="aboutTileCompletionRow"
        skipForNowClicked={skipForNowClicked}
        onUpdate={openEditAboutPopup}
        isVisitorView={isVisitorView}
      />
      {open && (
        <EditAbout
          open={open}
          onClose={closeEditAboutPopup}
          onOpen={openEditAboutPopup}
          profile={profile}
          ktDetails={{
            aboutKtId: aboutMe?.aboutKtId,
            ktPracticeArea: { id: aboutMe?.practiceAreaGuid, name: aboutAdditionalData?.practiceArea },
            ktTopic: { id: aboutMe?.primaryTopicGuid, name: aboutAdditionalData?.primaryTopic },
            ktSubTopic: { id: aboutMe?.subTopicGuid, name: aboutAdditionalData?.subTopic }
          }}
          ectDetails={{
            aboutEctId: aboutMe?.aboutEctId,
            sponsorPA: { id: aboutMe?.sponsorPracticeAreaGuid, name: aboutAdditionalData?.sponserPracticeArea },
            primaryTopic: { id: aboutMe?.primaryTopicGuid, name: aboutAdditionalData?.primaryTopic },
            sponsor: {
              id: aboutMe?.sponsorHrId || null,
              name: aboutAdditionalData?.sponserName || ""
            }
          }}
          saDetails={{
            aboutSaId: aboutMe?.aboutSaId,
            saPracticeArea1: { id: aboutMe?.practiceArea1Guid, name: aboutAdditionalData?.practiceAreaOne },
            saPracticeArea2: { id: aboutMe?.practiceArea2Guid, name: aboutAdditionalData?.practiceAreaTwo },
            sponsor1: {
              id: aboutMe?.sponsor1HrId || null,
              name: aboutAdditionalData?.sponser1Name || ""
            },
            sponsor2: {
              id: aboutMe?.sponsor2HrId || null,
              name: aboutAdditionalData?.sponser2Name || ""
            }
          }}
          permissions={aboutAdditionalData?.editAboutPermissions}
        />
      )}
    </>
  );
};

AboutTileCompletion.defaultProps = {
  isComplete: false,
  profileCompletionParams: [],
  profile: {},
  isVisitorView: false
};

AboutTileCompletion.propTypes = {
  isComplete: PropTypes.bool,
  profile: PropTypes.shape(),
  profileCompletionParams: PropTypes.arrayOf(PropTypes.shape()),
  loggedInUserHrId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isVisitorView: PropTypes.bool,
  skipForNowClicked: PropTypes.bool.isRequired
};

export default AboutTileCompletion;
