/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import LABEL from "constants/label";
import { Table, Label } from "semantic-ui-react";
import { Expand } from "../../common/Button";
import { InlineList } from "../../common";
import ProficiencyIndicator from "../ProficiencyIndicator/ProficiencyIndicator";
import "./styles.scss";

const { LANGUAGE_PROFICIENCIES } = LABEL;

/**
 * @param {object} items collection of languages and skills proficiency data
 * @param {string} limit number of languages to display when list is truncated
 *
 * @returns LanguagesSummary Component
 *
 * @example
 * <LanguagesSummary items={languagesData} limit={5} />
 */
function LanguagesSummary({ items, limit }) {
  const [itemCount, setItemCount] = useState(0);
  const [shortList, setShortList] = useState([]);
  const [listDisplayed, setListDisplayed] = useState([]);
  const [showFullList, setShowFullList] = useState(false);
  const [proficiencyLevels, setProficiencyLevels] = useState(new Map());

  const onShowListOptionClick = () => {
    setShowFullList((prevIsFullList) => !prevIsFullList);
  };

  useEffect(() => {
    setListDisplayed(showFullList ? items : shortList);
  }, [showFullList]);

  useEffect(() => {
    setItemCount(items?.length);
    if (items?.length) {
      const truncatedList = [...items.slice(0, limit)];
      setShortList(truncatedList);
      setListDisplayed(!showFullList ? truncatedList : items);
    }
  }, [items, limit]);

  return (
    !!itemCount &&
    !!listDisplayed?.length && (
      <>
        <div className="languages-summary__mobile-view">
          <InlineList title="Languages" items={items} accessor={{ id: "language", item: "language" }} />
        </div>
        <div className="languages-summary__desktop-view">
          <h5 data-testid="languages-summary-title" className="languages-summary__title">
            {LANGUAGE_PROFICIENCIES.TITLE}
          </h5>
          <Table striped className="languages-summary__table--main" data-testid="languages-summary-table">
            <Table.Header>
              <Table.Row data-testid="languages-summary-table-header">
                <Table.HeaderCell
                  data-testid="languages-summary-table-header--label"
                  className="languages-summary__table-header"
                >
                  {LANGUAGE_PROFICIENCIES.TABLE_HEADERS.LANGUAGE}
                </Table.HeaderCell>

                <Table.HeaderCell />

                <Table.HeaderCell
                  data-testid="languages-summary-table-header--label"
                  className="languages-summary__table-header"
                >
                  {LANGUAGE_PROFICIENCIES.TABLE_HEADERS.SKILL_PRESENT}
                </Table.HeaderCell>

                <Table.HeaderCell />

                <Table.HeaderCell
                  data-testid="languages-summary-table-header--label"
                  className="languages-summary__table-header"
                >
                  {LANGUAGE_PROFICIENCIES.TABLE_HEADERS.SKILL_READ}
                </Table.HeaderCell>

                <Table.HeaderCell />

                <Table.HeaderCell
                  data-testid="languages-summary-table-header--label"
                  className="languages-summary__table-header"
                >
                  {LANGUAGE_PROFICIENCIES.TABLE_HEADERS.SKILL_SPEAK}
                </Table.HeaderCell>

                <Table.HeaderCell />

                <Table.HeaderCell
                  data-testid="languages-summary-table-header--label"
                  className="languages-summary__table-header"
                >
                  {LANGUAGE_PROFICIENCIES.TABLE_HEADERS.SKILL_WRITE}
                </Table.HeaderCell>

                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {listDisplayed.map((item, index) => (
                <Table.Row data-testid="languages-summary-table-datarow" key={`language-proficiency-${item.language}`}>
                  <Table.Cell data-testid="languages-summary-table-data-name" className="languages-summary__table-cell">
                    {item.language}
                  </Table.Cell>

                  <Table.Cell />

                  <Table.Cell>
                    <ProficiencyIndicator
                      elementParentKey={`language-skill-proficiency-${item.language}`}
                      proficiency={item.fluencyPresent}
                    />
                  </Table.Cell>

                  <Table.Cell />

                  <Table.Cell>
                    <ProficiencyIndicator
                      elementParentKey={`language-skill-proficiency-${item.language}`}
                      proficiency={item.fluencyReading}
                    />
                  </Table.Cell>

                  <Table.Cell />

                  <Table.Cell>
                    <ProficiencyIndicator
                      elementParentKey={`language-skill-proficiency-${item.language}`}
                      proficiency={item.fluencySpeaking}
                    />
                  </Table.Cell>

                  <Table.Cell />

                  <Table.Cell>
                    <ProficiencyIndicator
                      elementParentKey={`language-skill-proficiency-${item.language}`}
                      proficiency={item.fluencyWriting}
                    />
                  </Table.Cell>
                  <Table.Cell />
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
          {itemCount > limit && (
            <Expand
              data-testid="languages-summary-table-expander"
              expanded={showFullList}
              onClick={onShowListOptionClick}
            />
          )}
        </div>
      </>
    )
  );
}
LanguagesSummary.defaultProps = {
  limit: 3
};

LanguagesSummary.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape).isRequired,
  limit: PropTypes.number
};

export default LanguagesSummary;
