import LABEL from "./label";

const sortOptionsMaterials = [
  {
    key: "0",
    value: LABEL.SORT.MostRecent,
    text: LABEL.SORT.MostRecent
  },
  {
    key: "1",
    value: LABEL.SORT.Relevancy,
    text: LABEL.SORT.Relevancy
  }
];

const sortOptionsCases = [
  {
    key: "0",
    value: LABEL.SORT.MostRecent,
    text: LABEL.SORT.MostRecent
  },
  {
    key: "1",
    value: LABEL.SORT.Relevancy,
    text: LABEL.SORT.Relevancy
  }
];

const protocols = [
  {
    key: "http",
    text: "http://",
    value: "http://"
  },
  {
    key: "https",
    text: "https://",
    value: "https://"
  }
];

const dateFilterOptions = {
  CASES: [
    { key: 0, value: 0, text: "All" },
    {
      key: 1,
      value: 1,
      text: "Past Five Years"
    },
    {
      key: 2,
      value: 2,
      text: "Past Two Years"
    },
    {
      key: 3,
      value: 3,
      text: "Past Year"
    },
    {
      key: 4,
      value: 4,
      text: "Past 90 Days"
    },
    {
      key: 5,
      value: 5,
      text: "Past 30 Days"
    }
  ],
  MATERIALS: [
    { key: 0, value: 0, text: "All" },
    {
      key: 1,
      value: 1,
      text: "Past Five Years"
    },
    {
      key: 2,
      value: 2,
      text: "Past Two Years"
    },
    {
      key: 3,
      value: 3,
      text: "Past Year"
    },
    {
      key: 6,
      value: 6,
      text: "Past 6 Months"
    },
    {
      key: 4,
      value: 4,
      text: "Past 90 Days"
    },
    {
      key: 5,
      value: 5,
      text: "Past 30 Days"
    }
  ]
};

const languages = [
  {
    name: "English",
    id: 1
  },
  {
    name: "French",
    id: 2
  },
  {
    name: "German",
    id: 3
  },
  {
    name: "Spanish",
    id: 4
  },
  {
    name: "Chinese",
    id: 14
  },
  {
    name: "Dutch",
    id: 9
  },
  {
    name: "Finnish",
    id: 13
  }
];

const apiErrorCodes = [400, 401, 403, 404, 500, 501, 503, 504];

const SkillsAndTools = "SkillsAndTools";

const userRole = {
  SA: "sa"
};
const userStatus = {
  LOA: "On Leave",
  Terminated: "Terminated"
};
const userWorkerType = {
  CW: "Contingent Worker",
  FTE: "Employee"
};
export {
  sortOptionsCases,
  sortOptionsMaterials,
  protocols,
  languages,
  SkillsAndTools,
  apiErrorCodes,
  dateFilterOptions,
  userStatus,
  userRole,
  userWorkerType
};
