import { takeEvery } from "redux-saga/effects";

import { peopleData } from "redux/constants";
import handleFetchPeopleData from "../worker/handleFetchPeopleData";

function* watchPeopleData() {
  yield takeEvery(peopleData.FETCH_PEOPLE_DATA, handleFetchPeopleData);
}

export default watchPeopleData;
