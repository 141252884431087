import { call, put } from "redux-saga/effects";
import { get } from "redux/api";
import { setProfileUpdateHistory, setError } from "redux/actions/profileUpdateHistory";

function* handleFetchProfileUpdateHistory(endpoint) {
  const { data, error } = yield call(get, endpoint);
  if (error) {
    yield put(setError(error));
  } else {
    yield put(setProfileUpdateHistory(data));
  }
}

export default handleFetchProfileUpdateHistory;
