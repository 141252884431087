import { externalCourseId } from "redux/constants";

const externalCourseIdReducer = (state = null, action) => {
  switch (action.type) {
    case externalCourseId.CHECK_COURSE_ID_SUCCESS: {
      return action?.data;
    }
    default:
      return state;
  }
};

export default externalCourseIdReducer;
