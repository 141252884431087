import { takeLatest } from "redux-saga/effects";

import { skills } from "redux/constants";
import handleFetchSkills from "../worker/handleFetchSkills";

function* watchSkills() {
  yield takeLatest(skills.FETCH_SKILL, handleFetchSkills);
}

export default watchSkills;
