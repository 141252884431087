import React from "react";
import { Grid, Placeholder } from "semantic-ui-react";
import PropTypes from "prop-types";
import InfoIcon from "components/common/InfoIcon";
import ToolTipContents from "components/ProfileCompletion/ToolTipContents";

const ViewerPercentage = ({ percentCompleted, profileCompletionLoading }) => {
  return (
    <>
      {profileCompletionLoading ? (
        <Placeholder>
          <Placeholder.Line />
        </Placeholder>
      ) : (
        <Grid.Row className="mt-0 pb-0" data-testid="viewerSection">
          <Grid.Column>
            <div className="percent__label justify-content-between">
              <div>
                This profile is <b>{percentCompleted}%</b> Up to Date
              </div>
              <InfoIcon>
                <ToolTipContents />
              </InfoIcon>
            </div>
          </Grid.Column>
        </Grid.Row>
      )}
    </>
  );
};

ViewerPercentage.propTypes = {
  percentCompleted: PropTypes.number.isRequired,
  profileCompletionLoading: PropTypes.bool.isRequired
};

export default ViewerPercentage;
