import { education } from "redux/constants";

const educationReducer = (state = [], action) => {
  switch (action.type) {
    case education.FETCH_EDUCATION_SUCCESS: {
      return [...action.data];
    }
    default:
      return state;
  }
};

export default educationReducer;
