import React from "react";
import PropTypes from "prop-types";
import { Image } from "semantic-ui-react";
import styles from "./styles.module.scss";

function RecorderToggleIcons({ recorderState }) {
  const { initRecording, audio } = recorderState;
  let icon;

  if (!initRecording) icon = <Image src="/icons/mic-big-idle.svg" className={styles["toggle__icons"]} />;
  if (initRecording) icon = <Image src="/icons/mic-big-on.svg" className={styles["toggle__icons"]} />;
  if (audio)
    icon = (
      <div className={`${styles["toggle__icons"]} d-flex`}>
        <Image src="/icons/volume-big-off.png" size="tiny" />
        <Image src="/icons/volume-on.svg" className="ml-1" />
      </div>
    );
  return <>{icon}</>;
}

RecorderToggleIcons.propTypes = {
  recorderState: PropTypes.shape({
    recordingSeconds: PropTypes.number,
    initRecording: PropTypes.bool,
    audio: PropTypes.string
  }).isRequired
};

export default RecorderToggleIcons;
