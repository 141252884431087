import { cases } from "redux/constants";

const casesReducer = (state = [], action) => {
  switch (action.type) {
    case cases.FETCH_CASES_SUCCESS: {
      return [...action.data];
    }
    default:
      return state;
  }
};

export default casesReducer;
