import { resume } from "redux/constants";

const getResume = (payload) => {
  return {
    type: resume.FETCH_RESUME,
    payload
  };
};

const setResume = (data) => ({
  type: resume.FETCH_RESUME_SUCCESS,
  data
});

const setResumeError = (error) => ({
  type: resume.FETCH_RESUME_FAILURE,
  error
});

export { getResume, setResume, setResumeError };
