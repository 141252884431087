const getDuration = (url, next) => {
  const player = new Audio(url);
  player.addEventListener(
    "durationchange",
    // eslint-disable-next-line func-names
    function (e) {
      if (this.duration !== Infinity) {
        // eslint-disable-next-line prefer-destructuring
        const duration = this.duration;
        player.remove();
        next(duration);
      }
    },
    false
  );
  player.load();
  player.currentTime = 24 * 60 * 60; // fake big time
  player.volume = 0;
  player.play();
};

export default getDuration;
