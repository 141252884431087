import React from "react";
import PropTypes from "prop-types";
import { Button, Dimmer, Loader, Modal, Segment } from "semantic-ui-react";
import LABEL from "constants/label";
import "./styles.scss";

const { COMMON } = LABEL;

const ConfirmationModalWithIcon = ({ isOpen, modalMessage, handleCancelClick, cancelText }) => {
  return (
    <Modal open={isOpen} centered closeOnDimmerClick={false} className="confirmation__custom__modal">
      <Modal.Content className="text-right p-1 d-flex flex-column">
        <div className="d-flex justify-content-center mb-2">
          <Segment className="loading__segment">
            <Dimmer active>
              <Loader size="large" />
            </Dimmer>
          </Segment>
        </div>
        <div className=" d-flex warningpopupContent justify-content-center mt-2">{modalMessage}</div>
        <div className="d-flex justify-content-center">
          <Button data-testid="cancel" basic className="button-sm" secondary onClick={handleCancelClick}>
            {cancelText}
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  );
};

ConfirmationModalWithIcon.defaultProps = {
  isOpen: false,
  cancelText: COMMON.No
};

ConfirmationModalWithIcon.propTypes = {
  isOpen: PropTypes.bool,
  modalMessage: PropTypes.string.isRequired,
  handleCancelClick: PropTypes.func.isRequired,
  cancelText: PropTypes.string
};

export default ConfirmationModalWithIcon;
