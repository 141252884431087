import CONFIG from "constants/config";
import * as utils from "@kdshared/okta-utils";
import { getPeopleData } from "redux/actions/peopleData";
import { getUserData } from "redux/actions/userData";
import { getMemberList } from "redux/actions/memberList";
import { getProfileCompletion } from "redux/actions/profileCompletion";

const { apiUrl, xApiKey } = CONFIG;

export const fetchOrganization = async (hrEmployeeId, dispatch) => {
  dispatch(
    getPeopleData({
      endpointUrl: `${apiUrl.peopleV2Data}`,
      data: {
        query: `
            query {
              employees(ids: "${hrEmployeeId}") {
                id
                preferredName
                preferredDisplayName
                preferredFirstName
                preferredLastName
                businessTitle
                workEmail
                slackId
                cohort
                primaryWorkPhone
                primaryWorkDevice
                mobile
                latestHireDate,
                pronouns
                salutation
                birthDate
                workerURL
                pastTerminationDate                
                hostOfficeRegion 
                pronouns
                phones{
                  deviceType
                  usage
                  countryCode
                  phoneNumber
                }
                education{
                  school
                  degree
                  fieldOfStudy
                  majorComment
                  startYear
                  endYear
                }
                workExperience{
                  jobTitle
                  startDate
                  endDate
                  company
                  companyLocation
                }
                languages{
                  language
                  fluencyReading
                  fluencyWriting
                  fluencySpeaking
                  fluencyPresent
                }
                topics {
                  topicDetails {
                    roleName
                    roleDescription
                    bstTags{
                      guids
                      labels
                    }
                 }
                }
                lineManager { 
                  id
                  preferredFirstName
                  preferredLastName
                  businessTitle
                  workEmail
                  hostOfficeLocation{
                    id
                    name
                  }
                  profilePicture
                 }
                 reportingLine { 
                  id
                  preferredFirstName
                  preferredLastName
                  businessTitle
                  workEmail
                  hostOfficeLocation{
                    id
                    name
                  }
                  profilePicture
                  alumni
                 }
                 education{
                  school
                  degree                  
                  fieldOfStudy
                  endYear
                  startYear
                }
                 hostOfficeLocation{
                   name
                   timezone
                   id
                   phone
                 }
                 managementLevel
                 jobPosition
                 jobTitle
                 jobCategory
                 alumni
                 workerType
                 workerSubType
                 status
                 assistantIds
                 assistants{
                   id
                   firstName
                   lastName
                   title
                   email
                   alumni
                 }
                 assists{
                   id
                   firstName
                   lastName
                   title
                   email
                   alumni
                 }
              }
            }`
      },
      config: {
        headers: {
          "x-api-key": `${xApiKey.peopleV2}`,
          psid: utils.getPsId()
        }
      }
    })
  );
};

export const fetchUserDetails = async (hrIds, dispatch) => {
  dispatch(
    getUserData({
      endpointUrl: `${apiUrl.peopleV2Data}`,
      data: {
        query: `
            query {
              employees(ids: ${JSON.stringify(hrIds)}) {
                id
                preferredName
                preferredDisplayName
                preferredFirstName
                preferredLastName
                hostOfficeLocation{
                  id
                  name
                }
                businessTitle
                slackId
                workEmail
                primaryWorkPhone
                primaryWorkDevice
                mobile
                slackId
                profilePicture
                lastUpdated
                alumni
                staffId
                languages{
                  language
                  fluencyReading
                  fluencyWriting
                  fluencySpeaking
                  fluencyPresent
                }
                managementLevel
                jobPosition
                jobTitle
                jobCategory
                alumni
                workerType
                workerSubType
                status
                assistantIds
                assistants{
                  id
                  firstName
                  lastName
                  title
                  email
                  alumni
                }
                assists{
                  id
                  firstName
                  lastName
                  title
                  email
                  alumni
                }
              }
            }`
      },
      config: {
        headers: {
          "x-api-key": `${xApiKey.peopleV2}`,
          psid: utils.getPsId()
        }
      }
    })
  );
};

export const fetchTeamMemberDetails = async (hrIds, dispatch) => {
  dispatch(
    getMemberList({
      endpointUrl: `${apiUrl.peopleV2Data}`,
      data: {
        query: `
              query {
                employees(ids: ${JSON.stringify(hrIds)}) {
                  id
                  preferredName
                  preferredDisplayName
                  preferredFirstName
                  preferredLastName
                  hostOfficeLocation{
                    id
                    name
                  }
                  businessTitle
                  slackId
                  workEmail
                  profilePicture
                  alumni
                  status
                  workerType
                  workerSubType
                  lastUpdated
                  assistants {
                    id
                    name
                    firstName
                    lastName
                    staffId
                    email
                  }
                }
              }`
      },
      config: {
        headers: {
          "x-api-key": `${xApiKey.peopleV2}`,
          psid: utils.getPsId()
        }
      }
    })
  );
};

export const fetchProfileCompletionData = (hrId, dispatch) => {
  dispatch(
    getProfileCompletion({
      endpoint: `${apiUrl.linkApi}profile/${hrId}/complete/percentage`,
      config: {
        headers: {
          "x-api-key": `${xApiKey.profile}`
        }
      }
    })
  );
};
