import { Card, Grid, Placeholder } from "semantic-ui-react";
import { isMD } from "pages/helpers/utils";
import "./styles.scss";
/**
 * Case - Single case card Loading
 *
 * @param  none
 *
 * @return Card Loading component
 *
 * @example  <Case key={id} caseObj={caseObj} />
 */

const CaseLoading = () => {
  return (
    <>
      <Card className="p-0 mmt-1" style={{ border: "1px solid #ccc" }}>
        <Card.Content className="p-0">
          <Grid className="m-0">
            <Grid.Row className="case__customGrid p-0">
              {/* Card aside section */}
              <Grid.Column
                computer={4}
                tablet={isMD() ? 4 : 16}
                className="case__caseAside-loading  custom-grid__aside mt-1"
              >
                <Placeholder>
                  <Placeholder.Paragraph>
                    <Placeholder.Line />
                  </Placeholder.Paragraph>
                </Placeholder>

                <Placeholder className="mt-1">
                  <Placeholder.Paragraph>
                    <Placeholder.Line />
                  </Placeholder.Paragraph>
                </Placeholder>

                <Placeholder className="mt-4">
                  <Placeholder.Paragraph>
                    <Placeholder.Line />
                  </Placeholder.Paragraph>
                </Placeholder>
              </Grid.Column>

              {/* Card main section */}
              <Grid.Column computer={12} tablet={isMD() ? 12 : 16} className="custom-grid__main">
                <Placeholder fluid className="case__caseDesc">
                  <Placeholder.Paragraph>
                    <Placeholder.Line length="full" />
                    <Placeholder.Line length="full" />
                    <Placeholder.Line length="short" />
                  </Placeholder.Paragraph>
                </Placeholder>

                <Placeholder className="mb-2">
                  <Placeholder.Paragraph>
                    <Placeholder.Line length="medium" />
                  </Placeholder.Paragraph>
                </Placeholder>

                <Grid.Row className="mb-h">
                  {/* Tags */}
                  <Grid.Column computer={12} tablet={isMD() ? 12 : 16}>
                    <>
                      <Placeholder className="case__tag-placeholder mb-1 float-left mr-2">
                        <Placeholder.Image />
                      </Placeholder>
                      <Placeholder className="case__tag-placeholder mb-1 mt-0">
                        <Placeholder.Image />
                      </Placeholder>
                    </>
                  </Grid.Column>
                </Grid.Row>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Card.Content>
      </Card>
    </>
  );
};

export default CaseLoading;
