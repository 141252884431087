import { profileCompletion } from "redux/constants";

const profileCompletionErrorReducer = (state = {}, action) => {
  switch (action.type) {
    case profileCompletion.FETCH_PROFILE_COMPLETION_FAILURE:
      return action.error;
    case profileCompletion.FETCH_PROFILE_COMPLETION:
    case profileCompletion.FETCH_PROFILE_COMPLETION_SUCCESS:
      return null;
    default:
      return state;
  }
};

export default profileCompletionErrorReducer;
