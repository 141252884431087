import { editProfile } from "redux/constants";

const deleteFile = (payload) => ({
  type: editProfile.DELETE_FILE,
  payload
});

const setDeleteFileSuccess = (data) => ({
  type: editProfile.DELETE_FILE_SUCCESS,
  data
});

const setDeleteFileError = (error) => ({
  type: editProfile.DELETE_FILE_FAILURE,
  error
});

const updateProposal = (payload) => ({
  type: editProfile.UPDATE_PROPOSAL,
  payload
});

const setUpdateProposalSuccess = (data) => ({
  type: editProfile.UPDATE_PROPOSAL_SUCCESS,
  data
});

const setUpdateProposalError = (error) => ({
  type: editProfile.UPDATE_PROPOSAL_FAILURE,
  error
});

const proposalFile = (payload) => ({
  type: editProfile.PROPOSAL_FILE,
  payload
});

const setProposalFileSuccess = (data) => ({
  type: editProfile.PROPOSAL_FILE_SUCCESS,
  data
});

const setProposalFileError = (error) => ({
  type: editProfile.PROPOSAL_FILE_FAILURE,
  error
});

const updateResume = (payload) => ({
  type: editProfile.UPDATE_RESUME,
  payload
});

const setUpdateResumeSuccess = (data) => ({
  type: editProfile.UPDATE_RESUME_SUCCESS,
  data
});

const setUpdateResumeError = (error) => ({
  type: editProfile.UPDATE_RESUME_FAILURE,
  error
});

const resumeFile = (payload) => ({
  type: editProfile.RESUME_FILE,
  payload
});

const setResumeFileSuccess = (data) => ({
  type: editProfile.RESUME_FILE_SUCCESS,
  data
});

const setResumeFileError = (error) => ({
  type: editProfile.RESUME_FILE_FAILURE,
  error
});

const updateProfile = (payload) => ({
  type: editProfile.UPDATE_PROFILE,
  payload
});

const setUpdateProfileSuccess = (data) => ({
  type: editProfile.UPDATE_PROFILE_SUCCESS,
  data
});

const setUpdateProfileError = (error) => ({
  type: editProfile.UPDATE_PROFILE_FAILURE,
  error
});

export {
  deleteFile,
  setDeleteFileSuccess,
  setDeleteFileError,
  updateProposal,
  setUpdateProposalSuccess,
  setUpdateProposalError,
  proposalFile,
  setProposalFileSuccess,
  setProposalFileError,
  updateResume,
  setUpdateResumeSuccess,
  setUpdateResumeError,
  resumeFile,
  setResumeFileSuccess,
  setResumeFileError,
  updateProfile,
  setUpdateProfileSuccess,
  setUpdateProfileError
};
