import { about } from "redux/constants";

const practiceAreaOptionsReducer = (state = [], action) => {
  switch (action.type) {
    case about.FETCH_PA_OPTIONS_SUCCESS: {
      const paOptions = [];
      action?.data?.terms.map((record) =>
        paOptions.push({ text: record?.term?.displayName, value: record?.term?.id, key: record?.term?.id })
      );
      return paOptions;
    }

    default:
      return state;
  }
};

export default practiceAreaOptionsReducer;
