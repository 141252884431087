import { call, put } from "redux-saga/effects";
import { get } from "redux/api";
import { setProfileImageHighResError, setProfileImageHighRes } from "redux/actions/profileImageHighRes";
import { Buffer } from "buffer";

function* handleFetchProfileImageHighRes({ payload }) {
  const { data, error } = yield call(get, payload);
  if (data) {
    const imgData = { img: `data:image/png;base64,${Buffer.from(data, "binary").toString("base64")}` };
    yield put(setProfileImageHighRes(imgData));
  }
  if (error) yield put(setProfileImageHighResError(error));
}

export default handleFetchProfileImageHighRes;
