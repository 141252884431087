/* eslint-disable indent */
import PropTypes from "prop-types";
import styles from "components/TaxonomySelector/style.module.scss";
import { Popup, Image } from "semantic-ui-react";

/**
 * ListSegment component
 *
 * Returns a list of items to be shown in a column
 *
 * <ListSegment />
 */

function ListSegment({
  list,
  updateCol,
  onNodeOpen,
  onNodeClose,
  onNodeSelect,
  setExpandedNode,
  setSelectedIndex,
  headingList,
  headingPos,
  setLoadingData,
  loadingData,
  loading,
  disabledGuidMsg,
  disabledLevelStartIndex
}) {
  if (!list.length) return <></>;

  const { colIndex, itemIndex } = loadingData;
  const handleOnOpenClick = (listItem, index, e) => {
    e.stopPropagation();

    onNodeOpen(listItem?.term?.id);
    setExpandedNode(listItem);
    setSelectedIndex(updateCol);
    setLoadingData({ colIndex: updateCol, itemIndex: index });
  };

  const handleOnCloseClick = (listItem, e) => {
    e.stopPropagation();
    onNodeClose(listItem?.term?.id, updateCol);
  };

  const handleOnSelectClick = (listItem) => {
    if (!listItem.isDisabled) {
      onNodeSelect(listItem);
      onNodeClose(listItem?.term?.id, updateCol);
    }
  };

  const getItemClass = (isOpen, isSelected, isDisabled) => {
    if (isOpen || isSelected)
      return `${styles["finder__item"]} item  ${
        updateCol > disabledLevelStartIndex ? "item-background" : styles["finder__disabled"]
      } ${styles["finder__item--selected"]} ${updateCol <= disabledLevelStartIndex && "item__background--lightgreen"}`;
    if (isDisabled)
      return `${styles["finder__item"]} item  ${
        updateCol > disabledLevelStartIndex ? "item-background" : styles["finder__disabled"]
      } `;
    return `${styles["finder__item"]} item   ${
      updateCol > disabledLevelStartIndex ? "item-background" : styles["finder__disabled"]
    }`;
  };

  return (
    <div className={`ui middle aligned selection list ${styles["finder__list__box"]}`}>
      {list.map((listItem, index) => {
        const title = listItem.term.name;
        const value = !listItem.isLeaf;
        return (
          <>
            {updateCol === 0 && headingPos.includes(index) && (
              <div className={`${styles["finder__column__heading"]} ${(index !== 0 && "border-head pt-1") || ""}`}>
                {headingList[index]}
              </div>
            )}
            <div key={listItem?.term?.id} className="d-flex justify-content-between">
              <div
                className={`${getItemClass(listItem.isOpen, listItem.isSelected, listItem.isDisabled)} ${
                  (updateCol > disabledLevelStartIndex && !listItem.isDisabled && "cursor-pointer") ||
                  `cursor-default ${styles["finder__disabled"]}`
                }`}
                role="button"
                tabIndex="0"
                onClick={() => {
                  if (updateCol > disabledLevelStartIndex && !listItem.isDisabled) {
                    handleOnSelectClick(listItem);
                  }
                }}
                onKeyDown={() => {}}
              >
                <div>
                  {title}
                  {listItem.isDisabled && disabledGuidMsg && (
                    <Popup
                      position="right center"
                      inverted
                      content={disabledGuidMsg}
                      disabled={!listItem.isDisabled}
                      trigger={<Image className="mx-1" inline src="/icons/alert icon.svg" />}
                      className="clipboard-tooltip"
                    />
                  )}
                </div>
              </div>
              {value && (
                <div
                  className={`cursor-pointer list__icon ${
                    listItem.isDisabled || (updateCol <= disabledLevelStartIndex && "list__icon--disabled") || ""
                  }`}
                  onClick={(e) => {
                    if (!listItem.isOpen) {
                      handleOnOpenClick(listItem, index, e);
                      return;
                    }
                    handleOnCloseClick(listItem, e);
                  }}
                  onKeyPress={(e) => {
                    if (!listItem.isOpen) {
                      handleOnOpenClick(listItem, index, e);
                      return;
                    }
                    handleOnCloseClick(listItem, e);
                  }}
                  role="presentation"
                >
                  {(colIndex === updateCol && itemIndex === index && loading && <div className="loading__spinner" />) ||
                    (!listItem.isOpen && <i aria-hidden="true" className="icon angle right" />) || (
                      <i aria-hidden="true" className="icon angle left" />
                    )}
                </div>
              )}
            </div>
          </>
        );
      })}
    </div>
  );
}

ListSegment.defaultProps = {
  list: [],
  updateCol: 0,
  disabledGuidMsg: "",
  disabledLevelStartIndex: 0
};

ListSegment.propTypes = {
  list: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape()), PropTypes.shape()]),
  updateCol: PropTypes.number,
  headingList: PropTypes.shape({}).isRequired,
  headingPos: PropTypes.arrayOf(Number).isRequired,
  onNodeOpen: PropTypes.func.isRequired,
  onNodeClose: PropTypes.func.isRequired,
  onNodeSelect: PropTypes.func.isRequired,
  setSelectedIndex: PropTypes.func.isRequired,
  setExpandedNode: PropTypes.func.isRequired,
  setLoadingData: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  loadingData: PropTypes.shape({ colIndex: 0, itemIndex: 0 }).isRequired,
  disabledGuidMsg: PropTypes.string,
  disabledLevelStartIndex: PropTypes.number
};

export default ListSegment;
