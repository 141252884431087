import React from "react";
import { Placeholder, Input } from "semantic-ui-react";
import PropTypes from "prop-types";

/**
 *
 * @param {bool} isLoading indicates whether the component is loading or not
 * @param {function} setName to set the award name
 * @param {string} name having award name
 * @param {boolean} invalid boolean flag for validation
 *
 * @returns AwardNameTextBox component
 *
 * @example
 *
 * <AwardNameTextBox isLoading={false} callback={(value)=>console.log(value)} />
 */
function AwardNameTextBox({ isLoading, setName, name, invalid, onTextChange }) {
  const handleOnChange = (e, data) => {
    setName(data.value);
    onTextChange(true);
  };
  let returnval = (
    <Input
      fluid
      value={name}
      onChange={handleOnChange}
      className={`${invalid ? "mandatory-input-warning" : ""} achievements__text-box`}
    />
  );

  if (isLoading)
    returnval = (
      <Placeholder fluid>
        <Placeholder.Header>
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Header>
      </Placeholder>
    );
  return returnval;
}

AwardNameTextBox.defaultProps = {
  isLoading: false
};

AwardNameTextBox.propTypes = {
  isLoading: PropTypes.bool,
  setName: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  invalid: PropTypes.bool.isRequired,
  onTextChange: PropTypes.func.isRequired
};

export default AwardNameTextBox;
