import { call, put } from "redux-saga/effects";
import { get, put as putAPI } from "redux/api";
import { map } from "lodash";

import CONFIG from "constants/config";
import LABELS from "constants/label";

import { user, userAccessRoles } from "redux/constants";
import { toastError, toastSuccess } from "helpers/utils";

const API_ENDPOINT_GET_ACCESS_ROLES = `${CONFIG.apiUrl.linkApi}reference/access-roles`;
const API_ENDPOINT_SAVE_ACCESS_ROLES = `${CONFIG.apiUrl.linkApi}profiles/`;

function AccessRoles(data) {
  this.key = data.id;
  this.text = data.name;
  this.value = data.id;
}

export function* handleFetchAccessRoles() {
  const { data, error } = yield call(get, { endpoint: API_ENDPOINT_GET_ACCESS_ROLES });
  if (error) {
    toastError();
  } else {
    yield put({
      type: userAccessRoles.FETCH_USER_ACCESS_ROLES_SUCCESS,
      payload: map(data, (role) => new AccessRoles(role))
    });
  }
}

export function* handleSaveAccessRoles({ payload }) {
  const { id, accessId, comment } = payload;
  const { error } = yield call(putAPI, {
    endpointUrl: `${API_ENDPOINT_SAVE_ACCESS_ROLES}${id}/${accessId}/access-role`,
    data: comment,
    config: { withCredentials: true, headers: { "Content-Type": "application/json" } }
  });

  if (error) {
    yield put({ type: userAccessRoles.SAVE_USER_ACCESS_ROLES_FAILURE });
    switch (error.response.status) {
      case 403:
        return toastError(5000, error.response.statusText, LABELS.ACCESS_ROLES.UserNotAllowed);
      default:
        return toastError();
    }
  } else {
    yield put({ type: userAccessRoles.SAVE_USER_ACCESS_ROLES_SUCCESS });
    // UPDATE REDUX STORE WITH updated access roleID in user object
    yield put({ type: user.UPDATE_ACCESS_ROLE_ID, payload: accessId });
    toastSuccess();
  }
  return null;
}
