import { ssRecentInternalMaterials } from "redux/constants";

const ssRecentInternalMaterialsReducer = (state = {}, action) => {
  switch (action.type) {
    case ssRecentInternalMaterials.FETCH_RECENT_SS_INTERNAL_MATERIALS_SUCCESS: {
      return { ...action.data };
    }
    default:
      return state;
  }
};

export default ssRecentInternalMaterialsReducer;
