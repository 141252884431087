import { topicsFinder } from "redux/constants";

const topicsFinderReducer = (state = {}, action) => {
  switch (action.type) {
    case topicsFinder.FETCH_TOPICS_FINDER_SUCCESS: {
      return [...action.data];
    }
    default:
      return state;
  }
};

export default topicsFinderReducer;
