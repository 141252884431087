import { topicsSkillFinder } from "redux/constants";

const topicsSkillFinderLoadingReducer = (state = false, action) => {
  switch (action.type) {
    case topicsSkillFinder.FETCH_SKILL_TOPICS_FINDER:
      return true;
    case topicsSkillFinder.FETCH_SKILL_TOPICS_FINDER_SUCCESS:
    case topicsSkillFinder.FETCH_SKILL_TOPICS_FINDER_FAILURE:
      return false;
    default:
      return state;
  }
};

export default topicsSkillFinderLoadingReducer;
