import { call, put } from "redux-saga/effects";
import { get } from "redux/api";
import { setSSInternalMaterials, setSSInternalMaterialsError } from "redux/actions/ssInternalMaterials";

function* handleFetchSSInternalMaterials({ payload }) {
  const { data, error } = yield call(get, payload);
  if (error) {
    yield put(setSSInternalMaterialsError(error));
  } else {
    yield put(setSSInternalMaterials(data));
  }
}

export default handleFetchSSInternalMaterials;
