import { call, put as putSaga } from "redux-saga/effects";
import { post, get, put } from "redux/api";
import {
  setSavePronunciationSuccess,
  setSavePronunciationError,
  setFetchPronunciationSuccess,
  setFetchPronunciationError,
  setPronunciationAudioId
} from "redux/actions/pronunciation";
import CONFIG from "constants/config";

const { apiUrl, xApiKey } = CONFIG;

function* handleSavePronunciation({ payload }) {
  try {
    const { res, error } = yield call(post, payload);
    if (error) {
      yield putSaga(setSavePronunciationError(error));
    } else {
      // eslint-disable-next-line no-underscore-dangle
      const endpoint = `${res.data._links.self}?expand=embeds`;
      const headers = {
        Authorization: `Bearer ${xApiKey.widen}`,
        noAuthHeader: true
      };
      const response = yield call(get, { endpoint, config: { headers }, serviceName: "widen" });
      if (response.error) {
        yield putSaga(setFetchPronunciationError(error));
      } else {
        const pronunciationData = {
          id: response.data.id,
          fileName: response.data.fileName,
          uploadDate: response.data.file_upload_date,
          playbackUrl: response.data.embeds.original.url
        };

        yield putSaga(setFetchPronunciationSuccess(pronunciationData));

        const reqBody = {
          audioKey: response.data.id,
          audioPath: response.data.embeds.original.url,
          lastUpdateDate: response.data.last_update_date
        };

        const resp = yield call(put, {
          endpoint: `${apiUrl.linkApi}profile/${payload.id}/audio`,
          data: reqBody,
          config: {
            headers: {
              "x-api-key": `${xApiKey.profile}`
            }
          }
        });

        if (resp.error) {
          yield putSaga(setSavePronunciationError(error));
        } else {
          yield putSaga(setSavePronunciationSuccess(res));
          yield putSaga(setPronunciationAudioId(resp.res.data));
        }
      }
    }
  } catch (err) {
    yield putSaga(setSavePronunciationError(err));
  }
}

export default handleSavePronunciation;
