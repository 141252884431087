import { takeEvery } from "redux-saga/effects";

import { memberList } from "redux/constants";
import handleFetchMemberListImage from "../worker/handleFetchMemberListImage";

function* watchMemberListImage() {
  yield takeEvery(memberList.FETCH_MEMBER_LIST_IMAGE, handleFetchMemberListImage);
}

export default watchMemberListImage;
