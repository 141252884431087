import { peopleData } from "redux/constants";

const peopleDataErrorReducer = (state = null, action) => {
  switch (action.type) {
    case peopleData.FETCH_PEOPLE_DATA_FAILURE:
      return action.error;
    case peopleData.FETCH_PEOPLE_DATA_SUCCESS:
    case peopleData.FETCH_PEOPLE_DATA:
      return null;
    default:
      return state;
  }
};

export default peopleDataErrorReducer;
