import { call, put as putSaga } from "redux-saga/effects";
import { get } from "redux/api";
import { setDownloadError, setDownloadSuccess } from "redux/actions/download";

function* handleDownload({ payload }) {
  try {
    const { data, error } = yield call(get, payload);
    if (error) {
      yield putSaga(setDownloadError(error));
    } else {
      const tempLink = document.createElement("a");
      tempLink.href = data?.documentData?.preSignedUrl;
      document.body.appendChild(tempLink);
      tempLink.click();
      tempLink.remove();
      yield putSaga(setDownloadSuccess(data));
    }
  } catch (error) {
    yield putSaga(setDownloadError(error));
  }
}

export default handleDownload;
