import { Dropdown, Popup } from "semantic-ui-react";
import PropTypes from "prop-types";

/**
 *
 * @param {boolean} open whether the popup is open or not
 * @param {element} children Popup contents
 * @param {element} trigger element to trigger the popup
 * @param {Function} onClose callback function to take action on onClose of Popup
 *
 * @returns
 * TaxonomyDropDown Component
 * @example
 *  <TaxonomyDropDown open={open} children={children} trigger={trigger} onClose={onClose} />
 */
function TaxonomyDropDown({ open, children, trigger, onClose, offset }) {
  return (
    <Popup
      inverted
      on="click"
      trigger={trigger}
      popper={{ className: "dropdown-popup" }}
      position="bottom left"
      basic
      offset={offset}
      onClose={onClose}
      open={open}
    >
      <Dropdown icon={null} direction="right" open={open} className="taxonomy__dropdown">
        <Dropdown.Menu className="taxonomy_menu">{children}</Dropdown.Menu>
      </Dropdown>
    </Popup>
  );
}

TaxonomyDropDown.propTypes = {
  trigger: PropTypes.element.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  offset: PropTypes.arrayOf(PropTypes.number).isRequired
};

export default TaxonomyDropDown;
