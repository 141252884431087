import { call, put as putSaga } from "redux-saga/effects";
import { put } from "redux/api";
import { setUploadResumeSuccess, setUploadResumeError } from "redux/actions/upload";

function* handleUploadResumeToProfile({ payload }) {
  try {
    const { res, error } = yield call(put, payload);
    if (error) {
      yield putSaga(setUploadResumeError(error));
    } else {
      yield putSaga(setUploadResumeSuccess(res));
    }
  } catch (error) {
    yield putSaga(setUploadResumeError(error));
  }
}

export default handleUploadResumeToProfile;
