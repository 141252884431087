import { takeLatest } from "redux-saga/effects";

import { profile } from "redux/constants";
import handleFetchProfile from "../worker/handleFetchProfile";

function* watchProfile() {
  yield takeLatest(profile.FETCH_PROFILE, handleFetchProfile);
}

export default watchProfile;
