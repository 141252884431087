import { call, put } from "redux-saga/effects";
import { get } from "redux/api";
import { setRecentSSCases, setError } from "redux/actions/ssRecentCases";

function* handleFetchSSRecentCases({ payload }) {
  const { data, error } = yield call(get, payload);
  if (error) {
    yield put(setError(error));
  } else {
    yield put(setRecentSSCases(data));
  }
}

export default handleFetchSSRecentCases;
