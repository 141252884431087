import { cases } from "redux/constants";

const updateCaseTopicsErrorReducer = (state = null, action) => {
  switch (action.type) {
    case cases.UPDATE_CASE_TOPICS_ERROR:
      return action.error;
    case cases.UPDATE_CASE_TOPICS_SUCCESS:
      return null;
    default:
      return state;
  }
};

export default updateCaseTopicsErrorReducer;
