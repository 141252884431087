import { call, put } from "redux-saga/effects";
import { get } from "redux/api";
import { setBusinessAndSubjectDataFailure, setBusinessAndSubjectDataSuccess } from "redux/actions/topics";

function* handleFetchBusinessSubjects({ payload }) {
  const { data, error } = yield call(get, payload);
  if (error) {
    yield put(setBusinessAndSubjectDataFailure(error));
  } else {
    yield put(setBusinessAndSubjectDataSuccess(data));
  }
}

export default handleFetchBusinessSubjects;
