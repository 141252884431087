import sendEventData from "analytics/analytics";
import { EVENTS, TRIGGERS } from "analytics/constants";
import {
  emailSingleVignette,
  emailMultipleVignettes,
  getPipeSeparatedVignettesIds,
  emailSingleAttachment
} from "./utils";

/**
 * EmailAllButton
 *
 * @param  {object} props children props
 *
 * @return Email button
 */

const EmailAllButton = (props) => {
  const { docs, children, projectId } = props;
  const vignetteIds = getPipeSeparatedVignettesIds(docs);
  const handleEmailAllSend = (event) => {
    event.stopPropagation();
    sendEventData(TRIGGERS.VIGNETTE.BULK_EMAIL, EVENTS.VIEW_KP_KB_CP, { kpid: vignetteIds, cpid: projectId });
    if (docs.length === 1) {
      const checkDoc = docs[0];
      if (checkDoc.caseVignettesStatus === "True")
        emailSingleVignette(
          checkDoc.fileName,
          checkDoc.projectId,
          checkDoc.attachmentId,
          checkDoc.caseType,
          checkDoc.shortDescription
        );
      else
        emailSingleAttachment(
          checkDoc.title,
          checkDoc.attachmentId,
          checkDoc.materialId,
          checkDoc.searchAbstract,
          checkDoc.kpCmsId
        );
    } else if (docs.length <= 8) {
      emailMultipleVignettes(docs);
    }
  };
  return children(handleEmailAllSend);
};

export default EmailAllButton;
