/* eslint-disable react/no-danger */
import React, { useState, useEffect } from "react";
import stripHtml from "string-strip-html";
import PropTypes from "prop-types";
import { TRIGGERS, EVENTS } from "analytics/constants";
import sendEventData from "analytics/analytics";
import LABEL from "constants/label";
import { Expand } from "../../Button";
import { sanitizeData, getLastSpacePosition, getLastWord } from "./helper/utils";
import styles from "./styles.module.scss";

const { ProfileSummary } = LABEL.PROFILE_SUMMARY;
/**
 * Text Limiter component
 *
 * @param  {number} limit - default: 297
 * @param  {number} mobileLimit - default: 255
 * @param  {string} data - html string or text string
 * @param  {boolean} countDots - whether to count dots or not "..."
 * @param  {boolean} html - determines if text contains html string
 * @param  {component} additional - extra element that we want to show
 *
 * @return summary of text with show less/show more link
 *
 * @example
 *
 * <Text data="this is a sample text"  />
 */
function Text({ limit, mobileLimit, data, countDots, html, additional }) {
  const [show, setShow] = useState(false);
  const [shortText, setShortText] = useState("");
  const [limitValue, setLimitValue] = useState(limit);

  const sanitizedData = data ? sanitizeData(data) : "";

  let totalCount = sanitizedData.length;
  let strippedData;
  let stripped;

  if (html) {
    strippedData = stripHtml(sanitizedData);
    stripped = strippedData.result;
    totalCount = stripped.length;
  }

  const processTextData = () => {
    let lastIndex = totalCount;
    let newData = sanitizedData;

    if (lastIndex > limitValue) {
      lastIndex = getLastSpacePosition(sanitizedData, limitValue, limitValue, countDots);

      newData = `${sanitizedData.substring(0, lastIndex)}...`;
    }

    setShortText(newData);
  };

  const processHtmlData = () => {
    let lastIndex = totalCount;
    let newData = sanitizedData;

    if (lastIndex > limitValue) {
      lastIndex = getLastSpacePosition(stripped, limitValue, limitValue, countDots);
      const { word, startIndex, special, wordLen } = getLastWord(lastIndex, stripped);

      if (special) {
        lastIndex = sanitizedData.indexOf(special, startIndex);
        lastIndex += 1;
      } else {
        lastIndex = sanitizedData.indexOf(word, startIndex);
        lastIndex += wordLen;
      }

      newData = `${sanitizedData.substring(0, lastIndex)}...`;
    } else {
      newData = sanitizedData;
    }

    // let newData = html_substring(data, limitValue, { breakWords: false, suffix: "..." });
    setShortText(newData);
  };

  const onClickHandler = () => {
    if (show) {
      window.$tabName = ProfileSummary; // Global variable for Analytics
      sendEventData(TRIGGERS.PROFILE_SUMMARY.SHOW_LESS_SUMMARY, EVENTS.PROFILE_SUMMARY); // Analytics Event
    } else {
      window.$tabName = ProfileSummary; // Global variable for Analytics
      sendEventData(TRIGGERS.PROFILE_SUMMARY.SHOW_MORE_SUMMARY, EVENTS.PROFILE_SUMMARY); // Analytics Event
    }
    setShow((prevShow) => !prevShow);
  };

  const onResize = () => {
    if (window.innerWidth > 1024) {
      setLimitValue(limit);
    } else {
      setLimitValue(mobileLimit);
    }
  };

  useEffect(() => {
    if (html) {
      processHtmlData();
    } else {
      processTextData();
    }
  }, [data, limitValue]);

  useEffect(() => {
    onResize();

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return sanitizedData ? (
    <div className={styles["text__limiter"]}>
      <div className="summary_description" dangerouslySetInnerHTML={{ __html: show ? sanitizedData : shortText }} />
      {(show || totalCount <= limitValue) && additional && (
        <div className={styles["text__limiter__additional"]}>{additional}</div>
      )}

      {totalCount > limitValue && <Expand expanded={show} onClick={onClickHandler} />}
    </div>
  ) : null;
}

Text.defaultProps = {
  limit: 297,
  mobileLimit: 255,
  countDots: true,
  html: true,
  additional: null
};

Text.propTypes = {
  limit: PropTypes.number,
  mobileLimit: PropTypes.number,
  data: PropTypes.string.isRequired,
  countDots: PropTypes.bool,
  html: PropTypes.bool,
  additional: PropTypes.node
};

export default Text;
