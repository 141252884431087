import { call, put } from "redux-saga/effects";
import { get } from "redux/api";
import { setRecentSSMaterials, setError } from "redux/actions/ssRecentMaterials";

function* handleFetchSSRecentMaterials({ payload }) {
  const { data, error } = yield call(get, payload);
  if (error) {
    yield put(setError(error));
  } else {
    yield put(setRecentSSMaterials(data));
  }
}

export default handleFetchSSRecentMaterials;
