import { ssRecentMaterials } from "redux/constants";

const getRecentSSMaterials = (payload) => {
  return {
    type: ssRecentMaterials.FETCH_RECENT_SSMATERIALS,
    payload
  };
};

const setRecentSSMaterials = (data) => ({
  type: ssRecentMaterials.FETCH_RECENT_SSMATERIALS_SUCCESS,
  data
});

const setError = (error) => ({
  type: ssRecentMaterials.FETCH_RECENT_SSMATERIALS_FAILURE,
  error
});

export { getRecentSSMaterials, setRecentSSMaterials, setError };
