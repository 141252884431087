import { takeLatest } from "redux-saga/effects";

import { materials } from "redux/constants";
import handleFetchMaterials from "../worker/handleFetchMaterials";

function* watchMaterials() {
  yield takeLatest(materials.FETCH_MATERIALS, handleFetchMaterials);
}

export default watchMaterials;
