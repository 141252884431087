import React from "react";
import { Dimmer, Loader, Segment } from "semantic-ui-react";
import "./FullPageLoader.scss";

const FullPageLoader = () => {
  return (
    <section className="fullpageloader__container d-flex justify-content-center align-items-center" textalign="center">
      <Segment className="loading__segment">
        <Dimmer active inverted>
          <Loader>Loading</Loader>
        </Dimmer>
      </Segment>
    </section>
  );
};

export default FullPageLoader;
