import { userProfileImageHighRes } from "redux/constants";

const profileImageHighResErrorReducer = (state = {}, action) => {
  switch (action.type) {
    case userProfileImageHighRes.FETCH_USER_IMAGE_HIGHRES_FAILURE:
      return action.error;
    case userProfileImageHighRes.FETCH_USER_IMAGE_HIGHRES_SUCCESS:
    case userProfileImageHighRes.FETCH_USER_IMAGE_HIGHRES:
      return null;
    default:
      return state;
  }
};

export default profileImageHighResErrorReducer;
