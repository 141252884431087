import { user } from "redux/constants";

const userReducer = (state = {}, action) => {
  switch (action.type) {
    case user.FETCH_SUCCESS: {
      return { ...action.data };
    }
    case user.UPDATE_ACCESS_ROLE_ID: {
      return { ...state, accessRoleId: action.payload };
    }
    default:
      return state;
  }
};

export default userReducer;
