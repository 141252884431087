import React from "react";
import PropTypes from "prop-types";
import { Placeholder } from "semantic-ui-react";
import LABEL from "constants/label";
import PipedList from "../PipedList";
import "./styles.scss";

const { CASES } = LABEL;
function ExpertiseSkillsListContainer({
  expertiseList,
  skillList,
  isCaseTopicsAndSkillsLoading,
  itemsToShow = 2,
  callback
}) {
  return (
    <div className="expertise-skills-container">
      <h4>{CASES.ExpertiseWorked}</h4>

      {(!isCaseTopicsAndSkillsLoading && (
        <>
          <PipedList items={expertiseList.slice(0, itemsToShow)} />
          {expertiseList.length > itemsToShow && (
            <p role="presentation" onClick={callback} onKeyUp={callback}>
              +{expertiseList?.length - itemsToShow}
            </p>
          )}
        </>
      )) || (
        <Placeholder className="mt-1 expertise-loading">
          <Placeholder.Paragraph>
            <Placeholder.Line length="medium" />
            <Placeholder.Line length="short" />
          </Placeholder.Paragraph>
        </Placeholder>
      )}
      <h4>{CASES.SkillsWorked}</h4>
      {(!isCaseTopicsAndSkillsLoading && (
        <>
          <PipedList items={skillList.slice(0, itemsToShow)} />
          {skillList.length > itemsToShow && (
            <p role="presentation" onClick={callback} onKeyUp={callback}>
              +{skillList?.length - itemsToShow}
            </p>
          )}
        </>
      )) || (
        <Placeholder className="mt-1 expertise-loading">
          <Placeholder.Paragraph>
            <Placeholder.Line length="medium" />
            <Placeholder.Line length="short" />
          </Placeholder.Paragraph>
        </Placeholder>
      )}
    </div>
  );
}

ExpertiseSkillsListContainer.defaultProps = {
  expertiseList: [],
  skillList: [],
  itemsToShow: 2,
  isCaseTopicsAndSkillsLoading: true,
  callback: () => {}
};

ExpertiseSkillsListContainer.propTypes = {
  expertiseList: PropTypes.arrayOf(PropTypes.shape({})),
  skillList: PropTypes.arrayOf(PropTypes.shape({})),
  itemsToShow: PropTypes.number,
  isCaseTopicsAndSkillsLoading: PropTypes.bool,
  callback: PropTypes.func
};

export default ExpertiseSkillsListContainer;
