import { cvBioDownloadDetails } from "redux/constants";

const fetchCvBioDownloadDetails = (payload) => {
  return {
    type: cvBioDownloadDetails.FETCH_CV_BIO_DOWNLOAD_DETAILS,
    payload
  };
};

const fetchCvBioDownloadDetailsSuccess = (data) => ({
  type: cvBioDownloadDetails.FETCH_CV_BIO_DOWNLOAD_DETAILS_SUCCESS,
  data
});

const fetchCvBioDownloadDetailsFailure = (error) => ({
  type: cvBioDownloadDetails.FETCH_CV_BIO_DOWNLOAD_DETAILS_FAILURE,
  error
});
export { fetchCvBioDownloadDetails, fetchCvBioDownloadDetailsSuccess, fetchCvBioDownloadDetailsFailure };
