import { editProfile } from "redux/constants";

const proposalUpdatingErrorReducer = (state = null, action) => {
  switch (action.type) {
    case editProfile.UPDATE_PROPOSAL_FAILURE:
      return action.error;
    case editProfile.UPDATE_PROPOSAL_SUCCESS:
    case editProfile.UPDATE_PROPOSAL:
      return null;
    default:
      return state;
  }
};

export default proposalUpdatingErrorReducer;
