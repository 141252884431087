import { noop } from "lodash";
import PropTypes from "prop-types";

/**
 * This component is only used for Demo purpose. It'll be removed before moving to production
 */

function BottomBar({ hrid, onChangeHandler }) {
  // mock data
  // Select box values -- for demo
  const userIdList = {
    "PROD USERS": [346482, 349210, 370575, 97692, 277901, 5497],
    ANONYMIZED: [104312, 112533, 112912],
    LOA: [133572, 135514, 112792],
    ALUMNI: [395105, 395026],
    ABOUT: [349247, 155052, 340, 367896, 5725, 583, 1787, 5117, 170612, 13, 306443],
    EXPERIENCE: [392295, 1201],
    "KEY TO PURPOSE": [285944],
    "NO OFFICE PHONE": [392295],
    "AWARDS & CERTIFICATES": [386621, 42717, 341157],
    CERTIFICATES: [341157],
    EDUCATION: [88993, 362266],
    CASES: [346734, 5117],
    "POWER USER": [383351, 368762, 92272],
    "BANNER-MOBILE": [4130, 7783, 8739]
  };

  // Select box -- for demo
  const selectHtml = [];
  const userIds = Object.keys(userIdList);
  userIds.forEach((uId) => {
    selectHtml.push(
      <optgroup label={uId} key={uId}>
        {userIdList[uId].map((id) => (
          <option key={id} value={id}>
            {id}
          </option>
        ))}
      </optgroup>
    );
  });

  const onChange = (e) => {
    onChangeHandler(e.target.value);
  };

  return (
    <div className="notification-bar">
      <>This is only for demo purposes.</>
      <>
        <select onChange={onChange} value={hrid || ""}>
          <option value="">Current</option>
          {selectHtml}
        </select>
      </>
    </div>
  );
}

BottomBar.defaultProps = {
  hrid: "",
  onChangeHandler: noop
};

BottomBar.propTypes = {
  hrid: PropTypes.string,
  onChangeHandler: PropTypes.func
};

export default BottomBar;
