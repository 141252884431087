import { pronunciation } from "redux/constants";
import LABEL from "constants/label";

const { COMMON } = LABEL;

const pronunciationDeletingReducer = (state = "", action) => {
  switch (action.type) {
    case pronunciation.DELETE_PRONUNCIATION:
      return COMMON.StatusProcessing;
    case pronunciation.DELETE_PRONUNCIATION_SUCCESS:
      return COMMON.StatusSuccess;
    case pronunciation.DELETE_PRONUNCIATION_FAILURE:
      return COMMON.StatusFailure;
    case pronunciation.RESET_PRONUNCIATION_STATE:
      return "";
    default:
      return state;
  }
};

export default pronunciationDeletingReducer;
