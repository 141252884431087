import React from "react";
import Pill from "components/common/Pill";
import PropTypes from "prop-types";
import LABEL from "constants/label";
import styles from "./styles.module.scss";

/**
 *
 * @param {string} year year of termination
 *
 * @returns
 * Alumni Component
 *
 * @example
 *
 * <Alumni year="Feb 2018" />
 */

function Alumni({ alumniSince }) {
  const { AlumniText, Since } = LABEL.ALUMNI;
  return (
    <div className={`${styles["alumni"]} d-flex justify-content-center align-items-center`}>
      <Pill backgroundColor="rgb(213, 0, 0, .33)" text={AlumniText} />
      {alumniSince && <div className={styles["alumni__text"]}>{`${Since} ${alumniSince}`}</div>}
    </div>
  );
}

Alumni.propTypes = {
  alumniSince: PropTypes.string.isRequired
};

export default Alumni;
