import { call, put } from "redux-saga/effects";
import { get } from "redux/api";
import { setResponsibilitySuccess, setResponsibilityError } from "redux/actions/cases";

function* handleFetchCaseResponsibility({ payload }) {
  const { data, error } = yield call(get, payload);
  if (data?.error?.code !== "200") {
    yield put(setResponsibilityError(error));
  } else {
    yield put(setResponsibilitySuccess(data?.data));
  }
}

export default handleFetchCaseResponsibility;
