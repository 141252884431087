import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import IconWithImage from "components/common/IconWithImage";
import LABEL from "constants/label";
import Popover from "../common/Popover";
import ManagePronunciationModal from "./ManagePronunciationModal";

const { MANAGE_PRONUNCIATION } = LABEL;

function ManagePronunciation({ hasPronunciation, hrId, audioUrl, loggedInUserHrId }) {
  let component;
  const [open, setOpen] = useState(false);
  const [recording, setRecording] = useState(null);

  useEffect(() => {
    if (audioUrl) {
      setRecording(new Audio(audioUrl));
    }
  }, [audioUrl]);

  const openManagePronunciationModal = () => {
    setOpen(true);
  };

  const closeManagePronunciationModal = () => {
    setOpen(false);
  };

  const handleOnPlay = () => {
    recording?.play();
  };

  if (loggedInUserHrId === hrId)
    component = (
      <>
        <Popover
          trigger={
            <div
              onClick={() => setOpen(true)}
              onKeyUp={() => setOpen(true)}
              role="presentation"
              className="ml-1 mb-h cursor-pointer"
            >
              <IconWithImage
                iconSize="small"
                imgSrc={(hasPronunciation && "/icons/volume.png") || "/icons/mic.svg"}
                backgroundSize={(hasPronunciation && "17px 17px") || "22px 22px"}
              />
            </div>
          }
          inverted
          on="hover"
          position="bottom center"
        >
          {(hasPronunciation && MANAGE_PRONUNCIATION.Title) || MANAGE_PRONUNCIATION.InitialTitle}
        </Popover>
        <ManagePronunciationModal
          open={open}
          hasPronunciation={hasPronunciation}
          onOpen={openManagePronunciationModal}
          onClose={closeManagePronunciationModal}
          staffId={hrId}
        />
      </>
    );
  if (loggedInUserHrId !== hrId && audioUrl)
    component = (
      <Popover
        trigger={
          <div onClick={handleOnPlay} onKeyUp={handleOnPlay} role="presentation" className="ml-1 mb-h cursor-pointer">
            <IconWithImage iconSize="small" imgSrc="/icons/volume.png" backgroundSize="17px 17px" />
          </div>
        }
        inverted
        on="hover"
        position="bottom center"
      >
        {MANAGE_PRONUNCIATION.PlayPronunciation}
      </Popover>
    );
  return <>{component}</>;
}
ManagePronunciation.defaultProps = {
  hasPronunciation: false,
  audioUrl: ""
};

ManagePronunciation.propTypes = {
  hasPronunciation: PropTypes.bool,
  hrId: PropTypes.number.isRequired,
  audioUrl: PropTypes.string
};

export default ManagePronunciation;
