import { skills } from "redux/constants";

const skillSummaryDeletingReducer = (state = false, action) => {
  switch (action.type) {
    case skills.DELETE_SKILLSUMMARY:
      return true;
    case skills.DELETE_SKILLSUMMARY_SUCCESS:
    case skills.DELETE_SKILLSUMMARY_FAILURE:
      return false;
    default:
      return state;
  }
};

export default skillSummaryDeletingReducer;
