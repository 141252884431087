import React, { useState, useEffect } from "react";
import { Modal, Grid, Button, Segment, Dimmer, Loader } from "semantic-ui-react";
import PropTypes from "prop-types";
import {
  formatDateMonthDDYearForAchievements,
  toastError,
  isEmptyOrNull,
  toastSuccess,
  checkIfRegex
} from "helpers/utils";
import LABEL from "constants/label";
import PopupDropDown from "components/common/PopupDropDown";
import "./styles.scss";
import { Icon } from "components/common";
import AwardsDropDown from "components/common/AwardsDropDown";
import { getAwardIconName, isKeyToPurposeAward } from "components/Achievements/helper/utils";
import { getAchievements } from "redux/actions/achievements";
import { useDispatch, useSelector } from "react-redux";
import { LOCATIONOPTIONS as locationOptions } from "helpers/constants";
import Hyperlink from "components/common/Hyperlink";
import AwardsTypeDropDown from "components/common/AwardsTypeDropDown";
import Calender from "components/common/Calendar";
import { updateAward } from "redux/actions/award";
import AwardNameTextBox from "components/common/AwardNameTextBox";
import AwardDescriptionTextArea from "components/common/AwardDescriptionTextArea";
import CONFIG from "constants/config";
import ConfirmationModal from "components/common/Modal/ConfirmationModal/ConfirmationModal";
import Tags from "components/common/Tags";

const { apiUrl, xApiKey } = CONFIG;

const {
  ACHIEVEMENTS: {
    BackToCredentials,
    AddNewAward,
    Select,
    BCG,
    External,
    emptyValue,
    DateFormat,
    invalidTextWarningMessage
  },
  ALL_AWARDS: {
    TABLE_HEADER: { AwardType, AwardCategory, DateReceived, Description, AwardName, HyperlinkHeader }
  },
  COMMON: { Save, Close, UnsavedConfirmation, Saving }
} = LABEL;

const ViewEditAwardPopup = ({ isOpen, close, award, mode, closeAll }) => {
  const dispatch = useDispatch();
  const isEditable = mode === "edit";
  const [locationWon, setLocationWon] = useState(award?.location || Select);
  const [awardTypeId, setAwardTypeId] = useState(award?.awardType || 0);
  const [awardTypeName, setAwardTypeName] = useState(award?.awardTypeName || Select);
  const [name, setName] = useState(award?.name || Select);
  const [awardNameRefId, setAwardNameRefId] = useState(award?.awardNameRefId || 1);
  const [awardSiteIsValid, setAwardSiteIsValid] = useState("");
  const [dateReceived, setDateReceived] = useState(award?.dateReceived || new Date());
  const [awardSiteUrl, setAwardSiteUrl] = useState(award?.awardSiteUrl || "");
  const [description, setDescription] = useState(award?.description);
  const [showWarningPopup, setShowWarningPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [awardDataUpdated, setAwardDataUpdated] = useState(false);
  const isValidInput =
    locationWon !== Select && awardTypeId !== 0 && name !== Select && name?.trim() !== "" && checkIfRegex(name);
  const [createdBy] = useState(new Date());
  const [backButtonClicked, setBackButtonClicked] = useState(false);
  const { peopleData, awardUpdatingError, awardUpdating, achievementsLoading } = useSelector((state) => state);

  const onCalendarChange = (dateSelected) => {
    setAwardDataUpdated(true);
    setDateReceived(dateSelected);
  };

  const renderHyperlink = (url) => {
    if (url)
      return (
        <a href={url} target="__blank" className="awardPopup__hyperLink">
          {url}
        </a>
      );
    return emptyValue;
  };

  const handleLocationChange = (e, data) => {
    setAwardDataUpdated(true);
    setLocationWon(data.text);
    if (data.text === Select) {
      setAwardTypeId(0);
      setName(Select);
    } else if (data.text === BCG) {
      setAwardTypeId(0);
      setAwardTypeName(Select);
      setName(Select);
    } else if (data.text === External) {
      setName("");
      setAwardNameRefId(0);
      setAwardTypeName(Select);
      setAwardTypeId(5);
    }
  };

  const handleAwardTypeChange = (e, data) => {
    setAwardDataUpdated(true);
    setAwardTypeId(data.value);
    setAwardTypeName(data.text);
    if (data.value === 3 || data.value === 4 || data.value === 0) {
      setName(Select);
    } else {
      setName("");
    }
  };

  const getIconName = () => {
    return isEditable ? getAwardIconName({ name, location: locationWon, awardNameRefId }) : getAwardIconName(award);
  };

  const resetState = () => {
    setAwardDataUpdated(false);
    setLocationWon(award?.location || Select);
    setDateReceived(award?.dateReceived || new Date());
    setName(award?.name || Select);
    setAwardNameRefId(award?.awardNameRefId || 1);
    setAwardTypeId(award?.awardType || 0);
    setAwardTypeName(award?.awardTypeName || Select);
    setDescription(award?.description || undefined);
    setAwardSiteUrl(award?.awardSiteUrl || "");
  };

  useEffect(() => {
    if (award) {
      resetState();
    }
  }, [award]);

  const handleOnSaveWrapUp = () => {
    setIsLoading(false);
    close();
    resetState();
  };

  const handleOnSave = () => {
    setIsLoading(true);
    const awardObj = {
      awardId: award?.awardId || undefined,
      location: locationWon,
      dateReceived,
      name: name?.trim(),
      awardNameRefId: awardTypeId === 3 || awardTypeId === 4 || awardTypeId === 0 ? awardNameRefId : undefined,
      awardType: awardTypeId,
      description: description?.trim(),
      awardSiteUrl: awardSiteIsValid ? awardSiteUrl : undefined,
      createdDate: createdBy,
      lastUpdateDate: createdBy,
      featured: award?.featured,
      sortOrder: award?.sortOrder
    };

    try {
      dispatch(
        updateAward({
          endpoint: `${apiUrl.linkApi}profile/${peopleData.id}/award`,
          data: awardObj,
          config: {
            headers: {
              "x-api-key": `${xApiKey.profile}`
            }
          }
        })
      );
    } catch (e) {
      setIsLoading(false);
      toastError();
    }
    return true;
  };

  useEffect(() => {
    if (!awardUpdating && isLoading) {
      if (isEmptyOrNull(awardUpdatingError)) {
        dispatch(
          getAchievements({
            endpoint: `${apiUrl.linkApi}profile/${peopleData.id}/awardscerts`,
            config: {
              headers: {
                "x-api-key": `${xApiKey.profile}`
              }
            }
          })
        );
      } else {
        handleOnSaveWrapUp();
        toastError();
      }
    }
  }, [awardUpdating]);

  useEffect(() => {
    if (!achievementsLoading && isLoading) {
      handleOnSaveWrapUp();
      toastSuccess();
    }
  }, [achievementsLoading]);

  const onCloseAwardPopup = () => {
    setBackButtonClicked(false);
    if (isEditable && awardDataUpdated) {
      setShowWarningPopup(true);
    } else {
      closeAll();
      resetState();
    }
  };

  const onCloseWarningPopup = () => {
    setShowWarningPopup(false);
    if (backButtonClicked) {
      close();
    } else {
      closeAll();
    }
    resetState();
    setBackButtonClicked(false);
  };

  const onClickBack = () => {
    setBackButtonClicked(true);
    if (isEditable && awardDataUpdated) {
      setShowWarningPopup(true);
    } else {
      resetState();
      close();
    }
  };

  const getReadOnlyAwardType = () => {
    return locationWon === BCG ? award?.awardTypeName : emptyValue;
  };

  const viewEditAwardPopup = (
    <Modal open={isOpen} centered closeOnDimmerClick={false} className="awardPopup" data-testid="viewEditAwardPopup">
      <Modal.Content className="text-right">
        {isLoading ? (
          <Segment className="loading__segment">
            <Dimmer active inverted>
              <Loader size="large">{Saving}</Loader>
            </Dimmer>
          </Segment>
        ) : null}
        <div className="awardPopup__backButton d-flex">
          <span
            onClick={onClickBack}
            className="cursor-pointer d-flex"
            data-testid="backButton"
            onKeyDown={() => {}}
            role="button"
            tabIndex="0"
          >
            <Icon name="back" size={10} />
            {BackToCredentials}
          </span>
        </div>
        <div className="d-flex justify-content-between">
          <div>
            <span data-testid={getIconName()}>
              <Icon name={getIconName()} size={192} />
            </span>
            {isKeyToPurposeAward(award) && !isEmptyOrNull(award?.chosenPrinciple) && (
              <div className="awardPopup__keyToPurposeDesc d-flex justify-content-center" data-testid="descriptionTag">
                <Tags tags={[award?.chosenPrinciple]} numberOfTagsToShow={1} showFull />
              </div>
            )}
          </div>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <div className={isEditable ? "awardPopup__awardNameHeader" : "awardPopup__awardName"}>
                  {isEditable ? AddNewAward : award?.name}
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column computer={8} tablet={10}>
                <h4 className={isEditable ? "awardPopup__mandatoryField" : null}>{AwardType}</h4>
                <div>
                  {isEditable ? (
                    <PopupDropDown
                      onClick={handleLocationChange}
                      options={locationOptions}
                      selectedIndex={(award?.location === BCG && 1) || (award?.location === External ? 2 : 0)}
                      id="dd_award_loc"
                    />
                  ) : (
                    award?.location
                  )}
                </div>
              </Grid.Column>
              <Grid.Column computer={8} tablet={10}>
                <h4 className={isEditable && locationWon !== External ? "awardPopup__mandatoryField" : null}>
                  {AwardCategory}
                </h4>
                <div>
                  {isEditable ? (
                    <AwardsTypeDropDown
                      disabled={locationWon !== BCG}
                      awardLocation={locationWon}
                      onAwardTypeChange={handleAwardTypeChange}
                      awardTypeName={awardTypeName}
                    />
                  ) : (
                    getReadOnlyAwardType()
                  )}
                </div>
              </Grid.Column>
            </Grid.Row>
            {isEditable && (
              <Grid.Row>
                <Grid.Column>
                  <h4 className={isEditable ? "awardPopup__mandatoryField" : null}>{AwardName}</h4>
                  {((awardTypeId === 3 || awardTypeId === 4 || awardTypeId === 0) && (
                    <AwardsDropDown
                      disabled={awardTypeId === 0}
                      handleOnChange={({ awardName, awardId }) => {
                        setName(awardName);
                        setAwardNameRefId(awardId);
                        setAwardDataUpdated(true);
                      }}
                      awardName={name}
                    />
                  )) || (
                    <>
                      <AwardNameTextBox
                        setName={(awardName) => setName(awardName)}
                        name={name}
                        onTextChange={setAwardDataUpdated}
                        invalid={!checkIfRegex(name)}
                      />
                      {!checkIfRegex(name) && (
                        <div className="awardPopup__invalidTextWarning d-flex" data-testid="awardNameWarning">
                          <Icon name="invalidTextWarningIcon" size={15} />
                          <span>{invalidTextWarningMessage}</span>
                        </div>
                      )}
                    </>
                  )}
                </Grid.Column>
              </Grid.Row>
            )}
            <Grid.Row>
              <Grid.Column computer={8} tablet={10}>
                <h4 className={isEditable ? "awardPopup__mandatoryField" : null}>{DateReceived}</h4>
                <div>
                  {isEditable ? (
                    <Calender
                      date={new Date(dateReceived)}
                      dateFormat={DateFormat}
                      callBack={onCalendarChange}
                      minDate=""
                    />
                  ) : (
                    formatDateMonthDDYearForAchievements(award?.dateReceived, false)
                  )}
                </div>
              </Grid.Column>
              <Grid.Column computer={8} tablet={8}>
                <h4>{HyperlinkHeader}</h4>
                <div>
                  {isEditable ? (
                    <Hyperlink
                      awardSiteUrl={awardSiteUrl}
                      setAwardSiteUrl={setAwardSiteUrl}
                      setAwardSiteIsValid={setAwardSiteIsValid}
                      onTextChange={setAwardDataUpdated}
                    />
                  ) : (
                    renderHyperlink(award?.awardSiteUrl)
                  )}
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row data-testid="description">
              <Grid.Column computer={16} tablet={16}>
                <h4>{Description}</h4>
                <div>
                  {isEditable ? (
                    <AwardDescriptionTextArea
                      callback={(desc) => {
                        setDescription(desc);
                        setAwardDataUpdated(true);
                      }}
                      desc={description}
                    />
                  ) : (
                    award?.description || emptyValue
                  )}
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onCloseAwardPopup} data-testid="closeButton">
          {Close}
        </Button>
        {isEditable && (
          <Button primary onClick={handleOnSave} data-testid="saveButton" disabled={!isValidInput}>
            {Save}
          </Button>
        )}
      </Modal.Actions>
    </Modal>
  );

  const confirmationPopup = (
    <ConfirmationModal
      isOpen={showWarningPopup}
      modalMessage={UnsavedConfirmation}
      handleCancelClick={() => setShowWarningPopup(false)}
      handleContinueClick={onCloseWarningPopup}
    />
  );

  return <>{showWarningPopup ? confirmationPopup : viewEditAwardPopup}</>;
};

ViewEditAwardPopup.defaultProps = {
  award: PropTypes.shape({
    location: Select,
    dateReceived: new Date(),
    name: Select,
    awardNameRefId: 1,
    awardTypeId: 0,
    awardType: 0,
    awardTypeName: Select,
    description: undefined,
    awardSiteUrl: undefined,
    awardId: undefined
  }),
  mode: ""
};

ViewEditAwardPopup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  closeAll: PropTypes.bool.isRequired,
  mode: PropTypes.string,
  award: PropTypes.shape({
    location: PropTypes.string,
    awardId: PropTypes.number,
    dateReceived: PropTypes.string,
    name: PropTypes.string,
    awardNameRefId: PropTypes.number,
    awardTypeId: PropTypes.number,
    awardType: PropTypes.number,
    awardTypeName: PropTypes.string,
    description: PropTypes.string,
    awardSiteUrl: PropTypes.string,
    featured: PropTypes.bool,
    sortOrder: PropTypes.number,
    chosenPrinciple: PropTypes.string
  })
};

export default ViewEditAwardPopup;
