import LABEL from "constants/label";
import { Image, Popup } from "semantic-ui-react";

const { ABOUT } = LABEL;

// logic to show disabled PA option for Edit About Popup
const decoratePAOptions = (practiceAreas, selectedPAId) => {
  const updatedPracticeAreas = practiceAreas?.map((practiceArea) => {
    const newPracticeArea = { ...practiceArea };
    newPracticeArea.disabled = newPracticeArea?.key === selectedPAId;
    newPracticeArea.content = (
      <>
        <span>{newPracticeArea?.text}</span>
        {newPracticeArea?.disabled && (
          <Popup
            data-testid="disabledItemToolTip"
            position="right center"
            inverted
            content={ABOUT.disabledOptionText}
            trigger={<Image data-testid="disabledIcon" className="ml-h my-0" inline src="/icons/alert icon.svg" />}
            className="clipboard-tooltip"
          />
        )}
      </>
    );
    return newPracticeArea;
  });
  return updatedPracticeAreas;
};

export default decoratePAOptions;
