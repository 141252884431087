import { takeLatest } from "redux-saga/effects";

import { profileViews } from "redux/constants";
import handleSaveProfileViews from "../worker/handleSaveProfileViews";

function* watchSaveProfileViews() {
  yield takeLatest(profileViews.SAVE_PROFILE_VIEWS, handleSaveProfileViews);
}

export default watchSaveProfileViews;
