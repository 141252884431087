import { call, put } from "redux-saga/effects";
import { get } from "redux/api";
import { setCCODetails, setCCOError } from "redux/actions/cco";

function* handleFetchCCO({ payload }) {
  const { data, error } = yield call(get, payload);
  if (error) {
    yield put(setCCOError(error));
  } else {
    yield put(setCCODetails(data));
  }
}

export default handleFetchCCO;
