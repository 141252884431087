import { topics } from "redux/constants";

const topicUpdatingErrorReducer = (state = null, action) => {
  switch (action.type) {
    case topics.UPDATE_TOPIC_FAILURE:
      return action.error;
    case topics.UPDATE_TOPIC_SUCCESS:
    case topics.UPDATE_TOPIC:
      return null;
    default:
      return state;
  }
};

export default topicUpdatingErrorReducer;
