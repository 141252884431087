import { cco } from "redux/constants";

const getCCODetails = (payload) => {
  return {
    type: cco.FETCH_CCO,
    payload
  };
};

const setCCODetails = (data) => ({
  type: cco.FETCH_CCO_SUCCESS,
  data
});

const setCCOError = (error) => ({
  type: cco.FETCH_CCO_FAILURE,
  error
});

export { getCCODetails, setCCODetails, setCCOError };
