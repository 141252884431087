import { impersonate } from "redux/constants";

const impersonateUserImageReducer = (state = {}, action) => {
  switch (action.type) {
    case impersonate.FETCH_IMAGE_SUCCESS: {
      return { ...action.data };
    }
    default:
      return state;
  }
};

export default impersonateUserImageReducer;
