import { takeLatest } from "redux-saga/effects";

import { profileCompletion } from "redux/constants";
import handleUpdateProfileCompletion from "../worker/handleUpdateProfileCompletion";

function* watchUpdateProfileCompletion() {
  yield takeLatest(profileCompletion.UPDATE_PROFILE_COMPLETE, handleUpdateProfileCompletion);
}

export default watchUpdateProfileCompletion;
