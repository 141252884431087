import React from "react";
import PropTypes from "prop-types";
import { letterLimit } from "helpers/utils";
import { EVENTS, TRIGGERS } from "analytics/constants";
import sendEventData from "analytics/analytics";
import { Popover } from "components/common";
import LABEL from "constants/label";

import "./styles.scss";
import CONFIG from "constants/config";
import CopyText from "../CopyText";

/**
 * CaseTitle - Case title or short description
 *
 * @param  {string} shortDescription Case data for Topics Paths popup
 * @param  {string} sortBy Sort by
 * @param  {number}  uniqueIndustryPAs filter options
 * @param  {string} industryFilter selected industry filter
 * @param  {function}  uniqueFunctionalPAs filter options
 *
 *
 * @return Case Title Component
 *
 * @example  <CaseTitle shortDescription={shortDescription} sortBy={sortBy} docRank= {docRank} projectId={projectId} getFilterterm={getFilterterm} />
 */

const { CASES } = LABEL;

function CaseTitle({ shortDescription, sortBy, docRank, projectId, getFilterterm, caseNumber, className }) {
  return (
    <div className="case__case-header">
      {(shortDescription && (
        <p className={`${className} case__case_title cursor-pointer fw-bold mr-1`} data-testid="caseTitle">
          <Popover
            trigger={
              <a
                href={`${CONFIG.cases.casePageUrl}${projectId}`}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => {
                  sendEventData(TRIGGERS.CASE.OPEN_CASE_PAGE, EVENTS.CASES_SECTION, {
                    preSortTerm: sortBy,
                    preFilterTerm: getFilterterm(),
                    docRank
                  }); // Analytics Event
                }}
              >
                {letterLimit(shortDescription, 215)}
              </a>
            }
            on="hover"
            inverted
            position="top left"
            popper={{ className: "case-tooltip" }}
            size="tiny"
          >
            {CASES.OpenCasePage}
          </Popover>
        </p>
      )) || (
        <p className={`${className} case__nocaseDesc fw-bold`} data-testid="noCaseTitle">
          {`Case title is not available, `}
          <Popover
            trigger={
              <a
                href={`${CONFIG.cases.casePageUrl}${projectId}`}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => {
                  sendEventData(TRIGGERS.CASE.OPEN_CASE_PAGE, EVENTS.CASES_SECTION, {
                    preSortTerm: sortBy,
                    preFilterTerm: getFilterterm(),
                    docRank
                  }); // Analytics Event
                }}
              >
                {`click here `}
              </a>
            }
            on="hover"
            inverted
            position="top left"
            popper={{ className: "case-tooltip" }}
            size="tiny"
          >
            {CASES.OpenCasePage}
          </Popover>
          to open the case page.
        </p>
      )}
      {!!caseNumber && (
        <div className="case__caseId-readOnly">
          <CopyText text={caseNumber} prefix="#" popUpText={CASES.CopyNumber} />
        </div>
      )}
    </div>
  );
}

CaseTitle.defaultProps = {
  shortDescription: "",
  sortBy: "",
  projectId: "",
  docRank: 0,
  getFilterterm: () => {},
  caseNumber: "",
  className: ""
};

CaseTitle.propTypes = {
  shortDescription: PropTypes.string,
  sortBy: PropTypes.string,
  projectId: PropTypes.string,
  docRank: PropTypes.number,
  getFilterterm: PropTypes.func,
  caseNumber: PropTypes.string,
  className: PropTypes.string
};

export default CaseTitle;
