import { peopleData } from "redux/constants";

const peopleDataLoadingReducer = (state = true, action) => {
  switch (action.type) {
    case peopleData.FETCH_PEOPLE_DATA:
      return true;
    case peopleData.FETCH_PEOPLE_DATA_SUCCESS:
    case peopleData.FETCH_PEOPLE_DATA_FAILURE:
      return false;
    default:
      return state;
  }
};

export default peopleDataLoadingReducer;
