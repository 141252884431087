import { ssMaterials } from "redux/constants";

const getSSMaterials = (payload) => {
  return {
    type: ssMaterials.FETCH_SSMATERIALS,
    payload
  };
};

const setSSMaterials = (data) => ({
  type: ssMaterials.FETCH_SSMATERIALS_SUCCESS,
  data
});

const setSSMaterialsError = (error) => ({
  type: ssMaterials.FETCH_SSMATERIALS_FAILURE,
  error
});

export { getSSMaterials, setSSMaterials, setSSMaterialsError };
