import { ssCases } from "redux/constants";

const ssCasesLoadingReducer = (state = true, action) => {
  switch (action.type) {
    case ssCases.FETCH_SSCASE:
      return true;
    case ssCases.FETCH_SSCASE_SUCCESS:
    case ssCases.FETCH_SSCASE_FAILURE:
      return false;
    default:
      return state;
  }
};

export default ssCasesLoadingReducer;
