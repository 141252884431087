import { takeLatest } from "redux-saga/effects";

import { profileCompletion } from "redux/constants";
import handleSetSkipForNow from "../worker/handleSetSkipForNow";

function* watchSkipForNow() {
  yield takeLatest(profileCompletion.SET_SKIP_FOR_NOW, handleSetSkipForNow);
}

export default watchSkipForNow;
