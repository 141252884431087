import { takeLatest } from "redux-saga/effects";

import { linkedIn } from "redux/constants";
import handleFetchLinkedInData from "../worker/handleFetchLinkedInData";

function* watchFetchLinkedInData() {
  yield takeLatest(linkedIn.FETCH_LINKEDIN_DATA, handleFetchLinkedInData);
}

export default watchFetchLinkedInData;
