const data = {
  page: {
    pageInfo: {
      pageName: "",
      staffSystemID: ""
    }
  },
  acrossTabs: {
    profile: {
      profileName: "",
      profileTitle: "",
      profileOffice: ""
    }
  },
  link: {
    trigger: ""
  },
  newTabName: ""
};
window.digitalData = data;
export default window.digitalData;
