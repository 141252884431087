import React from "react";
import PropTypes from "prop-types";
import { Progress } from "semantic-ui-react";

import InfoIcon from "components/common/InfoIcon";

import ToolTipContents from "components/ProfileCompletion/ToolTipContents";
import "./styles.scss";

function ProgressBar({ percentCompleted, infoIconData }) {
  return (
    <>
      <div className="percent__label justify-content-between">
        <div>
          <span>{percentCompleted}%</span>of your profile is Up to Date
        </div>
        <InfoIcon>
          <ToolTipContents />
        </InfoIcon>
      </div>
      <Progress
        percent={percentCompleted}
        className={`progress__bar ${percentCompleted === 100 ? "bar__completed" : ""}`}
        size="tiny"
      />
    </>
  );
}

ProgressBar.propTypes = {
  percentCompleted: PropTypes.number.isRequired
};

export default ProgressBar;
