import PropTypes from "prop-types";
import { Modal, Button, Image } from "semantic-ui-react";
import LABEL from "constants/label";
import "./styles.scss";

const {
  HOW_CAN_I_BE_MORE_FINDABLE: { CONTENT, HEADING, SUB_HEADING, HAVE_A_QUESTION, LIKE_BUTTON, SUBJECT, MAIL_ID, HOW_TO }
} = LABEL;

const HowCanIbeMoreFindable = ({ open, onOpen, onClose, openEdit }) => {
  return (
    <Modal open={open} onOpen={onOpen} onClose={onClose} className="walkme_container">
      <Modal.Header data-testid="heading">
        {HEADING}
        <Image className="cross_icon" src="/icons/close-grey.svg" alt="" width={30} onClick={onClose} />
      </Modal.Header>
      <Modal.Description>
        <p>
          {SUB_HEADING}
          <span>
            Check&nbsp;
            <a
              style={{ textDecoration: "underline" }}
              href="https://bcgcloud.sharepoint.com/:b:/s/KDChangeandComms/EWTJqPjetslCjOH5GrBN8hMB3J_2X2xiquV41BA5pv1NXQ?e=s2geLS"
              target="_blank"
            >
              profile guide
            </a>
            &nbsp; for now
          </span>
        </p>
      </Modal.Description>
      <Modal.Content>
        {CONTENT.map((item) => {
          return (
            <div>
              <strong>{item?.heading}</strong>
              <ul>
                <li>{item?.subHeading}</li>
                {item?.content ? <li>{item?.content}</li> : ""}
              </ul>
            </div>
          );
        })}
        <p>{HOW_TO}</p>
      </Modal.Content>
      <Modal.Actions className="button_wrap">
        <Button className="green_button" onClick={() => openEdit()} data-testid="btn-edit">
          <img className="like_icon" src="/icons/like_icon.svg" alt="" width={12} /> {LIKE_BUTTON}
        </Button>
        <a href={`mailto:${MAIL_ID}?subject=${SUBJECT}`} className="white_button" data-testid="btn-email">
          {HAVE_A_QUESTION}
        </a>
      </Modal.Actions>
    </Modal>
  );
};
export default HowCanIbeMoreFindable;

HowCanIbeMoreFindable.defaultProps = {
  open: false,
  onOpen: () => {},
  onClose: () => {},
  openEdit: () => {}
};

HowCanIbeMoreFindable.propTypes = {
  open: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  openEdit: PropTypes.func
};
