import React from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "semantic-ui-react";
import { Icon as IconC } from "components/common";
import LABEL from "constants/label";

const ConfirmationModal = ({ isOpen, modalMessage, handleCancelClick, handleContinueClick }) => {
  const { COMMON } = LABEL;
  return (
    <Modal
      open={isOpen}
      centered
      closeOnDimmerClick={false}
      className="custom-modal-small"
      data-testid="confirmationModal"
    >
      <Modal.Content className="text-right p-1">
        <div className="warningpopupContent">
          <IconC name="warning" size={32} className="mr-1" />
          <div className="d-flex align-items-center">{modalMessage}</div>
        </div>
        <Button data-testid="cancel" basic className="button-sm mr-1" secondary onClick={handleCancelClick}>
          {COMMON.No}
        </Button>
        <Button data-testid="continue" primary className="button-sm" type="submit" onClick={handleContinueClick}>
          {COMMON.Yes}
        </Button>
      </Modal.Content>
    </Modal>
  );
};

ConfirmationModal.defaultProps = {
  isOpen: false
};

ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool,
  modalMessage: PropTypes.string.isRequired,
  handleCancelClick: PropTypes.func.isRequired,
  handleContinueClick: PropTypes.func.isRequired
};

export default ConfirmationModal;
