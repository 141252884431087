import { linkedIn } from "redux/constants";

const linkedInUserStatusReducer = (state = {}, action) => {
  switch (action.type) {
    case linkedIn.FETCH_USER_LINKEDIN_STATUS_SUCCESS: {
      return action?.data;
    }
    default:
      return state;
  }
};

export default linkedInUserStatusReducer;
