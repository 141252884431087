import { call, put } from "redux-saga/effects";
import { get } from "redux/api";
import { setAboutAdditonalData, setAboutTopicDetails, setAboutSponserDetailsError } from "redux/actions/about";

function* handleFetchAboutTopicDetails({ payload }) {
  const { data, error } = yield call(get, payload);
  if (error) {
    yield put(setAboutSponserDetailsError(error));
  } else {
    yield put(setAboutAdditonalData({ [payload.topicType]: data?.browseTerms[0]?.term?.name }));
    yield put(setAboutTopicDetails());
  }
}

export default handleFetchAboutTopicDetails;
