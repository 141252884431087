import React, { useState } from "react";
import { Placeholder, TextArea } from "semantic-ui-react";

import PropTypes from "prop-types";

/**
 *
 * @param {bool} isLoading indicates whether the component is loading or not
 * @param {Function} callback callback method to send the value
 * @param {Text} desc description text
 *
 * @returns AwardDescriptionTextArea component
 *
 * @example
 *
 * <AwardDescriptionTextArea isLoading={false} callback={(value)=>console.log(value)} />
 */

function AwardDescriptionTextArea({ isLoading, callback, desc }) {
  const [description, setDescription] = useState(desc);

  const handleOnChange = (e, data) => {
    setDescription(data.value);
    callback(data.value);
  };
  let returnval = (
    <TextArea fluid="true" value={description} onChange={handleOnChange} className="awards__text-area d-block" />
  );

  if (isLoading)
    returnval = (
      <Placeholder fluid>
        <Placeholder.Header>
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Header>
      </Placeholder>
    );
  return returnval;
}

AwardDescriptionTextArea.defaultProps = {
  isLoading: false
};

AwardDescriptionTextArea.propTypes = {
  isLoading: PropTypes.bool,
  callback: PropTypes.func.isRequired
};

export default AwardDescriptionTextArea;
