import { education } from "redux/constants";

const educationLoadingReducer = (state = false, action) => {
  switch (action.type) {
    case education.FETCH_EDUCATION:
      return true;
    case education.FETCH_EDUCATION_SUCCESS:
    case education.FETCH_EDUCATION_FAILURE:
      return false;
    default:
      return state;
  }
};

export default educationLoadingReducer;
