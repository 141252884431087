import { takeLatest } from "redux-saga/effects";

import { resume } from "redux/constants";
import handleFetchProfileResumes from "../worker/handleFetchProfileResumes";

function* watchFetchResume() {
  yield takeLatest(resume.FETCH_RESUME, handleFetchProfileResumes);
}

export default watchFetchResume;
