import { convertArrayToObject } from "helpers/utils";
import { merge } from "lodash";
import { userData } from "redux/constants";

const userDataReducer = (state = {}, action) => {
  switch (action.type) {
    case userData.FETCH_USER_DATA_SUCCESS: {
      const employeeDataObject =
        (action?.data?.data?.data?.employees?.length &&
          convertArrayToObject(action?.data?.data?.data?.employees, "id")) ||
        {};
      const mergedObject = merge({ ...state }, { ...employeeDataObject });
      return {
        ...state,
        ...mergedObject
      };
    }

    default:
      return state;
  }
};

export default userDataReducer;
