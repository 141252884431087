import React, { useState, useCallback, useRef, useEffect } from "react";
import { Modal, Grid, Button, Segment, Dimmer, Loader, Input, Icon, Label } from "semantic-ui-react";
import { debounce, isNil } from "lodash";
import PropTypes from "prop-types";
import LABEL from "constants/label";
import TaxonomySelector from "components/TaxonomySelector";
import { updateCertificate } from "redux/actions/certificate";
import {
  checkScroll,
  toastError,
  toastSuccess,
  isEmptyOrNull,
  formatDateMonthDDYearForAchievements,
  checkIfRegex
} from "helpers/utils";
import Calender from "components/common/Calendar";
import { Icon as CustomIcon } from "components/common";
import { getAchievements } from "redux/actions/achievements";
import { useSelector, useDispatch } from "react-redux";
import "./styles.scss";
import CONFIG from "constants/config";
import ConfirmationModal from "components/common/Modal/ConfirmationModal/ConfirmationModal";
import { checkIfCourseIdExists, checkIfCourseIdExistsSuccess } from "redux/actions/externalCourseId";

const { apiUrl, xApiKey } = CONFIG;
const {
  AFFILIATIONS_PAGE,
  COMMON: { Close, Save, Saving, UnsavedConfirmation },
  ACHIEVEMENTS: {
    BackToCredentials,
    AddNewCred,
    AddNewExternalCred,
    emptyValue,
    DuplicateCertificateMessage,
    HiddenCred,
    ExpiredCredential,
    DateFormat,
    invalidTextWarningMessage,
    CourseIdWarning,
    ICONS: { ExternalCredential, InternalCred },
    ALL_CERTIFICATIONS: {
      TABLE_HEADER: {
        CertificationName,
        CourseId,
        Level,
        Validity,
        Skills,
        Description,
        DateAwarded,
        CertificateAuthority
      }
    }
  }
} = LABEL;

const ViewEditCredentialsPopup = ({ isOpen, close, isBcgCredential, mode, credential, closeAll }) => {
  const isEditable = mode === "edit";
  const targetRefSkill = useRef();
  const dispatch = useDispatch();
  const [name, setName] = useState(credential?.name && !isNil(credential?.name) ? credential?.name : "");
  const [dateAwarded, setDateAwarded] = useState(credential?.dateReceived || new Date());
  const [showWarningPopup, setShowWarningPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [validity, setValidity] = useState(credential?.certificationExpiry);
  const [skillScrollable, setSkillScrollable] = useState(false);
  const [courseId, setCourseId] = useState("" || credential?.externalId);
  const [certificateAuthority, setCertificateAuthority] = useState("" || credential?.authority);
  const [skillList, setSkillList] = useState(credential?.skills || []);
  const [level, setLevel] = useState(credential?.level);
  const [description, setDescription] = useState(credential?.description || "");
  const [credentialDataUpdated, setCredentialDataUpdated] = useState(false);
  const [backButtonClicked, setBackButtonClicked] = useState(false);

  const [externalCourseId, setExternalCourseId] = useState("");

  const { id } = useSelector((state) => state?.peopleData);
  const { peopleData, achievementsLoading, certificateUpdating, certificateUpdatingError, doesCourseExist } =
    useSelector((state) => state);

  const validateCourseId = debounce((e) => {
    setExternalCourseId(e.target.value);
  }, CONFIG.DEBOUNCE_DELAY);

  // clear the course id state on unmount
  useEffect(() => {
    return () => {
      dispatch(checkIfCourseIdExistsSuccess(false));
    };
  }, []);

  useEffect(() => {
    if (externalCourseId)
      dispatch(
        checkIfCourseIdExists({
          endpoint: `${apiUrl.linkApi}profile/certification/externalId/${externalCourseId}`,
          config: {
            headers: {
              "x-api-key": `${xApiKey.profile}`
            }
          }
        })
      );
  }, [externalCourseId]);

  const isValidCredential =
    name?.trim() !== "" &&
    checkIfRegex(name) &&
    dateAwarded !== "" &&
    !doesCourseExist &&
    (isBcgCredential || (certificateAuthority && checkIfRegex(certificateAuthority)));

  const onChangeCredentialData = (field, value) => {
    setCredentialDataUpdated(true);
    switch (field) {
      case CertificationName:
        setName(value);
        break;
      case Description:
        setDescription(value);
        break;
      case Level:
        setLevel(value);
        break;
      case CourseId:
        setCourseId(value);
        break;
      case DateAwarded:
        setDateAwarded(value);
        break;
      case CertificateAuthority:
        setCertificateAuthority(value);
        break;
      case Validity:
        setValidity(value);
        break;
      default:
        break;
    }
  };

  const handleAddSkill = (newSkill) => {
    setCredentialDataUpdated(true);
    setSkillList((prev) => [...prev, newSkill]);
  };

  const resetState = () => {
    setCredentialDataUpdated(false);
    setName(credential?.name && !isNil(credential?.name) ? credential?.name : "");
    setDateAwarded(credential?.dateReceived || new Date());
    setValidity(credential?.certificationExpiry);
    setCourseId("" || credential?.externalId);
    setCertificateAuthority("" || credential?.authority);
    setLevel(credential?.level);
    setDescription(credential?.description || "");
    setSkillList(credential?.skills || []);
  };

  const handleOnSave = () => {
    setIsLoading(true);

    const certificationObj = {
      certificationId: credential?.certificationId || undefined,
      dateReceived: dateAwarded,
      name: name.trim(),
      externalId: isBcgCredential ? courseId : undefined,
      certificationExpiry: isEmptyOrNull(validity)
        ? undefined
        : new Date(
            new Date(validity)?.setHours(new Date().getHours(), new Date().getMinutes(), new Date().getSeconds())
          ),
      skills: skillList,
      level,
      description,
      authority: !isBcgCredential ? certificateAuthority.trim() : undefined,
      sortOrder: credential?.sortOrder,
      featured: credential?.featured,
      hidden: credential?.hidden
    };

    setIsLoading(true);
    try {
      dispatch(
        updateCertificate({
          endpoint: `${apiUrl.linkApi}profile/${peopleData.id}/certification?${
            isBcgCredential ? "addedBy=user&source=internal" : "source=external"
          }`,
          data: certificationObj,
          config: {
            headers: {
              "x-api-key": `${xApiKey.profile}`
            }
          }
        })
      );
    } catch (e) {
      setIsLoading(false);
      toastError();
    }
    return true;
  };
  const handleOnSaveWrapUp = () => {
    setIsLoading(false);
    close(false);
    resetState();
  };

  useEffect(() => {
    if (credential) {
      resetState();
    }
  }, [credential]);

  useEffect(() => {
    if (!certificateUpdating && isLoading) {
      if (isEmptyOrNull(certificateUpdatingError)) {
        dispatch(
          getAchievements({
            endpoint: `${apiUrl.linkApi}profile/${peopleData.id}/awardscerts`,
            config: {
              headers: {
                "x-api-key": `${xApiKey.profile}`
              }
            }
          })
        );
      } else {
        handleOnSaveWrapUp();
        toastError();
      }
    }
  }, [certificateUpdating]);

  useEffect(() => {
    if (!achievementsLoading && isLoading) {
      handleOnSaveWrapUp();
      toastSuccess();
    }
  }, [achievementsLoading]);

  const SkillsTaxonomy = useCallback(
    () => (
      <TaxonomySelector
        placeholder={AFFILIATIONS_PAGE.SearchSkill}
        endpoint={apiUrl.smartLogicApi}
        tbdb={AFFILIATIONS_PAGE.SkillsAndTools}
        headingList={{ 0: AFFILIATIONS_PAGE.SkillsTaxonomy, 1: AFFILIATIONS_PAGE.ToolsTaxonomy }}
        headingPos={[0, 1]}
        offset={[-515, -480]}
        trigger={
          <button
            type="button"
            className="credentialsPopup__topic-box__share position-absolute "
            data-testid="skills-picker"
          >
            <CustomIcon name="Selector" size={26} />
          </button>
        }
        disabledTopicName={skillList}
        disabledGuidMsg={AFFILIATIONS_PAGE.disabledSkillMsg}
        cbOnSelection={({ term }) => {
          handleAddSkill(term?.name);
        }}
        disabledLevelStartIndex={0}
      />
    ),
    [isEditable, skillList]
  );

  const handleDeleteSkill = (skillName) => {
    setCredentialDataUpdated(true);
    setSkillList(skillList.filter((skill) => skill !== skillName));
  };
  const scroll = (e, dir) => {
    const el = e.currentTarget.parentElement.parentElement.children[0];
    if (dir === "UP") {
      el.scrollTo({ top: el.scrollTop - 30, behavior: "smooth" });
    } else {
      el.scrollTo({ top: el.scrollTop + 30, behavior: "smooth" });
    }
  };

  useEffect(() => {
    checkScroll(targetRefSkill, setSkillScrollable);
  }, [skillList]);

  const onCloseWarningPopup = () => {
    if (backButtonClicked) {
      close(false);
    } else {
      closeAll();
    }
    setShowWarningPopup(false);
    resetState();
  };

  const onCloseClick = () => {
    setBackButtonClicked(false);
    if (isEditable && credentialDataUpdated) {
      setShowWarningPopup(true);
    } else {
      closeAll();
    }
  };

  const onBackClick = () => {
    setBackButtonClicked(true);
    if (isEditable && credentialDataUpdated) {
      setShowWarningPopup(true);
    } else {
      close(false);
      resetState();
    }
  };

  const renderSkills = () => {
    return credential?.skills
      ? credential?.skills?.map((item) => {
          return (
            <Label className="credentialsPopup__skillTag" key={item}>
              {item}
            </Label>
          );
        })
      : emptyValue;
  };

  const confirmationPopup = (
    <ConfirmationModal
      isOpen={showWarningPopup}
      modalMessage={UnsavedConfirmation}
      handleCancelClick={() => setShowWarningPopup(false)}
      handleContinueClick={onCloseWarningPopup}
    />
  );

  const viewEditCredentialPopup = (
    <Modal
      open={isOpen}
      centered
      closeOnDimmerClick={false}
      className="credentialsPopup"
      data-testid="viewEditcredentialsPopup"
    >
      <Modal.Content className="text-right">
        {isLoading ? (
          <Segment className="loading__segment">
            <Dimmer active inverted>
              <Loader size="large">{Saving}</Loader>
            </Dimmer>
          </Segment>
        ) : null}
        <div className="credentialsPopup__backButton d-flex justify-content-between">
          <span
            className="cursor-pointer d-flex"
            onClick={onBackClick}
            data-testid="backButton"
            onKeyDown={() => {}}
            role="button"
            tabIndex="0"
          >
            <CustomIcon name="back" size={10} />
            {BackToCredentials}
          </span>
          <div>
            {credential?.hidden && (
              <Label className="credentialsPopup__hiddenTag" data-testid="tags-label">
                {HiddenCred}
              </Label>
            )}
            {credential?.expired && (
              <Label
                className={`credentialsPopup__hiddenTag ${credential?.expired ? "credentialsPopup__expiredTag" : null}`}
                data-testid="tags-label"
              >
                {ExpiredCredential}
              </Label>
            )}
          </div>
        </div>
        <div className="d-flex justify-content-between">
          {credential?.addedByCredly ? (
            <span data-testid="credlyLogo">
              <img src={credential?.imageURL} width="192" height="192" alt="credlyLogo" />
            </span>
          ) : (
            <span data-testid={isBcgCredential ? InternalCred : ExternalCredential}>
              <CustomIcon name={isBcgCredential ? InternalCred : ExternalCredential} size={192} />
            </span>
          )}
          <Grid>
            <Grid.Row>
              {isEditable ? (
                <Grid.Column
                  className={isEditable ? "credentialsPopup__credentialNameHeader" : "credentialsPopup__credentialName"}
                >
                  {isBcgCredential ? AddNewCred : AddNewExternalCred}
                  <div className="credentialsPopup__duplicateMessage d-flex">
                    <CustomIcon name="yellow-exclamation" size={16} />
                    <span>{DuplicateCertificateMessage}</span>
                  </div>
                </Grid.Column>
              ) : (
                <Grid.Column className="credentialsPopup__header">{credential?.name}</Grid.Column>
              )}
            </Grid.Row>
            {isEditable && (
              <Grid.Row>
                <Grid.Column computer={16} tablet={16}>
                  <h4 className={isEditable ? "credentialsPopup__mandatoryField" : null}>{CertificationName}</h4>
                  {isEditable && (
                    <>
                      <Input
                        name="name"
                        value={name}
                        onChange={(e) => onChangeCredentialData(CertificationName, e.target.value)}
                        className={`achievements__text-box ${!checkIfRegex(name) ? "mandatory-input-warning" : ""} `}
                      />
                      {!checkIfRegex(name) && (
                        <div
                          className="credentialsPopup__invalidTextWarning d-flex"
                          data-testid="credentialNameWarning"
                        >
                          <CustomIcon name="invalidTextWarningIcon" size={15} />
                          <span>{invalidTextWarningMessage}</span>
                        </div>
                      )}
                    </>
                  )}
                </Grid.Column>
              </Grid.Row>
            )}
            <Grid.Row className="course__row">
              <Grid.Column computer={8} tablet={10}>
                <h4 className={isEditable ? "credentialsPopup__mandatoryField" : null}>{DateAwarded}</h4>
                {isEditable ? (
                  <Calender
                    isLoading={isLoading}
                    date={new Date(dateAwarded)}
                    minDate=""
                    dateFormat={DateFormat}
                    callBack={(value) => onChangeCredentialData(DateAwarded, value)}
                  />
                ) : (
                  formatDateMonthDDYearForAchievements(credential?.dateReceived, false)
                )}
              </Grid.Column>
              <Grid.Column computer={8} tablet={10}>
                {isBcgCredential ? (
                  <>
                    <h4>{CourseId}</h4>
                    {isEditable ? (
                      <>
                        <Input
                          name="courseId"
                          value={courseId}
                          onChange={(e) => onChangeCredentialData(CourseId, e.target.value)}
                          onKeyDown={validateCourseId}
                          className={`achievements__text-box ${doesCourseExist ? "warn__input" : ""} `}
                        />
                        {doesCourseExist && (
                          <div className="warn__message">
                            <CustomIcon name="invalidTextWarningIcon" size={12} />
                            <p className="warn__message--text">{CourseIdWarning}</p>
                          </div>
                        )}
                      </>
                    ) : (
                      credential?.externalId || emptyValue
                    )}
                  </>
                ) : (
                  <>
                    <h4 className={isEditable ? "credentialsPopup__mandatoryField" : null}>{CertificateAuthority}</h4>
                    {isEditable ? (
                      <>
                        <Input
                          name="certificateAuthority"
                          value={certificateAuthority}
                          onChange={(e) => onChangeCredentialData(CertificateAuthority, e.target.value)}
                          className={`achievements__text-box ${
                            !checkIfRegex(certificateAuthority) ? "mandatory-input-warning" : ""
                          } `}
                        />
                        {!checkIfRegex(certificateAuthority) && (
                          <div
                            className="credentialsPopup__invalidTextWarning d-flex"
                            data-testid="certificateAuthorityWarning"
                          >
                            <CustomIcon name="invalidTextWarningIcon" size={15} />
                            <span>{invalidTextWarningMessage}</span>
                          </div>
                        )}
                      </>
                    ) : (
                      credential?.authority
                    )}
                  </>
                )}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="pt-0">
              <Grid.Column computer={8} tablet={10}>
                <h4>{Level}</h4>
                {isEditable ? (
                  <Input
                    name="Level"
                    value={level}
                    onChange={(e) => onChangeCredentialData(Level, e.target.value)}
                    className="achievements__text-box"
                  />
                ) : (
                  (!isEmptyOrNull(level) && level) || "-"
                )}
              </Grid.Column>
              <Grid.Column computer={8} tablet={10}>
                <h4>{Validity}</h4>
                {isEditable ? (
                  <Calender
                    isLoading={isLoading}
                    date={isEmptyOrNull(credential?.certificationExpiry) ? "" : new Date(validity)}
                    minDate={new Date(dateAwarded)}
                    maxDate=""
                    dateFormat={DateFormat}
                    callBack={(value) => onChangeCredentialData(Validity, value)}
                  />
                ) : (
                  formatDateMonthDDYearForAchievements(credential?.certificationExpiry, false) || emptyValue
                )}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <h4>{Skills}</h4>
                {isEditable ? (
                  <div
                    className={`credentialsPopup__topic-box position-relative people-picker  ${
                      !id ? "disabled-input" : ""
                    }`}
                  >
                    <div
                      className="credentialsPopup__topic-box__scroll-container credentialsPopup__topic-box__scroll-container--skills"
                      ref={targetRefSkill}
                    >
                      {skillList?.map((i) => (
                        <span className=" chip small mb-h mr-h" data-testid="chip-skills" key={i}>
                          <span className="mr-h">{i}</span>
                          <Icon name="delete" data-testid="delete" onClick={() => handleDeleteSkill(i)} />
                        </span>
                      ))}
                    </div>

                    <SkillsTaxonomy />

                    <div className="credentialsPopup__topic-box__scroll  position-absolute">
                      <button
                        type="button"
                        onClick={(e) => scroll(e, "UP")}
                        disabled={!skillScrollable}
                        data-testid="btn-up"
                      >
                        <Icon name="angle up" />
                      </button>
                      <button
                        type="button"
                        onClick={(e) => scroll(e, "DOWN")}
                        disabled={!skillScrollable}
                        data-testid="btn-down"
                      >
                        <Icon name="angle down" />
                      </button>
                    </div>
                  </div>
                ) : (
                  renderSkills()
                )}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column computer={16} tablet={16}>
                <h4>{Description}</h4>
                {isEditable ? (
                  <Input
                    name="description"
                    value={description}
                    onChange={(e) => onChangeCredentialData(Description, e.target.value)}
                    className="achievements__text-box"
                  />
                ) : (
                  credential?.description || emptyValue
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button data-testid="closeButton" onClick={onCloseClick}>
          {Close}
        </Button>
        {isEditable && (
          <Button primary data-testid="saveButton" onClick={handleOnSave} disabled={!isValidCredential}>
            {Save}
          </Button>
        )}
      </Modal.Actions>
    </Modal>
  );

  return <>{showWarningPopup ? confirmationPopup : viewEditCredentialPopup}</>;
};

ViewEditCredentialsPopup.defaultProps = {
  mode: "",
  close: () => {},
  isBcgCredential: false
};

ViewEditCredentialsPopup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func,
  isBcgCredential: PropTypes.bool,
  closeAll: PropTypes.func.isRequired,
  mode: PropTypes.string,
  credential: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    staffId: PropTypes.number,
    certificationId: PropTypes.number,
    certificationExpiry: PropTypes.string,
    dateReceived: PropTypes.string,
    description: PropTypes.string,
    hidden: PropTypes.bool,
    authority: PropTypes.string,
    skills: PropTypes.arrayOf([PropTypes.shape]),
    externalId: PropTypes.string,
    level: PropTypes.string,
    name: PropTypes.string,
    createdBy: PropTypes.string,
    addedByCredly: PropTypes.bool,
    imageURL: PropTypes.string,
    sortOrder: PropTypes.number,
    featured: PropTypes.bool,
    lastModifiedTime: PropTypes.string,
    expired: PropTypes.bool
  }).isRequired
};

export default ViewEditCredentialsPopup;
