import { about } from "redux/constants";

const practiceAreaOptionsErrorReducer = (state = {}, action) => {
  switch (action.type) {
    case about.FETCH_PA_OPTIONS_FAILURE:
      return action?.error?.response?.status;
    case about.FETCH_PA_OPTIONS_SUCCESS:
      return [];
    default:
      return state;
  }
};

export default practiceAreaOptionsErrorReducer;
