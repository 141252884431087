import { awardNames } from "redux/constants";

const getAwardNames = (payload) => {
  return {
    type: awardNames.FETCH_AWARD_NAMES,
    payload
  };
};

const setAwardNames = (data) => ({
  type: awardNames.FETCH_AWARD_NAMES_SUCCESS,
  data
});

const setError = (error) => ({
  type: awardNames.FETCH_AWARD_NAMES_FAILURE,
  error
});

export { getAwardNames, setAwardNames, setError };
