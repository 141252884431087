import { takeLatest } from "redux-saga/effects";

import { ssRecentMaterials } from "redux/constants";
import handleFetchSSRecentMaterials from "../worker/handleFetchSSRecentMaterials";

function* watchSSRecentMaterials() {
  yield takeLatest(ssRecentMaterials.FETCH_RECENT_SSMATERIALS, handleFetchSSRecentMaterials);
}

export default watchSSRecentMaterials;
