/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Checkbox, Dimmer, Loader, Modal, Segment } from "semantic-ui-react";
import Avatar from "components/common/Avatar";
import { useDispatch, useSelector } from "react-redux";
import LABEL from "constants/label";
import CONFIG from "constants/config";
import { getLinkedInData, getUserLinkedInStatus, updateLinkedInOptinOptions } from "redux/actions/linkedIn";
import { toastError, toastSuccess, noOfDaystoExpire } from "helpers/utils";

import "./styles.scss";
import { getCookie, setCookie } from "components/App/helper/utils";
import sendEventData from "analytics/analytics";
import { EVENTS, TRIGGERS } from "analytics/constants";

const { apiUrl, xApiKey, knowledge } = CONFIG;
function LinkedInMainPopup({ open, onModalClose, linkedInAlreadyOpted, setComponentToShow, onAllowCb }) {
  const {
    LINKEDIN: {
      REMOVE_ACCESS,
      PRIVACY_POLICY,
      GUIDE_TEXT,
      RENEW_CONSENT_HEADER,
      REMIND_ME_LATER,
      SAVE_SKIP_COUNT,
      TIME_EXPIRATION,
      LINKED_IN,
      SKIP,
      SKIPS
    },
    EDUCATION: { Education },
    EXPERIENCE: { Experience },
    COMMON: { SaveAndClose, Decline, Allow }
  } = LABEL;

  const { header: headerText, text: contentText } = GUIDE_TEXT;
  const dispatch = useDispatch();
  const [selectedItems, setSelectedItems] = useState([]);
  const [isDirty, setIsDirty] = useState(false);
  const {
    linkedInRedirectUrlLoading,
    profile,
    linkedInUserStatus,
    updateLinkedInOptinOptionsLoading,
    updateLinkedInOptinOptionsSuccess,
    updateLinkedInOptinOptionsError,
    linkedInDeclineStatusLoading
  } = useSelector((state) => state);

  const { optinFields, showRenewConsent, status } = linkedInUserStatus;

  /**
   * START
   *
   * Following block of code decides the Header of Linkedin Modal based on certain conditions.
   * The order of the following statement is key to it working correctly.
   */

  // default heading
  let LinkedInHeading = (
    <p>
      <strong>Knowledge Profiles</strong> would like to access some of your LinkedIn info:
    </p>
  );

  // if linkedIn is already opted in and renew consent is not to be shown.
  if (linkedInAlreadyOpted)
    LinkedInHeading = (
      <div>
        <strong>Knowledge Profiles</strong> has access to your LinkedIn info
        <p>(To opt out, please click on the switch button)</p>
      </div>
    );

  // if renew consent screen is to be shown.
  if (showRenewConsent) {
    const daysLeft = noOfDaystoExpire(status?.optinTimestamp);
    LinkedInHeading = (
      <p>
        <strong>
          {RENEW_CONSENT_HEADER} <span className="highlight_days">{daysLeft}</span> days
        </strong>
      </p>
    );
  }

  /**
   * END
   */

  useEffect(() => {
    if (optinFields) setSelectedItems(optinFields);
    else setSelectedItems(["EXP", "EDU"]);
  }, [optinFields]);

  const handleItemsCheckBoxClick = (e, data) => {
    setIsDirty(true);
    if (data?.checked) setSelectedItems([...selectedItems, data?.value]);
    if (!data?.checked) setSelectedItems(selectedItems?.filter((item) => item.toUpperCase() !== data?.value));
  };

  const handleOnAllowClick = () => {
    onAllowCb(selectedItems.join(","));
  };

  const skipCounts = getCookie(SAVE_SKIP_COUNT);

  const handleSkip = () => {
    const newSkipCount = skipCounts - 1;
    setCookie(SAVE_SKIP_COUNT, newSkipCount, 8);
    onModalClose();
    setCookie(TIME_EXPIRATION, skipCounts, 1);
  };
  const skipWord = skipCounts > 1 ? SKIPS : SKIP;
  const handleOnSaveAndCloseClick = () => {
    window.$tabName = LINKED_IN; // Global variable for Analytics
    sendEventData(TRIGGERS.LINKEDIN.SAVE_AND_CLOSE, EVENTS.EXPERIENCE_SECTION); // Analytics Event
    if (isDirty)
      dispatch(
        updateLinkedInOptinOptions({
          endpoint: `${apiUrl?.linkApi}linkedin/${profile?.hrId}/enable`,
          data: selectedItems,
          config: {
            headers: {
              "x-api-key": `${xApiKey.profile}`
            }
          }
        })
      );
    else onModalClose();
  };

  useEffect(() => {
    if (updateLinkedInOptinOptionsSuccess?.code === 200) {
      dispatch(
        getLinkedInData({
          endpoint: `${apiUrl.linkApi}linkedin/${profile?.hrId}/data`,
          config: {
            headers: {
              "x-api-key": `${xApiKey.profile}`
            }
          }
        })
      );
      dispatch(
        getUserLinkedInStatus({
          endpoint: `${apiUrl.linkApi}linkedin/${profile?.hrId}/userOptinStatus`,
          config: {
            headers: {
              "x-api-key": `${xApiKey.profile}`
            }
          }
        })
      );
      onModalClose();
      toastSuccess();
    }
  }, [updateLinkedInOptinOptionsSuccess]);

  useEffect(() => {
    if (updateLinkedInOptinOptionsError) toastError();
  }, [updateLinkedInOptinOptionsError]);

  return (
    <Modal
      open={open}
      onClose={onModalClose}
      className="linkedin__popup custom-modal"
      data-testid="linkedin-main-popup"
      closeOnDimmerClick={false}
    >
      <Modal.Content>
        {/* Save loader screen */}
        {(linkedInRedirectUrlLoading || updateLinkedInOptinOptionsLoading || linkedInDeclineStatusLoading) && (
          <Segment className="loading__segment">
            <Dimmer active inverted>
              {linkedInRedirectUrlLoading && <Loader size="large">Connecting...</Loader>}
              {updateLinkedInOptinOptionsLoading && <Loader size="large">Updating...</Loader>}
              {linkedInDeclineStatusLoading && <Loader size="large">Updating...</Loader>}
            </Dimmer>
          </Segment>
        )}
        <div className="d-flex align-items-center flex-column">
          <img src="/icons/linkedIn.svg" alt="linkedin logo" className="mb-2" />

          <div className="linkedin__popup-content">
            <div className="avatar-linkedin">
              <Avatar imgSrc="/icons/avatar-linkedin.svg" />
            </div>
            <div className="avatar-kn">
              <Avatar imgSrc="/icons/avatar-bcg.svg" />
            </div>
            <div className="mt-1 linkedin__popup-text">
              {LinkedInHeading}
              <ul>
                <li>
                  <div className="d-flex justify-content-between">
                    <p>{Experience}</p>
                    <Checkbox
                      slider
                      data-testid="experience-checkbox"
                      value="EXP"
                      onClick={handleItemsCheckBoxClick}
                      checked={!!selectedItems?.find((item) => item?.toUpperCase() === "EXP")}
                    />
                  </div>
                </li>
                <li>
                  <div className="d-flex justify-content-between">
                    <p>{Education}</p>
                    <Checkbox
                      slider
                      data-testid="education-checkbox"
                      value="EDU"
                      onClick={handleItemsCheckBoxClick}
                      checked={!!selectedItems?.find((item) => item?.toUpperCase() === "EDU")}
                    />
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="linkedin__popup-guide" data-testid="optin-guide">
            <p>{headerText}</p>
            <ul>
              {contentText.map((text, index) => (
                <li key={`content-${index}`}>{text}</li>
              ))}
            </ul>
          </div>

          <div
            className="d-flex mt-2 linkedin__popup-actions justify-content-between align-items-center"
            data-testid="optin-actions"
          >
            {(!linkedInAlreadyOpted || showRenewConsent) && (
              <>
                <div className="policy__content d-flex">
                  <a
                    className="privacy__policy"
                    href={knowledge.privacyPolicyLink}
                    data-testid="privacy-policy-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {PRIVACY_POLICY}
                  </a>
                </div>
                <div className="remind_me_later" data-testid="remind-me-later">
                  {skipCounts > 0 && showRenewConsent && (
                    <>
                      <Button onClick={handleSkip}>{REMIND_ME_LATER}</Button>

                      <p className="skips_left">
                        (up to {skipCounts} {skipWord} only)
                      </p>
                    </>
                  )}
                </div>

                <div>
                  <Button
                    basic
                    secondary
                    size="medium"
                    className="mr-1"
                    onClick={() => {
                      setComponentToShow(1);
                    }}
                    data-testid="decline-button"
                  >
                    {Decline}
                  </Button>
                  <Button
                    primary
                    type="submit"
                    data-testid="allow-button"
                    onClick={handleOnAllowClick}
                    disabled={!selectedItems?.length}
                  >
                    {Allow}
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
        {linkedInAlreadyOpted && !showRenewConsent && (
          <div className="linkedin__popup-actions-2 mt-2" data-testid="optout-actions">
            <Button
              basic
              secondary
              className="mr-1"
              onClick={() => {
                window.$tabName = LINKED_IN; // Global variable for Analytics
                sendEventData(TRIGGERS.LINKEDIN.REMOVE_ACCESS, EVENTS.EXPERIENCE_SECTION); // Analytics Event
                setComponentToShow(2);
              }}
              data-testid="remove-access-button"
            >
              {REMOVE_ACCESS}
            </Button>
            <Button
              primary
              type="submit"
              className="center__item"
              data-testid="save-close-button"
              onClick={handleOnSaveAndCloseClick}
            >
              {SaveAndClose}
            </Button>
          </div>
        )}
      </Modal.Content>
    </Modal>
  );
}

LinkedInMainPopup.defaultProps = {
  linkedInAlreadyOpted: false,
  setComponentToShow: () => {},
  onAllowCb: () => {}
};

LinkedInMainPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
  linkedInAlreadyOpted: PropTypes.bool,
  setComponentToShow: PropTypes.func,
  onAllowCb: PropTypes.func
};

export default LinkedInMainPopup;
