import { linkedIn } from "redux/constants";

const linkedInDataLoadingReducer = (state = false, action) => {
  switch (action.type) {
    case linkedIn.FETCH_LINKEDIN_DATA:
      return true;
    case linkedIn.FETCH_LINKEDIN_DATA_SUCCESS:
    case linkedIn.FETCH_LINKEDIN_DATA_FAILURE:
      return false;
    default:
      return state;
  }
};

export default linkedInDataLoadingReducer;
