import { designatedBy } from "redux/constants";

const designatedByReducer = (state = [], action) => {
  switch (action.type) {
    case designatedBy.FETCH_DESIGNATEDBY_SUCCESS:
      return action.data?.designatedByList;
    case designatedBy.FETCH_DESIGNATEDBY_FAILURE:
      return null;
    default:
      return state;
  }
};

export default designatedByReducer;
