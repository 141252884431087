import { profileViews } from "redux/constants";

const getCVBioMaterialsViews = (payload) => {
  return {
    type: profileViews.FETCH_CV_BIO_MATERIALS_VIEWS_DETAILS,
    payload
  };
};

const setCVBioMaterialsViewsSuccess = (data) => ({
  type: profileViews.FETCH_CV_BIO_MATERIALS_VIEWS_DETAILS_SUCCESS,
  data
});

const setCVBioMaterialsViewsFailure = (error) => ({
  type: profileViews.FETCH_CV_BIO_MATERIALS_VIEWS_DETAILS_FAILURE,
  error
});

export { getCVBioMaterialsViews, setCVBioMaterialsViewsSuccess, setCVBioMaterialsViewsFailure };
