import { ssRecentCases } from "redux/constants";

const ssRecentCasesReducer = (state = {}, action) => {
  switch (action.type) {
    case ssRecentCases.FETCH_RECENT_SSCASE_SUCCESS: {
      return { ...action.data };
    }
    default:
      return state;
  }
};

export default ssRecentCasesReducer;
