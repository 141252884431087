import { proposalBios } from "redux/constants";

const proposalBiosLoadingReducer = (state = true, action) => {
  switch (action.type) {
    case proposalBios.FETCH_PROPOSAL_BIOS:
      return true;
    case proposalBios.FETCH_PROPOSAL_BIOS_FAILURE:
    case proposalBios.FETCH_PROPOSAL_BIOS_SUCCESS:
      return false;
    default:
      return state;
  }
};

export default proposalBiosLoadingReducer;
