import { call, put } from "redux-saga/effects";
import { delete as del } from "redux/api";
import { setDeleteSkillSummarySuccess, setDeleteSkillSummaryError } from "redux/actions/skills";

function* handleDeleteSkillSummary({ payload }) {
  const { data, error } = yield call(del, payload);
  if (error) {
    yield put(setDeleteSkillSummaryError(error));
  } else {
    yield put(setDeleteSkillSummarySuccess(data));
  }
}

export default handleDeleteSkillSummary;
