import { Card } from "semantic-ui-react";
import PropTypes from "prop-types";
import { Icon } from "components/common";

import { formatYearByMoment } from "helpers/utils";

import "./styles.scss";

/**
 * EducationItem Component
 *
 * @param  {string} school
 * @param  {string} degree
 * @param  {string} major
 *
 * @return element containing user's education information
 *
 * @example
 *
 * <EducationItem degree="B.Tech" school="School" major="CSE" start="Jan 10 2014" end="Jan 19 2018"  />
 */
function EducationItem({ degree, school, fieldOfStudy, start, end }) {
  return (
    <Card.Content>
      <div className="education__item edu__item">
        <div className="education__icon d-flex justify-content-center align-items-center">
          <Icon name="graduateCap" />
        </div>
        <div className="education__content">
          {school && (
            <div className="education__heading edu__school">
              <strong>{school}</strong>
            </div>
          )}
          {degree && <div className="education__text edu__degree">{degree}</div>}
          {fieldOfStudy && <div className="education__text edu__major">{fieldOfStudy}</div>}
          {start !== "NULL" && end !== "NULL" && (
            <div className="education__text--sm edu__duration">
              {formatYearByMoment(start, "YYYY")} - {formatYearByMoment(end, "YYYY")}
            </div>
          )}
        </div>
      </div>
    </Card.Content>
  );
}

EducationItem.defaultProps = {
  degree: "",
  school: "",
  fieldOfStudy: "",
  start: "",
  end: ""
};

EducationItem.propTypes = {
  degree: PropTypes.string,
  school: PropTypes.string,
  fieldOfStudy: PropTypes.string,
  start: PropTypes.number,
  end: PropTypes.number
};

export default EducationItem;
