import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { Input, Icon } from "semantic-ui-react";
import { Icon as ProfilesCustomIcon } from "components/common";
import "./styles.scss";

/**
 * TopicPicker
 *
 * @param  {string} currentValue Topic Name from Link DB
 * @param  {object} topicsFinder topics list data
 * @param  {bool} topicsFinderLoading indicator flag whether topics list data is still loading
 * @param  {func} callback function fires when picker value changes
 * @param  {bool} readOnly indicates that this picker has no other function than display and read-only
 *
 * @returns TopicPicker component
 */

const TopicPicker = ({ currentValue, callback, readOnly, onClick }) => {
  // local states
  const [selectedItem, setSelectedItem] = useState("");
  const [isDisabled, setIsDisabled] = useState(readOnly || !selectedItem);

  useEffect(() => {
    setSelectedItem(currentValue);
  }, [currentValue]);

  useEffect(() => {
    setIsDisabled(readOnly || !!selectedItem);
  }, [selectedItem]);

  // Delete
  const handleDelete = () => {
    setSelectedItem();
    callback();
  };

  const customIcon = (
    <i className="icon topic-picker__inputIconSlot">
      <div className="d-flex align-items-center justify-content-center topic-picker__iconContainer">
        <ProfilesCustomIcon name="shareLg" size={18} />
      </div>
    </i>
  );

  return (
    <div className="topic-picker position-relative">
      <Input
        data-testid="topic-picker"
        fluid
        placeholder="Add Topic"
        icon={customIcon}
        disabled={isDisabled}
        onClick={onClick}
        readOnly
      />
      {selectedItem && (
        <span className="mr-1 float-left chip position-absolute topic-picker__chip" data-testid="chip">
          <span className="topic-picker__chip__name">
            <strong>{selectedItem || currentValue}</strong>
          </span>
          {!readOnly && (
            <Icon
              name="delete"
              data-testid="delete"
              onClick={handleDelete}
              className="topic-picker__chip__delete cursor-pointer"
            />
          )}
        </span>
      )}
    </div>
  );
};

TopicPicker.defaultProps = {
  currentValue: "",
  callback: () => {},
  readOnly: false
};

TopicPicker.propTypes = {
  currentValue: PropTypes.string,
  callback: PropTypes.func,
  readOnly: PropTypes.bool
};

export default TopicPicker;
