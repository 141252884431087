import React from "react";
import { Label } from "semantic-ui-react";
import PropTypes from "prop-types";
import "./styles.scss";

/**
 *
 * @param {string} text header title
 * @param {string} backgroundColor edit button (optional to pass)
 *
 * @returns Pill component with the props passed
 *
 * @example
 *
 * <Pill
 *  text="Alumni"
 *  backgroundColor="orange"
 * />
 */
function Pill({ text, backgroundColor }) {
  return (
    <Label className="pill text-align-center" style={{ backgroundColor: `${backgroundColor}` }}>
      {text}
    </Label>
  );
}

Pill.defaultProps = {
  backgroundColor: ""
};

Pill.propTypes = {
  text: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string
};

export default Pill;
